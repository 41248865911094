import React, { ReactNode } from 'react'
import { useColorModeValue } from 'native-base'
import { TagState } from './tag-pill'
import { StyleSheet } from 'react-native'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { InterfaceButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { LINE_WIDTH } from '../../../constants/constants'
import { Box } from '../../common/box/box'
import { Button } from '../../common/nb-button/nb-button'

type BoxButtonProps = InterfaceBoxProps | InterfaceButtonProps

type TagBaseProps = BoxButtonProps & {
  tagState: TagState
  onEditableTagPress: () => void
  onPassiveTagPress?: () => void
  children: ReactNode
}

// For some reason using these in the StyleSheet is flaky.
const TAG_BORDER_RADIUS = 'full'
const TAG_PADDING_X = 2
const TAG_PADDING_Y = 2
const TAG_MARGIN_RIGHT = 2
const TAG_MARGIN_BOTTOM = 2

// This is the component that wraps the tag contents
// We don't want nested buttons, or buttons that do nothing.
// So for the tag base component:
// * tagState === 'passive' -> Box (just display)
// * tagState === 'editable' -> Button (click to edit)
// * tagState === 'editing' -> Box (contains buttons)
export const TagWrapper = ({
  tagState,
  onEditableTagPress, // Edit the tag
  onPassiveTagPress, // Go to the tag page
  children,
  mr = TAG_MARGIN_RIGHT,
  mb = TAG_MARGIN_BOTTOM,
  pb = TAG_PADDING_Y,
  pl = TAG_PADDING_X,
  pr = TAG_PADDING_X,
  pt = TAG_PADDING_Y,
  height,
  width,
}: TagBaseProps) => {
  // HOOKS
  const color = useColorModeValue(BLACK, WHITE)
  const invertedColor = useColorModeValue(WHITE, BLACK)

  // VARS
  const isPassive = tagState === 'passive'
  const isEditable = tagState === 'editable'
  const backgroundColor = isPassive ? 'transparent' : invertedColor
  const borderColor = color

  if (isEditable) {
    return (
      <Button
        height={height}
        width={width}
        borderRadius={TAG_BORDER_RADIUS}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        onPress={onEditableTagPress}
        pl={pl}
        pr={pr}
        pt={pt}
        pb={pb}
        mr={mr}
        mb={mb}
        style={styles.tagBase}
      >
        {children}
      </Button>
    )
  } else if (isPassive) {
    return (
      <Button
        height={height}
        width={width}
        borderRadius={TAG_BORDER_RADIUS}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        onPress={onPassiveTagPress}
        pl={pl}
        pr={pr}
        pt={pt}
        pb={pb}
        mr={mr}
        mb={mb}
        style={styles.tagBase}
        disabled={!onPassiveTagPress}
        borderWidth={LINE_WIDTH}
      >
        {children}
      </Button>
    )
  } else {
    return (
      <Box
        height={height}
        width={width}
        borderRadius={TAG_BORDER_RADIUS}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        pl={pl}
        pr={pr}
        pt={pt}
        pb={pb}
        mr={mr}
        mb={mb}
        style={styles.tagBase}
      >
        {children}
      </Box>
    )
  }
}

const styles = StyleSheet.create({
  tagBase: {
    borderWidth: LINE_WIDTH,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
