import React from 'react'
import { Friend } from '../../../../../api/_openapi'
import { useColorModeValue } from 'native-base'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { SansText } from '../../../components/common/copy/text-sans'
import { ButtonIcon } from '../../../components/common/buttons/button-icon'
import { ActionSheetMenu } from '../../../components/common/action-sheet/action-sheet-menu'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { Row } from '../../../components/common/row/row'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/common/nb-button/nb-button'

type FriendListItemProps = {
  item: Friend
  index: number
  accept: (friend: Friend) => void
  reject: (friend: Friend) => void
  unfriend: (friend: Friend) => void
  add: (friend: Friend) => void
}

export const FriendListItem = ({
  item,
  index,
  accept,
  reject,
  unfriend,
  add,
}: FriendListItemProps) => {
  const [actionSheetIsOpen, setActionSheetIsOpen] = React.useState(false)
  const color = useColorModeValue(WHITE, BLACK)
  const { t } = useTranslation()
  const backgroundColor = useColorModeValue(BLACK, WHITE)

  const actionSheetMenuItems = []

  if (item.status === 'accepted') {
    //unfriend
    actionSheetMenuItems.push({
      label: t('friends.unfriend'),
      closesMenu: true,
      onPress: () => {
        unfriend(item)
      },
    })
  } else if (item.status === 'pending') {
    if (item.userInitiated) {
      //cancel request
      actionSheetMenuItems.push({
        label: 'Delete',
        closesMenu: true,
        onPress: () => {
          unfriend(item)
        },
      })
    } else {
      //accept
      actionSheetMenuItems.push({
        label: 'Accept',
        closesMenu: true,
        onPress: () => {
          accept(item)
        },
      })
      //reject
      actionSheetMenuItems.push({
        label: 'Reject',
        closesMenu: true,
        onPress: () => {
          reject(item)
        },
      })
    }
  } else if (item.status === 'none') {
    //add
    actionSheetMenuItems.push({
      label: 'Add',
      closesMenu: true,
      onPress: () => {
        add(item)
      },
    })
  }

  //close
  actionSheetMenuItems.push({
    label: t('common.cancel'),
    closesMenu: true,
    onPress: () => {},
  })

  return (
    <>
      <Row key={index} alignItems={'center'} justifyContent={'space-between'}>
        <Row marginRight={2} alignItems={'center'}>
          <AvatarCircle
            size={'md'}
            source={{
              uri: item.imageUrl || undefined,
            }}
            name={item.username || ''}
          />
          <SansText>{item.username}</SansText>
        </Row>
        {item.status === 'pending' && item.userInitiated === false && (
          <SansText>{item.message}</SansText>
        )}
        <Row alignItems={'center'}>
          {item.status === 'pending' && (
            <Button
              borderRadius={10}
              size={'xs'}
              height={5}
              color={color}
              backgroundColor={backgroundColor}
              disabled={item.userInitiated}
              mr={5}
              onPress={() => {
                if (!item.userInitiated) {
                  accept(item)
                }
              }}
            >
              {item.userInitiated ? 'Pending' : 'Confirm'}
            </Button>
          )}
          <ButtonIcon
            iconKey="ellipsis"
            size={'xs'}
            disabled={false}
            variant={'ghost'}
            onPress={() => {
              setActionSheetIsOpen(true)
            }}
          />
        </Row>
      </Row>
      {actionSheetIsOpen && (
        <ActionSheetMenu
          isOpen={actionSheetIsOpen}
          onClose={() => {
            setActionSheetIsOpen(false)
          }}
          menuItems={actionSheetMenuItems || []}
        />
      )}
    </>
  )
}
