import {
  UsernameAvailabilityService,
  FastUser,
  FastUserService,
  FastTag,
} from '../../../../../api/_openapi'
import { undecorateId } from '../../../../../api/decorate-ids'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { FAMOUS_USERS } from '../../../constants/constants'
import { setFamousUsers } from '../../ui/ui'

// Check if username is available using the UsernameAvailabilityService
export function checkUsernameAvailability(username: string): Promise<boolean> {
  return UsernameAvailabilityService.usernameAvailability(username)
    .then((res) => {
      return Boolean(res.available)
    })
    .catch((err) => {
      console.log('usernameAvailability error: ', err)
      elsewhereToast.showToast({
        title: 'toast.checkUsernameAvailabilityError.title',
        description: err.message,
        status: 'error',
      })
      return false
    })
}

export function fetchFastUserById(id: string): Promise<FastUser | null> {
  const userId = undecorateId(id)
  return FastUserService.fastUser(userId).catch((err) => {
    console.log('apiUsersIdGet error: ', err)
    elsewhereToast.showToast({
      title: 'toast.loadingUserError.title',
      description: err.message,
      status: 'error',
    })
    return null
  })
}

export async function fetchFamousDreamers(): Promise<FastUser[]> {
  return FastUserService.fastUsers()
}

export async function fetchFamousDreamer(
  username: string,
): Promise<FastTag | null> {
  return null
}
