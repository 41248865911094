import React, { useState, useEffect } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { RouteProp } from '@react-navigation/native'
import { MainStackParamList } from '../../navigation/types'
import { DreamFeedList } from '../../components/composite/feed/feed-list-dream'
import { selectUser } from '../../ducks/user/user'
import {
  fetchGroupDreams,
  syncGroupDreams,
} from '../../ducks/dream-tag/thunks/dream-thunks'
import { LoadingPage } from '../../components/layout/loading-page'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import { useDispatch } from 'react-redux'
import { useGroupDreams } from '../../hooks/useGroupDreams'

export type FeedNavigationProp = MainStackNavigationProp<
  'PrivateFeed' | 'GroupFeed'
>
export type FeedRouteProp = RouteProp<
  MainStackParamList,
  'PrivateFeed' | 'GroupFeed'
>

export default function GroupFeed({
  route,
}: MainStackScreenProps<'GroupFeed'>) {
  // ROUTE
  const groupIdFromRoute = route.params?.groupId
  const [groupDreamsLoading, setGroupDreamsLoading] = useState(true)
  const dispatch = useDispatch<any>()

  // SELECTORS
  const user = useSelector(selectUser)
  const { dreams, loading } = useGroupDreams(groupIdFromRoute)

  // LOAD GROUP DREAMS
  useEffect(() => {
    if (!groupIdFromRoute) {
      return
    }

    setGroupDreamsLoading(true)
    dispatch(fetchGroupDreams(groupIdFromRoute)).then(() => {
      setGroupDreamsLoading(false)
    })
  }, [groupIdFromRoute])

  useEffect(() => {
    if (!groupIdFromRoute) {
      return
    }
  }, [groupIdFromRoute])

  const onRefresh = () => {
    if (!groupIdFromRoute) {
      return
    }
    dispatch(
      syncGroupDreams(groupIdFromRoute, new Date('1900-01-01').toISOString()),
    )
  }

  if (groupDreamsLoading || loading || !user) {
    return <LoadingPage hasQuote={true} />
  } else {
    return (
      <>
        <DreamFeedList entries={dreams} refresh={onRefresh} appUser={user} />
      </>
    )
  }
}
