import React, { useEffect, useState, useMemo } from 'react'
import { Platform } from 'react-native'
import { useSelector } from '../../ducks/root-reducer'
import { selectFastDreams } from '../../ducks/dream-tag/dream-tag'
import { StreakFreezeService } from '../../../../api/_openapi'
import { selectUser } from '../../ducks/user/user'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { useTranslation } from 'react-i18next'
import {
  findLatestStreakLength,
  getStreakInfo,
  isStreakInDanger,
} from './getStreakInfo'
import { getContentWidth } from '../../modules/ui-helpers/ui-helpers'
import StreakBox from '../../components/composite/streak/streak-box'
import { titleCase } from '../../modules/strings/title-case'
import { PADDING_HORIZONTAL_PIXELS } from '../../constants/constants'

export type DateType = {
  type: string
  date: string
}

function Streak() {
  // STATE
  const [dates, setDates] = useState<DateType[]>([])

  // SELECTORS
  const user = useSelector(selectUser)
  const dreams = useSelector(selectFastDreams)

  // HOOKS
  const { t } = useTranslation()

  // VARS
  const isWeb = Platform.OS === 'web'
  const userLanguage = user?.languageCode || 'en'
  const contentWidth = getContentWidth(Platform.OS)
  const showingProfile = contentWidth > 800 && isWeb
  const boxWidth = showingProfile
    ? 300
    : contentWidth - PADDING_HORIZONTAL_PIXELS * 2

  // MEMOS
  const streakInfo = useMemo(
    () => getStreakInfo(dates, userLanguage),
    [dates, userLanguage],
  )

  // FUNCTIONS
  const freezeStreak = () => {
    if (!user) return
    StreakFreezeService.createStreakFreeze(user.id, {
      date: formatDateForDatabase(new Date()),
    })
      .then((freezeStreak) => {
        setDates((prevState) => {
          const date = {
            type: 'streak',
            date: freezeStreak.date,
          }
          if (prevState) {
            return [...prevState, date]
          }
          return [date]
        })
      })
      .catch((err) => {
        console.log('Error freezing streak', err)
      })
  }

  // EFFECTS
  useEffect(() => {
    if (dreams && user?.id) {
      StreakFreezeService.streakFreezes(user.id)
        .then((streaks) => {
          const simpleDreams: DateType[] = dreams.map((dream) => {
            return {
              type: 'dream',
              date: dream.date,
            }
          })
          const simpleStreaks: DateType[] = streaks
            ? streaks.map((streak) => {
                return {
                  type: 'streak',
                  date: streak.date,
                }
              })
            : []
          const allDates = simpleDreams.concat(simpleStreaks)
          setDates(allDates)
        })
        .catch((err) => {
          console.log('Error fetching streaks', err)
        })
    }
  }, [user, dreams])

  return (
    <StreakBox
      width={boxWidth}
      onFreezeStreak={freezeStreak}
      streakSubtitle={titleCase(t('dashboard.dreamStreak'), userLanguage)}
      freezeBtnLabel={t('dashboard.freeze')}
      streakInfo={streakInfo}
      streakLength={findLatestStreakLength(dates)}
      streakIsInDanger={isStreakInDanger(dates)}
    />
  )
}

export default Streak
