import React from 'react'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { ButtonIcon } from '../buttons/button-icon'
import { useImageSize } from '../../../hooks/useImageDimensions'
import { scaleCloudinaryImageWidth } from '../../../modules/image-helpers/image-helpers'
import { MD_THUMB_RES } from '../../../constants/ui-constants'
import { Row } from '../row/row'
import { Box } from '../box/box'
import { NbImage } from '../image/nb-image'

const IMG_PADDING = 8

type ImageArrayEditableProps = {
  image: ImageOnObject
  onDelete: (image: ImageOnObject) => void
  width: number
}

export const ImageSquare = ({
  image,
  onDelete,
  width,
}: ImageArrayEditableProps) => {
  const scaledImageUrl = scaleCloudinaryImageWidth(
    image.url || '',
    MD_THUMB_RES,
  )

  const { width: imageWidth, height: imageHeight } = useImageSize(
    scaledImageUrl || '',
  )

  // Set height and width based on orientation
  const longestSideLen = Math.max(imageWidth, imageHeight, width)
  const shortestSideLen = Math.min(imageWidth, imageHeight)
  const orientation = imageHeight > imageWidth ? 'portrait' : 'landscape'
  const realImageWidth =
    orientation === 'landscape'
      ? width
      : (width * shortestSideLen) / longestSideLen
  const realImageHeight =
    orientation === 'portrait'
      ? width
      : (width * shortestSideLen) / longestSideLen

  return (
    <Box width={width} flexDirection={'column'} justifyContent={'flex-start'}>
      <Row justifyContent={'flex-end'}>
        <ButtonIcon
          iconKey={'close'}
          variant={'ghost'}
          onPress={() => {
            onDelete(image)
          }}
          bgColor={'transparent'}
          size={'xs'}
        />
      </Row>
      <Row
        justifyContent={'center'}
        alignItems={'center'}
        height={width}
        width={width}
      >
        <NbImage
          source={{ uri: scaledImageUrl }}
          alt={image.alt || 'Picked image'}
          width={realImageWidth - IMG_PADDING * 2}
          height={realImageHeight - IMG_PADDING * 2}
        />
      </Row>
    </Box>
  )
}
