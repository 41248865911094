import React, { useState, useEffect } from 'react'
import { ScrollView } from 'react-native'
import { LoadingPage } from '../../components/layout/loading-page'
import { RouteProp } from '@react-navigation/native'
import { useSelector } from '../../ducks/root-reducer'
import { selectInterpretationStyles } from '../../ducks/ui/ui'
import { FeedEntryGeneric } from '../../components/composite/feed/feed-entry-generic'
import { interpreters } from '../../constants/ui-constants'
import { getInterpStyleKeyForAnalysisResult } from '../../modules/analysis-helpers/interpretation-helpers'
import { useTranslation } from 'react-i18next'
import { InsightService } from '../../../../api/_openapi'
import { copyToClipboard } from '../../modules/ui-helpers/copy-to-clipboard'
import { markInsightAsDeleted } from '../../ducks/dream-tag/functions/insight-functions'
import { ActionSheetMenuItem } from '../../components/common/action-sheet/action-sheet-menu'
import { BodyTextMarkdown } from '../../components/common/copy/text-body-markdown'
import { ContentDivider } from '../../components/layout/content-divider'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { elsewhereToast } from '../../components/common/toast/toast'
import { dreamservicesByLanguage } from '../../i18n/dream-services'
import { DreamWorkerFooter } from './dreamworker-footer'
import i18n from '../../i18n/i18nnext'
import {
  InterpretationStyleKey,
  SupportedLanguage,
} from '../../../../api/frontend-types'
import {
  MainStackScreenProps,
  MainStackNavigationProp,
  MainStackParamList,
} from '../../navigation/types'

export type InsightViewNavigationProp =
  MainStackNavigationProp<'PrivateInterpretationView'>
export type InsightViewRouteProp = RouteProp<
  MainStackParamList,
  'PrivateInterpretationView'
>

// 403 if the user has no access to the insight

export type InsightPageProps = {}

export default function InterpretationView({
  navigation,
  route,
}: MainStackScreenProps<'PrivateInterpretationView'>) {
  // STATE
  const [interpIsLoading, setInterpIsLoading] = useState<boolean>(true)
  const [interpText, setInterpText] = React.useState<string>('')
  const [diaryInterpretation, setDiaryInterpretation] =
    React.useState<string>('')
  const [styleKey, setStyleKey] = useState<InterpretationStyleKey | null>(null)

  // HOOKS
  const { t } = useTranslation()

  // SELECTORS
  const interpStyles = useSelector(selectInterpretationStyles)

  // ROUTE
  const { id, language } = route.params

  // VARS
  const userLanguage = (i18n.resolvedLanguage || 'en') as SupportedLanguage
  const interpStyleKey = styleKey || 'standard'
  const interpreter = interpreters[interpStyleKey] || interpreters.standard
  const { img: authorImg, i18nKey } = interpreter
  const styleLabel = t(i18nKey)
  const interpLanguage = language || userLanguage
  const dreamWorkersAvailable = Boolean(dreamservicesByLanguage[userLanguage])

  // COMPONENTS
  const ExtraFooter: JSX.Element | undefined = dreamWorkersAvailable ? (
    <DreamWorkerFooter language={userLanguage} />
  ) : undefined

  useEffect(() => {
    InsightService.fastInsight(id)
      .then((insight) => {
        const key = getInterpStyleKeyForAnalysisResult(insight, interpStyles)
        const text = insight.insight || ''
        setStyleKey(key)
        setInterpText(text)
        setDiaryInterpretation(insight.data['diaryInterpretation'] || '')
        setInterpIsLoading(false)
      })
      .catch(() => {
        console.log('Error fetching insight')
        setInterpIsLoading(false)
      })
  }, [id])

  const actionSheetMenuItems: ActionSheetMenuItem[] = [
    {
      label: t('common.copyToClipboard'),
      closesMenu: true,
      onPress: () => {
        copyToClipboard(interpText, t)
      },
    },
    {
      label: t('common.delete'),
      closesMenu: true,
      onPress: () => {
        if (id) {
          markInsightAsDeleted(id)
            .then((result) => {
              if (result) {
                navigation.goBack()
                elsewhereToast.showToast({
                  description: t('common.deleted'),
                  status: 'success',
                })
              }
            })
            .catch((e) => {
              console.log('Error deleting insight')
              console.error(e)
            })
        }
      },
    },
    {
      label: t('common.cancel'),
      closesMenu: true,
      onPress: () => {},
    },
  ]

  if (interpIsLoading) {
    return <LoadingPage title={t('loading.loadingGeneric')} />
  } else {
    const DescriptionComponent = () => {
      return (
        <>
          <BodyTextMarkdown>{interpText}</BodyTextMarkdown>
          <ContentDivider />
          <HeadingSmall mt={0}>Waking Life Associations</HeadingSmall>
          <BodyTextMarkdown>{diaryInterpretation}</BodyTextMarkdown>
        </>
      )
    }

    return (
      <ScrollView>
        <FeedEntryGeneric
          inGroup={false}
          description={interpText}
          descriptionComponent={
            diaryInterpretation ? <DescriptionComponent /> : undefined
          }
          avatarLabel={styleLabel}
          avatarCircleSrc={authorImg}
          actionSheetMenuItems={actionSheetMenuItems}
          language={interpLanguage as SupportedLanguage}
          avatarLabelLanguage={userLanguage as SupportedLanguage}
          extraFooter={ExtraFooter}
        />
      </ScrollView>
    )
  }
}
