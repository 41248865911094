/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagDateCount } from '../models/TagDateCount';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagDateCountService {
  /**
   * Retrieves a TagDateCount resource.
   * Retrieves a TagDateCount resource.
   * @param id TagDateCount identifier
   * @param startDate
   * @param endDate
   * @param tagId
   * @param tagName
   * @param tagType
   * @param tagLanguage
   * @returns TagDateCount TagDateCount resource
   * @throws ApiError
   */
  public static tagDreamDates(
    id: string,
    startDate?: string,
    endDate?: string,
    tagId?: string,
    tagName?: string,
    tagType?: string,
    tagLanguage?: string,
  ): CancelablePromise<TagDateCount> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/groups/{id}/tag-dream-dates',
      path: {
        'id': id,
      },
      query: {
        'startDate': startDate,
        'endDate': endDate,
        'tagId': tagId,
        'tagName': tagName,
        'tagType': tagType,
        'tagLanguage': tagLanguage,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
