import React, { useEffect } from 'react'
import { useSelector } from '../../../ducks/root-reducer'
import { DreamFeedList } from '../../../components/composite/feed/feed-list-dream'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { syncGroupDreams } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { selectUser } from '../../../ducks/user/user'
import {
  MainStackNavigationProp,
  MainStackParamList,
  MainStackScreenProps,
} from '../../../navigation/types'
import { RouteProp } from '@react-navigation/native'
import { useDreams } from '../../../hooks/useDreams'
import { selectLastSyncTime } from '../../../ducks/dream-tag/dream-tag'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'

export type PrivateDraftsFeedNavigationProp =
  MainStackNavigationProp<'PrivateDraftsFeed'>
export type PrivateDraftsFeedRouteProp = RouteProp<
  MainStackParamList,
  'PrivateDraftsFeed'
>

export default function PrivateDraftsFeed({
  route,
}: MainStackScreenProps<'PrivateDraftsFeed'>) {
  // HOOKS

  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const groupIdFromRoute = route.params?.groupId
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = groupIdFromRoute || privateGroupId
  const { dreams: draftDreams, loading } = useDreams(activeGroupId, true)

  // SELECTORS
  const user = useSelector(selectUser)
  const lastSyncTime = useSelector(selectLastSyncTime)

  const onRefreshUserDreams = () => {
    if (!user || !user.userGroupId) return
    const time = lastSyncTime ? new Date(lastSyncTime) : new Date('1900-01-01')
    dispatch(syncGroupDreams(user.userGroupId, time.toISOString()))
  }

  useEffect(() => {
    onRefreshUserDreams()
  }, [user?.userGroupId])

  if (!user || loading) return null

  return (
    <DreamFeedList
      entries={draftDreams}
      refresh={onRefreshUserDreams}
      noEntriesMessage={t('emptyFeedPage.noDrafts')}
      appUser={user}
      showType={true}
    />
  )
}
