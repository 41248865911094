import React from 'react'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { X_TICKS_PER_RANGE } from '../../config/frontend-config'
import { dreamsToChartData } from '../../components/composite/chart/helpers/dreams-to-chart-data'
import {
  ChartPadding,
  TimelineChart,
} from '../../components/composite/chart/timeline-chart'
import {
  DateIntervalKey,
  DateRangeWithKey,
} from '../../../../api/frontend-types'
import { getDreamsInRange } from '../../modules/date-helpers/date-helpers'
import { sortDreamsByDate } from '../../ducks/dream-tag/modules/dream-helpers'
import { FastDream } from '../../../../api/_openapi'
import i18n from '../../i18n/i18nnext'

type DreamsPerDayChartProps = {
  dateRangeWithKey: DateRangeWithKey
  dreams: FastDream[]
}

export const DreamsPerDayChart = ({
  dateRangeWithKey,
  dreams,
}: DreamsPerDayChartProps) => {
  // HOOKS
  const { t } = useTranslation()
  const userLineColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const dreamsInRange = getDreamsInRange(
    dreams,
    dateRangeWithKey.range.start,
    dateRangeWithKey.range.end,
  )
  const sortedDreamsInRange = sortDreamsByDate(dreamsInRange, 'asc')
  const userLanguage = i18n.resolvedLanguage || 'en'
  const chartLegend = t('chartsPage.dreamsPerPeriod.legend')
  const chartData = dreamsToChartData(
    {
      id: '',
      dateCounts: {
        ...sortedDreamsInRange.reduce((acc: any, dream) => {
          const date = dream.date
          acc[date] = acc[date] ? acc[date] + 1 : 1
          return acc
        }, {}),
      },
      total: sortedDreamsInRange.length,
    },
    userLineColor,
    chartLegend,
  )
  const chartPadding: ChartPadding = {
    left: 50,
    right: 30,
    top: 30,
  }
  const getChartTitle = (dateRangeKey: DateIntervalKey) => {
    return t(`chartsPage.dreamsPerPeriod.${dateRangeKey.toLowerCase()}`)
  }

  return (
    <TimelineChart
      chartData={chartData}
      loading={false}
      chartPadding={chartPadding}
      tickCount={X_TICKS_PER_RANGE[dateRangeWithKey.key]}
      getChartTitle={getChartTitle}
      dateRangeWithKey={dateRangeWithKey}
      userLanguage={userLanguage}
      bucketizeMode={'sum'}
    />
  )
}
