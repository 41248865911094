import * as React from 'react'
import { Path, Circle } from 'react-native-svg'
import { Icon, IconProps } from '../../../components/common/icon/icon'

//////////////////////////////////
// TAG ICONS (20 x 20 viewBox)
//////////////////////////////////

////////////// DELETE TAG /////////////

export const IconDeleteTag = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="m6 6 8 8M14 6l-8 8"
        fill={fill || 'none'}
        stroke={'currentColor'}
        strokeLinejoin="round"
      />
    </Icon>
  )
}

///////////// SETTING /////////////

export const IconSetting = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M15 9c0 4-5 7-5 7s-5-3-5-7a5 5 0 0 1 10 0Z"
        fill={fill || 'none'}
        stroke={'currentColor'}
        strokeLinejoin="round"
      />
      <Circle
        cx={10}
        cy={9}
        r={2}
        stroke={'currentColor'}
        fill={fill || 'none'}
      />
    </Icon>
  )
}

export const IconSettingOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M15 9c0 4-5 7-5 7s-5-3-5-7a5 5 0 0 1 10 0Z"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Circle
        cx={10}
        cy={9}
        r={2}
        stroke={'currentColor'}
        fill={fill || 'none'}
      />
      <Circle cx={10} cy={10} r={9.5} stroke={'currentColor'} fill={'none'} />
    </Icon>
  )
}

///////////// CHARACTER /////////////

export const IconCharacter = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle
        cx={10}
        cy={7}
        r={2}
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Path
        d="M10 9a4 4 0 0 0-4 4v1h8v-1a4 4 0 0 0-4-4Z"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
    </Icon>
  )
}

export const IconCharacterOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle
        cx={10}
        cy={7}
        r={2}
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Path
        d="M10 9a4 4 0 0 0-4 4v1h8v-1a4 4 0 0 0-4-4Z"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Circle cx={10} cy={10} r={9.5} stroke="currentColor" fill={'none'} />
    </Icon>
  )
}

///////////// ANIMAL /////////////

export const IconAnimal = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="m16 15-1.4-2m0 0L9.589 5.841A1.973 1.973 0 0 0 6 6.972V9a4 4 0 0 0 4 4h4.6ZM6 7H4M10 13v2"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Path
        d="M6 9V6.973A1.973 1.973 0 0 1 9.589 5.84L14.6 13H10a4 4 0 0 1-4-4Z"
        fill={'none'}
      />
      <Path
        d="M 13 10 A 3 3 226 0 1 8 8"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconAnimalOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle cx={10} cy={10} r={9.5} stroke="currentColor" fill={'none'} />
      <Path
        d="m16 15-1.4-2m0 0L9.589 5.841A1.973 1.973 0 0 0 6 6.972V9a4 4 0 0 0 4 4h4.6ZM6 7H4M10 13v2"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Path
        d="M6 9V6.973A1.973 1.973 0 0 1 9.589 5.84L14.6 13H10a4 4 0 0 1-4-4Z"
        fill={'none'}
      />
      <Path
        d="M 13 10 A 3 3 226 0 1 8 8"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

///////////// EMOTION /////////////

export const IconEmotion = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle
        cx={10}
        cy={10}
        r={6}
        stroke="currentColor"
        fill={fill || 'none'}
      />
      <Path
        d="M13 10a3 3 0 1 1-6 0"
        stroke="currentColor"
        fill={fill || 'none'}
      />
      <Circle cx={8} cy={8} r={1} fill="currentColor" />
      <Circle cx={12} cy={8} r={1} fill="currentColor" />
    </Icon>
  )
}

export const IconEmotionOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle
        cx={10}
        cy={10}
        r={6}
        stroke="currentColor"
        fill={fill || 'none'}
      />
      <Circle cx={10} cy={10} r={9.5} stroke="currentColor" fill="none" />
      <Path
        d="M13 10a3 3 0 1 1-6 0"
        stroke="currentColor"
        fill={fill || 'none'}
      />
      <Circle cx={8} cy={8} r={1} fill="currentColor" />
      <Circle cx={12} cy={8} r={1} fill="currentColor" />
    </Icon>
  )
}

///////////// ACTION /////////////

export const IconAction = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="m11 5 5 5-5 5M4 8h8M4 12h8M6 10h8"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconActionOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle cx={10} cy={10} r={9.5} stroke="currentColor" fill={'none'} />
      <Path
        d="m11 5 5 5-5 5M4 8h8M4 12h8M6 10h8"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

// OTHER

export const IconOther = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M5 9V5h4l7 7-4 4-7-7Z"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Circle cx={8} cy={8} r={1} fill="currentColor" />
    </Icon>
  )
}

export const IconOtherOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Circle cx={10} cy={10} r={9.5} stroke="currentColor" fill={'none'} />
      <Path
        d="M5 9V5h4l7 7-4 4-7-7Z"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Circle cx={8} cy={8} r={1} fill="currentColor" />
    </Icon>
  )
}

///////////// THEME /////////////

export const IconTheme = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M6.5 4.5H13.5V15.5L10 13L6.5 15.5V4.5Z"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path d="M6.5 7.5L13.5 7.5" stroke={'currentColor'} fill={'none'} />
    </Icon>
  )
}

export const IconThemeOutlined = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M6.5 4.5H13.5V15.5L10 13L6.5 15.5V4.5Z"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path d="M6.5 7.5L13.5 7.5" stroke={'currentColor'} />
      <Circle cx="10" cy="10" r="9.5" stroke={'currentColor'} fill={'none'} />
    </Icon>
  )
}

///////////// DREAM_TYPE /////////////

export const IconDreamType = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M15.6716 11.9628L16.1441 12.1263C16.2002 11.9642 16.1693 11.7846 16.0622 11.6506C15.9551 11.5166 15.7867 11.4469 15.6162 11.4659L15.6716 11.9628ZM9.32842 4.03711L9.80093 4.20061C9.85702 4.0385 9.8261 3.85894 9.71899 3.72494C9.61189 3.59093 9.44356 3.52119 9.27307 3.54018L9.32842 4.03711ZM15.6162 11.4659C15.4141 11.4884 15.2085 11.5 15 11.5V12.5C15.2456 12.5 15.4881 12.4863 15.7269 12.4597L15.6162 11.4659ZM15 11.5C11.9624 11.5 9.5 9.03752 9.5 5.99995H8.5C8.5 9.5898 11.4101 12.5 15 12.5V11.5ZM9.5 5.99995C9.5 5.36894 9.60606 4.76376 9.80093 4.20061L8.85591 3.87361C8.62513 4.54052 8.5 5.25615 8.5 5.99995H9.5ZM4.5 9.99994C4.5 7.17082 6.63649 4.84002 9.38376 4.53404L9.27307 3.54018C6.02514 3.90193 3.5 6.6557 3.5 9.99994H4.5ZM10 15.4999C6.96243 15.4999 4.5 13.0375 4.5 9.99994H3.5C3.5 13.5898 6.41015 16.4999 10 16.4999V15.4999ZM15.1991 11.7993C14.4535 13.9539 12.4067 15.4999 10 15.4999V16.4999C12.8459 16.4999 15.2635 14.6712 16.1441 12.1263L15.1991 11.7993Z"
        fill={'currentColor'}
      />
    </Icon>
  )
}

export const IconDreamTypeOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props} fill={fill || 'none'}>
      <Path
        d="M15.6716 11.9628L16.1441 12.1263C16.2002 11.9642 16.1693 11.7846 16.0622 11.6506C15.9551 11.5166 15.7867 11.4469 15.6162 11.4659L15.6716 11.9628ZM9.32842 4.03711L9.80093 4.20061C9.85702 4.0385 9.8261 3.85894 9.71899 3.72494C9.61189 3.59093 9.44356 3.52119 9.27307 3.54018L9.32842 4.03711ZM15.6162 11.4659C15.4141 11.4884 15.2085 11.5 15 11.5V12.5C15.2456 12.5 15.4881 12.4863 15.7269 12.4597L15.6162 11.4659ZM15 11.5C11.9624 11.5 9.5 9.03752 9.5 5.99995H8.5C8.5 9.5898 11.4101 12.5 15 12.5V11.5ZM9.5 5.99995C9.5 5.36894 9.60606 4.76376 9.80093 4.20061L8.85591 3.87361C8.62513 4.54052 8.5 5.25615 8.5 5.99995H9.5ZM4.5 9.99994C4.5 7.17082 6.63649 4.84002 9.38376 4.53404L9.27307 3.54018C6.02514 3.90193 3.5 6.6557 3.5 9.99994H4.5ZM10 15.4999C6.96243 15.4999 4.5 13.0375 4.5 9.99994H3.5C3.5 13.5898 6.41015 16.4999 10 16.4999V15.4999ZM15.1991 11.7993C14.4535 13.9539 12.4067 15.4999 10 15.4999V16.4999C12.8459 16.4999 15.2635 14.6712 16.1441 12.1263L15.1991 11.7993Z"
        fill={'currentColor'}
      />
      <Circle cx="10" cy="10" r="9.5" stroke={'currentColor'} fill={'none'} />
    </Icon>
  )
}

export const IconMoon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M21.6461 14.6461L22.6107 14.9101C22.7055 14.5637 22.6072 14.193 22.3533 13.939C22.0993 13.685 21.7286 13.5868 21.3822 13.6816L21.6461 14.6461ZM9.35385 2.35382L10.3184 2.61781C10.4132 2.27137 10.3149 1.90069 10.061 1.64671C9.80698 1.39274 9.4363 1.29448 9.08987 1.38929L9.35385 2.35382ZM21.3822 13.6816C20.6245 13.8889 19.826 14 19 14V16C20.006 16 20.9821 15.8647 21.9101 15.6106L21.3822 13.6816ZM19 14C14.0294 14 10 9.97053 10 4.99997H8C8 11.0751 12.9249 16 19 16V14ZM10 4.99997C10 4.17394 10.111 3.37543 10.3184 2.61781L8.38932 2.08984C8.13532 3.0179 8 3.99392 8 4.99997H10ZM3 12C3 7.85526 5.80257 4.36256 9.61783 3.31835L9.08987 1.38929C4.42769 2.6653 1 6.93106 1 12H3ZM12 21C7.02944 21 3 16.9705 3 12H1C1 18.0751 5.92487 23 12 23V21ZM20.6816 14.3821C19.6374 18.1974 16.1447 21 12 21V23C17.0689 23 21.3347 19.5723 22.6107 14.9101L20.6816 14.3821Z"
        fill={'currentColor'}
      />
    </Icon>
  )
}

///////////// COLOR /////////////

export const IconColor = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M10.5 7L12.25 5.25C12.9404 4.55964 14.0596 4.55964 14.75 5.25V5.25C15.4404 5.94036 15.4404 7.05965 14.75 7.75L13 9.5M10.5 7L5.00004 12.5L5 15H7.50004L13 9.5M10.5 7L13 9.5"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M9 5.5L14.5 11"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M6 11.5L11 11.5"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconColorOutlined = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        d="M10.5 7L12.25 5.25C12.9404 4.55964 14.0596 4.55964 14.75 5.25V5.25C15.4404 5.94036 15.4404 7.05965 14.75 7.75L13 9.5M10.5 7L5.00004 12.5L5 15H7.50004L13 9.5M10.5 7L13 9.5"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M9 5.5L14.5 11"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M6 11.5L11 11.5"
        stroke={'currentColor'}
        strokeLinejoin="round"
        fill={'none'}
      />
      <Circle cx="10" cy="10" r="9.5" stroke={'currentColor'} fill={'none'} />
    </Icon>
  )
}
