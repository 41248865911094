import React, { FC, ReactNode } from 'react'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Dimensions, Platform } from 'react-native'
import { Box } from '../common/box/box'

type ContentAreaProps = InterfaceBoxProps & {
  children?: ReactNode
  mobilePadding?: number
  webPadding?: number
}

// Content area that's horizontally padded on mobile or small screens
// and full width on larger web screens
export const PaddedContentAreaConditional: FC<ContentAreaProps> = ({
  children,
  mobilePadding,
  webPadding,
  ...rest
}: ContentAreaProps) => {
  // Get screen width and platform
  const pageWidth = Dimensions.get('window').width
  const mobileLayout = !(Platform.OS === 'web') || pageWidth < WEB_MAX_WIDTH

  // Calculate padding
  const mobileLayoutPadding = mobilePadding || 4
  const webLayoutPadding = webPadding === undefined ? 1 : webPadding
  const paddingHorizontal = mobileLayout
    ? mobileLayoutPadding
    : webLayoutPadding

  const maxWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  return (
    <Box
      pl={paddingHorizontal}
      pr={paddingHorizontal}
      style={{
        width: '100%',
        marginHorizontal: 'auto',
        maxWidth,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
