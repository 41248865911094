import { selectGenderOptions } from '../ducks/ui/ui'
import { FormDetails, FormFieldDetails } from '../components/common/form/form'
import { myersBriggsPersonalityTypes } from '../modules/select-options/myers-briggs'
import { sub } from 'date-fns'
// @ts-ignore
import store from '../ducks/store'
import { checkUsernameAvailability } from '../ducks/user/functions/user-functions'
import { dateFromString } from '../modules/strings/string-helpers'
import { WHITE } from '../constants/ui-constants'

const fields: { [key: string]: FormFieldDetails } = {
  friendName: {
    label: 'Friend Name',
    description:
      "What do you call your friend in your dreams? Don't worry, this won't share any information with your friend! :)",
    name: 'friendName',
    type: 'input',
    focus: true,
    placeholder: 'common.friendName',
    rules: {
      validate: async (value: string) => {
        if (!value) {
          return 'formWarning.friendName.required'
        }
        if (!!value && value === store.getState().user?.user?.username) {
          return true
        }
        if (value.length > 64) {
          return 'formWarning.friendName.tooLong'
        }
        return true
      },
    },
  },
}

export const friendForm: FormDetails = {
  pages: [
    {
      fields: [fields.friendName],
    },
  ],
}
