import { ViewState } from 'react-map-gl'
import {
  FastDream,
  FastDreamLocation,
  FastDreamLocationService,
  FastTag,
  FastTagLocation,
  FastTagLocationService,
} from '../../../../../api/_openapi'
import React, { useState, useEffect, useRef } from 'react'
import ReactMapGL, { ViewStateChangeEvent } from 'react-map-gl'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { getInitialViewport } from './map-helpers'
import WebMercatorViewport from '@math.gl/web-mercator'
import DreamPointOverlay, { DreamPointSvg } from './DreamPointOverlay'
import useContentWidth from '../../../hooks/useContentWidth'
import { PlacesAutocomplete } from '../../../components/composite/places-autocomplete/places-autocomplete'
import { BLACK, PADDING_TO_TABS, WHITE } from '../../../constants/ui-constants'
import { GeolocateControlComponent, PointsNearby } from './GeolocateControl'
import { DreamPoint } from '../../../../../api/frontend-types'
import { defaultViewport } from './map-config'
import type { MapRef } from 'react-map-gl'
import { useColorModeValue } from 'native-base'
import {
  LINE_WIDTH,
  PADDING_HORIZONTAL,
  PADDING_HORIZONTAL_PIXELS,
} from '../../../constants/constants'
import { Row } from '../../../components/common/row/row'
import { Column } from '../../../components/common/column/column'

const SHOW_POINTS_NEARBY = false

type DreamMapProps = {
  mode: 'tag' | 'dream'
  dream: FastDream | null
  tag?: FastTag | null
  dreamPoints: DreamPoint[]
  allowAdding?: boolean
  setDreamPoints?: (points: any) => void
  useCurrentLocation?: boolean
  showNearbyDreams?: boolean
  activeTabIndex?: number
}

const DreamMap = ({
  mode = 'dream',
  dream,
  tag,
  dreamPoints,
  setDreamPoints,
  allowAdding = false,
  useCurrentLocation = false,
  showNearbyDreams = false,
  activeTabIndex,
}: DreamMapProps) => {
  // STATE
  const [pointsNearby, setPointsNearby] = useState<DreamPoint[]>([])
  const [viewport, setViewport] = useState<ViewState>(defaultViewport)
  const [initialViewHasLoaded, setInitialViewHasLoaded] = useState(false)

  // HOOKS
  const color = useColorModeValue(BLACK, WHITE)
  const { contentWidth } = useContentWidth()
  const mapWidth = contentWidth - PADDING_HORIZONTAL_PIXELS * 2

  // REFS
  const mapRef = useRef<MapRef>(null)

  // SELECTORS
  const user = useSelector(selectUser)

  // VARS
  const hasDreamPoints = dreamPoints.length > 0
  const mapHeight = mapWidth * 0.6
  const hasDimensions = mapWidth && mapHeight && mapWidth > 0 && mapHeight > 0

  const canAdd = allowAdding && (mode !== 'tag' || dreamPoints.length === 0)

  // Resize the map when mapWidth and mapHeight change
  // Or when the tab becomes active
  useEffect(() => {
    const showMap = mapWidth && mapHeight
    if (showMap && mapRef.current) {
      mapRef.current.resize()
    }
  }, [mapHeight, mapWidth, mapRef.current, activeTabIndex])

  // Set viewport based on points
  useEffect(() => {
    if (!hasDimensions || initialViewHasLoaded || !hasDreamPoints) {
      return
    } else {
      setInitialViewHasLoaded(true)
      const initialVp = getInitialViewport(dreamPoints, mapWidth, mapHeight)
      setViewport(initialVp)
    }
  }, [mapWidth, mapHeight, dreamPoints, hasDimensions, hasDreamPoints])

  // Remove a point from the map
  const removeDreamPoint = async (dreamPoint: DreamPoint) => {
    if (!allowAdding || !setDreamPoints) {
      return
    }

    if (mode === 'dream' && dream) {
      await FastDreamLocationService.deleteDreamLocation(
        dream.id,
        dreamPoint.id,
      ).catch((e) => {
        console.error(e)
      })
    } else if (tag) {
      await FastTagLocationService.deleteTagLocation(tag.id, dreamPoint.id)
    }
    setDreamPoints((prevState: any) => {
      return prevState.filter((point: any) => point.id !== dreamPoint.id)
    })
  }

  // Move a point on the map
  const updateCoordinates = (
    dreamPoint: DreamPoint,
    newCoordinates: [number, number],
  ) => {
    if (!allowAdding || !setDreamPoints) {
      return
    }

    if (mode === 'dream' && dream) {
      FastDreamLocationService.updateDreamLocation(dream.id, dreamPoint.id, {
        latitude: newCoordinates[1],
        longitude: newCoordinates[0],
      })
    } else if (tag) {
      FastTagLocationService.updateTagLocation(tag.id, dreamPoint.id, {
        latitude: newCoordinates[1],
        longitude: newCoordinates[0],
      })
    }

    setDreamPoints((prevState: any) => {
      return prevState.map((point: any) => {
        if (point.id === dreamPoint.id) {
          return {
            ...point,
            longitude: newCoordinates[0],
            latitude: newCoordinates[1],
          }
        }
        return point
      })
    })
  }

  // Add a point to the map
  const addPlace = async (place: any) => {
    // Early return if not allowed to add points
    if (!allowAdding || !setDreamPoints) {
      return
    }

    // Get the lat and long of the place
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    let result: DreamPoint
    if (mode === 'dream' && dream) {
      const newDreamPoint: Partial<FastDreamLocation> = {
        createdAt: new Date().toISOString(),
        dreamId: dream.id,
        latitude: lat,
        longitude: lng,
      }
      result = await FastDreamLocationService.addDreamLocation(
        dream.id,
        newDreamPoint,
      )
    } else if (tag && user) {
      const newTagPoint: Partial<FastTagLocation> = {
        createdAt: new Date().toISOString(),
        tagId: tag.id,
        userId: user.id,
        latitude: lat,
        longitude: lng,
      }
      result = await FastTagLocationService.addTagLocation(tag.id, newTagPoint)
    }

    setDreamPoints((prevState: any) => {
      return [...prevState, result]
    })
    const bounds = place.geometry.viewport
    const vp = new WebMercatorViewport({
      ...viewport,
      width: mapWidth,
      height: mapHeight,
    })
    const { longitude, latitude, zoom } = vp.fitBounds([
      [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
      [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()],
    ])

    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom,
    })
  }

  return (
    <Column
      paddingTop={PADDING_TO_TABS}
      paddingBottom={PADDING_TO_TABS}
      zIndex={1}
      alignItems={'center'}
      justifyContent={'center'}
      width={contentWidth}
    >
      {canAdd && (
        <Row pb={4} zIndex={999}>
          <PlacesAutocomplete width={contentWidth} onPlaceSelected={addPlace} />
        </Row>
      )}
      <Row
        width={'100%'}
        justifyContent={'center'}
        paddingTop={PADDING_HORIZONTAL}
        paddingBottom={PADDING_HORIZONTAL}
        borderWidth={LINE_WIDTH}
        borderColor={color}
      >
        <ReactMapGL
          initialViewState={{
            longitude: -100,
            latitude: 40,
            zoom: 3.5,
          }}
          ref={mapRef}
          style={{ width: mapWidth, height: mapHeight }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          onMove={(e: ViewStateChangeEvent) => {
            setViewport(e.viewState)
          }}
          {...viewport}
          latitude={viewport.latitude || defaultViewport.latitude}
          longitude={viewport.longitude || defaultViewport.longitude}
        >
          {useCurrentLocation && (
            <GeolocateControlComponent
              dreamPoints={dreamPoints}
              setPointsNearby={setPointsNearby}
            />
          )}
          <DreamPointOverlay>
            <DreamPointSvg
              data={dreamPoints}
              removeDatum={removeDreamPoint}
              updateCoordinates={updateCoordinates}
            />
          </DreamPointOverlay>
        </ReactMapGL>
      </Row>
      {SHOW_POINTS_NEARBY && pointsNearby.length > 0 && (
        <PointsNearby pointsNearby={pointsNearby} />
      )}
    </Column>
  )
}

export default DreamMap
