import React from 'react'
import { Svg, Path } from 'react-native-svg'

export function Add({ color, size }: { color: string; size: number }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M2 12H22" stroke={color} strokeWidth="2" />
      <Path d="M12 2L12 22" stroke={color} strokeWidth="2" />
    </Svg>
  )
}
