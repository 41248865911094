import React from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { DreamFeedList } from '../../components/composite/feed/feed-list-dream'
import { selectUser, selectUserFetchFailed } from '../../ducks/user/user'
import { LoadingPage } from '../../components/layout/loading-page'
import { MainStackScreenProps } from '../../navigation/types'
import { FetchFailedPage } from '../../components/layout/fetch-failed-page'
import { useUserDreams } from '../../hooks/useUserDreams'
import { useGroupDreams } from '../../hooks/useGroupDreams'

export default function FavoritesFeed({
  route,
}: MainStackScreenProps<'PrivateFavoritesFeed' | 'GroupFavoritesFeed'>) {
  const groupIdFromRoute = route.params?.groupId

  // SELECTORS
  const user = useSelector(selectUser)
  const { dreams: userDreams, loading: userDreamsLoading } = useUserDreams({
    favorites: true,
  })
  const { dreams: groupDreams, loading: groupDreamsLoading } = useGroupDreams(
    groupIdFromRoute,
    {
      favorites: true,
    },
  )

  const loading = userDreamsLoading || (groupIdFromRoute && groupDreamsLoading)
  const dreams = groupIdFromRoute ? groupDreams : userDreams

  const userFetchFailed = useSelector(selectUserFetchFailed)

  if (userFetchFailed) {
    return <FetchFailedPage />
  } else if (loading || !user) {
    return <LoadingPage hasQuote={true} />
  } else {
    return (
      <>
        <DreamFeedList entries={dreams} appUser={user} refresh={() => {}} />
      </>
    )
  }
}
