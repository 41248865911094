import * as React from 'react'
import { SELECTION_COLOR } from '../../../constants/constants'
import { useUserDate } from '../../../hooks/useUserDate'
import { SansText } from './text-sans'

type DisplayDateProps = {
  date: string
  displayDate?: string | null
  mt?: number
  mb?: number
}

// Probably worth making this a component
// Since dates and date formatting are a pain
// Only simple formatting for now
export const DisplayDate = ({
  date,
  displayDate,
  mt,
  mb,
}: DisplayDateProps) => {
  const { dateString } = useUserDate(date)
  const dateToShow = displayDate || dateString
  return (
    <SansText
      mt={mt || 0}
      mb={mb || 0}
      fontSize={'sm'}
      style={{ textAlign: 'center' }}
      selectionColor={SELECTION_COLOR}
    >
      {dateToShow}
    </SansText>
  )
}
