import React from 'react'
import { SupportedLanguage } from '../../../../api/frontend-types'
import { InfoText } from '../../components/common/copy/text-info'
import Autolink from 'react-native-autolink'
import { ElsewhereIcon } from '../../components/common/elsewhere-icon/elsewhere-icon'
import { LIGHT_MID_GRAY } from '../../constants/ui-constants'
import { Column } from '../../components/common/column/column'
import { useTranslation } from 'react-i18next'
import { MainStackNavigationProp } from '../../navigation/types'
import { useNavigation } from '@react-navigation/core'

type DreamWorkerFooterProps = {
  language: SupportedLanguage
}

export type NavProppyMcPropFace = MainStackNavigationProp<
  'PrivateDreamServiceView' | 'PrivateDreamServicesView'
>

// interpretationPage.goDeeperLink
export const DreamWorkerFooter = ({ language }: DreamWorkerFooterProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavProppyMcPropFace>()

  const onLinkPress = () => {
    navigation.navigate('PrivateDreamServicesView', {
      languageSpoken: language,
    })
  }

  return (
    <Column mt={8} alignItems={'center'}>
      <ElsewhereIcon size={'xs'} iconKey="search" color={LIGHT_MID_GRAY} />
      <InfoText mt={3} maxW={300} textAlign={'center'}>
        <Autolink
          text={t('interpretationPage.goDeeperLink')}
          matchers={[
            {
              pattern: /<linkTag>(.*)<\/linkTag>/g,
              getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
              onPress: onLinkPress,
            },
          ]}
          linkProps={{
            style: {
              textDecorationLine: 'underline',
            },
            onPress: onLinkPress,
          }}
        />
      </InfoText>
    </Column>
  )
}
