import React from 'react'
import { Keyboard, Pressable, Platform } from 'react-native'
import { Tag } from '../../../../api/_openapi'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { AskYourDreamsInput } from './ask-your-dreams-input'
import { BodyText } from '../../components/common/copy/text-body'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useSelector } from '../../ducks/root-reducer'
import { selectSubscriptionStatus } from '../../ducks/subscription/subscription'
import { SubscriptionStatus } from '../../../../api/frontend-types'
import { SubscriptionStatusAlert } from '../../components/composite/subscriptions/subscription-status-alert'
import { AskYourDreamsNavigationProp } from '.'

export type AskYourDreamsHeaderNavigationProp = AskYourDreamsNavigationProp

type TagPageHeaderProps = {
  fullTag?: Tag | null
  insights?: any
  setInsights?: any
}

export const AskYourDreamsHeader = React.memo(
  ({ fullTag, insights, setInsights }: TagPageHeaderProps) => {
    // HOOKS
    const backgroundColor = useColorModeValue(WHITE, BLACK)
    const { t } = useTranslation()

    // SELECTORS
    const status: SubscriptionStatus = useSelector(selectSubscriptionStatus)

    // VARS
    const numQuestionsRemaining = status.reportsRemaining || 0
    const disableInput = numQuestionsRemaining <= 0
    const userIsFree = status.tier === 'free'
    const numDreamsUsedInAnswer = userIsFree ? 30 : 100
    const rawHeading = fullTag ? fullTag.name : ''
    const translatedHeading =
      fullTag?.type === 'SYMBOL' ? t(`symbol.${rawHeading}`) : rawHeading

    return (
      <Pressable
        pointerEvents="box-none"
        onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
        style={{
          backgroundColor,
          maxWidth: WEB_MAX_WIDTH,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <SubscriptionStatusAlert
          statusToShow="reportsRemaining"
          tier={status.tier}
          creditsRemaining={status.creditsRemaining}
          itemsRemaining={numQuestionsRemaining}
        />
        <PaddedContentArea>
          <BodyText my={6} type={'primary'} fontSize={'18px'}>
            {t('askYourDreams.description', { count: numDreamsUsedInAnswer })}
          </BodyText>
          <AskYourDreamsInput
            id={fullTag?.id || ''}
            tagName={translatedHeading || ''}
            insights={insights}
            setInsights={setInsights}
            disabled={disableInput}
          />
        </PaddedContentArea>
      </Pressable>
    )
  },
)
