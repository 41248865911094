import { DateRange, DateRangeKey } from '../../../../api/frontend-types'
import { NUM_MILLISECONDS_IN_DAY } from '../../constants/constants'
import { getMinMaxDate } from '../../modules/date-helpers/date-helpers'
import { FastDream } from '../../../../api/_openapi'

export const dateRangeFromKey = (dateOption: DateRangeKey): DateRange => {
  const today = new Date()
  /*const lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7,
  )*/
  const twoWeeksAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 14,
  )
  /*const lastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate(),
  )*/
  const last3Months = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    today.getDate(),
  )
  const lastYear = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate(),
  )

  switch (dateOption) {
    case 'LAST_TWO_WEEKS': {
      return { start: twoWeeksAgo, end: today }
    }
    case 'LAST_THREE_MONTHS':
      return { start: last3Months, end: today }
    case 'LAST_YEAR':
      return { start: lastYear, end: today }
    default:
      return { start: null, end: today }
  }
}

function dreamIsLaterThanDate(dream: FastDream, date: Date | null): boolean {
  if (!dream.date) {
    return false
  } else if (!date) {
    return true
  } else {
    // TODO: split date into parts
    return new Date(dream.date) >= date
  }
}

function dreamIsEarlierThanDate(dream: FastDream, date: Date | null): boolean {
  if (!dream.date) {
    return false
  } else if (!date) {
    return true
  } else {
    // TODO: split date into parts
    return new Date(dream.date) <= date
  }
}

function dreamIsWithinDateRange(
  dream: FastDream,
  dateRange: DateRange,
): boolean {
  if (!dream.date) {
    return false
  } else {
    const dreamIsLaterThanStart = dreamIsLaterThanDate(dream, dateRange.start)
    const dreamIsEarlierThanEnd = dreamIsEarlierThanDate(dream, dateRange.end)
    return dreamIsLaterThanStart && dreamIsEarlierThanEnd
  }
}

// Select a default date range for the chart
// Based on the number of dreams the user has logged recently
// We want to show a range that is long enough to be interesting
// We choose the last period that has at least 'minDreamsInRange' dreams
// E.g. 10 is good, to give fortnight a chance to be selected as default for power users
export const chooseDateRange = (
  dreams: FastDream[],
  minDreamsInRange: number = 10,
): DateRangeKey => {
  const lastFortnight = dateRangeFromKey('LAST_TWO_WEEKS')
  const last3Months = dateRangeFromKey('LAST_THREE_MONTHS')
  const lastYear = dateRangeFromKey('LAST_YEAR')

  const notEnoughDreams = dreams.length < minDreamsInRange
  const { min: minOverall } = getMinMaxDate(dreams.map((dream) => dream.date))

  // Week
  const dreamsInLastFortnight = dreams.filter((dream) =>
    dreamIsLaterThanDate(dream, lastFortnight.start),
  )

  const { min: minLastFortnight } = getMinMaxDate(
    dreamsInLastFortnight.map((dream) => dream.date),
  )

  // If someone is dreaming more than once a day, then show the last fortnight
  if (
    dreamsInLastFortnight.length >= minDreamsInRange ||
    (notEnoughDreams && minOverall >= minLastFortnight)
  ) {
    return 'LAST_TWO_WEEKS'
  }

  // 3 Months
  const dreamsInLast3Months = dreams.filter((dream) =>
    dreamIsLaterThanDate(dream, last3Months.start),
  )

  const { min: minLast3Months } = getMinMaxDate(
    dreamsInLast3Months.map((dream) => dream.date),
  )

  if (
    dreamsInLast3Months.length >= minDreamsInRange ||
    (notEnoughDreams && minOverall >= minLast3Months)
  ) {
    return 'LAST_THREE_MONTHS'
  }

  // Year
  const dreamsInLastYear = dreams.filter((dream) =>
    dreamIsLaterThanDate(dream, lastYear.start),
  )
  const { min: minLastYear } = getMinMaxDate(
    dreamsInLastYear.map((dream) => dream.date),
  )
  if (
    dreamsInLastYear.length >= minDreamsInRange ||
    (notEnoughDreams && minOverall >= minLastYear)
  ) {
    return 'LAST_YEAR'
  }

  // Forget about year, if it's this long ago, just show all time
  return 'ALL_TIME'
}

// Get main stats for a given date range
// 1) Number of dreams
// 2) number of dreams per day
// 3) month with most dreams
export const getStats = (
  dreams: FastDream[],
  dateRangeKey: DateRangeKey,
  language: string,
): {
  numDreams: number
  numDreamsPerDay: number
  monthWithMostDreams: string
} => {
  const dateRange = dateRangeFromKey(dateRangeKey)
  const dreamsInRange = dreams.filter((dream) =>
    dreamIsWithinDateRange(dream, dateRange),
  )
  // const earliestDream = dreamsInRange.reduce((a, b) =>
  //   a.date < b.date ? a : b,
  // )
  const earliestDream = dreamsInRange.reduce((acc, curr) => {
    if (!acc.date) {
      return curr
    } else if (acc.date < curr.date) {
      return acc
    } else {
      return curr
    }
  }, {} as FastDream)

  const earliestDreamDate = new Date(earliestDream.date)

  const { start, end } = dateRange

  // If the start date is null (i.e. all time), then use the earliest dream date
  // Otherwise, use the start date
  const startDate = start ? start : earliestDreamDate

  // 1) Number of dreams
  const numDreams = dreamsInRange.length

  // 2) Number of dreams per day
  const numDays = Math.round(
    (end.getTime() - startDate.getTime()) / NUM_MILLISECONDS_IN_DAY,
  )
  const numDreamsPerDay = numDreams / numDays
  const numDreamsPerDayTwoDecimals = Math.round(numDreamsPerDay * 100) / 100

  // 3) Month with most dreams
  // E.g. Jan 2020
  const months = dreamsInRange.map((dream) => {
    const date = new Date(dream.date)
    return date.toLocaleString(language, {
      month: 'short',
      year: 'numeric',
    })
  })
  const monthWithMostDreams = months
    .sort(
      (a, b) =>
        months.filter((v) => v === a).length -
        months.filter((v) => v === b).length,
    )
    .pop()

  const numDreamsPerDayTwoDecimalsCheck = isNaN(numDreamsPerDayTwoDecimals)
    ? 0
    : numDreamsPerDayTwoDecimals

  return {
    numDreams,
    numDreamsPerDay: numDreamsPerDayTwoDecimalsCheck,
    monthWithMostDreams: monthWithMostDreams || '-',
  }
}
