import React from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { Logotype } from '../../assets/icons/Logotype'
import { BodyText } from '../../components/common/copy/text-body'
import { useTranslation } from 'react-i18next'
import { AboutList } from '../../components/composite/about-list/about-list'
import { RootStackNavigationProp } from '../../navigation/types'
import { Row } from '../../components/common/row/row'
import { AboutSection } from '../../../../api/frontend-types'
import { aboutContactDetails, aboutLegal } from '../../config/about'
import { ScrollView } from '../../components/common/scrollview/scrollview'

export type PrivacyAndTermsNavigationProp =
  RootStackNavigationProp<'PrivatePrivacyAndTerms'>

export default function PrivacyAndTerms() {
  const { t } = useTranslation()
  const sections: AboutSection[] = [aboutLegal, aboutContactDetails]

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea>
        <Row justifyContent={'center'} mb={8} mt={6}>
          <Logotype width={170} height={25} />
        </Row>
        <BodyText>{t('termsPage.description.paragraph1')}</BodyText>
        <AboutList sections={sections} />
      </PaddedContentArea>
    </ScrollView>
  )
}
