import React, { useState, useEffect } from 'react'
import { useColorModeValue } from 'native-base'
import { Loading } from '../../layout/loading'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import { Box } from '../box/box'

type SvgUrlProps = {
  width: number
  height: number
  imageWidth?: number
  imageHeight?: number
  url: string
  viewBox?: string
  fill?: string
  stroke?: string
}

// The fill of SVGs from Illustrator can be overridden with the 'fill' prop on react-native-svg SvgUri
// But minified SVGs can't be overridden for some reason
// So we have to fetch the SVG and replace the fill and stroke colors manually
// https://github.com/software-mansion/react-native-svg/issues/1317#issuecomment-861498269
export const SvgUrl = React.memo(
  ({
    width,
    height,
    imageWidth = width,
    imageHeight = height,
    url,
    viewBox = '0 0 2048 2048',
    fill,
    stroke,
  }: SvgUrlProps) => {
    const [imgXml, setImgXml] = useState('<svg></svg>')

    const color = useColorModeValue(BLACK, WHITE)
    const svgFillColor = useColorModeValue(LIGHT_BLACK, CREAM)

    // If fill or stroke are not provided, use the default color
    if (!fill) {
      fill = svgFillColor
    }
    if (!stroke) {
      stroke = svgFillColor
    }

    const backgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)

    useEffect(() => {
      getImgXml(url, fill, stroke)
    }, [url, fill, stroke])

    const getImgXml = async (url: string, fill?: string, stroke?: string) => {
      // If any of the required props are missing, don't update the SVG
      if (!url || !fill || !stroke) return
      const res = await fetch(url)
      if (!res.ok) {
        console.error('SvgUrl', res.status, res.statusText)
        return
      }
      const xml = await res.text()
      const newXml = xml
        .replace(/fill="#[0-9a-f]{6}"/g, `fill="${fill}"`)
        .replace(/stroke="#[0-9a-f]{6}"/g, `stroke="${stroke}"`)
        .replace(/<svg /g, `<svg viewBox="${viewBox}" `)
      setImgXml(newXml)
    }

    if (!url) {
      return (
        <Box
          height={height}
          width={width}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor,
          }}
        >
          <Loading color={color} width={50} />
        </Box>
      )
    } else {
      return (
        <Box
          height={`${height}px`}
          width={`${width}px`}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor,
          }}
        >
          <img
            width={`${imageWidth}px`}
            height={`${imageHeight}px`}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(imgXml)}`}
          />
        </Box>
      )
    }
  },
)
