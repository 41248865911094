import React from 'react'
import { FeatureCarousel } from '../../../components/composite/subscriptions/features-carousel'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'

export type SubscriptionsNavigationProp =
  RootStackNavigationProp<'PrivateSubscriptionSettingsEdit'>

export default function Subscriptions({
  navigation,
  route,
}: RootStackScreenProps<'PrivateSubscriptionSettingsEdit'>) {
  const { tab } = route?.params || {}
  return (
    <FeatureCarousel
      offeringsToShow={['free', 'premium', 'supporter']}
      initialTab={tab}
    />
  )
}
