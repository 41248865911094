import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { PURGE } from 'redux-persist'
import { QueueDispatchActionKey, RefetchFunctionKey } from '../queue-actions'

export const QUEUE_MAX_RETRIES = 3

export type QueueItemWrite = {
  id: string
  onSuccess: {
    action: QueueDispatchActionKey
  }
  onFailure: {
    action: QueueDispatchActionKey
  }
  refetchAction: {
    functionKey: RefetchFunctionKey
    args: any[]
  }
}

export type QueueItem = QueueItemWrite & {
  numRetries: number
}

export type QueueState = {
  items: QueueItem[]
}

const initialState: QueueState = {
  items: [],
}

const queueSlice = createSlice({
  name: 'queue',
  initialState,
  // Queue should be purged when the user logs out
  // https://redux-toolkit.js.org/usage/usage-guide
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState
    })
  },
  reducers: {
    addQueueItem(state, action: PayloadAction<QueueItemWrite>) {
      const item: QueueItem = {
        ...action.payload,
        numRetries: 0,
      }
      state.items.push(item)
    },
    removeQueueItem(state, action: PayloadAction<string>) {
      console.log('Removing queue item', action.payload)
      state.items = state.items.filter((item) => item.id !== action.payload)
    },
    incrementQueueItemRetries(state, action: PayloadAction<string>) {
      const item = state.items.find((item) => item.id === action.payload)
      if (item) {
        item.numRetries += 1
      } else {
        console.error('Could not find item to increment retries')
      }
    },
    clearQueue(state) {
      state.items = []
    },
  },
})

export const { addQueueItem, removeQueueItem } = queueSlice.actions

// SELECTORS

export const selectQueueItems = (state: RootState): QueueItem[] => {
  return state.queue.items
}

export const selectQueueItemById =
  (id: string) =>
  (state: RootState): QueueItem | undefined => {
    return state.queue.items.find((item) => item.id === id)
  }

export default queueSlice
