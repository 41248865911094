import React, { FC, ReactNode } from 'react'
import Markdown, {
  MarkdownProps,
} from '@ronradtke/react-native-markdown-display'
import i18n from '../../../i18n/i18nnext'
import { SimpleTextAlign } from '../../../../../api/frontend-types'

type MarkdownTextProps = Omit<MarkdownProps, 'children'> & {
  children?: ReactNode
}

export const MarkdownText: FC<MarkdownTextProps> = ({
  children,
  style,
  ...rest
}: MarkdownTextProps) => {
  const childrenAsString = `${children}`
  const textAlign: SimpleTextAlign = i18n.dir() === 'rtl' ? 'right' : 'left'

  return (
    <Markdown
      style={{
        ...style,
        body: {
          ...style?.body,
          textAlign,
        },
      }}
      {...rest}
    >
      {childrenAsString}
    </Markdown>
  )
}
