import React from 'react'
import { FastComment, FastCommentService } from '../../../../../api/_openapi'
import { useColorModeValue } from 'native-base'
import { TextArea } from '../../../components/common/inputs/input-textarea'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { MEDIUM_FONT_SIZE } from '../../../constants/constants'
import { ButtonIcon } from '../../../components/common/buttons/button-icon'
import { Platform } from 'react-native'
import { OFFSET_BLACK, OFFSET_WHITE } from '../../../constants/ui-constants'
import { useDispatch } from 'react-redux'
import { incCommentCount } from '../../../ducks/dream-tag/dream-tag'
import { Row } from '../../../components/common/row/row'
import i18n from '../../../i18n/i18nnext'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import { Column } from '../../../components/common/column/column'

type DreamCommentProps = {
  shareId: string
  onAdded?: (comment: FastComment) => void
  scrollToFocusedInput?: (reactNode: any) => void
}

export default function AddDreamComment({
  shareId,
  onAdded,
  scrollToFocusedInput,
}: DreamCommentProps) {
  // STATE
  const [description, setDescription] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  // SELECTORS
  const user = useSelector(selectUser)

  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const textColor = useColorModeValue(OFFSET_BLACK, OFFSET_WHITE)

  // VARS
  const addButtonIsDisabled = description.trim() === ''
  const language = i18n.resolvedLanguage || 'en'
  const sansFontFamily = getSansFont(language)

  // FUNCTIONS
  const addComment = async () => {
    setLoading(true)
    try {
      const comment = await FastCommentService.createComment(shareId, {
        description,
      })
      dispatch(incCommentCount(shareId))
      if (onAdded) {
        onAdded(comment)
      }
      setDescription('')
    } catch (e) {
      elsewhereToast.showToast({
        description: t('common.errorGeneric'),
        status: 'error',
      })
    } finally {
      setLoading(false)
      scrollToFocusedInput && scrollToFocusedInput(taRef.current)
    }
  }

  const taRef = React.useRef<any>(null)

  return (
    <Row alignItems="center">
      <Row alignItems="start" height={'100%'} ml={1} mr={2}>
        <AvatarCircle
          source={{ uri: user?.profileImageUrl || undefined }}
          name={user?.username || ''}
          loading={false}
          disablePress={true}
          size={'xs'}
        />
      </Row>
      <Column flex={1}>
        <TextArea
          inputRef={taRef}
          onFocus={() =>
            scrollToFocusedInput && scrollToFocusedInput(taRef.current)
          }
          fieldName="add-comment-input"
          id={Platform.OS === 'ios' ? undefined : shareId}
          value={description}
          placeholder={t('comments.placeholder')}
          autoCapitalize="none"
          autoCorrect={true}
          onChangeText={setDescription}
          maxLength={2500}
          minRows={1}
          fontFamily={sansFontFamily}
          fontSize={MEDIUM_FONT_SIZE}
          mb={0}
          color={textColor}
        />
      </Column>
      <Row alignItems="start" height={'100%'} ml={4} pr={2} pt={'6px'}>
        <ButtonIcon
          iconKey={'plus'}
          onPress={addComment}
          isDisabled={addButtonIsDisabled}
          size={'xs'}
          loading={loading}
          variant={'outlined'}
        />
      </Row>
    </Row>
  )
}
