// How many symbols to show in a row
// Based on the width of the screen
export function getNumGridColsPerRow(width: number): number {
  if (width < 300) {
    return 2
  } else if (width < 400) {
    return 3
  } else if (width < 600) {
    return 4
  } else if (width < 800) {
    return 5
  } else {
    return 6
  }
}

// Split the items into rows
export function splitArrayIntoRows<T>(arr: T[], rowLength: number): T[][] {
  const rows = []
  for (let i = 0; i < arr.length; i += rowLength) {
    rows.push(arr.slice(i, i + rowLength))
  }
  return rows
}
