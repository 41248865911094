import React, { useRef } from 'react'
import {
  PixelRatio,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewProps,
  StyleProp,
  TextStyle,
} from 'react-native'
import { TextInput, Platform, ViewStyle } from 'react-native'
import {
  BLACK,
  DARK_GRAY,
  LIGHT_GRAY,
  PLACEHOLDER_COLOR_LIGHT,
  PLACEHOLDER_COLOR_DARK,
  WHITE,
} from '../../../constants/ui-constants'
import * as sizes from '../../../constants/tag-chip-sizes'
import { useColorModeValue } from 'native-base'

const Input = ({ id, value, onChangeText, style }: any) => {
  const input = useRef<HTMLInputElement | null>(null)
  return (
    <input
      ref={input}
      id={`tag-input-${id}`}
      type="text"
      size={value.length + 1}
      onInput={() => {
        input.current?.setAttribute('size', value.length + 1)
      }}
      value={value}
      onChange={(e) => onChangeText(e.target.value)}
      style={{ ...style, textAlign: 'center' }}
    />
  )
}

const TextInputAutoSize = Platform.OS === 'web' ? Input : TextInput

interface TagChipProps extends ViewProps {
  id: string
  text: string
  checked?: boolean
  onPress?: () => void
  leftIcon?: any
  rightIcon?: any
  onDelete?: () => void
  textStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  editable: boolean
  isReadOnly?: boolean
  onChangeTagName: (text: string) => void
  onSaveTagName: (text: string) => void
  size?: 'sm' | 'md'
}

const TagChipComponent: React.FC<TagChipProps> = React.memo(
  ({
    id,
    text,
    checked,
    onPress,
    leftIcon,
    rightIcon,
    onDelete,
    textStyle,
    editable,
    isReadOnly,
    onChangeTagName,
    onSaveTagName,
    style,
    size = 'sm',
  }) => {
    const GRAY = useColorModeValue(
      PLACEHOLDER_COLOR_LIGHT,
      PLACEHOLDER_COLOR_DARK,
    )
    const chipStyle = StyleSheet.create({
      mainContainer: {
        alignSelf: 'flex-start',
        borderWidth: 1,
        minHeight: sizes.CHIP_HEIGHT[size],
        maxHeight: sizes.CHIP_HEIGHT[size],
        borderRadius: sizes.CHIP_RADIUS[size],
        borderColor: GRAY,
        margin: sizes.CHIP_MARGIN[size],
      },
      colorCircle: {
        height: sizes.CHIP_LEFT_ICON_SIZE[size],
        width: sizes.CHIP_LEFT_ICON_SIZE[size],
        borderRadius: sizes.CHIP_LEFT_ICON_RADIUS[size],
        borderColor: GRAY,
      },
    })

    const _renderRightIcon = (icon: any) => {
      if (!icon) {
        return null
      }

      return (
        <View
          style={{
            height: sizes.CHIP_RIGHT_ICON_SIZE[size],
            width: sizes.CHIP_RIGHT_ICON_SIZE[size],
            borderRadius: sizes.CHIP_RIGHT_ICON_RADIUS[size],
          }}
        >
          {icon}
        </View>
      )
    }

    const _checkRightIconTouch = () => {
      if (rightIcon) {
        return _renderRightIcon(rightIcon)
      }

      return null
    }

    const _renderLeftIcon = (icon: any) => {
      if (!icon) {
        return null
      }

      return (
        <View
          style={{
            height: sizes.CHIP_LEFT_ICON_SIZE[size],
            width: sizes.CHIP_LEFT_ICON_SIZE[size],
            borderRadius: sizes.CHIP_LEFT_ICON_RADIUS[size],
          }}
        >
          {icon}
        </View>
      )
    }

    const _renderContent = () => {
      const textStyleProp = textStyle ? (textStyle as object) : {}
      const [inputText, setInputText] = React.useState<string>(text)
      const keyboardAppearance = useColorModeValue('light', 'dark')
      const borderColor = useColorModeValue(BLACK, WHITE)
      const backgroundColor = useColorModeValue(LIGHT_GRAY, DARK_GRAY)
      return (
        <View
          style={[
            {
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            },
          ]}
        >
          {checked || leftIcon ? (
            <View
              style={{
                marginLeft: PixelRatio.roundToNearestPixel(4),
                marginRight: PixelRatio.roundToNearestPixel(8),
              }}
            >
              {_renderLeftIcon(leftIcon)}
            </View>
          ) : null}
          {!editable ? (
            <Text
              style={{
                fontSize: sizes.CHIP_TEXT_SIZE[size],
                marginRight:
                  rightIcon || onDelete ? 0 : sizes.CHIP_TEXT_MARGIN[size],
                marginLeft:
                  checked || leftIcon ? 0 : sizes.CHIP_TEXT_MARGIN[size],
                ...textStyleProp,
              }}
            >
              {text}
            </Text>
          ) : (
            <TextInputAutoSize
              id={id}
              style={{
                fontSize: sizes.CHIP_TEXT_SIZE[size],
                marginRight:
                  Platform.OS === 'web' || rightIcon || onDelete
                    ? 0
                    : sizes.CHIP_TEXT_MARGIN[size],
                marginLeft:
                  Platform.OS === 'web' || checked || leftIcon
                    ? 0
                    : sizes.CHIP_TEXT_MARGIN[size],
                color: borderColor,
                backgroundColor: isReadOnly ? 'transparent' : backgroundColor,
                paddingHorizontal:
                  Platform.OS === 'web' || isReadOnly
                    ? 0
                    : sizes.CHIP_TEXT_MARGIN[size] / 3,
                ...textStyleProp,
                maxHeight:
                  sizes.CHIP_HEIGHT[size] - sizes.CHIP_TEXT_MARGIN[size] / 1.25,
                height:
                  sizes.CHIP_HEIGHT[size] - sizes.CHIP_TEXT_MARGIN[size] / 1.25,
                padding: 0,
              }}
              value={inputText}
              onChangeText={(text) => {
                setInputText(text)
                onChangeTagName && onChangeTagName(text)
              }}
              onBlur={() => {
                // When the input loses focus, we update the value in the DB
                onSaveTagName && onSaveTagName(inputText)
              }}
              defaultValue={text}
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              selectTextOnFocus={false}
              editable={!isReadOnly}
              keyboardAppearance={keyboardAppearance}
            />
          )}
          {rightIcon || onDelete ? (
            <View
              style={{
                marginLeft: PixelRatio.roundToNearestPixel(2),
                marginRight: PixelRatio.roundToNearestPixel(4),
              }}
            >
              {_checkRightIconTouch()}
            </View>
          ) : null}
        </View>
      )
    }

    const styleProp = style !== undefined ? style : {}

    if (onPress !== undefined) {
      return (
        <TouchableOpacity onPress={() => onPress?.()}>
          <View
            style={[
              chipStyle.mainContainer,
              {
                marginLeft: sizes.CHIP_MARGIN[size],
                marginRight: sizes.CHIP_MARGIN[size],
              },
              styleProp,
            ]}
          >
            {_renderContent()}
          </View>
        </TouchableOpacity>
      )
    }

    return (
      <View
        style={[
          chipStyle.mainContainer,
          {
            marginLeft: 0,
            marginRight: sizes.CHIP_MARGIN[size] * 2,
          },
          styleProp,
        ]}
      >
        {_renderContent()}
      </View>
    )
  },
)

interface Sizes {
  CHIP_HEIGHT: number
  CHIP_RADIUS: number
  CHIP_MARGIN: number
  CHIP_TEXT_SIZE: number
  CHIP_TEXT_MARGIN: number
  CHIP_LEFT_ICON_SIZE: number
  CHIP_LEFT_ICON_RADIUS: number
  CHIP_RIGHT_ICON_SIZE: number
  CHIP_RIGHT_ICON_RADIUS: number
}

const TagChip: typeof TagChipComponent & Sizes = TagChipComponent as any

Object.assign(TagChip, sizes)

export default TagChip
