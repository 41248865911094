import React from 'react'
import { Svg, Path } from 'react-native-svg'

export function Home({ color, size }: { color: string; size: number }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M12 15L12 21" stroke={color} strokeWidth="2" />
      <Path
        d="M21 21H3V12L12 3L21 12V21Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
