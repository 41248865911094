import React from 'react'
import { BodyText } from '../common/copy/text-body'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Box, InterfaceBoxProps } from '../common/box/box'

type QuoteProps = InterfaceBoxProps & {
  quote: string
  author: string
  source?: string
}

export const Quote = ({
  quote,
  author,
  source,
  mt = 2,
  mb = 2,
}: QuoteProps) => {
  return (
    <Box
      mt={mt}
      mb={mb}
      paddingLeft={4}
      paddingRight={4}
      flexDirection={'column'}
      alignItems={'center'}
      maxWidth={WEB_MAX_WIDTH}
      mx="auto"
    >
      <BodyText
        type="gray"
        mb={4}
        fontSize={'md'}
        textAlign="center"
      >{`“${quote}”`}</BodyText>
      <BodyText type="gray" fontSize={'md'} fontStyle={'italic'}>
        {`${author}${source ? ',' : ''}`}
      </BodyText>
      <BodyText type="gray" fontSize={'md'} fontStyle={'italic'}>
        {source}
      </BodyText>
    </Box>
  )
}
