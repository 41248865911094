/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DreamSearch } from '../models/DreamSearch';
import type { DreamSearch_DreamSearchRequest } from '../models/DreamSearch_DreamSearchRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DreamSearchService {
  /**
   * Creates a DreamSearch resource.
   * Creates a DreamSearch resource.
   * @param requestBody The new DreamSearch resource
   * @returns DreamSearch DreamSearch resource created
   * @throws ApiError
   */
  public static dreamSearch(
    requestBody: DreamSearch_DreamSearchRequest,
  ): CancelablePromise<DreamSearch> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreams/search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
