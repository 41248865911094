import React, { useRef, useState } from 'react'
import { Button, AlertDialog } from 'native-base'
import { ButtonPill } from '../buttons/button-pill'
import { useTranslation } from 'react-i18next'
import { InputField } from '../inputs/input-field'
import { SansText } from '../copy/text-sans'
import { SANS_BOLD_FONT_WEIGHT } from '../../../constants/constants'
import { getPaddedContentWidth } from '../../../modules/ui-helpers/ui-helpers'
import { Platform } from 'react-native'
import { ScriptType } from '../../../../../api/frontend-types'

type AlertPopupProps = {
  isOpen: boolean
  header?: string
  headerComponent?: React.ReactNode
  description?: string
  descriptionElement?: React.ReactNode
  descriptionScriptType?: ScriptType
  cancelLabel?: string
  confirmLabel?: string
  onClose: () => void
  onConfirm?: (input?: string) => void
  onCancel?: () => void
  hideConfirm?: boolean
  hideCancel?: boolean
  width?: number
  hasUserInput?: boolean
  hideClose?: boolean
}

export const AlertPopup = ({
  isOpen,
  header,
  headerComponent,
  description,
  descriptionScriptType,
  onClose,
  cancelLabel,
  confirmLabel,
  onConfirm,
  width,
  hideConfirm = false,
  hideCancel = false,
  hideClose = false,
  hasUserInput,
  descriptionElement,
}: AlertPopupProps) => {
  // STATE
  const [userInput, setUserInput] = useState('')

  // HOOKS
  const { t } = useTranslation()
  const cancelRef = useRef(null)

  // VARS
  const displayCancel = cancelLabel || t('common.cancel')
  const contentWidth = getPaddedContentWidth(Platform.OS)
  const dialogWidth = width || contentWidth

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      width={dialogWidth}
      marginLeft={4}
      marginRight={4}
    >
      <AlertDialog.Content borderRadius={0}>
        <AlertDialog.Header>
          {header && (
            <SansText pr={8} fontWeight={SANS_BOLD_FONT_WEIGHT}>
              {header}
            </SansText>
          )}
          {headerComponent && headerComponent}
        </AlertDialog.Header>
        {!hideClose && <AlertDialog.CloseButton ml={2} borderRadius={0} />}
        {/* DESCRIPTION */}
        {/* (Either a string, or an element) */}
        <AlertDialog.Body>
          {description && (
            <SansText scriptType={descriptionScriptType}>
              {description}
            </SansText>
          )}
          {descriptionElement && descriptionElement}
        </AlertDialog.Body>
        {/* USER INPUT */}
        {hasUserInput && (
          <InputField
            value={userInput}
            onChangeText={(text) => {
              setUserInput(text)
            }}
            mb={4}
            marginRight={4}
            marginLeft={4}
            autoFocus={true}
          />
        )}
        {/* BUTTONS */}
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <>
              {!hideCancel && (
                <Button
                  variant="unstyled"
                  colorScheme="coolGray"
                  onPress={onClose}
                  ref={cancelRef}
                  borderRadius={'full'}
                  padding={2}
                >
                  <SansText
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    {displayCancel}
                  </SansText>
                </Button>
              )}
            </>
            <>
              {!hideConfirm && (
                <ButtonPill
                  buttonSize={'sm'}
                  onPress={() => {
                    if (onConfirm && hasUserInput) {
                      onConfirm(userInput)
                    } else if (onConfirm) {
                      onConfirm()
                    }
                  }}
                >
                  {confirmLabel}
                </ButtonPill>
              )}
            </>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
