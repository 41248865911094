import React, { useState } from 'react'
import { Platform, Alert } from 'react-native'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Logotype } from '../../assets/icons/Logotype'
import { Img } from '../../components/common/image/image'
import { useTranslation } from 'react-i18next'
import { FormScrollWrapper } from '../../components/common/form/form-scroll-wrapper'
import { ElsewhereAlert } from '../../components/common/alert/alert'
import { HeadingSans } from '../../components/common/copy/heading-sans'
import { TextInput } from '../../components/common/inputs/text-input'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import axios from 'axios'
import { BodyText } from '../../components/common/copy/text-body'
import { useAuth } from '../../contexts/auth-context'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Row } from '../../components/common/row/row'
import { Column } from '../../components/common/column/column'
import { Center } from '../../components/common/center/center'
import { Link } from '../../components/common/link/link'
import { FormControl } from '../../components/common/form-control/form-control'

const journalImg = require('../../assets/images/elsewhere-journal.jpg')

export default function Journal({ route, navigation }: any) {
  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()

  // VARS
  const imageWidth = 500

  // STATE
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailIsSubmitted, setEmailIsSubmitted] = React.useState(false)
  const [errors, setErrors] = React.useState({} as any)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    const data = {
      name,
      email,
      date: new Date().toISOString(),
    }

    try {
      const response = await axios.post(
        'https://sheetdb.io/api/v1/5an7sx7uk4lle',
        data,
      )

      if (response.status === 201) {
        Alert.alert('Success', 'Form submitted successfully!')
        setEmailIsSubmitted(true)
      } else {
        Alert.alert('Error', 'Failed to submit the form')
      }
    } catch (error) {
      console.error('Error:', error)
      Alert.alert('Error', 'Failed to submit the form')
    } finally {
      setIsLoading(false)
    }
  }

  const scrollViewRef = React.useRef<any>(null)

  return (
    <FormScrollWrapper
      scrollViewRef={scrollViewRef}
      name={'login'}
      contentContainerStyle={{
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        marginHorizontal: 'auto',
      }}
    >
      <Column
        style={{ width: '100%', height: '100%', alignItems: 'flex-start' }}
      >
        <PaddedContentArea>
          <Center>
            {Platform.OS === 'web' && !Boolean(authData?.token) && (
              <Center m={5}>
                <Link href="/">
                  <Logotype width={205} height={25} />
                </Link>
              </Center>
            )}
            {Platform.OS !== 'web' ? (
              <>
                <Img
                  source={journalImg}
                  width={imageWidth}
                  height={imageWidth}
                  alt={'Elsewhere Journal'}
                />
                <Row justifyContent={'center'} mb={8} mt={6}>
                  <Logotype width={170} height={25} />
                </Row>
              </>
            ) : (
              <>
                <Img
                  source={journalImg}
                  width={imageWidth}
                  height={imageWidth}
                  alt={'Elsewhere Journal'}
                />
                <HeadingMain mb={8} mt={8}>
                  {t('common.elsewhereJournal')}
                </HeadingMain>
                <BodyText textAlign={'center'} mb={4}>
                  {t('journal.description')}
                </BodyText>
              </>
            )}
          </Center>
          {emailIsSubmitted ? (
            <ElsewhereAlert
              mb={4}
              maxW={WEB_MAX_WIDTH}
              status="success"
              title={t('toast.journal.emailadded.title')}
              bodyText={t('toast.journal.emailadded.description')}
            />
          ) : (
            <FormControl
              isInvalid={'email' in errors}
              isDisabled={emailIsSubmitted}
              isReadOnly={emailIsSubmitted}
            >
              <HeadingSans mb={2} mt={4}>
                {t('common.yourName')}
              </HeadingSans>
              <TextInput
                autoFocus={true}
                autoComplete="name"
                keyboardType="default"
                textContentType="username"
                autoCorrect={false}
                placeholder={t('globalUser.franzkafka.name')}
                onChangeText={(name) => setName(name)}
                value={name}
              />
              <HeadingSans mb={2} mt={4}>
                {t('common.email')}
              </HeadingSans>
              <TextInput
                autoFocus={true}
                autoComplete="email"
                keyboardType="email-address"
                textContentType="username"
                autoCorrect={false}
                placeholder={t('common.emailPlaceholder')}
                onChangeText={(email) => setEmail(email)}
                value={email}
                autoCapitalize="none"
                onSubmitEditing={handleSubmit}
              />
            </FormControl>
          )}
          {!emailIsSubmitted ? (
            <ButtonPill
              isDisabled={!email.match(/^[^\s@]+@[^\s@]+\.(([^\s@]+){2,})$/i)}
              onPress={handleSubmit}
              mt="5"
              isLoading={isLoading}
            >
              {t('journal.registerInterest')}
            </ButtonPill>
          ) : null}
        </PaddedContentArea>
      </Column>
    </FormScrollWrapper>
  )
}
