/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsernameAvailability } from '../models/UsernameAvailability';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsernameAvailabilityService {
  /**
   * Retrieves a UsernameAvailability resource.
   * Retrieves a UsernameAvailability resource.
   * @param username UsernameAvailability identifier
   * @returns UsernameAvailability UsernameAvailability resource
   * @throws ApiError
   */
  public static usernameAvailability(
    username: string,
  ): CancelablePromise<UsernameAvailability> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/login/username-availability/{username}',
      path: {
        'username': username,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
