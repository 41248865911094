import React from 'react'
import { RNMultiToggleSwitch } from './MultiToggleSwitch'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { Row } from '../row/row'

type RNMultiToggleSwitchProps =
  | RNMultiToggleSwitchPropsBoolean
  | RNMultiToggleSwitchPropsMulti

type RNMultiToggleSwitchPropsBoolean = {
  type: 'boolean'
  options?: never
  value?: boolean
  onToggle: (value: string | boolean) => void
}

type RNMultiToggleSwitchPropsMulti = {
  type: 'multi'
  options: { label: string; value: string; onPress?: any }[]
  value?: string
  onToggle: (value: string | boolean) => void
}

export const MultiToggleSwitch = React.memo(
  ({
    type = 'boolean',
    options = [],
    value,
    onToggle = () => {},
  }: RNMultiToggleSwitchProps) => {
    const { t } = useTranslation()

    const defaultOptions = [
      { label: t('common.off'), value: false },
      { label: t('common.on'), value: true },
    ]
    const defaultBooleanActiveIndex = value ? 1 : 0
    const defaultMultiActiveIndex =
      value && options
        ? options.map((o) => o.value.toString()).indexOf(value.toString())
        : 0

    return (
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {type === 'boolean' ? (
          <RNMultiToggleSwitch defaultActiveIndex={defaultBooleanActiveIndex}>
            {defaultOptions.map((option) => {
              return (
                <RNMultiToggleSwitch.Item
                  key={option.label}
                  onPress={() => {
                    onToggle(option.value)
                  }}
                >
                  <SansText fontWeight={400}>{option.label}</SansText>
                </RNMultiToggleSwitch.Item>
              )
            })}
          </RNMultiToggleSwitch>
        ) : (
          <RNMultiToggleSwitch
            defaultActiveIndex={defaultMultiActiveIndex || 0}
          >
            {options.map((option) => {
              return (
                <RNMultiToggleSwitch.Item
                  key={option.label}
                  onPress={() => {
                    onToggle(option.value)
                    option.onPress && option.onPress(option.value)
                  }}
                >
                  <SansText fontWeight={400}>{t(option.label)}</SansText>
                </RNMultiToggleSwitch.Item>
              )
            })}
          </RNMultiToggleSwitch>
        )}
      </Row>
    )
  },
)
