import React from 'react'
import { Platform } from 'react-native'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { BodyText } from '../../components/common/copy/text-body'
import { Logotype } from '../../assets/icons/Logotype'
import { useTranslation } from 'react-i18next'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { Autolink } from 'react-native-autolink'
import { useAuth } from '../../contexts/auth-context'
import { Center } from '../../components/common/center/center'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { Link } from '../../components/common/link/link'

export default function DeleteMyData() {
  const { t } = useTranslation()
  const { authData } = useAuth()

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      {Platform.OS === 'web' && !Boolean(authData?.token) && (
        <Center m={5}>
          <Link href="/">
            <Logotype width={205} height={25} />
          </Link>
        </Center>
      )}
      <PaddedContentArea alignItems={'center'}>
        <HeadingSmall>{t('deleteMyData.heading')}</HeadingSmall>
        <BodyText>
          <Autolink
            text={t('deleteMyData.content')}
            email={false}
            matchers={[
              {
                pattern: /<privacy>(.*)<\/privacy>/g,
                getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                getLinkUrl: () => 'https://elsewhere.to/privacy',
              },
              {
                pattern: /team@elsewhere.to/g,
                getLinkText: (replacerArgs) => `${replacerArgs[0]}`,
                getLinkUrl: (replacerArgs) =>
                  `mailto:${replacerArgs[0]}?subject=${t(
                    'deleteMyData.heading',
                  )}`,
              },
            ]}
            linkProps={{
              style: {
                textDecorationLine: 'underline',
              },
            }}
          />
        </BodyText>
      </PaddedContentArea>
    </ScrollView>
  )
}
