import { FormFieldOption } from '../../../../api/frontend-types'

const personalityTypes = [
  'ISTJ',
  'ISFJ',
  'INFJ',
  'INTJ',
  'ISTP',
  'ISFP',
  'INFP',
  'INTP',
  'ESTP',
  'ESFP',
  'ENFP',
  'ENTP',
  'ESTJ',
  'ESFJ',
  'ENFJ',
  'ENTJ',
].sort((a, b) => a.localeCompare(b))

const myersBriggsPersonalityTypes: FormFieldOption[] = personalityTypes.map(
  (type) => ({
    label: type,
    value: type,
    scriptType: 'latin',
  }),
)

myersBriggsPersonalityTypes.unshift({
  label: 'Prefer not to answer',
  languageKey: 'Prefer not to answer',
  value: '',
})

export { myersBriggsPersonalityTypes }
