import React from 'react'
import { Svg, Path } from 'react-native-svg'

export function Facebook({ size = 20 }: { size: number }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20">
      <Path
        fill="currentColor"
        d="M10 0C4.5 0 0 4.5 0 10c0 4.9 3.6 8.9 8.3 9.7v-6.9H5.9V10h2.4V7.8c0-2.4 1.4-3.7 3.6-3.7 1 0 2 .1 2 .1v2.5h-1.4c-1 0-1.3.6-1.3 1.2V10h2.7l-.4 2.7h-2.3v6.9c4.7-.8 8.3-4.8 8.3-9.7C20 4.5 15.5 0 10 0z"
      />
    </Svg>
  )
}
