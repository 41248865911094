import React, { useEffect, useState } from 'react'
import { MainStackScreenProps } from '../../../navigation/types'
import { TabProps } from '../../../components/layout/tab-view-tabs'
import { capitalize } from '../../../modules/strings/string-helpers'
import { Dimensions } from 'react-native'
import { Loading } from '../../../components/layout/loading'
import { TabView } from '../../../components/layout/tab-view'
import { useFriend } from '../../../hooks/useFriend'
import { useTranslation } from 'react-i18next'
import { SharedDreamsHeader } from './header'
import { FastDream, FastDreamService } from '../../../../../api/_openapi'
import { NoContentMessage } from '../../../components/layout/no-content-message'
import { DreamFeedList } from '../../../components/composite/feed/feed-list-dream'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { GridDisplay } from '../../../components/layout/grid-display'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { Factoid } from '../../../components/composite/stats/factoid'
import { CatIcon } from '../../../assets/react-native-svg/illustrations/Cat'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { Row } from '../../../components/common/row/row'
import { Column } from '../../../components/common/column/column'
import { Box } from '../../../components/common/box/box'

export default function SharedDreams({
  navigation,
  route,
}: MainStackScreenProps<'PrivateFriendSharedDreams'>) {
  const friend = useFriend()
  const user = useSelector(selectUser)
  const { t } = useTranslation()
  const pageTitle = capitalize(t('Shared Dreams'))
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  const [friendsDreams, setFriendsDreams] = useState<FastDream[]>([])
  const [yourDreams, setYourDreams] = useState<FastDream[]>([])

  useEffect(() => {
    if (!friend) {
      return
    }

    if (friend?.friendTagId) {
      FastDreamService.groupDreams(friend.groupId, friend.friendTagId).then(
        (dreams) => {
          setYourDreams(dreams)
        },
      )
    }
  }, [friend, friend?.friendTagId])

  useEffect(() => {
    if (!friend) {
      return
    }

    if (friend?.yourTagId) {
      FastDreamService.groupDreams(friend.groupId, friend.yourTagId).then(
        (dreams) => {
          setFriendsDreams(dreams)
        },
      )
    }
  }, [friend, friend?.yourTagId])

  if (!friend) {
    return <Loading />
  }

  const SharedDreamFeed = (dreams: FastDream[]) => {
    return (
      <Column>
        <Box>
          <PaddedContentArea alignItems={'center'}>
            <GridDisplay
              mb={6}
              maxWidth={WEB_MAX_WIDTH}
              width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
              items={[
                {
                  id: 'factoid-num-dreams',
                  item: (
                    <Factoid
                      key={'factoid-num-dreams'}
                      Icon={CatIcon}
                      factoidFact={dreams.length.toString()}
                      textLineOne={'dreams'}
                      textLineTwo={''}
                      layout="fact-text-text"
                    />
                  ),
                },
              ]}
            />
          </PaddedContentArea>
        </Box>
        {user && (
          <DreamFeedList
            entries={dreams}
            refresh={() => null}
            noEntriesMessage={'test'}
            simpleNoEntriesPage={false}
            appUser={user}
          />
        )}
      </Column>
    )
  }

  const FriendsDreamsAboutYou = () => {
    if (friendsDreams.length === 0) {
      return (
        <Row width={'100%'} justifyContent={'center'}>
          <NoContentMessage
            message={friend.friendName + " hasn't dreamed about you yet!"}
          />
        </Row>
      )
    }

    return SharedDreamFeed(friendsDreams)
  }

  const YourDreamsAboutFriend = () => {
    if (yourDreams.length === 0) {
      return (
        <Row width={'100%'} justifyContent={'center'}>
          <NoContentMessage
            message={
              "You haven't had any dreams about " + friend.friendName + ' yet!'
            }
          />
        </Row>
      )
    }

    return SharedDreamFeed(yourDreams)
  }

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'DreamsAboutFriend',
      children: <YourDreamsAboutFriend />,
      label: 'Your dreams of ' + friend.friendName,
    },
    {
      name: 'DreamsAboutYou',
      children: <FriendsDreamsAboutYou />,
      label: friend.friendName + "'s dreams of you",
    },
  ]

  return (
    <TabView
      header={() => (
        <SharedDreamsHeader
          friendsDreams={friendsDreams}
          yourDreams={yourDreams}
        />
      )}
      tabs={tabs}
      scenes={{
        DreamsAboutFriend: YourDreamsAboutFriend,
        DreamsAboutYou: FriendsDreamsAboutYou,
      }}
    />
  )
}
