import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  IconEdit,
  IconSignout,
  IconTrash,
} from '../../../assets/react-native-svg/icons/icons-ui'
import { useTranslation } from 'react-i18next'
import { SettingsSection } from '../../../../../api/frontend-types'
import { SettingsList } from '../../../components/composite/settings/settings'
import { AlertPopup } from '../../../components/common/dialog/alert-dialog'
import { useSelector } from '../../../ducks/root-reducer'
import {
  selectGroupById,
  selectUserMembershipInGroup,
} from '../../../ducks/groups/groups'
import {
  deleteGroupForUser,
  leaveGroup,
} from '../../../ducks/groups/thunks/group-thunks'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { selectUser } from '../../../ducks/user/user'
import { Loading } from '../../../components/layout/loading'

export type GroupSettingsNavigationProp =
  RootStackNavigationProp<'GroupSettings'>

export default function GroupSettings({
  navigation,
  route,
}: RootStackScreenProps<'GroupSettings'>) {
  // State
  const [leaveGroupAlertOpen, setLeaveGroupAlertOpen] = useState(false)

  // Hooks
  const dispatch = useDispatch<any>()
  let { t } = useTranslation()

  // Route vars
  const activeGroupId = useActiveGroupId()
  const user = useSelector(selectUser)

  // Selectors
  const group = useSelector(selectGroupById(activeGroupId))
  const membershipInfo = useSelector(
    selectUserMembershipInGroup(user?.id, activeGroupId),
  )

  // Vars
  const isGroupOwner = membershipInfo && membershipInfo.role === 'owner'

  const sections: SettingsSection[] = [
    {
      title: t('settings.group.groupInfo'),
      permissions: 'isOwner',
      items: [
        {
          label: t('settings.group.editGroup.title'),
          subLabel: t('settings.group.editGroup.description'),
          icon: IconEdit,
          internalLink: 'GroupProfileEdit',
          internalLinkParams: {
            groupId: activeGroupId,
          },
        },
      ],
    },
    {
      title: t('common.group_plural', { count: 1 }),
      items: [
        {
          label: t('common.leaveGroup'),
          permissions: 'isMember', // Only members can leave group
          icon: IconSignout,
          onPress: () => {
            setLeaveGroupAlertOpen(true)
          },
        },
        {
          label: t('common.deleteGroup'),
          permissions: 'isOwner', // Only owners can delete group
          icon: IconTrash,
          onPress: () => {
            setLeaveGroupAlertOpen(true)
          },
        },
      ],
    },
  ]

  // Filter settings for permission level
  // (e.g. don't show members settings that only owners can edit)
  const sectionsToShow = sections
    // 1) Filter whole settings sections for permissions
    .filter((s) => {
      const p = s.permissions
      const canSeeBecauseOwner = p === 'isOwner' && isGroupOwner
      const canSeeBecauseMember = p === 'isMember' && !isGroupOwner
      return !p || canSeeBecauseOwner || canSeeBecauseMember
    })
    // 2) Filter items within sections for permissions
    .map((s) => {
      return {
        ...s,
        items: s.items.filter((item) => {
          const p = item.permissions
          const canSeeBecauseOwner = p === 'isOwner' && isGroupOwner
          const canSeeBecauseMember = p === 'isMember' && !isGroupOwner
          return !p || canSeeBecauseOwner || canSeeBecauseMember
        }),
      }
    })

  if (!membershipInfo || !group) {
    return <Loading />
  }

  return (
    <>
      <SettingsList
        sections={sectionsToShow}
        navigation={navigation}
        route={route}
      />
      {leaveGroupAlertOpen && (
        <AlertPopup
          isOpen={leaveGroupAlertOpen}
          header={
            isGroupOwner ? t('common.deleteGroup') : t('common.leaveGroup')
          }
          description={
            isGroupOwner
              ? t('dialog.deleteGroup.description', {
                  groupName: group?.name,
                })
              : t('dialog.leaveGroup.description', {
                  groupName: group?.name,
                })
          }
          confirmLabel={
            isGroupOwner
              ? t('common.delete')
              : t('dialog.leaveGroup.confirmButtonLabel')
          }
          onConfirm={() => {
            setLeaveGroupAlertOpen(false)
            if (isGroupOwner) {
              dispatch(deleteGroupForUser(group))
            } else {
              dispatch(leaveGroup(membershipInfo))
            }
            navigation.navigate('MainTabs', {
              screen: 'MainStack',
              params: { screen: 'PrivateFeed' },
            })
          }}
          onClose={() => {
            setLeaveGroupAlertOpen(false)
          }}
        />
      )}
    </>
  )
}
