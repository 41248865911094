import { useEffect, useState } from 'react'

export const useIsConnected = (): boolean | null => {
  const [isConnected, setIsConnected] = useState<boolean | null>(
    navigator.onLine,
  )

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsConnected(navigator.onLine)
    }
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)
    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [])

  return isConnected
}
