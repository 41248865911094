import { Insight, InterpretationStyle } from '../../../../api/_openapi'
import {
  AnalysisResult,
  InterpretationStyleKey,
} from '../../../../api/frontend-types'

/**
 * An AnalysisResult (interpretation) may or may not have a style id on it
 * We need to extract this possible information and turn it into a style key
 * For display purposes
 * @param analysisResult The AnalysisResult
 * @param allInterpStyles Array of all interpretation styles
 * @returns the key for the interpretation style, or 'standard' if not found
 */
export function getInterpStyleKeyForAnalysisResult(
  analysisResult: Insight | null,
  allInterpStyles: InterpretationStyle[],
): InterpretationStyleKey {
  if (!analysisResult) {
    return 'standard'
  } else {
    let ret: InterpretationStyleKey = 'standard'
    const interpStyleId = analysisResult?.data?.interpretationStyle
    const interpStyle = allInterpStyles.find((s) => s.id === interpStyleId)
    if (interpStyle && interpStyle.key) {
      ret = interpStyle.key
    }
    return ret
  }
}
