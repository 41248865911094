import { Image } from 'react-native'
import React from 'react'

export const useImageSize = (source: string) => {
  const [size, setSize] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  React.useEffect(() => {
    Image.getSize(
      source,
      (widthSize, heightSize) => {
        setSize({ width: widthSize, height: heightSize })
      },
      (err) => {
        console.error(err)
      },
    )
  }, [source])

  return size
}
