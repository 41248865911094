import React, { FC, ReactNode } from 'react'
import { useColorModeValue } from 'native-base'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { LINE_WIDTH } from '../../constants/constants'
import { BLACK, CREAM, LIGHT_BLACK, WHITE } from '../../constants/ui-constants'
import { Box } from '../common/box/box'

type HighlightBoxProps = InterfaceBoxProps & {
  // For some mobile devices, it seems
  // the width and height props need to be explicit
  width: number
  height?: number
  children: ReactNode
  hasBorderTop?: boolean
  hasBorderRight?: boolean
  hasBorderBottom?: boolean
  hasBorderLeft?: boolean
}

export const HighlightBox: FC<HighlightBoxProps> = React.memo(
  ({
    width,
    height,
    children,
    hasBorderTop = true,
    hasBorderRight = true,
    hasBorderBottom = true,
    hasBorderLeft = true,
    ...rest
  }: HighlightBoxProps) => {
    const borderColor = useColorModeValue(BLACK, WHITE)
    const bgColor = useColorModeValue(CREAM, LIGHT_BLACK)
    const { style, ...restProps } = rest
    return (
      <Box
        bgColor={bgColor}
        height={height ? `${height}px` : undefined}
        width={`${width}px`}
        flexDirection={'column'}
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
          },
          style,
        ]}
        borderTopWidth={hasBorderTop ? LINE_WIDTH : 0}
        borderRightWidth={hasBorderRight ? LINE_WIDTH : 0}
        borderBottomWidth={hasBorderBottom ? LINE_WIDTH : 0}
        borderLeftWidth={hasBorderLeft ? LINE_WIDTH : 0}
        borderColor={borderColor}
        {...restProps}
      >
        {children}
      </Box>
    )
  },
)
