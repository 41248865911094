import React from 'react'
import { StyleSheet } from 'react-native'
import { SansText } from '../../common/copy/text-sans'
import { useColorModeValue } from 'native-base'
import { CREAM, LIGHT_BLACK } from '../../../constants/ui-constants'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { snakeCase } from '../../../modules/strings/string-helpers'
import { SymbolComponent, SymbolComponentMap } from '../../../assets/symbols'
import { LINE_WIDTH } from '../../../constants/constants'
import { PressableFixed } from '../../../utilities/PressableFixed'
import { HeadingMain } from '../../common/copy/heading-main'
import { Row } from '../../common/row/row'
import { Box } from '../../common/box/box'
import { Column } from '../../common/column/column'

type SquareDisplayProps = {
  width: number
  hidden?: boolean
  hiddenText?: string
  showNumUses?: boolean
  clickable?: boolean
  onPress?: () => void
  editable?: boolean
  showTitle?: boolean
  onDeleteSymbol?: () => void
  manualTitle?: string
  symbolKey: string
  count?: number
  label: string
}

export const SquareDisplay = React.memo(
  ({
    width,
    hidden,
    hiddenText,
    showNumUses,
    clickable = true,
    showTitle = true,
    editable,
    onDeleteSymbol,
    onPress,
    symbolKey,
    count,
    label,
  }: SquareDisplayProps) => {
    // HOOKS
    const color = useColorModeValue(LIGHT_BLACK, CREAM)
    const backgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)
    const borderColor = useColorModeValue(LIGHT_BLACK, CREAM)

    // VARS
    const symbolWidth = Math.floor((width / 100) * 40) // 55% of the square width

    // ELEMENTS

    // 1) Symbol illustration
    const SymbolComponent: SymbolComponent | null =
      SymbolComponentMap[snakeCase(symbolKey)] || null

    const SymbolElement = SymbolComponent ? (
      <SymbolComponent size={symbolWidth} color={color} />
    ) : null

    // 2) Symbol title
    // React native doesn't support borders on nested text elements (May 2023)
    // And we need this to outline numUses with a circle
    // So we have to split the text by word and wrap each word in a box
    // So we can do a flex-wrap layout
    const labelTextSplit = label.split(' ')
    let symbolTitle: JSX.Element | null = null
    if (showTitle) {
      symbolTitle = hidden ? (
        <Box>
          <SansText fontSize={'sm'}>?</SansText>
        </Box>
      ) : (
        <Box>
          {labelTextSplit.map((word, i) => {
            return (
              <Box key={i} style={styles.wordBox}>
                <SansText fontSize={'sm'}>{word}</SansText>
              </Box>
            )
          })}
        </Box>
      )
    }

    return (
      <PressableFixed
        onPress={
          editable
            ? undefined
            : () => {
                if (!clickable) {
                  console.log('TODO: symbols in groups not implemented yet')
                  return undefined
                } else if (hidden) {
                  return
                } else {
                  if (onPress) {
                    onPress()
                  }
                }
              }
        }
        _web={{
          // @ts-ignore
          cursor: editable ? 'default' : 'pointer',
        }}
      >
        <Column
          style={{
            ...styles.square,
            width: width,
            height: width,
            backgroundColor: backgroundColor,
          }}
          borderRadius={300}
          borderColor={borderColor}
          borderWidth={LINE_WIDTH}
        >
          {/* CROSS ICON TO DELETE SYMBOL TAG */}
          {editable && (
            <Row
              justifyContent={'flex-end'}
              style={{
                width: '100%',
                position: 'absolute',
                top: 0,
              }}
            >
              <ButtonIcon
                iconKey="close"
                variant={'ghost'}
                size={'2xs'}
                bgColor={'transparent'}
                onPress={() => {
                  if (onDeleteSymbol) {
                    onDeleteSymbol()
                  }
                }}
              />
            </Row>
          )}
          {hidden ? (
            <>
              <Box
                height={`${symbolWidth}px`}
                width={`${symbolWidth}px`}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <HeadingMain mt={4}>{hiddenText}</HeadingMain>
              </Box>
            </>
          ) : (
            <>{SymbolElement}</>
          )}

          <Box style={styles.symbolLabel} mt={2}>
            {symbolTitle}

            {!hidden && Boolean(showNumUses) && (
              <Box
                style={{
                  ...styles.numSymbolUsesBox,
                  backgroundColor,
                  borderColor,
                }}
                borderRadius={'full'}
              >
                <SansText
                  style={{ ...styles.numUses, color: borderColor }}
                  fontSize={'sm'}
                >
                  {count}
                </SansText>
              </Box>
            )}
          </Box>
        </Column>
      </PressableFixed>
    )
  },
)

const styles = StyleSheet.create({
  square: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  symbolLabel: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  numSymbolUsesBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    minHeight: 20,
    minWidth: 20,
    paddingHorizontal: 4,
    paddingTop: 1,
  },
  wordBox: {
    marginRight: 4,
  },
  numUses: {
    fontSize: 12,
  },
})
