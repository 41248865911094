import React from 'react'
import { Size } from '../../../core/theme'
import { IIconButtonProps, useColorModeValue } from 'native-base'
import { ColorValue } from 'react-native'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  ElsewhereIconType,
  iconMap,
} from '../../../modules/ui-helpers/icon-map'

export type ButtonIconProps = IIconButtonProps & {
  iconKey: ElsewhereIconType
  size?: Size
  color?: ColorValue
  fill?: ColorValue
}

export const ElsewhereIcon = ({
  color,
  fill,
  size,
  iconKey,
  marginBottom,
}: ButtonIconProps) => {
  const Icon = iconMap[iconKey]
  const defaultColor = useColorModeValue(BLACK, WHITE)
  const iconColor = color || defaultColor

  return (
    <Icon
      color={iconColor}
      fill={fill}
      size={size}
      marginBottom={marginBottom}
    />
  )
}
