import React, { MutableRefObject, TextareaHTMLAttributes } from 'react'
import { useColorModeValue } from 'native-base'
import { RefCallBack } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { BODY_FONT } from '../../../constants/constants'
import i18n from 'i18next'
import { getBodyFont } from '../../../modules/language-helpers/language-helpers'

type InputTextAreaProps = TextareaHTMLAttributes<any> & {
  // Make placeholder required, otherwise you can't see the text area
  placeholder: string
  initialHeight: number | undefined
  inputRef: RefCallBack
  initialString?: MutableRefObject<string>
  isInvalid: boolean
  onBlur: () => void
  onChangeText: (text: string) => void
  minHeight?: number
  fieldName?: string
  minRows?: number
  isDisabled?: boolean
  autoCorrect?: boolean
  fontFamily?: string
  mb?: number
  fontSize?: number
  color?: string
}

export function TextArea({
  placeholder,
  style,
  initialHeight = 222,
  onChangeText,
  inputRef,
  isInvalid,
  onBlur,
  initialString,
  minHeight, // unused on web
  minRows = 10,
  mb = 20,
  fieldName,
  isDisabled = false,
  autoCorrect,
  fontFamily,
  fontSize = 18,
  color,
  ...otherProps
}: InputTextAreaProps) {
  // HOOKS
  const defaultColor = useColorModeValue(BLACK, WHITE)
  const backgroundColor = useColorModeValue(WHITE, BLACK)

  // VARS
  // This needs to be a string on web, apparently
  const autoCorrectValue = `${autoCorrect || false}`
  const textColor = color || defaultColor
  const dir = i18n.dir()
  const language = i18n.resolvedLanguage || 'en'
  const fontFamilyValue = fontFamily || getBodyFont(language)

  return (
    <TextareaAutosize
      id={`textarea-${fieldName}`}
      ref={inputRef}
      placeholder={placeholder}
      dir={dir}
      style={{
        fontFamily: fontFamilyValue,
        fontSize,
        fontWeight: '400',
        fontStyle: 'normal',
        display: 'flex',
        flexBasis: 'auto',
        flexShrink: '0',
        padding: '0px',
        position: 'relative',
        textDecoration: 'none',
        zIndex: '0',
        borderColor: 'rgb(64, 64, 64)',
        borderWidth: '0px',
        overflow: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor,
        lineHeight: '1.25em',
        color: textColor,
        outline: 'none',
        marginBottom: mb,
        resize: 'none',
        width: '100%',
      }}
      defaultValue={initialString?.current}
      minRows={minRows}
      onChange={(e) => onChangeText(e.target.value)}
      disabled={isDisabled}
      autoCorrect={autoCorrectValue}
      {...otherProps}
    />
  )
}
