import React, { ReactNode } from 'react'

// See https://github.com/facebook/react-native/issues/28602

export const requestIdleCallback = function (
  cb: any,
  { timeout }: { timeout: number },
) {
  const start = Date.now()
  return setTimeout(function () {
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start))
      },
    })
  }, timeout)
}

// See https://itnext.io/improving-slow-mounts-in-react-apps-cff5117696dc

export const Defer = ({
  chunkSize,
  children,
}: {
  chunkSize: number
  children: ReactNode
}) => {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize)

  const childrenArray = React.useMemo(
    () => React.Children.toArray(children),
    [children],
  )

  React.useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length),
          )
        },
        { timeout: 50 },
      )
    }
  }, [renderedItemsCount, childrenArray.length, chunkSize])

  return <>{childrenArray.slice(0, renderedItemsCount)}</>
}
