import React, { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import { GridDisplay } from '../../components/layout/grid-display'
import axios from 'axios'
import { MaskIcon } from '../../assets/react-native-svg/illustrations/Mask'
import { GhostIcon } from '../../assets/react-native-svg/illustrations/Ghost'
import { useAuth } from '../../contexts/auth-context'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { Factoid } from '../../components/composite/stats/factoid'
import { useTranslation } from 'react-i18next'
import { DateRangeWithKey } from '../../../../api/frontend-types'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import { FlyingIcon } from '../../assets/react-native-svg/illustrations/Flying'
import { SentimentTimelineChart } from './sentiment-timeline-chart'
import { OpenAPI } from '../../../../api/_openapi'
import { Box } from '../../components/common/box/box'

type SentimentDataItem = {
  sentiment: string | null
  positive: string | null
  negative: string | null
}

type SentimentPercentages = {
  negative: number
  positive: number
  sentiment: number
  total: number
}

type AverageSentimentResponse = {
  globalAverageSentiment: SentimentDataItem
  userAverageSentiment: SentimentDataItem
  percentDreamsPositive: number
  sentimentPercentages: SentimentPercentages
}

type SentimentChartProps = {
  dateRangeWithKey: DateRangeWithKey
}

type SentimentFactoidType = 'percentile' | 'goodDreams' | 'average'

type SentimentFactoidData = {
  percentile: number | null
  goodDreams: number | null
  average: number | null
}

export const PADDING_RIGHT = 60

export const SentimentCharts = ({ dateRangeWithKey }: SentimentChartProps) => {
  // STATE
  const [factoidData, setFactoidData] = useState<SentimentFactoidData | null>(
    null,
  )

  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()

  // VARS
  const { range: dateRange } = dateRangeWithKey

  // PAGE VARIABLES
  const pageWidth = Dimensions.get('window').width

  const factoidLabels: {
    [key in SentimentFactoidType]: {
      line1: string
      line2: string
      Icon: (props: any) => JSX.Element
    }
  } = {
    percentile: {
      line1: t('chartsPage.factoids.percentile.line1'),
      line2: t('chartsPage.factoids.percentile.line2'),
      Icon: MaskIcon,
    },
    goodDreams: {
      line1: t('chartsPage.factoids.goodDreams.line1'),
      line2: t('chartsPage.factoids.goodDreams.line2'),
      Icon: FlyingIcon,
    },
    average: {
      line1: t('chartsPage.factoids.averageSentiment.line1'),
      line2:
        factoidData && factoidData.average && factoidData.average > 0
          ? t('chartsPage.factoids.averageSentiment.line2.positive')
          : t('chartsPage.factoids.averageSentiment.line2.negative'),
      Icon: GhostIcon,
    },
  }

  // EFFECTS
  // Set dreams and tags
  useEffect(() => {
    // Call /api/power-di/average-sentiment
    // With startDate and endDate parameters
    const params = {
      startDate: dateRange.start
        ? // ? dateRange.start.toISOString().slice(0, 10)
          dateRange.start.toISOString()
        : null,
      // endDate: dateRange.end.toISOString().slice(0, 10),
      endDate: dateRange.end.toISOString(),
    }

    // Average sentiment
    axios({
      method: 'get',
      url: `${OpenAPI.BASE}/api/power-di/average-sentiment`,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authData?.token,
      },
    })
      .then(function (response) {
        const data: AverageSentimentResponse = response.data
        const factoidData = calculateFactoids(data)
        setFactoidData(factoidData)
      })
      .catch((err) => {
        console.log('Error getting average sentiment')
        console.log(err)
        console.log('params were', params)
      })
  }, [dateRange])

  return (
    <Box>
      <PaddedContentArea alignItems={'center'}>
        {factoidData && (
          <GridDisplay
            mt={4}
            mb={6}
            maxWidth={WEB_MAX_WIDTH}
            width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
            items={Object.entries(factoidData).map(([key, value]) => {
              // @ts-ignore
              const { line1, line2, Icon } = factoidLabels[key]

              // The average says "above" or "below"
              // So we want the absolute value
              let factoidForDisplay = value
              if (key === 'average') {
                factoidForDisplay = value ? Math.abs(value) : null
              }

              return {
                id: key,
                item: (
                  <Factoid
                    key={key}
                    Icon={Icon}
                    factoidFact={value ? `${factoidForDisplay}%` : 'N/A'}
                    textLineOne={`${line1}`}
                    textLineTwo={line2}
                    layout="text-fact-text"
                  />
                ),
              }
            })}
          />
        )}
        <SentimentTimelineChart dateRangeWithKey={dateRangeWithKey} />
      </PaddedContentArea>
    </Box>
  )
}

// We want to know what percentage better or worse the user is than the global average
// So we need to calculate the difference between the two
function calculateFactoids(
  avgSentiment: AverageSentimentResponse,
): SentimentFactoidData {
  const { globalAverageSentiment, userAverageSentiment } = avgSentiment
  const { sentiment: globalSentiment } = globalAverageSentiment

  const { sentiment: userSentiment } = userAverageSentiment

  let factoidAvg
  if (globalSentiment !== null && userSentiment !== null) {
    const globalSentimentNumber = parseFloat(globalSentiment)
    const userSentimentNumber = parseFloat(userSentiment)
    const sentimentDifference = globalSentimentNumber - userSentimentNumber
    // Divide by 2, since the range is -1 to 1
    // Instead of 0 to 1 or -1 to 0
    factoidAvg = Math.round((sentimentDifference * 100) / 2)
  } else {
    factoidAvg = null
  }

  // sentimentPercentages.sentiment is the
  // "percentage of users who have better sentiment than you"
  // We want to invert that to get the percentage of users who have worse sentiment than you
  const youAreHappierThan = 100 - avgSentiment.sentimentPercentages.sentiment

  // Get percent positive dreams
  const percentPositiveDreams = avgSentiment.percentDreamsPositive
    ? Math.round(avgSentiment.percentDreamsPositive)
    : null

  return {
    percentile: Math.round(youAreHappierThan),
    goodDreams: percentPositiveDreams,
    average: factoidAvg,
  }
}
