import React from 'react'
import { Box as NbBox } from 'native-base'
import { InterfaceBoxProps as NbBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'

export type InterfaceBoxProps = NbBoxProps

// This is a simple wrapper around native-base's Box component
export const Box = ({ children, ...rest }: InterfaceBoxProps) => {
  return <NbBox {...rest}>{children}</NbBox>
}
