import React from 'react'
import { IScrollViewProps } from 'native-base/lib/typescript/components/basic/ScrollView/types'
import { ScrollView as NbScrollView } from 'native-base'

export type ScrollViewProps = IScrollViewProps

// This is a simple wrapper around native-base's View component
export const ScrollView = ({ children, ...rest }: ScrollViewProps) => {
  return <NbScrollView {...rest}>{children}</NbScrollView>
}
