import React from 'react'
import { TabProps, Tabs } from '../../../components/layout/tab-view-tabs'
import { TabView } from '../../../components/layout/tab-view'
import { useTranslation } from 'react-i18next'
import { MainStackScreenProps } from '../../../navigation/types'
import { Friend, FriendService } from '../../../../../api/_openapi'
import { FlatList, Keyboard, Platform, Pressable } from 'react-native'
import { CentredListEmptyComponent } from '../../../components/layout/centred-list-empty-component'
import { Loading } from '../../../components/layout/loading'
import { NoContentMessage } from '../../../components/layout/no-content-message'
import { FriendListItem } from './FriendListItem'
import { LINE_WIDTH, WEB_MAX_WIDTH } from '../../../constants/constants'
import { InputSearch } from '../../../components/common/inputs/input-search'
import { loadUserGroups } from '../../../ducks/groups/thunks/group-thunks'
import { useDispatch } from 'react-redux'
import { Row } from '../../../components/common/row/row'

export default function FriendsMain({
  navigation,
  route,
}: MainStackScreenProps<'PrivateFriends'>) {
  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const isWeb = Platform.OS === 'web'

  const [searchTerm, setSearchTerm] = React.useState('')
  const [hasSearched, setHasSearched] = React.useState(false)
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [searchRes, setSearchRes] = React.useState<Friend[]>([])
  const shouldShowResults =
    hasSearched && !searchLoading && searchRes.length > 0

  const [loading, setLoading] = React.useState(true)
  const [friends, setFriends] = React.useState<Friend[]>([])

  const searchAsync = async (username: string): Promise<Friend[]> => {
    //@ts-ignore
    return FriendService.searchFriends({
      username,
    })
  }

  const handleSearch = async () => {
    setSearchLoading(true)
    setHasSearched(true)
    try {
      const results = await searchAsync(searchTerm)
      setSearchRes(results)
    } catch (error) {
      console.error('Search error:', error)
    } finally {
      setSearchLoading(false)
    }
  }

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term)
    setHasSearched(false)
  }

  React.useEffect(() => {
    if (loading) {
      FriendService.getFriends().then((response) => {
        setFriends(response)
        setLoading(false)
      })
    }
  }, [loading])

  const accept = (friend: Friend) => {
    FriendService.acceptRequest(friend.id, {}).then(() => {
      //reload groups!
      dispatch(loadUserGroups())

      setFriends([])
      setLoading(true)
    })
  }

  const reject = (friend: Friend) => {
    FriendService.rejectRequest(friend.id, {}).then(() => {
      setFriends([])
      setLoading(true)
    })
  }

  const unfriend = (friend: Friend) => {
    FriendService.unfriend(friend.id).then(() => {
      //reload groups!
      dispatch(loadUserGroups())

      setFriends([])
      setLoading(true)
    })
  }

  const add = (friend: Friend) => {
    FriendService.sendRequest(friend.id, {
      message: friend.message,
    }).then(() => {
      setFriends([])
      setLoading(true)
    })
  }

  // COMPONENTS
  const FriendsTab = () => (
    <Tabs.FlatList
      data={friends}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      keyExtractor={(item: Friend) => item.id || ''}
      ListEmptyComponent={CentredListEmptyComponent(
        loading ? (
          <Loading />
        ) : (
          <NoContentMessage message={"You don't have any friends :("} />
        ),
      )}
      renderItem={({ item, index }) => {
        return (
          <FriendListItem
            item={item}
            index={index}
            accept={accept}
            reject={reject}
            unfriend={unfriend}
            add={add}
          />
        )
      }}
    />
  )

  const GroupsTab = () => <p>Groups!</p>

  const SearchTab = () => (
    <Pressable
      style={{
        flex: 1,
        maxWidth: WEB_MAX_WIDTH,
        margin: 'auto',
      }}
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
    >
      <Row paddingLeft={4} paddingRight={4} justifyContent="center" mt={6}>
        <InputSearch
          value={searchTerm}
          onChangeText={handleSetSearchTerm}
          placeholder={t('searchPage.searchPlaceholder')}
          onSubmit={handleSearch}
          onSubmitEditing={handleSearch}
          onClear={() => {
            setSearchTerm('')
            setHasSearched(false)
          }}
          showClearButton={searchTerm !== ''}
          mb={4}
          autoFocus
          wrapperWidth={isWeb ? WEB_MAX_WIDTH - LINE_WIDTH * 2 : undefined}
        />
      </Row>

      {searchLoading && (
        <Row paddingLeft={4} paddingRight={4} justifyContent="center">
          <Loading />
        </Row>
      )}
      {!searchLoading && searchRes.length === 0 && hasSearched && (
        <Row
          paddingLeft={4}
          paddingRight={4}
          marginTop={12}
          justifyContent="center"
        >
          <NoContentMessage message={'No search results'} />
        </Row>
      )}
      {shouldShowResults && (
        <FlatList
          data={searchRes}
          keyExtractor={(item, i) => (item?.id || '').toString() + i}
          contentContainerStyle={{
            marginTop: LINE_WIDTH * 3,
            flex: undefined,
            height: undefined,
          }}
          renderItem={({ item, index }) => (
            <FriendListItem
              item={item}
              index={index}
              accept={accept}
              reject={reject}
              unfriend={unfriend}
              add={add}
            />
          )}
          initialNumToRender={5}
        />
      )}
    </Pressable>
  )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Friends',
      children: <FriendsTab />,
      label: t('common.friends'),
    },
    {
      name: 'Groups',
      children: <GroupsTab />,
      label: t('Groups'),
    },
    {
      name: 'Search',
      children: <SearchTab />,
      label: t('Search'),
    },
  ]

  return (
    <TabView
      tabs={tabs}
      scenes={{
        Friends: FriendsTab,
        Groups: GroupsTab,
        Search: SearchTab,
      }}
    />
  )
}
