import React, { useState, memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { deleteDream } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { useTranslation } from 'react-i18next'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { useSelector } from '../../../ducks/root-reducer'
import { idEquals } from '../../../ducks/helpers'
import { useUserDate } from '../../../hooks/useUserDate'
import { elsewhereToast } from '../../common/toast/toast'
import { ActionSheetMenu } from '../../common/action-sheet/action-sheet-menu'
import { ButtonIcon } from '../../common/buttons/button-icon'
import Clipboard from '@react-native-clipboard/clipboard'
import { selectUser } from '../../../ducks/user/user'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import { TEMP_DREAM_ID_PREFIX } from '../../../constants/constants'
import { unshareDreamFromGroups } from '../../../ducks/groups/thunks/group-thunks'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  selectPrivateGroupId,
  selectUserGroups,
} from '../../../ducks/groups/groups'
import { DreamViewNavigationProp } from '../../../screens/Dreams/DreamView'
import { DraftViewNavigationProp } from '../../../screens/Drafts/DraftView'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'

export type DreamEllipsisNavigationProp =
  | DreamViewNavigationProp
  | DraftViewNavigationProp

const DreamEllipsis = () => {
  // STATE
  const [actionSheetIsOpen, setActionSheetIsOpen] = useState(false)
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const [unshareAlertOpen, setUnshareAlertOpen] = useState(false)

  // SELECTORS
  const user = useSelector(selectUser)
  const joinedGroups = useSelector(selectUserGroups)
  const privateGroupId = useSelector(selectPrivateGroupId)

  // HOOKS
  const { t } = useTranslation()
  const route = useRoute<any>()
  const dreamId = route.params.id
  const dispatch = useDispatch<any>()
  const navigation = useNavigation<DreamEllipsisNavigationProp>()

  // DREAM
  const dream = useSelector(selectFastDreamById(dreamId))
  const { dateString } = useUserDate(dream?.date as string)

  // VARS
  const isDraft = Boolean(dream?.isDraft)
  const dreamType = dream?.type
  const activeGroupId = useActiveGroupId()
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)

  // I18N
  const editLabel = isDraft ? t('editDraftPage.header') : t('common.edit')

  const currentGroup = useMemo(() => {
    return joinedGroups.find((g) => g.id === activeGroupId)
  }, [user, privateGroupId, joinedGroups, activeGroupId, navigation, route])

  const hasGroups =
    joinedGroups.filter((g) => g.type !== 'user' && g.name !== 'Public')
      .length > 0

  const confirmMessage = dream?.title
    ? t('deleteDreamDialog.description.hasTitle', { titleParam: dream?.title })
    : t('deleteDreamDialog.description.noTitle')

  // TODO: code below is duplicated in /feed-item-dream
  const copyToClipboard = async () => {
    Clipboard.setString(
      `${t('common.dream_plural', { count: 1 }).toLocaleUpperCase()}:\n\n` +
        (dream?.title || dateString) +
        '\n\n' +
        dream?.description +
        (dream?.note
          ? `\n\n${t('common.note').toLocaleUpperCase()}:\n\n` + dream?.note
          : ''),
    )
    elsewhereToast.showToast({
      description: t('toast.copyToClipboard.title'),
      status: 'success',
    })
  }

  const actionSheetMenuItems = [
    {
      label: editLabel,
      closesMenu: true,
      onPress: () => {
        const editRoute = isDraft
          ? 'PrivateDraftEdit'
          : dreamType === 'journal-entry'
          ? 'PrivateDiaryEdit'
          : 'PrivateDreamEdit'
        navigation.push(editRoute, {
          id: route.params.id,
        })
      },
    },
    ...(!hasGroups || isDraft || dreamType !== 'dream'
      ? []
      : [
          {
            label: t('common.share'),
            closesMenu: true,
            onPress: () => {
              navigation.push('PrivateDreamShare', {
                id: route.params.id,
              })
            },
          },
        ]),
    ...(isUserPrivateGroup || dreamType !== 'dream'
      ? []
      : [
          {
            label: t('common.unshare'),
            closesMenu: true,
            onPress: () => {
              setUnshareAlertOpen(true)
            },
          },
        ]),
    {
      label: t('common.copyToClipboard'),
      closesMenu: true,
      onPress: () => copyToClipboard(),
    },
  ]

  // Vars
  // If the dream doesn't have an id (only a tempId)
  // it's not editable yet
  const actionSheetOpenable = Boolean(
    dreamId && !dreamId.includes(TEMP_DREAM_ID_PREFIX),
  )
  const appUser = useSelector(selectUser)
  const isLoggedInUser = idEquals(dream?.userId, appUser?.id)

  if (!dreamId || !currentGroup) {
    return null
  }

  return (
    <>
      {isLoggedInUser && (
        <ButtonIcon
          iconKey="ellipsis"
          size={'xs'}
          disabled={!actionSheetOpenable || !isLoggedInUser}
          variant={'ghost'}
          onPress={() => {
            if (actionSheetOpenable) {
              setActionSheetIsOpen(true)
            } else {
              elsewhereToast.showToast({
                title: 'toast.waitForImageAndTagsWarning.title',
                description: 'toast.waitForImageAndTagsWarning.description',
                status: 'warning',
              })
            }
          }}
        />
      )}
      {actionSheetIsOpen && (
        <ActionSheetMenu
          isOpen={actionSheetIsOpen}
          onClose={() => {
            setActionSheetIsOpen(false)
          }}
          menuItems={[
            ...actionSheetMenuItems,
            ...(!isUserPrivateGroup
              ? []
              : [
                  {
                    label: t('common.delete'),
                    closesMenu: true,
                    onPress: () => {
                      setDeleteAlertOpen(true)
                    },
                  },
                ]),
            {
              label: t('common.cancel'),
              onPress: () => {
                setActionSheetIsOpen(false)
              },
            },
          ]}
        />
      )}
      {deleteAlertOpen && (
        <AlertPopup
          isOpen={deleteAlertOpen}
          header={t('deleteDreamDialog.title')}
          description={confirmMessage}
          confirmLabel={t('common.delete')}
          onConfirm={() => {
            setDeleteAlertOpen(false)
            dispatch(deleteDream(privateGroupId || '', dreamId))
            navigation.replace('MainTabs', {
              screen: 'MainStack',
              params: {
                screen: 'PrivateFeed',
              },
            })
          }}
          onClose={() => {
            setDeleteAlertOpen(false)
          }}
        />
      )}
      {unshareAlertOpen && (
        <AlertPopup
          isOpen={unshareAlertOpen}
          header={t('common.unshare')}
          description={t('dialog.unshareDream.description', {
            groupName: currentGroup?.name || '',
          })}
          confirmLabel={t('common.unshare')}
          onConfirm={() => {
            setUnshareAlertOpen(false)
            dispatch(unshareDreamFromGroups(dreamId, [currentGroup.id]))
          }}
          onClose={() => {
            setUnshareAlertOpen(false)
          }}
        />
      )}
    </>
  )
}

export default memo(DreamEllipsis)
