import React from 'react'
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'
import { Center as NbCenter } from 'native-base'

export type CenterProps = InterfaceHStackProps

// This is a simple wrapper around native-base's Column component
export const Center = ({ children, flexDirection, ...rest }: CenterProps) => {
  return <NbCenter {...rest}>{children}</NbCenter>
}
