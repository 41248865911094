import axios from 'axios'
import { parse, ParseResult } from 'papaparse'
import { allowedColumns, requiredColumns, UploadDream } from './index.web'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { MAX_FILESIZE_BYTES } from '../../../constants/constants'
import { bytesToMB } from '../../../modules/ui-helpers/file-helpers'
import { isValidYYYYMMDDDate } from '../../../modules/date-helpers/date-helpers'
import { OpenAPI } from '../../../../../api/_openapi'

export function validateCsvFile(file: File): boolean {
  if (!file) {
    elsewhereToast.showToast({
      description: 'common.noFileChosen',
      status: 'error',
      duration: 4000,
    })
    return false
  }
  // Warn if the file is too large
  else if (file.size > MAX_FILESIZE_BYTES) {
    elsewhereToast.showToast({
      description: 'errors.fileTooLarge',
      status: 'error',
      descriptionParam: bytesToMB(MAX_FILESIZE_BYTES),
      duration: 4000,
    })
    return false
  }
  // Warn if the file is not a CSV
  else if (file.type !== 'text/csv') {
    elsewhereToast.showToast({
      description: 'importDataPage.wrongFormatWarning',
      status: 'error',
      duration: 4000,
    })
    return false
  } else {
    return true
  }
}

const papaParseConfig = {
  header: true,
}

export function parseFile(
  file: File,
  onParse: (result: UploadDream[]) => void,
) {
  parse(file, {
    ...papaParseConfig,
    complete: (result: ParseResult<UploadDream>) => {
      const { data, errors, meta } = result

      // Error handling
      if (errors.length) {
        elsewhereToast.showToast({
          description: 'errors.fileParseError',
          status: 'error',
          duration: 4000,
        })
        return
      } else {
        // Check that the file has the right columns
        const columns = meta.fields || []
        const missingColumns = requiredColumns.filter(
          (column) => !columns.includes(column),
        )
        // Warn if there are missing columns
        if (missingColumns.length) {
          elsewhereToast.showToast({
            description: 'errors.requiredUploadHeadersMissing',
            status: 'error',
            descriptionParam: missingColumns.join(', '),
            duration: 5000,
          })
          return
        }
        const extraColumns = columns.filter(
          (column) => !allowedColumns.includes(column as keyof UploadDream),
        )
        // Warn if there are extra columns
        if (extraColumns.length) {
          elsewhereToast.showToast({
            description: 'errors.unrecognizedHeaders',
            status: 'error',
            descriptionParam: extraColumns.join(' / '),
            duration: 5000,
          })
          return
        }

        // Check that every date is in the right format
        // We ignore date_format for now and assume YYYY-MM-DD
        // Loop through each row
        const hasInvalidDates = data.some((row) => {
          return !isValidYYYYMMDDDate(row.date)
        })

        // Warn if there are invalid dates
        if (hasInvalidDates) {
          elsewhereToast.showToast({
            description: 'errors.invalidDateFormat',
            status: 'error',
            duration: 5000,
          })
          return
        }

        // If there are no errors, call the onParse function
        onParse(result.data)
      }
    },
  })
}

// We have a route, api/magic-import POST, that can be used to upload a file to the server.
// This is the page where the user can upload a file.
// The file will be sent to the server and processed.

// function onSubmit() {
//   const file = document.getElementById('fileToUpload') as HTMLInputElement
//   if (file.files) {
//     onFileUpload(file.files[0], token)
//   }
// }

export async function onFileUpload(file: File, token: string | undefined) {
  if (!token) {
    console.log('No token found')
    return
  }

  // Send the file to the server
  // The server will process the file
  // The server will return a response
  // The response will be displayed to the user
  const route = `${OpenAPI.BASE}/api/magic-import`
  const formData = new FormData()
  formData.append('file', file)
  const response = await axios({
    method: 'post',
    url: route,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
  })
  console.log('Response: ', response)
}
