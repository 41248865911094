import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/auth-context'
import { elsewhereToast } from '../../components/common/toast/toast'
import { useTranslation } from 'react-i18next'
import { bookImg } from '../../constants/ui-constants'
import { getContentHeight } from '../../modules/ui-helpers/ui-helpers'
import Autolink from 'react-native-autolink'
import {
  RootStackNavigationProp,
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/types'
import { RouteProp } from '@react-navigation/native'
import { SansText } from '../../components/common/copy/text-sans'
import { LoadingPage } from '../../components/layout/loading-page'
import { Img } from '../../components/common/image/image'
import { Column } from '../../components/common/column/column'

export type LoginNavigationProp = RootStackNavigationProp<'Login'>
export type LoginRouteProp = RouteProp<RootStackParamList, 'Login'>

export default function MagicLogin({
  navigation,
  route,
}: RootStackScreenProps<'MagicLogin'>) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  // HOOKS
  const { t } = useTranslation()
  const auth = useAuth()

  // VARS
  const pageContentHeight = getContentHeight()
  const routeEmail = route.params?.email || ''
  const code = route.params?.code || ''
  const email = routeEmail.replace(/ /g, '+') // Replace space with + (some url encoding thing)

  const signIn = async (email: string, code: string) => {
    const success = await auth.signIn(email, code)
    if (success as any) {
      return
    } else {
      elsewhereToast.showToast({
        description: 'toast.invalidPasscode.description',
        status: 'error',
        duration: 4000,
      })
      setHasError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (email && code) {
      console.log('email', email)
      console.log('code', code)

      signIn(email, code)
    }
  }, [email, code])

  if (isLoading) {
    return <LoadingPage hasQuote={true} />
  } else {
    return (
      <Column
        height={pageContentHeight}
        justifyContent="center"
        alignItems="center"
      >
        {/* INSTRUCTIONS TO RETURN TO LOGIN IF ERROR */}
        {hasError && (
          <Column justifyContent="center" alignItems="center">
            <Img
              source={bookImg}
              borderRadius="full"
              width={200}
              height={200}
              alt={t('symbol.Book')}
            />
            <SansText maxW={400} mt={10}>
              <Autolink
                text={t('magicLogin.error') as string}
                matchers={[
                  {
                    pattern: /<linkTag>(.*)<\/linkTag>/g,
                    getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                    onPress: () => {
                      navigation.navigate('Login')
                    },
                  },
                ]}
                linkProps={{
                  style: {
                    textDecorationLine: 'underline',
                  },
                }}
              />
            </SansText>
          </Column>
        )}
      </Column>
    )
  }
}
