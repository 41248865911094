import React, { ReactNode } from 'react'
import { Dimensions } from 'react-native'
import { Loading } from './loading'
import { InfoText } from '../common/copy/text-info'
import { useTranslation } from 'react-i18next'
import { Column } from '../common/column/column'

type LoadingPageProps = {
  title?: ReactNode
  hasQuote?: boolean
}

export const LoadingPage = ({ title, hasQuote }: LoadingPageProps) => {
  // HOOKS
  const { t } = useTranslation()

  // VARS
  const pageHeight = Dimensions.get('window').height
  const approximateHeaderHeight = 60
  const approximateFooterHeight = 60
  const componentHeight =
    pageHeight - approximateHeaderHeight - approximateFooterHeight
  const titleText = title || t('loading.loadingGeneric')

  // Check if the title is a string
  // If it is, use it as the title
  const titleIsString = typeof titleText === 'string'

  return (
    <Column
      style={{
        width: '100%',
        height: componentHeight,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Loading hasQuote={hasQuote} />
      {
        // If the title is a string, display it within an InfoText component
        titleIsString && !hasQuote && <InfoText mt={4}>{titleText}</InfoText>
      }
      {
        // If the title is not a string, display it as is
        !titleIsString && !hasQuote && titleText
      }
    </Column>
  )
}
