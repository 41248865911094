import * as React from 'react'
import { AvatarCircle, AvatarCircleProps } from './avatar-circle'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import {
  SM_THUMB_RES,
  MD_THUMB_RES,
  XL_THUMB_RES,
} from '../../../constants/ui-constants'
import { Size } from '../../../core/theme'

// Standard https://docs.nativebase.io/avatar props
// Plus a few extras
type AvatarCircleMediaProps = Omit<AvatarCircleProps, 'source'> & {
  source?: string
  name: string
  loading?: boolean
  disablePress?: boolean
}

const imgSizeMap: { [key in Size]: number } = {
  '2xs': SM_THUMB_RES,
  xs: SM_THUMB_RES,
  sm: SM_THUMB_RES,
  md: MD_THUMB_RES,
  lg: XL_THUMB_RES,
  xl: XL_THUMB_RES,
  '2xl': XL_THUMB_RES,
}

export const AvatarCircleMedia = ({
  source,
  name,
  loading,
  disablePress = false,
  ...rest
}: AvatarCircleMediaProps) => {
  const scaleSize: number =
    imgSizeMap[(rest.size as Size) || 'md'] || MD_THUMB_RES

  // Resize the image if needed
  const smlImgUrl = updateCloudinaryUrlForDisplay(source, scaleSize)
  return (
    <AvatarCircle
      {...rest}
      source={{ uri: smlImgUrl }}
      name={name}
      loading={loading}
      disablePress={disablePress}
    />
  )
}
