import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap } from '../../../modules/strings/string-helpers'
import DreamEllipsis from '../../../components/composite/feed/dream-ellipsis'
import { MainStackType } from '../../MainStack'
import { Platform } from 'react-native'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="GroupFeed"
        component={Screens.GroupFeed}
        options={{
          title: t('common.journal'),
        }}
      />
      <MainStack.Screen
        name="GroupDreamView"
        component={Screens.DreamView}
        options={{
          title: cap(t('common.dream_plural', { count: 1 })),
          headerLeft: HeaderLeft,
          headerRight: () => <DreamEllipsis />,
        }}
      />
      <MainStack.Screen
        name="GroupFavoritesFeed"
        component={Screens.FavoritesFeed}
        options={{
          title: cap(t('common.likedDreams', { count: 1 })),
        }}
      />
    </>
  )
}
