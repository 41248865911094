import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './translations'
import ICU from 'i18next-icu'
import 'intl-pluralrules'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import { SUPPORTED_LANGUAGES } from './config'
import LanguageDetector from 'i18next-browser-languagedetector'

export const languageDetector = new LanguageDetector(null, {
  order: ['subdomain'],
  lookupFromSubdomainIndex: 0,
})

i18n.use(languageDetector).use(initReactI18next).use(ICU).init({
  fallbackLng: 'en',
  supportedLngs: SUPPORTED_LANGUAGES,
  ns: [],
  defaultNS: undefined,
  compatibilityJSON: 'v3', // needed for react-native
  debug: false,
  resources: resources,
})

export default i18n
