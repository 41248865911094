import React, { FC } from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { ScriptType, SimpleTextAlign } from '../../../../../api/frontend-types'
import i18n from '../../../i18n/i18nnext'
import { getHeadingFontKey } from '../../../modules/language-helpers/language-helpers'
import { TextProps, Text } from './text'

type HeadingSmallProps = TextProps & {
  textAlign?: SimpleTextAlign
  scriptType?: ScriptType
}

export const HeadingSmall: FC<HeadingSmallProps> = React.memo(
  ({
    children,
    mb = 6,
    mt = 8,
    textAlign = 'center',
    scriptType,
    ...rest
  }: HeadingSmallProps) => {
    const bgColor = useColorModeValue(BLACK, WHITE)
    // VARS
    const language: string = i18n.resolvedLanguage || 'en'
    const fontKey = getHeadingFontKey(language, scriptType)
    const textAlignValue = textAlign || 'center'
    return (
      <Text
        bgColor={bgColor}
        fontFamily={fontKey}
        style={{
          textTransform: 'uppercase',
          fontWeight: '300',
          textAlign: textAlignValue,
        }}
        fontSize={'xl'}
        lineHeight={'xl'}
        mt={mt}
        mb={mb}
        {...rest}
      >
        {children}
      </Text>
    )
  },
)
