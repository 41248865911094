import * as React from 'react'
import { ColorValue } from 'react-native'
import { G, Path, Circle } from 'react-native-svg'
import { Icon, IconProps } from '../../../components/common/icon/icon'

type SvgGroupProps = {
  stroke?: string
  fill?: ColorValue
}

export const PublicSvgGroup = ({ stroke }: SvgGroupProps) => {
  return (
    <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
      <Path
        d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12ZM4 16h24"
        stroke={stroke || 'currentColor'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16 27.675c2.761 0 5-5.227 5-11.675S18.761 4.325 16 4.325 11 9.552 11 16s2.239 11.675 5 11.675Z"
        stroke={stroke || 'currentColor'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  )
}

export const OfflineIcon = ({ color, size, ...rest }: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" size={size} color={color} {...rest}>
      <G
        transform="matrix(-0.747672, 0, 0, 0.747672, 20.972065, 2.332601)"
        style=""
      >
        <Path
          d="M24 15c0-2.64-2.05-4.78-4.65-4.96A7.49 7.49 0 0 0 12 4c-1.33 0-2.57.36-3.65.97l1.49 1.49C10.51 6.17 11.23 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19a2.996 2.996 0 0 1 1.79 5.4l1.41 1.41c1.09-.92 1.8-2.27 1.8-3.81zM4.41 3.86L3 5.27l2.77 2.77h-.42A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h11.73l2 2l1.41-1.41L4.41 3.86zM6 18c-2.21 0-4-1.79-4-4s1.79-4 4-4h1.73l8 8H6z"
          fill={'currentColor'}
        />
      </G>
    </Icon>
  )
}

export const IconEyeClosed = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M25.137 15.913 28 20.85M19.275 18.663l.887 5.037M12.713 18.65l-.888 5.05M6.85 15.913l-2.862 4.962M4 13.112C6.1 15.712 9.95 19 16 19s9.9-3.288 12-5.888"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Icon>
  )
}

export const IconCaretDown = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M20 8.00006L12 16.0001L4 8.00006"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  )
}

export const IconImageUpload = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fill="currentColor"
        d="M19 13a1 1 0 0 0-1 1v.38l-1.48-1.48a2.79 2.79 0 0 0-3.93 0l-.7.7l-2.48-2.48a2.85 2.85 0 0 0-3.93 0L4 12.6V7a1 1 0 0 1 1-1h7a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-5a1 1 0 0 0-1-1ZM5 20a1 1 0 0 1-1-1v-3.57l2.9-2.9a.79.79 0 0 1 1.09 0l3.17 3.17l4.3 4.3Zm13-1a.89.89 0 0 1-.18.53L13.31 15l.7-.7a.77.77 0 0 1 1.1 0L18 17.21Zm4.71-14.71l-3-3a1 1 0 0 0-.33-.21a1 1 0 0 0-.76 0a1 1 0 0 0-.33.21l-3 3a1 1 0 0 0 1.42 1.42L18 4.41V10a1 1 0 0 0 2 0V4.41l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"
      />
    </Icon>
  )
}

export const IconPublic = (props: IconProps) => {
  const { color } = props
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
          fill={'currentColor'}
        />
        <Path
          d="M12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"
          fill={'currentColor'}
        />
      </G>
    </Icon>
  )
}

//////////////////////////////////
// PRIMARY ICONS (24 x 24 viewBox)
//////////////////////////////////

export const IconEyeOpen = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M12 19.5C6.5 19.5 3 15 1.5 12 3 9 6.5 4.5 12 4.5S21 9 22.5 12c-1.5 3-5 7.5-10.5 7.5Z"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={fill || 'none'}
      />
      <Circle
        cx={12}
        cy={12}
        r={3}
        stroke={'currentColor'}
        strokeWidth={2}
        fill={fill || 'none'}
      />
    </Icon>
  )
}

export const IconPlus = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path d="M2 12h20M12 2v20" stroke="currentColor" strokeWidth={2} />
      </G>
    </Icon>
  )
}

export const IconCheck = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M22 5 8 19l-6-6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  )
}

export const IconSearch = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Circle
        cx={11}
        cy={11}
        r={8}
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
      />
      <Path stroke="currentColor" strokeWidth={2} d="m16 16 5.5 5.5" />
    </Icon>
  )
}
