import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from './shared'
import * as Screens from '../screens'

export default function (
  RootStack: any,
  defaultGroupOptions: any,
  defaultScreenOptions: any,
) {
  const { t } = useTranslation()
  return (
    <>
      <RootStack.Screen
        name="Home"
        component={Screens.Home}
        options={{ headerShown: false, headerTitle: t('common.siteName') }}
      />
      <RootStack.Screen
        name="Login"
        component={Screens.Login}
        options={{
          ...defaultScreenOptions,
          headerTitle: t('common.login'),
          headerLeft: HeaderLeft,
        }}
      />
      <RootStack.Screen
        name="MagicLogin"
        component={Screens.MagicLogin}
        options={{
          ...defaultScreenOptions,
          headerTitle: t('common.login'),
        }}
      />
      <RootStack.Screen
        name="Signup"
        component={Screens.Signup}
        options={{
          ...defaultScreenOptions,
          headerTitle: t('common.signUp'),
          headerLeft: HeaderLeft,
        }}
      />
    </>
  )
}
