import React, { FC, ReactNode } from 'react'
import { InterfaceTextProps } from 'native-base/lib/typescript/components/primitives/Text/types'
import { SansText } from '../../common/copy/text-sans'
import {
  SANS_BOOK_FONT_WEIGHT,
  SELECTION_COLOR,
} from '../../../constants/constants'
import { LIGHT_MID_GRAY } from '../../../constants/ui-constants'

type TextContentProps = InterfaceTextProps & {
  children?: ReactNode
  type?: 'primary' | 'gray'
}

export const ChartTitleText: FC<TextContentProps> = ({
  children,
  fontWeight = SANS_BOOK_FONT_WEIGHT,
  type = 'primary',
  textTransform = 'uppercase',
  ...rest
}: TextContentProps) => {
  return (
    <SansText
      selectionColor={SELECTION_COLOR}
      selectable={true}
      width={'100%'}
      fontSize={'sm'}
      color={type === 'gray' ? LIGHT_MID_GRAY : undefined}
      textTransform={textTransform}
      {...rest}
    >
      {children}
    </SansText>
  )
}
