import axios from 'axios'
import { elsewhereToast } from '../components/common/toast/toast'
import { OpenAPI } from '../../../api/_openapi'

export type AuthData = {
  token: string
  user: string
}

const signIn = (email: string, code: string): Promise<AuthData | undefined> => {
  const data = {
    email,
    code,
  }

  return axios({
    method: 'post',
    url: `${OpenAPI.BASE}/login/verify-code`,
    data: data,
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      const { data } = response
      return data
    })
    .then(({ token, user }) => {
      return { token, user } as AuthData
    })
    .catch(function (error) {
      console.log(error)
      return undefined
    })
}

const refreshToken = (oldToken: string): Promise<AuthData | undefined> => {
  return axios({
    method: 'get',
    url: `${OpenAPI.BASE}/api/refresh-token`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + oldToken,
    },
  })
    .then(function (response) {
      const { data } = response
      return data
    })
    .then(({ token, user }) => {
      return { token, user } as AuthData
    })
    .catch(function (error) {
      console.log('Error refreshing token.', `${OpenAPI.BASE}/refresh-token`)
      console.log(error)
      return undefined
    })
}

const sendPasscode = (email: string): Promise<boolean> => {
  const data = {
    email,
  }
  return axios({
    method: 'post',
    url: `${OpenAPI.BASE}/login/sign-in-code`,
    data: data,
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function () {
      return true
    })
    .catch(function (error) {
      console.log('error', error)
      elsewhereToast.showToast({
        title: 'toast.emailNotRegistered.title',
        description: 'toast.emailNotRegistered.description',
        status: 'error',
      })
      return false
    })
}

export const authService = {
  signIn,
  sendPasscode,
  refreshToken,
}
