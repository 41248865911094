import React from 'react'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { useTranslation } from 'react-i18next'
import { InsightTab } from '../../Insights/InsightsMain/InsightTab'
import { TabProps } from '../../../components/layout/tab-view-tabs'
import { TabView } from '../../../components/layout/tab-view'
import { InsightListHeader } from '../../Insights/InsightsMain/header'
import { TagTypeWithInsights } from '../../../../../api/frontend-types'
import { TAGTYPE_ICON_MAP } from '../../../constants/ui-constants'
import { cap } from '../../../modules/strings/string-helpers'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { MainStackScreenProps } from '../../../navigation/types'

export default function InsightList({
  route,
}: MainStackScreenProps<'PrivateInsightList'>) {
  // ROUTE
  const { type }: { type: TagTypeWithInsights } = route?.params

  const TYPE = type.toLocaleUpperCase() as TagTypeWithInsights

  // STATE
  const { t } = useTranslation()

  // HOOKS
  const user = useSelector(selectUser)
  const subscriptionStatus = useSelector(selectSubscriptionStatus)

  const InsightListTab = () => (
    <InsightTab
      route={route}
      user={user}
      type={TYPE}
      onlyMostRecent={true}
      showLocked={false}
      noInsightsMsg={t(`insightsPage.emptyInsights${cap(type)}`)}
      subscriptionStatus={subscriptionStatus}
    />
  )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Insights',
      children: <InsightListTab />,
      label: t('insights.' + type.toLowerCase()),
    },
  ]

  const header = () => <InsightListHeader symbolKey={TAGTYPE_ICON_MAP[TYPE]} />

  return (
    <TabView
      header={header}
      tabs={tabs}
      scenes={{
        Insights: InsightListTab,
      }}
    />
  )
}
