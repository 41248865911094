/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Insight } from '../models/Insight';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InsightService {
  /**
   * Retrieves the collection of Insight resources.
   * Retrieves the collection of Insight resources.
   * @returns Insight Insight collection
   * @throws ApiError
   */
  public static dreamSeriesInsights(): CancelablePromise<Array<Insight>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dream-series-insights',
    });
  }
  /**
   * Retrieves a Insight resource.
   * Retrieves a Insight resource.
   * @param id Insight identifier
   * @param user
   * @returns Insight Insight resource
   * @throws ApiError
   */
  public static fastInsight(
    id: string,
    user?: string,
  ): CancelablePromise<Insight> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-insights/{id}',
      path: {
        'id': id,
      },
      query: {
        'user': user,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of Insight resources.
   * Retrieves the collection of Insight resources.
   * @param tagType
   * @param tagId
   * @param limit
   * @param user
   * @returns Insight Insight collection
   * @throws ApiError
   */
  public static fastTagInsights(
    tagType?: string,
    tagId?: string,
    limit?: number,
    user?: string,
  ): CancelablePromise<Array<Insight>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-tag-insights',
      query: {
        'tagType': tagType,
        'tagId': tagId,
        'limit': limit,
        'user': user,
      },
    });
  }
  /**
   * Retrieves a Insight resource.
   * Retrieves a Insight resource.
   * @param id Insight identifier
   * @param regenerate
   * @returns Insight Insight resource
   * @throws ApiError
   */
  public static generateInsight(
    id: string,
    regenerate?: boolean,
  ): CancelablePromise<Insight> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/insights/{id}/generate',
      path: {
        'id': id,
      },
      query: {
        'regenerate': regenerate,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
