import React from 'react'
import { HeadingSans } from '../common/copy/heading-sans'
import { ButtonIcon } from '../common/buttons/button-icon'
import { ElsewhereIconType } from '../../modules/ui-helpers/icon-map'
import { Row } from '../common/row/row'
import { Box, InterfaceBoxProps } from '../common/box/box'

type HeadingSansButtonRowProps = InterfaceBoxProps & {
  heading: string
  buttonIconKey?: ElsewhereIconType
  onButtonPress?: () => void
  hasButton?: boolean
}

// A heading sans component with a button on the right
// Used e.g. in the edit tags area of the edit dream interface
// (Add theme, etc)
export const HeadingSansWithOptionalButtonRow = ({
  heading,
  buttonIconKey,
  onButtonPress,
  hasButton,
  ...rest
}: HeadingSansButtonRowProps) => {
  const showButton = Boolean(hasButton && buttonIconKey && onButtonPress)

  return (
    <Row
      alignItems={'center'}
      justifyContent={'space-between'}
      height={10}
      {...rest}
    >
      {/* LEFT（UNUSED) */}
      <Box width={10} />
      {/* CENTER（HEADING) */}
      <HeadingSans mb={0} mr={2}>
        {heading}
      </HeadingSans>
      {/* RIGHT（BUTTON/UNUSED) */}
      <Box width={10}>
        {showButton && buttonIconKey && onButtonPress && (
          <ButtonIcon
            iconKey={buttonIconKey}
            variant={'outlined'}
            onPress={() => {
              onButtonPress()
            }}
            size={'sm'}
          />
        )}
      </Box>
    </Row>
  )
}
