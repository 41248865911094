import React from 'react'
import { useSelector } from '../../../ducks/root-reducer'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import { useTranslation } from 'react-i18next'
import { LoadingPage } from '../../../components/layout/loading-page'
import { shallowEqualsDream } from '../../../ducks/shallowEqual'
import { TabProps } from '../../../components/layout/tab-view-tabs'
import { TabView } from '../../../components/layout/tab-view'
import { HeadingMainWithDate } from '../../../components/common/copy/heading-main-with-date'
import { DreamTab } from '../../../components/composite/tabs/dream-tab'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../../navigation/types'
import { Column } from '../../../components/common/column/column'

export type DraftViewNavigationProp =
  MainStackNavigationProp<'PrivateDraftView'>

export default function DraftView({
  navigation,
  route,
}: MainStackScreenProps<'PrivateDraftView'>) {
  // HOOKS
  const { t } = useTranslation()

  // ROUTE PARAMS
  const { id } = route.params

  // SELECTORS
  const dream = useSelector(selectFastDreamById(id), shallowEqualsDream)

  // Tab bar layout
  // 1) DREAM TAB
  const DreamTabContents = () => <DreamTab inGroup={false} dream={dream} />

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'dream',
      children: <DreamTabContents />,
      label: t('common.draft'),
    },
  ]

  if (!dream) {
    return <LoadingPage />
  } else {
    return (
      <>
        <TabView
          hideTabHeader={false}
          header={() => (
            <Column paddingX={4} pointerEvents="none">
              <HeadingMainWithDate
                date={dream.date}
                displayDate={dream.displayDate}
              >
                {dream.title}
              </HeadingMainWithDate>
            </Column>
          )}
          tabs={tabs}
          scenes={{
            dream: DreamTabContents,
          }}
        />
      </>
    )
  }
}
