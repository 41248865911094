import React, { useEffect } from 'react'
import { Dimensions } from 'react-native'
import { DrawerContent } from '../components/composite/drawer/drawer-content'
import { useColorModeValue } from 'native-base'
import { MainTabs } from './MainTabs'
import { useSelector } from '../ducks/root-reducer'
import { selectIsElsewhereTeam, selectUser } from '../ducks/user/user'
import { BLACK, WHITE } from '../constants/ui-constants'
import { Platform } from 'react-native'
import { UserStats, UserStatsService } from '../../../api/_openapi'
import { Drawer } from 'react-native-drawer-layout'
import { useInitialURL } from '../hooks/useInitialURL'
import { getSearchParamFromURL } from '../modules/strings/string-helpers'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { RevenueCatInit } from '../RevenueCatInit'
import { ShakeInit } from '../ShakeInit'
import { NotificationsInit } from '../NotificationsInit'
import { RootStackScreenProps } from './types'
import { useDrawer } from '../contexts/drawer-context'
import i18n from 'i18next'

export const DrawerWrapper = React.memo(
  ({
    navigation,
    route,
    defaultScreenOptions,
  }: RootStackScreenProps<'MainTabs'> & { defaultScreenOptions: any }) => {
    // HOOKS
    const { url: initialUrl } = useInitialURL()
    const { drawerVisible, setDrawerVisible } = useDrawer()
    const backgroundColor = useColorModeValue(WHITE, BLACK)
    const overlayBackgroundColor = useColorModeValue(
      'rgba(0, 0, 0, 0.1)',
      'rgba(255, 255, 255, 0.1)',
    )

    // SELECTORS
    const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
    const user = useSelector(selectUser)

    // VARS
    const windowWidth = Math.min(Dimensions.get('window').width, 500)
    const drawerWidth = Number(windowWidth * 0.88)
    const drawerPosition = i18n.dir() === 'rtl' ? 'right' : 'left'

    // Invite code redirect
    useEffect(() => {
      const redirect = async () => {
        try {
          const inviteCode =
            (await AsyncStorage.getItem('@InviteCode')) ||
            getSearchParamFromURL(initialUrl as string, 'inviteCode')
          if (inviteCode) {
            navigation.navigate('Invite', { inviteCode })
            await AsyncStorage.removeItem('@InviteCode')
          }
        } catch (e) {
          console.log('Error in invite code redirect', e)
        }
      }
      redirect()
    }, [navigation, initialUrl])

    // Log stats
    useEffect(() => {
      if (!isElsewhereTeam || __DEV__) {
        return
      }

      UserStatsService.userStats()
        .then((stats: UserStats[]) => {
          const today = new Date().toISOString().slice(0, 10)
          console.log('----------------------------------------------------')
          console.log(`USER STATS, ${today} (Only visible internally)`)
          console.log('----------------------------------------------------')
          console.log('User stats', stats)
          console.log('----------------------------------------------------')
        })
        .catch((error) => {
          console.log('Error fetching user stats', error)
        })
    }, [user])

    const renderDrawer = () => {
      return (
        <DrawerContent
          navigation={navigation}
          route={route}
          drawerWidth={drawerWidth}
          closeDrawer={() => {
            if (drawerVisible) setDrawerVisible(false)
          }}
        />
      )
    }
    return (
      <RevenueCatInit>
        <ShakeInit>
          <NotificationsInit>
            <Drawer
              open={drawerVisible}
              onOpen={() => {
                if (!drawerVisible) setDrawerVisible(true)
              }}
              onClose={() => {
                if (drawerVisible) setDrawerVisible(false)
              }}
              drawerStyle={{
                backgroundColor: backgroundColor,
                width: drawerWidth,
              }}
              drawerPosition={drawerPosition}
              drawerType={Platform.OS === 'web' ? 'front' : 'slide'}
              renderDrawerContent={renderDrawer}
              keyboardDismissMode="on-drag"
              overlayStyle={{ backgroundColor: overlayBackgroundColor }}
            >
              <MainTabs
                openDrawer={() => {
                  if (!drawerVisible) setDrawerVisible(true)
                }}
                defaultScreenOptions={defaultScreenOptions}
              />
            </Drawer>
          </NotificationsInit>
        </ShakeInit>
      </RevenueCatInit>
    )
  },
)
