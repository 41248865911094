import React from 'react'
import { Link } from '@react-navigation/native'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import { PaddedContentArea } from '../../layout/content-area-padded'
import { Row } from '../../common/row/row'
import { ScrollView } from '../../common/scrollview/scrollview'

export type ProfileType = 'user' | 'group' | 'public'

export type UserListItem = {
  id: string
  displayName: string
  profileImage?: string
  profileType: ProfileType
}

type GroupMembersViewProps = {
  users: UserListItem[]
  activeGroupId?: string
}

export const UserList = ({ users, activeGroupId }: GroupMembersViewProps) => {
  return (
    <ScrollView>
      <PaddedContentArea>
        {users.map((u) => {
          const isUserPrivateGroup = u.profileType === 'user'
          return (
            u.displayName && (
              <Link
                key={u.id}
                to={{
                  screen: isUserPrivateGroup
                    ? 'PrivateDreamerView'
                    : 'GroupDreamerView',
                  params: {
                    groupId: isUserPrivateGroup ? undefined : activeGroupId,
                    userId: u.id,
                  },
                }}
              >
                <Row alignItems={'center'} width={'100%'}>
                  <Row width={16} height={16} marginX={2}>
                    <AvatarCircle
                      size={'md'}
                      source={{
                        uri: u.profileImage || undefined,
                      }}
                      name={u.displayName}
                    />
                  </Row>
                  <SansText scriptType="latin">{u.displayName}</SansText>
                </Row>
              </Link>
            )
          )
        })}
      </PaddedContentArea>
    </ScrollView>
  )
}
