import React, { FC, ReactNode } from 'react'
import i18n from 'i18next'
import { TextProps, Text } from './text'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import {
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
  SANS_BOOK_FONT_WEIGHT,
} from '../../../constants/constants'
import {
  ScriptType,
  SupportedLanguage,
} from '../../../../../api/frontend-types'

type SansTextProps = TextProps & {
  children?: ReactNode
  language?: SupportedLanguage
  scriptType?: ScriptType
}

export const SansText: FC<SansTextProps> = ({
  children,
  fontWeight = SANS_BOOK_FONT_WEIGHT,
  fontSize = MEDIUM_FONT_SIZE,
  lineHeight = MD_LINE_HEIGHT,
  textTransform = 'none',
  language,
  scriptType,
  ...rest
}: SansTextProps) => {
  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const textAlign = i18n.dir() === 'rtl' ? 'right' : 'left'
  const font = getSansFont(lang, scriptType)

  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={font}
      selectable={true}
      lineHeight={lineHeight}
      textTransform={textTransform}
      textAlign={textAlign}
      {...rest}
    >
      {children}
    </Text>
  )
}
