/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DreamerProfile } from '../models/DreamerProfile';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DreamerProfileService {
  /**
   * Retrieves the collection of DreamerProfile resources.
   * Retrieves the collection of DreamerProfile resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns DreamerProfile DreamerProfile collection
   * @throws ApiError
   */
  public static apiDreamerProfilesGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<DreamerProfile>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreamer_profiles',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a DreamerProfile resource.
   * Creates a DreamerProfile resource.
   * @param requestBody The new DreamerProfile resource
   * @returns DreamerProfile DreamerProfile resource created
   * @throws ApiError
   */
  public static apiDreamerProfilesPost(
    requestBody: DreamerProfile,
  ): CancelablePromise<DreamerProfile> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreamer_profiles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a DreamerProfile resource.
   * Retrieves a DreamerProfile resource.
   * @param id DreamerProfile identifier
   * @returns DreamerProfile DreamerProfile resource
   * @throws ApiError
   */
  public static apiDreamerProfilesIdGet(
    id: string,
  ): CancelablePromise<DreamerProfile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreamer_profiles/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the DreamerProfile resource.
   * Replaces the DreamerProfile resource.
   * @param id DreamerProfile identifier
   * @param requestBody The updated DreamerProfile resource
   * @returns DreamerProfile DreamerProfile resource updated
   * @throws ApiError
   */
  public static apiDreamerProfilesIdPut(
    id: string,
    requestBody: DreamerProfile,
  ): CancelablePromise<DreamerProfile> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/dreamer_profiles/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
}
