import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../../ducks/root-reducer'
import { useColorModeValue } from 'native-base'
import { selectUserLanguage } from '../../ducks/user/user'
import { dateRangeFromKey } from '../Charts/chart-helpers'
import { BLACK, PADDING_TO_TABS, WHITE } from '../../constants/ui-constants'
import { DateRangeSelect } from '../../components/composite/chart/date-range-select'
import { dreamsToChartData } from '../../components/composite/chart/helpers/dreams-to-chart-data'
import { X_TICKS_PER_RANGE } from '../../config/frontend-config'
import {
  DateRangeWithKey,
  ScriptType,
  SupportedLanguage,
} from '../../../../api/frontend-types'
import {
  ChartPadding,
  TimelineChart,
} from '../../components/composite/chart/timeline-chart'
import {
  FastTag,
  TagDateCount,
  TagDateCountService,
  TagStatsResponseService,
} from '../../../../api/_openapi'
import { Dimensions } from 'react-native'
import { IconEmotion } from '../../assets/react-native-svg/icons/icons-tag'
import { CatIcon } from '../../assets/react-native-svg/illustrations/Cat'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { GridDisplay } from '../../components/layout/grid-display'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import {
  Factoid,
  FactoidLayout,
} from '../../components/composite/stats/factoid'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { Column } from '../../components/common/column/column'
import { Box } from '../../components/common/box/box'

type TagStatsFactoidType =
  | 'userSentimentRank'
  | 'globalSentimentRank'
  | 'userUsageRank'

type TagStatsFactoidData = {
  userUsageRank: number | null
  userSentimentRank: number | null
  globalSentimentRank: number | null
}

type TagChartTabProps = {
  tag: FastTag
  activeGroupId: string
}

export const TagChartTab = ({ tag, activeGroupId }: TagChartTabProps) => {
  // STATE
  const [dateRangeWithKey, setDateRangeWithKey] = useState<DateRangeWithKey>({
    key: 'LAST_YEAR',
    range: dateRangeFromKey('LAST_YEAR'),
  })

  const [tagStats, setTagStats] = useState<TagStatsFactoidData | null>(null)

  // SELECTORS
  const userLanguage = useSelector(selectUserLanguage)

  // HOOKS
  const { t } = useTranslation()

  const userLineColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const pageWidth = Dimensions.get('window').width

  const [tagDateCount, setTagDateCount] = useState<TagDateCount>({
    dateCounts: {},
    total: 0,
    id: '',
  })

  // EFFECTS
  useEffect(() => {
    if (!activeGroupId) {
      return
    }

    const startDate = dateRangeWithKey.range.start
      ? formatDateForDatabase(dateRangeWithKey.range.start)
      : undefined
    const endDate = dateRangeWithKey.range.end
      ? formatDateForDatabase(dateRangeWithKey.range.end)
      : undefined

    TagDateCountService.tagDreamDates(
      activeGroupId,
      startDate,
      endDate,
      tag.id,
      'Lucid',
      'DREAM_TYPE',
    ).then((data) => {
      setTagDateCount(data)
    })
  }, [tag, activeGroupId, dateRangeWithKey.key])

  const chartLegend = t('chartsPage.dreamsPerPeriod.legend')
  const chartData = dreamsToChartData(tagDateCount, userLineColor, chartLegend)

  const chartPadding: ChartPadding = {
    left: 40,
    right: 72,
    top: 30,
  }

  //factoids
  const factoidLabels: {
    [key in TagStatsFactoidType]: {
      line1: string
      line2: string
      Icon: (props: any) => JSX.Element
      factoidScriptType?: ScriptType
    }
  } = {
    userUsageRank: {
      line1: t('dashboard.rank'),
      line2: '',
      Icon: CatIcon,
      factoidScriptType: 'latin',
    },
    userSentimentRank: {
      line1: t('tagPage.tagSentimentFactoidForYou.line1'),
      line2: t('tagPage.tagSentimentFactoidForYou.line2'),
      Icon: IconEmotion,
      factoidScriptType: 'latin',
    },
    globalSentimentRank: {
      line1: t('tagPage.tagSentimentFactoidForOthers.line1'),
      line2: t('tagPage.tagSentimentFactoidForOthers.line2'),
      Icon: IconEmotion,
      factoidScriptType: 'latin',
    },
  }

  useEffect(() => {
    const loadTagStats = async () => {
      try {
        const stats = await TagStatsResponseService.apiTagStats(tag.id)
        setTagStats({
          userUsageRank:
            stats.userUsageRank !== undefined ? stats.userUsageRank : null,
          userSentimentRank:
            stats.userSentimentRank !== undefined
              ? stats.userSentimentRank
              : null,
          globalSentimentRank:
            stats.globalSentimentRank !== undefined
              ? stats.globalSentimentRank
              : null,
        })
      } catch (error) {
        console.log('error loading tag stats', error)
      }
    }
    loadTagStats()
  }, [tag])

  return (
    <Column pr={4} pl={4} py={PADDING_TO_TABS}>
      {
        <Box>
          <PaddedContentArea pt={0} alignItems={'center'}>
            {tagStats && (
              <GridDisplay
                mb={6}
                maxWidth={WEB_MAX_WIDTH}
                width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
                items={Object.entries(tagStats).map(([key, value]) => {
                  // @ts-ignore
                  const { line1, line2, Icon, factoidScriptType } =
                    factoidLabels[key as TagStatsFactoidType]

                  let displayValue: string
                  if (key === 'userUsageRank') {
                    displayValue = value !== null ? `#${value}` : 'N/A'
                  } else {
                    displayValue = value !== null ? `${value}%` : 'N/A'
                  }
                  // The average says "above" or "below"
                  // So we want the absolute value
                  return {
                    id: key,
                    item: (
                      <Factoid
                        key={key}
                        Icon={Icon}
                        factoidFact={displayValue}
                        textLineOne={`${line1}`}
                        textLineTwo={line2}
                        factoidFactScriptType={factoidScriptType}
                        layout={getFactoidLayoutForLanguage(
                          userLanguage,
                          key as TagStatsFactoidType,
                        )}
                      />
                    ),
                  }
                })}
              />
            )}
          </PaddedContentArea>
        </Box>
      }
      <Column pr={10} pl={10}>
        <DateRangeSelect
          onChange={(value: any) => {
            setDateRangeWithKey(value)
          }}
          value={dateRangeWithKey}
        />
      </Column>
      <TimelineChart
        // chartTitle={chartTitle}
        chartData={chartData}
        loading={false}
        chartPadding={chartPadding}
        tickCount={X_TICKS_PER_RANGE[dateRangeWithKey.key]}
        dateRangeWithKey={dateRangeWithKey}
        userLanguage={userLanguage}
        bucketizeMode={'sum'}
        mt={6}
      />
    </Column>
  )
}

// Different languages need different orders of the factoid lines
function getFactoidLayoutForLanguage(
  language: SupportedLanguage,
  key: TagStatsFactoidType,
): FactoidLayout {
  if (key === 'userUsageRank') {
    return 'fact-text'
  } else {
    if (language === 'ja') {
      return 'text-fact-text'
    } else {
      return 'fact-text-text'
    }
  }
}
