/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Language } from '../models/Language';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LanguageService {
  /**
   * Retrieves the collection of Language resources.
   * Retrieves the collection of Language resources.
   * @param supported
   * @param supportedArray
   * @param code
   * @param codeArray
   * @returns Language Language collection
   * @throws ApiError
   */
  public static apiLanguagesGetCollection(
    supported?: boolean,
    supportedArray?: Array<boolean>,
    code?: string,
    codeArray?: Array<string>,
  ): CancelablePromise<Array<Language>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/languages',
      query: {
        'supported': supported,
        'supported[]': supportedArray,
        'code': code,
        'code[]': codeArray,
      },
    });
  }
  /**
   * Retrieves a Language resource.
   * Retrieves a Language resource.
   * @param code Language identifier
   * @returns Language Language resource
   * @throws ApiError
   */
  public static apiLanguagesCodeGet(
    code: string,
  ): CancelablePromise<Language> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/languages/{code}',
      path: {
        'code': code,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
