import React, { FC } from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  SimpleTextAlign,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import i18n from 'i18next'
import { getFontSuffix } from '../../../modules/language-helpers/language-helpers'
import { TextProps, Text } from './text'

type HeadingSansProps = TextProps & {
  textAlign?: SimpleTextAlign
  language?: SupportedLanguage
}

export const HeadingSans: FC<HeadingSansProps> = ({
  children,
  mb = 6,
  textAlign,
  language,
  ...rest
}: HeadingSansProps) => {
  const bgColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const fontSuffix = getFontSuffix(lang)
  const textAlignDefault = i18n.dir() === 'rtl' ? 'right' : 'left'

  return (
    <Text
      bgColor={bgColor}
      fontFamily={'sans' + fontSuffix}
      fontWeight={400}
      style={{
        textTransform: 'uppercase',
        textAlign: textAlign || textAlignDefault,
      }}
      fontSize={'lg'}
      lineHeight={'lg'}
      mb={mb}
      {...rest}
    >
      {children}
    </Text>
  )
}
