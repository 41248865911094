import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Form } from '../../../components/common/form/form'
import { useSelector, useDispatch } from 'react-redux'
import { editDraftForm } from '../../../forms/dream-form'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import { formatDateTimeForDatabase } from '../../../modules/strings/string-helpers'
import {
  dreamifyDraft,
  updateDream,
} from '../../../ducks/dream-tag/thunks/dream-thunks'
import { selectDefaultArtStyle, selectUser } from '../../../ducks/user/user'
import { RootStackScreenProps } from '../../../navigation/types'
import { useDreamNavigator } from '../../../hooks/useDreamNavigator'
import { FastDream } from '../../../../../api/_openapi'

export default function DraftEdit({
  route,
}: RootStackScreenProps<'PrivateDraftEdit'>) {
  // HOOKS
  const dispatch = useDispatch<any>()
  const { view, feed } = useDreamNavigator()
  const { t } = useTranslation()
  const { id } = route.params

  // SELECTORS
  const dream = useSelector(selectFastDreamById(id))
  const defaultArtStyle = useSelector(selectDefaultArtStyle)
  const user = useSelector(selectUser)

  // ON SUBMIT
  const onSubmitForm = (data: any) => {
    if (!user?.userGroupId) return

    const isDraft = Boolean(data.isDraft)

    const updatedDraft: FastDream = {
      ...dream,
      title: data.title || '',
      description: data.description || '',
      date: data.date,
      displayDate: data.displayDate || '',
      note: data.note || '',
      updatedAt: formatDateTimeForDatabase(new Date()),
      isDraft: isDraft,
      ...data.place,
    }

    // If 'draft' is toggled off, save the dream
    if (!isDraft && dream?.type !== 'journal-entry') {
      dispatch(dreamifyDraft(user.userGroupId, data, defaultArtStyle))
      view(
        {
          ...dream,
          ...data,
          isDraft,
        },
        'replace',
        'interpretation',
      )
    }
    // Otherwise, save the draft/diary entry
    else {
      dispatch(updateDream(user.userGroupId, id, updatedDraft))
      feed(isDraft, dream?.type, 'replace')
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <Form
        name={'newDreamForm'}
        form={editDraftForm}
        onSubmit={onSubmitForm}
        headerTitle={t('editDraftPage.header')}
        prepopulateValues={dream}
        confirmCancelMessage={t('editDraftPage.exitWarning')}
        checkOnCancelFields={['description', 'title', 'note']}
      />
    </View>
  )
}
