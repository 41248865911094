import { MediaObjectWithDream } from '../../../../../../../api/frontend-types'
import { MAX_FILESIZE_BYTES } from '../../../../../constants/constants'
import { elsewhereToast } from '../../../toast/toast'
import axios from 'axios'
import { OpenAPI } from '../../../../../../../api/_openapi'
import {
  ImageForUpload,
  UploadImagesErrorMessages,
  UploadOptions,
} from './types'

export async function uploadImages(
  images: ImageForUpload[],
  token: string,
  errorMessages: UploadImagesErrorMessages,
  options?: UploadOptions,
): Promise<MediaObjectWithDream[]> {
  const newArray: (MediaObjectWithDream | undefined)[] = await Promise.all(
    images.map(async (image) => {
      const uri: string | undefined = image ? image.path : undefined
      const fileSize = image?.size || 0
      if (fileSize > MAX_FILESIZE_BYTES) {
        // setLoading(false)
        elsewhereToast.showToast({
          title: errorMessages.fileSize,
          status: 'error',
          duration: 5000,
        })
        return
      } else {
        if (uri && options) {
          const resolvedFilename = (image?.path || '').split('/')
          const filename =
            resolvedFilename && resolvedFilename.pop()?.split('.')[0]
          const bodyFormData = new FormData()
          bodyFormData.append('format', 'image/jpg')
          bodyFormData.append('type', options.type || 'image')
          bodyFormData.append('alt', options.alt || 'image description')

          // Must have either a secure_url or a file
          if (image?.secure_url) {
            bodyFormData.append('url', image.secure_url)
          } else {
            bodyFormData.append('file', {
              uri: uri,
              type: image.mime && image.mime.replace('jpeg', 'jpg'),
              name: image?.filename || filename || `${Date.now()}`,
            } as any)
          }

          const resp = await axios({
            method: 'post',
            url: `${OpenAPI.BASE}/api/media_objects`,
            data: bodyFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
            transformRequest: () => {
              // Override data to return formData
              // since axios converts that to string
              // https://stackoverflow.com/questions/56235286/react-native-post-form-data-with-object-and-file-in-it-using-axios
              return bodyFormData
            },
          })

          if (resp.status === 201) {
            const mediaObj = resp.data
            const dreamImage: MediaObjectWithDream = {
              id: mediaObj.id,
              url: mediaObj.url,
              alt: mediaObj.alt,
            }
            return dreamImage
            // setLoading(false)
          } else {
            elsewhereToast.showToast({
              title: errorMessages.uploading,
              status: 'error',
            })
          }
        }
      }
    }),
  )

  const filteredArray = newArray.filter(
    (i) => i !== undefined,
  ) as MediaObjectWithDream[]

  return filteredArray
}
