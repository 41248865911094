import { ColorDefinitionMap } from './color-types'
import { sName, lName, colorTemperatures } from './colorConstants'
import { hName, simpleColors, complexColorNames } from './colorNames'
import { h, s, l } from './colorValues'

export const colorMap: ColorDefinitionMap = {
  // MAGENTA
  [complexColorNames.magentaPink]: {
    name: complexColorNames.magentaPink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.MAGENTA, s.MAGENTA, l.LIGHT],
    hslType: [hName.MAGENTA, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.PINK, simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.magenta]: {
    name: complexColorNames.magenta,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.MAGENTA, s.MAGENTA, l.MID],
    hslType: [hName.MAGENTA, sName.SATURATED, lName.MID],
    associations: [simpleColors.PINK, simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkMagenta]: {
    name: complexColorNames.darkMagenta,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.MAGENTA, s.MAGENTA, l.DARKER_DARK],
    hslType: [hName.MAGENTA, sName.SATURATED, lName.DARK],
    associations: [simpleColors.PINK, simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED MAGENTA
  [complexColorNames.duskyMagentaPink]: {
    name: complexColorNames.duskyMagentaPink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_LIGHT],
    hslType: [hName.MAGENTA, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.duskyMagenta]: {
    name: complexColorNames.duskyMagenta,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_MID],
    hslType: [hName.MAGENTA, sName.DESATURATED, lName.MID],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.duskyDarkMagenta]: {
    name: complexColorNames.duskyDarkMagenta,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_DARK],
    hslType: [hName.MAGENTA, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  // PINK
  [complexColorNames.pink]: {
    name: complexColorNames.pink,
    colorTemperature: colorTemperatures.WARM,
    associations: [simpleColors.PINK, simpleColors.RED],
    hsl: [h.PINK, s.PINK, l.LIGHT],
    hslType: [hName.PINK, sName.SATURATED, lName.LIGHT],
    simpleOpts: {
      isSimpleColor: true,
      id: simpleColors.PINK,
      simpleOrder: 0,
    },
  },
  [complexColorNames.midPink]: {
    name: complexColorNames.midPink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.PINK, s.PINK, l.MID],
    associations: [simpleColors.PINK, simpleColors.RED],
    hslType: [hName.PINK, sName.SATURATED, lName.MID],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkPink]: {
    name: complexColorNames.darkPink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.PINK, s.PINK, l.DARKER_DARK],
    hslType: [hName.PINK, sName.SATURATED, lName.DARK],
    associations: [simpleColors.PINK, simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED PINK
  [complexColorNames.purplePink]: {
    name: complexColorNames.purplePink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_LIGHT],
    hslType: [hName.PINK, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE, simpleColors.PINK],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.midPurplePink]: {
    name: complexColorNames.midPurplePink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_MID],
    hslType: [hName.PINK, sName.DESATURATED, lName.MID],
    associations: [simpleColors.PURPLE, simpleColors.PINK],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkPurplePink]: {
    name: complexColorNames.darkPurplePink,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_DARK],
    hslType: [hName.PINK, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.PURPLE, simpleColors.PINK],
    simpleOpts: { isSimpleColor: false },
  },
  // RED
  [complexColorNames.salmon]: {
    name: complexColorNames.salmon,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.RED, s.RED, l.LIGHT],
    hslType: [hName.RED, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.RED, simpleColors.PINK],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.red]: {
    name: complexColorNames.red,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.RED, s.RED, l.MID],
    hslType: [hName.RED, sName.SATURATED, lName.MID],
    associations: [simpleColors.RED],
    simpleOpts: {
      id: simpleColors.RED,
      isSimpleColor: true,
      simpleOrder: 1,
    },
  },
  [complexColorNames.darkRed]: {
    name: complexColorNames.darkRed,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.RED, s.RED, l.DARK],
    hslType: [hName.RED, sName.SATURATED, lName.DARK],
    associations: [simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  // RED-BROWN (Desaturated red)
  [complexColorNames.duskySalmon]: {
    name: complexColorNames.duskySalmon,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_LIGHT],
    hslType: [hName.RED, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BROWN, simpleColors.PINK, simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.redBrown]: {
    name: complexColorNames.redBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_MID],
    hslType: [hName.RED, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BROWN, simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkRedBrown]: {
    name: complexColorNames.darkRedBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_DARK],
    hslType: [hName.RED, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BROWN, simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  // ORANGE-RED
  [complexColorNames.apricot]: {
    name: complexColorNames.apricot,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.LIGHT],
    hslType: [hName.ORANGE_RED, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.ORANGE, simpleColors.RED, simpleColors.PINK],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.orangeRed]: {
    name: complexColorNames.orangeRed,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.MID],
    hslType: [hName.ORANGE_RED, sName.SATURATED, lName.MID],
    associations: [simpleColors.ORANGE, simpleColors.RED],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkOrangeRed]: {
    name: complexColorNames.darkOrangeRed,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.DARK],
    hslType: [hName.ORANGE_RED, sName.SATURATED, lName.DARK],
    associations: [simpleColors.ORANGE, simpleColors.RED, simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  // CHOCOLATE BROWN
  [complexColorNames.lightChocolateBrown]: {
    name: complexColorNames.lightChocolateBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_LIGHT],
    hslType: [hName.ORANGE_RED, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.midChocolateBrown]: {
    name: complexColorNames.midChocolateBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_MID],
    hslType: [hName.ORANGE_RED, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.chocolateBrown]: {
    name: complexColorNames.chocolateBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_DARK],
    hslType: [hName.ORANGE_RED, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  // ORANGE
  [complexColorNames.lightOrange]: {
    name: complexColorNames.lightOrange,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE, s.ORANGE, l.LIGHT],
    hslType: [hName.ORANGE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.ORANGE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.orange]: {
    name: complexColorNames.orange,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE, s.ORANGE, l.MID],
    hslType: [hName.ORANGE, sName.SATURATED, lName.MID],
    associations: [simpleColors.ORANGE],
    simpleOpts: {
      id: simpleColors.ORANGE,
      isSimpleColor: true,
      simpleOrder: 2,
    },
  },
  [complexColorNames.darkOrange]: {
    name: complexColorNames.darkOrange,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.ORANGE, s.ORANGE, l.DARK],
    hslType: [hName.ORANGE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.ORANGE, simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  // BROWN (Desaturated orange)
  [complexColorNames.lightBrown]: {
    name: complexColorNames.lightBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_LIGHT],
    hslType: [hName.ORANGE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.brown]: {
    name: complexColorNames.brown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_MID],
    hslType: [hName.ORANGE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BROWN],
    simpleOpts: {
      id: simpleColors.BROWN,
      isSimpleColor: true,
      simpleOrder: 5,
    },
  },
  [complexColorNames.darkBrown]: {
    name: complexColorNames.darkBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_DARK],
    hslType: [hName.ORANGE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  // GOLDEN YELLOW
  [complexColorNames.lightGoldenYellow]: {
    name: complexColorNames.lightGoldenYellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.LIGHT],
    hslType: [hName.GOLD_YELLOW, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.YELLOW, simpleColors.ORANGE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.goldenYellow]: {
    name: complexColorNames.goldenYellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.MID],
    hslType: [hName.GOLD_YELLOW, sName.SATURATED, lName.MID],
    associations: [simpleColors.YELLOW, simpleColors.ORANGE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkGoldenYellow]: {
    name: complexColorNames.darkGoldenYellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.DARK],
    hslType: [hName.GOLD_YELLOW, sName.SATURATED, lName.DARK],
    associations: [
      simpleColors.YELLOW,
      simpleColors.ORANGE,
      simpleColors.BROWN,
    ],
    simpleOpts: { isSimpleColor: false },
  },
  // GOLDEN BROWN
  [complexColorNames.lightGoldenBrown]: {
    name: complexColorNames.lightGoldenBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.DESAT_LIGHT],
    hslType: [hName.GOLD_YELLOW, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.goldenBrown]: {
    name: complexColorNames.goldenBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.DESAT_MID],
    hslType: [hName.GOLD_YELLOW, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkGoldenBrown]: {
    name: complexColorNames.darkGoldenBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.DESAT_DARK],
    hslType: [hName.GOLD_YELLOW, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
  // YELLOW
  [complexColorNames.lightYellow]: {
    name: complexColorNames.lightYellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.YELLOW, s.YELLOW, l.LIGHT],
    hslType: [hName.YELLOW, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.yellow]: {
    name: complexColorNames.yellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.YELLOW, s.YELLOW, l.MID],
    hslType: [hName.YELLOW, sName.SATURATED, lName.MID],
    associations: [simpleColors.YELLOW],
    simpleOpts: {
      id: simpleColors.YELLOW,
      isSimpleColor: true,
      simpleOrder: 3,
    },
  },
  [complexColorNames.darkYellow]: {
    name: complexColorNames.darkYellow,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.YELLOW, s.YELLOW, l.DARK],
    hslType: [hName.YELLOW, sName.SATURATED, lName.DARK],
    associations: [simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  // YELLOW-BROWN
  [complexColorNames.lightYellowBrown]: {
    name: complexColorNames.lightYellowBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_LIGHT],
    hslType: [hName.YELLOW, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BROWN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.yellowBrown]: {
    name: complexColorNames.yellowBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_MID],
    hslType: [hName.YELLOW, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BROWN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkYellowBrown]: {
    name: complexColorNames.darkYellowBrown,
    colorTemperature: colorTemperatures.WARM,
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_DARK],
    hslType: [hName.YELLOW, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BROWN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  // YELLOW-GREEN
  [complexColorNames.lightYellowGreen]: {
    name: complexColorNames.lightYellowGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_LIGHT],
    hslType: [hName.YELLOW_GREEN, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.yellowGreen]: {
    name: complexColorNames.yellowGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_MID],
    hslType: [hName.YELLOW_GREEN, sName.SATURATED, lName.MID],
    associations: [simpleColors.GREEN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkYellowGreen]: {
    name: complexColorNames.darkYellowGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_DARK],
    hslType: [hName.YELLOW_GREEN, sName.SATURATED, lName.DARK],
    associations: [simpleColors.GREEN, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  // OLIVE GREEN
  [complexColorNames.lightOliveGreen]: {
    name: complexColorNames.lightOliveGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DESAT_LIGHT],
    hslType: [hName.YELLOW_GREEN, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.oliveGreen]: {
    name: complexColorNames.oliveGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DESAT_MID],
    hslType: [hName.YELLOW_GREEN, sName.DESATURATED, lName.MID],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkOliveGreen]: {
    name: complexColorNames.darkOliveGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DESAT_DARK],
    hslType: [hName.YELLOW_GREEN, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  // LIME GREEN
  [complexColorNames.lightLimeGreen]: {
    name: complexColorNames.lightLimeGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.GREEN, s.GREEN, l.LIGHT],
    hslType: [hName.GREEN, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.limeGreen]: {
    name: complexColorNames.limeGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.GREEN, s.GREEN, l.MID],
    hslType: [hName.GREEN, sName.SATURATED, lName.MID],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkLimeGreen]: {
    name: complexColorNames.darkLimeGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.GREEN, s.GREEN, l.DARKER_DARK],
    hslType: [hName.GREEN, sName.SATURATED, lName.DARK],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED LIME GREEN
  [complexColorNames.lightForestGreen]: {
    name: complexColorNames.lightForestGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_LIGHT],
    hslType: [hName.GREEN, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.forestGreen]: {
    name: complexColorNames.forestGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_MID],
    hslType: [hName.GREEN, sName.DESATURATED, lName.MID],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkForestGreen]: {
    name: complexColorNames.darkForestGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_DARK],
    hslType: [hName.GREEN, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  // SPRING GREEN
  [complexColorNames.lightSpringGreen]: {
    name: complexColorNames.lightSpringGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.LIGHT],
    hslType: [hName.SPRING_GREEN, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.springGreen]: {
    name: complexColorNames.springGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.MID],
    hslType: [hName.SPRING_GREEN, sName.SATURATED, lName.MID],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.green]: {
    name: complexColorNames.green,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.DARK],
    hslType: [hName.SPRING_GREEN, sName.SATURATED, lName.DARK],
    associations: [simpleColors.GREEN],
    simpleOpts: {
      id: simpleColors.GREEN,
      isSimpleColor: true,
      simpleOrder: 4,
    },
  },
  // DESATURATED SPRING GREEN
  [complexColorNames.lightSeaGreen]: {
    name: complexColorNames.lightSeaGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_LIGHT],
    hslType: [hName.SPRING_GREEN, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.seaGreen]: {
    name: complexColorNames.seaGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_MID],
    hslType: [hName.SPRING_GREEN, sName.DESATURATED, lName.MID],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkSeaGreen]: {
    name: complexColorNames.darkSeaGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_DARK],
    hslType: [hName.SPRING_GREEN, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.GREEN],
    simpleOpts: { isSimpleColor: false },
  },
  // TURQUOISE
  [complexColorNames.lightTurquoise]: {
    name: complexColorNames.lightTurquoise,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_LIGHT],
    hslType: [hName.TURQUOISE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.turquoise]: {
    name: complexColorNames.turquoise,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_MID],
    hslType: [hName.TURQUOISE, sName.SATURATED, lName.MID],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.teal]: {
    name: complexColorNames.teal,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_DARK],
    hslType: [hName.TURQUOISE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED TURQUOISE
  [complexColorNames.lightBlueGreen]: {
    name: complexColorNames.lightBlueGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_LIGHT],
    hslType: [hName.TURQUOISE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.blueGreen]: {
    name: complexColorNames.blueGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_MID],
    hslType: [hName.TURQUOISE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkBlueGreen]: {
    name: complexColorNames.darkBlueGreen,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_DARK],
    hslType: [hName.TURQUOISE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.GREEN, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // SKY BLUE / AZURE
  [complexColorNames.lightBlue]: {
    name: complexColorNames.lightBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.LIGHT],
    hslType: [hName.SKY_BLUE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.blue]: {
    name: complexColorNames.blue,
    colorTemperature: colorTemperatures.COOL,
    associations: [simpleColors.BLUE],
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.MID],
    hslType: [hName.SKY_BLUE, sName.SATURATED, lName.MID],
    simpleOpts: {
      id: simpleColors.BLUE,
      isSimpleColor: true,
      simpleOrder: 6,
    },
  },
  [complexColorNames.prussianBlue]: {
    name: complexColorNames.prussianBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.DARKER_DARK],
    hslType: [hName.SKY_BLUE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED SKY BLUE
  [complexColorNames.lightBlueGray]: {
    name: complexColorNames.lightBlueGray,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_LIGHT],
    hslType: [hName.SKY_BLUE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BLUE, simpleColors.GRAY],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.blueGray]: {
    name: complexColorNames.blueGray,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_MID],
    hslType: [hName.SKY_BLUE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BLUE, simpleColors.GRAY],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkBlueGray]: {
    name: complexColorNames.darkBlueGray,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_DARK],
    hslType: [hName.SKY_BLUE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BLUE, simpleColors.GRAY],
    simpleOpts: { isSimpleColor: false },
  },
  // ROYAL BLUE
  [complexColorNames.cornflowerBlue]: {
    name: complexColorNames.cornflowerBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE, s.BLUE, l.LIGHT],
    hslType: [hName.BLUE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.royalBlue]: {
    name: complexColorNames.royalBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE, s.BLUE, l.MID],
    hslType: [hName.BLUE, sName.SATURATED, lName.MID],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkRoyalBlue]: {
    name: complexColorNames.darkRoyalBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE, s.BLUE, l.DARK],
    hslType: [hName.BLUE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURATED ROYAL BLUE
  [complexColorNames.lightSlateBlue]: {
    name: complexColorNames.lightSlateBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DESAT_LIGHT],
    hslType: [hName.BLUE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.slateBlue]: {
    name: complexColorNames.slateBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DARKER_MID],
    hslType: [hName.BLUE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkSlateBlue]: {
    name: complexColorNames.darkSlateBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DARKEST_DARK],
    hslType: [hName.BLUE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // BLUE_PURPLE
  [complexColorNames.lightPurpleBlue]: {
    name: complexColorNames.lightPurpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.LIGHT],
    hslType: [hName.BLUE_PURPLE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.purpleBlue]: {
    name: complexColorNames.purpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.MID],
    hslType: [hName.BLUE_PURPLE, sName.SATURATED, lName.MID],
    associations: [simpleColors.PURPLE, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkPurpleBlue]: {
    name: complexColorNames.darkPurpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.DARK],
    hslType: [hName.BLUE_PURPLE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.PURPLE, simpleColors.BLUE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESATURED PURPLE BLUE
  [complexColorNames.lightDuskyPurpleBlue]: {
    name: complexColorNames.lightDuskyPurpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_LIGHT],
    hslType: [hName.BLUE_PURPLE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.duskyPurpleBlue]: {
    name: complexColorNames.duskyPurpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_MID],
    hslType: [hName.BLUE_PURPLE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkDuskyPurpleBlue]: {
    name: complexColorNames.darkDuskyPurpleBlue,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_DARK],
    hslType: [hName.BLUE_PURPLE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  // PURPLE
  [complexColorNames.lightPurple]: {
    name: complexColorNames.lightPurple,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.PURPLE, s.PURPLE, l.LIGHT],
    hslType: [hName.PURPLE, sName.SATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.purple]: {
    name: complexColorNames.purple,
    colorTemperature: colorTemperatures.COOL,
    associations: [simpleColors.PURPLE],
    hsl: [h.PURPLE, s.PURPLE, l.MID],
    hslType: [hName.PURPLE, sName.SATURATED, lName.MID],
    simpleOpts: {
      id: simpleColors.PURPLE,
      isSimpleColor: true,
      simpleOrder: 7,
    },
  },
  [complexColorNames.darkPurple]: {
    name: complexColorNames.darkPurple,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.PURPLE, s.PURPLE, l.DARK],
    hslType: [hName.PURPLE, sName.SATURATED, lName.DARK],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  // DESAUTRATED PURPLE
  [complexColorNames.lightDuskyPurple]: {
    name: complexColorNames.lightDuskyPurple,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DESAT_LIGHT],
    hslType: [hName.PURPLE, sName.DESATURATED, lName.LIGHT],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.duskyPurple]: {
    name: complexColorNames.duskyPurple,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DESAT_MID],
    hslType: [hName.PURPLE, sName.DESATURATED, lName.MID],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.darkDuskyPurple]: {
    name: complexColorNames.darkDuskyPurple,
    colorTemperature: colorTemperatures.COOL,
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DESAT_DARK],
    hslType: [hName.PURPLE, sName.DESATURATED, lName.DARK],
    associations: [simpleColors.PURPLE],
    simpleOpts: { isSimpleColor: false },
  },
  // BLACK
  [complexColorNames.black]: {
    name: complexColorNames.black,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.BLACK, s.BLACK, l.BLACK],
    hslType: [hName.BLACK, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.BLACK],
    simpleOpts: {
      id: simpleColors.BLACK,
      isSimpleColor: true,
      simpleOrder: 8,
    },
  },
  // GRAY
  [complexColorNames.lightGray]: {
    name: complexColorNames.lightGray,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.GRAY, s.GRAY, l.DESAT_LIGHT],
    hslType: [hName.GRAY, sName.CUSTOM, lName.LIGHT],
    associations: [simpleColors.GRAY],
    simpleOpts: { isSimpleColor: false },
  },
  [complexColorNames.gray]: {
    name: complexColorNames.gray,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.GRAY, s.GRAY, l.DESAT_MID],
    hslType: [hName.GRAY, sName.CUSTOM, lName.MID],
    associations: [simpleColors.GRAY],
    simpleOpts: {
      id: simpleColors.GRAY,
      isSimpleColor: true,
      simpleOrder: 9,
    },
  },
  [complexColorNames.darkGray]: {
    name: complexColorNames.darkGray,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.GRAY, s.GRAY, l.DARKEST_DARK],
    hslType: [hName.GRAY, sName.CUSTOM, lName.DARK],
    associations: [simpleColors.GRAY, simpleColors.BLACK],
    simpleOpts: { isSimpleColor: false },
  },
  // WHITE
  [complexColorNames.white]: {
    name: complexColorNames.white,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.WHITE, s.WHITE, l.WHITE],
    hslType: [hName.WHITE, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.WHITE],
    simpleOpts: {
      id: simpleColors.WHITE,
      isSimpleColor: true,
      simpleOrder: 10,
    },
  },
  // CREAM
  [complexColorNames.cream]: {
    name: complexColorNames.cream,
    colorTemperature: colorTemperatures.NEUTRAL,
    hsl: [h.CREAM, s.CREAM, l.CREAM],
    hslType: [hName.CREAM, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.WHITE, simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  // GOLD
  [complexColorNames.gold]: {
    name: complexColorNames.gold,
    colorTemperature: colorTemperatures.METALLIC,
    hsl: [h.GOLD, s.GOLD, l.GOLD],
    hslType: [hName.GOLD, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.YELLOW],
    simpleOpts: { isSimpleColor: false },
  },
  // SILVER
  [complexColorNames.silver]: {
    name: complexColorNames.silver,
    colorTemperature: colorTemperatures.METALLIC,
    hsl: [h.SILVER, s.SILVER, l.SILVER],
    hslType: [hName.SILVER, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.GRAY],
    simpleOpts: { isSimpleColor: false },
  },
  // BRONZE
  [complexColorNames.bronze]: {
    name: complexColorNames.bronze,
    colorTemperature: colorTemperatures.METALLIC,
    hsl: [h.BRONZE, s.BRONZE, l.BRONZE],
    hslType: [hName.BRONZE, sName.CUSTOM, lName.CUSTOM],
    associations: [simpleColors.BROWN],
    simpleOpts: { isSimpleColor: false },
  },
}

export const huesList = Object.values(h)
