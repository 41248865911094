//////////////////////////////////////////////////////
// FORMATTERS
//////////////////////////////////////////////////////

import { isJapanese } from '../../../modules/language-helpers/language-helpers'

function shortMonth(d: Date, userLanguage: string) {
  return d.toLocaleString(userLanguage, {
    month: 'short',
  })
}

// E.g. in Japanese we want to show 12日, not just 12
function day(d: Date, userLanguage: string) {
  return d.toLocaleString(userLanguage, {
    day: 'numeric',
  })
}

export function getYear(d: Date, userLanguage: string) {
  return d.toLocaleString(userLanguage, {
    year: 'numeric',
  })
}

export function monthYearFormat(d: Date, userLanguage: string) {
  const monthString = shortMonth(d, userLanguage)
  const yearString = getYear(d, userLanguage)

  // If Japanese, we want to show the year first
  if (isJapanese(userLanguage)) {
    return `${yearString} ${monthString}`
  } else {
    return `${monthString} ${yearString}`
  }
}

export function monthDayFormat(d: Date, userLanguage: string) {
  const dayString = day(d, userLanguage)
  const monthString = shortMonth(d, userLanguage)
  return `${dayString} ${monthString}`
}
