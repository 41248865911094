import React from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { notificationsForm } from '../../../forms/user-forms'
import { selectUser } from '../../../ducks/user/user'
import { updateUser } from '../../../ducks/user/thunks/user-thunks'
import { useTranslation } from 'react-i18next'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'

export type NotificationsSettingsEditNavigationProp =
  RootStackNavigationProp<'PrivateNotificationsSettingsEdit'>

export default function NotificationsSettingsEdit({}: RootStackScreenProps<'PrivateNotificationsSettingsEdit'>) {
  const [loading, setLoading] = React.useState(false)
  const user = useSelector(selectUser)
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  const {
    allowDailyNotifications,
    allowInsightNotifications,
    allowNotifications,
    allowWeeklyReportNotifications,
    morningReminderTime,
  } = user || {}

  const onSubmitForm = async (data: any) => {
    setLoading(true)

    const userPatch = {
      // allowDailyNotifications: data.allowDailyNotifications,
      // allowInsightNotifications: data.allowInsightNotifications,
      allowNotifications: data.allowNotifications,
      allowDailyNotifications: data.dailyReminder[0] || false,
      morningReminderTime: data.dailyReminder[1] || '08:00:00',
    }
    dispatch(updateUser(user?.id || '', userPatch)).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Form
      name={'notificationsForm'}
      form={notificationsForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        allowDailyNotifications,
        allowInsightNotifications,
        allowNotifications,
        allowWeeklyReportNotifications,
        dailyReminder: [allowDailyNotifications, morningReminderTime],
      }}
      headerTitle={t('settings.notificationSettings')}
    />
  )
}
