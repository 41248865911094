// The simplest set of colors
// One complex colour can be a 'type of' multiple simple colours
export const simpleColors = {
  PINK: 'pink',
  RED: 'red',
  BROWN: 'brown',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  BLACK: 'black',
  WHITE: 'white',
  GRAY: 'gray',
}

// These are used for grouping tints into circles.
// color name + saturation type (e.g. saturated magenta) is the 'key' for one circle
export const hName = {
  // Three tint circles (light, mid, dark)
  MAGENTA: 'magenta',
  PINK: 'pink',
  RED: 'red',
  ORANGE_RED: 'orangeRed',
  ORANGE: 'orange',
  GOLD_YELLOW: 'goldenYellow',
  YELLOW: 'yellow',
  YELLOW_GREEN: 'yellowGreen',
  GREEN: 'green',
  SPRING_GREEN: 'springGreen',
  TURQUOISE: 'turquoise',
  SKY_BLUE: 'skyBlue',
  BLUE: 'blue',
  BLUE_PURPLE: 'bluePurple',
  PURPLE: 'purple',
  GRAY: 'gray',

  // Single tint circles
  BLACK: 'black',
  WHITE: 'white',
  CREAM: 'cream',
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze',
}

// This is every colour that can be chosen
// Our 'pencil box', so to speak.
// These names are used in the tag strings
export const complexColorNames = {
  // Magenta
  magentaPink: 'magenta pink',
  magenta: 'magenta',
  darkMagenta: 'dark magenta',
  duskyMagentaPink: 'dusky magenta pink',
  duskyMagenta: 'dusky magenta',
  duskyDarkMagenta: 'dusky dark magenta',

  // Pink
  pink: 'pink',
  midPink: 'mid pink',
  darkPink: 'dark pink',
  purplePink: 'purple-pink',
  midPurplePink: 'mid purple-pink',
  darkPurplePink: 'dark purple-pink',

  // Red
  salmon: 'salmon',
  red: 'red',
  darkRed: 'dark red',
  duskySalmon: 'dusky salmon',
  redBrown: 'red-brown',
  darkRedBrown: 'dark red-brown',

  // Orange-red
  apricot: 'apricot',
  orangeRed: 'orange-red',
  darkOrangeRed: 'dark orange-red',
  lightChocolateBrown: 'light chocolate brown',
  midChocolateBrown: 'mid chocolate brown',
  chocolateBrown: 'chocolate brown',

  // Orange
  lightOrange: 'light orange',
  orange: 'orange',
  darkOrange: 'dark orange',
  lightBrown: 'light brown',
  brown: 'brown',
  darkBrown: 'dark brown',

  // Golden yellow
  lightGoldenYellow: 'light golden yellow',
  goldenYellow: 'golden yellow',
  darkGoldenYellow: 'dark golden yellow',
  lightGoldenBrown: 'light golden brown',
  goldenBrown: 'golden brown',
  darkGoldenBrown: 'dark golden brown',

  // Yellow
  lightYellow: 'light yellow',
  yellow: 'yellow',
  darkYellow: 'dark yellow',
  lightYellowBrown: 'light yellow-brown',
  yellowBrown: 'yellow-brown',
  darkYellowBrown: 'dark yellow-brown',

  // Yellow-green
  lightYellowGreen: 'light yellow-green',
  yellowGreen: 'yellow-green',
  darkYellowGreen: 'dark yellow-green',
  lightOliveGreen: 'light olive green',
  oliveGreen: 'olive green',
  darkOliveGreen: 'dark olive green',

  // Green / lime green
  lightLimeGreen: 'light lime green',
  limeGreen: 'lime green',
  darkLimeGreen: 'dark lime green',
  lightForestGreen: 'light forest green',
  forestGreen: 'forest green',
  darkForestGreen: 'dark forest green',

  // Green / spring green
  lightSpringGreen: 'light spring green',
  springGreen: 'spring green',
  green: 'green',
  lightSeaGreen: 'light sea green',
  seaGreen: 'sea green',
  darkSeaGreen: 'dark sea green',

  // Turquoise
  lightTurquoise: 'light turquoise',
  turquoise: 'turquoise',
  teal: 'teal',
  lightBlueGreen: 'light blue-green',
  blueGreen: 'blue-green',
  darkBlueGreen: 'dark blue-green',

  // Sky blue
  lightBlue: 'light blue',
  blue: 'blue',
  prussianBlue: 'prussian blue',
  lightBlueGray: 'light blue-gray',
  blueGray: 'blue-gray',
  darkBlueGray: 'dark blue-gray',

  // Royal blue
  cornflowerBlue: 'cornflower blue',
  royalBlue: 'royal blue',
  darkRoyalBlue: 'dark royal blue',
  lightSlateBlue: 'light slate blue',
  slateBlue: 'slate blue',
  darkSlateBlue: 'dark slate blue',

  // Blue-purple
  lightPurpleBlue: 'light purple-blue',
  purpleBlue: 'purple-blue',
  darkPurpleBlue: 'dark purple-blue',
  lightDuskyPurpleBlue: 'light dusky purple-blue',
  duskyPurpleBlue: 'dusky purple-blue',
  darkDuskyPurpleBlue: 'dark dusky purple-blue',

  // Purple
  lightPurple: 'light purple',
  purple: 'purple',
  darkPurple: 'dark purple',
  lightDuskyPurple: 'light dusky purple',
  duskyPurple: 'dusky purple',
  darkDuskyPurple: 'dark dusky purple',

  // Black:
  black: 'black',

  // Gray:
  lightGray: 'light gray',
  gray: 'gray',
  darkGray: 'dark gray',

  // White
  white: 'white',
  cream: 'cream',

  // Metallic
  gold: 'gold',
  silver: 'silver',
  bronze: 'bronze',
}
