import * as React from 'react'
import { useColorModeValue } from 'native-base'
import { CatIcon } from '../../assets/react-native-svg/illustrations/Cat'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Dimensions } from 'react-native'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { SansText } from '../common/copy/text-sans'
import { Row } from '../common/row/row'
import { Box } from '../common/box/box'
import { Column } from '../common/column/column'

type NoContentMessageProps = InterfaceBoxProps & {
  width?: number
  color?: string
  message?: string
}

export const NoContentMessage = ({
  width,
  color,
  message = 'Nothing to see here',
  ...rest
}: NoContentMessageProps) => {
  const pageWidth = Dimensions.get('window').width
  const autoColor = useColorModeValue(BLACK, WHITE)
  const iconColor = color || autoColor
  const autoWidth = pageWidth - PADDING_HORIZONTAL_PIXELS * 2
  const boxWidthToUse = width || autoWidth
  const iconWidth = boxWidthToUse / 2 < 128 ? boxWidthToUse / 2 : 128

  return (
    <Box
      style={{
        maxWidth: WEB_MAX_WIDTH,
      }}
      {...rest}
    >
      <Column alignItems={'center'} mb={3}>
        <Row justifyContent={'center'} mb={3}>
          <CatIcon size={iconWidth} color={iconColor} />
        </Row>
        <SansText textAlign={'center'} fontSize={'sm'} maxW={iconWidth * 1.5}>
          {message}
        </SansText>
      </Column>
    </Box>
  )
}
