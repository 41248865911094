/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastTag } from '../models/FastTag';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastTagService {
  /**
   * Retrieves the collection of FastTag resources.
   * Retrieves the collection of FastTag resources.
   * @param id FastTag identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastTag FastTag collection
   * @throws ApiError
   */
  public static dreamTags(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastTag>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreams/{id}/tags',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Retrieves the collection of FastTag resources.
   * Retrieves the collection of FastTag resources.
   * @param types
   * @param system
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastTag FastTag collection
   * @throws ApiError
   */
  public static typeTags(
    types: Array<string>,
    system: boolean = true,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastTag>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-tags',
      query: {
        'types': types,
        'system': system,
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a FastTag resource.
   * Creates a FastTag resource.
   * @param requestBody The new FastTag resource
   * @returns FastTag FastTag resource created
   * @throws ApiError
   */
  public static createTag(
    requestBody: FastTag,
  ): CancelablePromise<FastTag> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/fast-tags',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a FastTag resource.
   * Retrieves a FastTag resource.
   * @param id FastTag identifier
   * @returns FastTag FastTag resource
   * @throws ApiError
   */
  public static fastTag(
    id: string,
  ): CancelablePromise<FastTag> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-tags/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastTag resources.
   * Retrieves the collection of FastTag resources.
   * @param type
   * @param id FastTag identifier
   * @param limit
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastTag FastTag collection
   * @throws ApiError
   */
  public static sharedFriendshipTags(
    type: string,
    id: string,
    limit?: number,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastTag>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/friendships/{id}/shared-tags',
      path: {
        'id': id,
      },
      query: {
        'type': type,
        'limit': limit,
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
}
