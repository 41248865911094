/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterpretationRequest } from '../models/InterpretationRequest';
import type { InterpretationRequest_AnalysisResult } from '../models/InterpretationRequest_AnalysisResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InterpretationRequestService {
  /**
   * Creates a InterpretationRequest resource.
   * Creates a InterpretationRequest resource.
   * @param id InterpretationRequest identifier
   * @param requestBody The new InterpretationRequest resource
   * @returns InterpretationRequest_AnalysisResult InterpretationRequest resource created
   * @throws ApiError
   */
  public static interpretDream(
    id: string,
    requestBody: InterpretationRequest,
  ): CancelablePromise<InterpretationRequest_AnalysisResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreams/{id}/interpretation',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
