import React, { useState, useEffect } from 'react'
import { AlertPopup } from './alert-dialog'
import { useTranslation } from 'react-i18next'
import {
  fetchGroupById,
  getInviteCode,
} from '../../../ducks/groups/functions/groupFunctions'
import { useAuth } from '../../../contexts/auth-context'

type DialogGroupCodeProps = {
  isOpen: boolean
  onClose: () => void
  groupId: string
}

// Should use `https://elsewhere.to/join?code=${code}`?
export const DialogGroupCode = React.memo(
  ({ isOpen, onClose, groupId }: DialogGroupCodeProps) => {
    // State
    const [inviteCode, setInviteCode] = useState<string | null>(null)

    // Hooks
    const { t } = useTranslation()
    const { authData } = useAuth()

    // Vars
    const token = authData?.token || ''

    // Effects
    useEffect(() => {
      if (groupId && token && isOpen) {
        fetchGroupById(groupId)
          .then((group) => {
            // If the group already has an invite code, use that
            if (group?.inviteCode) {
              setInviteCode(group.inviteCode)
            }
            // Otherwise, create a new invite code
            else {
              getInviteCode(groupId, token).then((inviteCode) => {
                if (inviteCode) {
                  const code = inviteCode.inviteCode
                  setInviteCode(code)
                }
              })
            }
          })
          .catch((err) => {
            console.log('Error fetching group in dialog-group-code', err)
          })
      }
    }, [groupId, token, isOpen])

    return (
      <AlertPopup
        header={t('dialog.groupCode.title')}
        description={
          inviteCode
            ? `http${!__DEV__ ? 's' : ''}://${
                __DEV__
                  ? 'localhost:3000'
                  : process.env.REACT_APP_ENV === 'staging'
                  ? 'staging.elsewhere.to'
                  : 'elsewhere.to'
              }/invite/${inviteCode}`
            : t('loading.loadingGeneric')
        }
        descriptionScriptType="latin"
        hideConfirm={true}
        cancelLabel={t('common.close')}
        isOpen={isOpen}
        onClose={onClose}
      />
    )
  },
)
