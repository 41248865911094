import { WHITE } from '../constants/ui-constants'
import { FormDetails, FormFieldDetails } from '../components/common/form/form'
import { Platform } from 'react-native'

const fields: { [key: string]: FormFieldDetails } = {
  groupName: {
    label: 'addGroupForm.groupName.title',
    name: 'name',
    type: 'input',
    placeholder: 'addGroupForm.groupName.title',
    rules: {
      required: true,
      validate: async (value: string) => {
        // TODO: Check if group name is already taken?
        return value.length > 0
      },
    },
    focus: true,
  },
  groupImage: {
    label: 'addGroupForm.groupImage.title',
    //   description: 'Image for your group',
    name: 'image',
    type: 'image-picker',
    imagePickerProps: {
      // @ts-ignore
      mediaType: 'photo',
      includeBase64: false,
      width: 512,
      height: 512,
      cropping: true,
      cropperCircleOverlay: true,
      cropperChooseColor: WHITE,
      cropperCancelColor: WHITE,
      type: 'profile-image',
      alt: 'Profile picture for group',
      croppingAspectRatio: Platform.OS === 'web' ? 1 : undefined,
      showSkipCropButton: Platform.OS === 'web' ? false : undefined,
      croppingDefaultSelectionRatio: Platform.OS === 'web' ? 0.99 : undefined,
    },
    rules: {
      required: false,
    },
  },
  groupDescription: {
    name: 'description',
    label: 'addGroupForm.groupDescription.title',
    type: 'textarea-uncontrolled',
    placeholder: 'addGroupForm.groupDescription.placeholder',
    inputProps: {
      maxLength: 10000,
    },
    rules: {
      required: false,
    },
  },
}

export const newGroupForm: FormDetails = {
  pages: [
    {
      fields: [fields.groupName, fields.groupImage, fields.groupDescription],
    },
  ],
}

export const editGroupForm: FormDetails = {
  pages: [
    {
      fields: [fields.groupName, fields.groupImage, fields.groupDescription],
    },
  ],
}
