import { ViewState } from 'react-map-gl'

export const defaultViewport: any = {
  // USA
  latitude: 39.8283,
  longitude: -98.5795,
  zoom: 2,
  bearing: 0,
  pitch: 0,
  width: 'fit',
  padding: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}
