import { AlertStatus, PlatformKey } from '../../../../api/frontend-types'
import { Dimensions } from 'react-native'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'

// On mobile, content is full width.
export function getContentHeight() {
  const pageHeight = Dimensions.get('window').height
  const approximateHeaderHeight = 60
  const approximateFooterHeight = 60
  const componentHeight =
    pageHeight - approximateHeaderHeight - approximateFooterHeight
  return componentHeight
}

// On mobile, content is full width.
export function getContentWidth(platform: PlatformKey | undefined) {
  const { width } = Dimensions.get('window')
  switch (platform) {
    case 'web':
      return width < WEB_MAX_WIDTH ? width : WEB_MAX_WIDTH
    default:
      return width
  }
}

export function getPaddedContentWidth(platform: PlatformKey | undefined) {
  const contentWidth = getContentWidth(platform)
  return contentWidth - PADDING_HORIZONTAL_PIXELS * 2
}

export function getColorForStatus(
  status: AlertStatus | undefined,
  useHex = true,
) {
  switch (status) {
    case 'success':
      return useHex ? '#10b981' : 'emerald.500'
    case 'error':
      return useHex ? '#ef4444' : 'red.500'
    case 'warning':
      return useHex ? '#f59e0b' : 'amber.500'
    case 'info':
      return useHex ? '#3b82f6' : 'blue.500'
    default:
      return useHex ? '#3b82f6' : 'blue.500'
  }
}

// Some weird thing happens on iOS / Android where react-native-markdown-display
// text is too high in the container. This function returns the margin bottom
// to offset that
export function getMarkdownMarginBottomOffset(
  platform: PlatformKey | undefined,
) {
  switch (platform) {
    case 'android':
      return 0
    case 'ios':
      return -3.5
    default:
      return undefined
  }
}

// Some weird thing happens on iOS / Android where link text
// is too high in the container. This function returns the margin bottom
export function getLinkMarginBottomOffset(platform: PlatformKey | undefined) {
  switch (platform) {
    case 'android':
      return -6
    case 'ios':
      return 0
    default:
      return undefined
  }
}
