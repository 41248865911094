import React from 'react'
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'
import { Row as NbRow } from 'native-base'
import i18n from 'i18next'

export type RowProps = InterfaceHStackProps

// This is a simple wrapper around native-base's Row component
// That reverses the direction of the row if the language is RTL
export const Row = ({ children, flexDirection, ...rest }: RowProps) => {
  const defaultDir = i18n.dir() === 'rtl' ? 'row-reverse' : 'row'

  // If the flexDirection happens to be column (e.g. mobile layout), we don't want to reverse it
  const dir = flexDirection === 'column' ? flexDirection : defaultDir

  return (
    <NbRow flexDirection={dir} {...rest}>
      {children}
    </NbRow>
  )
}
