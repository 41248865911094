import React from 'react'
import { FireIcon } from '../../../assets/react-native-svg/illustrations/Fire'
import { useColorModeValue } from 'native-base'
import { HighlightBox } from '../../../components/layout/highlight-box'
import { PocketWatchIcon } from '../../../assets/react-native-svg/illustrations/PocketWatch'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../../../components/common/copy/text-sans'
import { StreakInfo } from '../../../../../api/frontend-types'
import { ContentDivider } from '../../layout/content-divider'
import { HeadingMain } from '../../common/copy/heading-main'
import { Row } from '../../common/row/row'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'

type StreakBoxProps = {
  width: number
  streakLength: number
  streakSubtitle: string // e.g. 'Dream Streak'
  freezeBtnLabel: string // e.g. 'Freeze'
  streakInfo: StreakInfo[]
  streakIsInDanger: boolean
  onFreezeStreak: () => void
}

function StreakBox({
  width,
  onFreezeStreak,
  streakSubtitle,
  freezeBtnLabel,
  streakInfo,
  streakLength,
  streakIsInDanger,
}: StreakBoxProps) {
  const iconColor = useColorModeValue(BLACK, WHITE)

  return (
    <HighlightBox
      marginX={0}
      width={width}
      padding={4}
      paddingTop={4}
      paddingBottom={4}
    >
      <Row justifyContent="center" width="100%">
        <Column alignItems={'center'} mr={10} justifyContent="center">
          <HeadingMain>{streakLength}</HeadingMain>
          {/* TODO */}
          <SansText mt={streakIsInDanger ? 2 : 2}>{streakSubtitle}</SansText>
          {/* {streakIsInDanger && (
            <ButtonPill
              mt={2}
              mb={1}
              buttonSize={'xs'}
              onPress={onFreezeStreak}
            >
              {freezeBtnLabel}
            </ButtonPill>
          )} */}
        </Column>
        <FireIcon color={iconColor} size={100} />
      </Row>
      <ContentDivider mt={4} mb={2} />
      <Row width="80%" mt={4} justifyContent="space-between">
        {streakInfo.map((day, index) => {
          return (
            <Column key={index} alignItems="center">
              <Box height={4} mb={2}>
                {day.hasDream && !day.isFreeze && (
                  <FireIcon color={iconColor} size={18} />
                )}
                {day.hasDream && day.isFreeze && (
                  <PocketWatchIcon color={iconColor} size={16} />
                )}
                {!day.hasDream && (
                  <SansText>
                    {streakIsInDanger && isToday(day) ? '!!' : '-'}
                  </SansText>
                )}
              </Box>
              <SansText fontWeight={isToday(day) ? 'bold' : 'normal'}>
                {day.letter}
              </SansText>
            </Column>
          )
        })}
      </Row>
    </HighlightBox>
  )
}

export default StreakBox

// Whether a day within a streak represents the current day
function isToday(day: StreakInfo) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const isToday = day.date.getDate() === today.getDate()
  return isToday
}
