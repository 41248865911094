import React, { useState } from 'react'
import i18n from 'i18next'
import { Input as NBTextInput } from 'native-base'
import {
  IInputProps,
  InterfaceInputProps,
} from 'native-base/lib/typescript/components/primitives/Input/types'
import { ButtonIcon } from '../buttons/button-icon'
import { ButtonPill } from '../buttons/button-pill'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  getSansFontKey,
  isRtlLanguage,
} from '../../../modules/language-helpers/language-helpers'
import {
  LanguageDirection,
  ScriptType,
} from '../../../../../api/frontend-types'
import { Box } from '../box/box'

// The regular props from NativeBase's Input component
// Plus a type prop, so we can say it's a password input
type InputFieldProps = IInputProps & {
  type?: InterfaceInputProps['type']
  wrapperWidth?: number | string
  onSubmit?: () => void
  onChangeText?: (text: string) => void
  submitLabel?: string
  submitIsLoading?: boolean
  submitButtonWidth?: number | string
  enterKeyHint?: 'enter' | 'done' | 'next' | 'search' | 'send'
  dir?: LanguageDirection
  scriptType?: ScriptType
}

export const InputField = ({
  type,
  wrapperWidth,
  onSubmit,
  onChangeText,
  submitLabel,
  submitIsLoading,
  submitButtonWidth,
  enterKeyHint,
  dir,
  scriptType,
  ...rest
}: InputFieldProps) => {
  const isPasswordInput = type === 'password'
  const isControlled = Boolean(onChangeText && rest.value)

  // STATE
  const [show, setShow] = useState(!isPasswordInput)

  const borderColor = useColorModeValue(BLACK, WHITE)
  const keyboardAppearance = useColorModeValue('light', 'dark')

  // VARS
  const userLanguage = i18n.resolvedLanguage || 'en'
  const direction = dir || isRtlLanguage(userLanguage) ? 'rtl' : 'ltr'
  const fontKey = getSansFontKey(userLanguage, scriptType)

  return (
    <Box position={'relative'} width={wrapperWidth} flexGrow={1}>
      <NBTextInput
        _focus={{ borderColor }}
        textAlign={direction === 'rtl' ? 'right' : 'left'}
        fontSize={'md'}
        size={'md'}
        fontFamily={fontKey}
        fontWeight={400}
        variant={'underlined'}
        type={show ? 'text' : 'password'}
        autoCapitalize="none"
        // @ts-ignore
        enterKeyHint={enterKeyHint}
        keyboardAppearance={keyboardAppearance}
        value={isControlled ? rest.value : undefined}
        onChangeText={onChangeText}
        {...rest}
      />
      {isPasswordInput && (
        <Box position={'absolute'} right={0} top={4}>
          <ButtonIcon
            iconKey={show ? 'eye-open' : 'eye-closed'}
            onPress={() => {
              setShow(!show)
            }}
          />
        </Box>
      )}
      {
        // If we have a submit label, show a button to the right of the input
        // that submits the form
        onSubmit && (
          <ButtonPill
            buttonSize={'sm'}
            onPress={onSubmit}
            isLoading={submitIsLoading}
            width={submitButtonWidth || 'auto'}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            {submitLabel || 'Submit'}
          </ButtonPill>
        )
      }
    </Box>
  )
}
