import React, { useState, useEffect } from 'react'
import { Form } from '../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { shareDreamForm } from '../../forms/dream-form'
import { FastShare, FastShareService } from '../../../../api/_openapi'
import { formatDateTimeForDatabase } from '../../modules/strings/string-helpers'
import {
  shareDreamIntoGroups,
  unshareDreamFromGroups,
} from '../../ducks/groups/thunks/group-thunks'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { useSelector } from '../../ducks/root-reducer'
import { RootStackScreenProps } from '../../navigation/types'
import { useDream } from '../../hooks/useDream'

export default function DreamShare({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDreamShare'>) {
  // State
  const [shares, setShares] = useState<FastShare[]>([])

  // Selectors
  const privateGroupId = useSelector(selectPrivateGroupId)
  console.log('private group id', privateGroupId)

  // Hooks
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()

  // Vars
  const id = (route?.params as any).id as string
  const dreamToShare = useDream(id)

  const fetchShares = async () => {
    return FastShareService.getDreamShares(id)
  }

  const setAndFetchShares = async () => {
    setShares(await fetchShares())
  }

  // Get the groups the dream has already been shared to
  useEffect(() => {
    setAndFetchShares()
  }, [id])

  const onSubmitForm = async (data: { groups: string[] }) => {
    if (!dreamToShare.dream) {
      return
    }

    //refetch to ensure they're up-to-date
    const shares = await fetchShares()

    const dreamIdToShare = dreamToShare.dream.id || ''

    //Handle new shares (excluding private group and groups already shared to)
    const shareGroups = data.groups.filter(
      (groupId) =>
        groupId !== privateGroupId &&
        !shares.find((s) => s.groupId === groupId),
    )

    if (shareGroups.length > 0) {
      const sharedDate = formatDateTimeForDatabase(new Date())
      console.log('shareGroups', shareGroups)
      dispatch(shareDreamIntoGroups(dreamIdToShare, shareGroups, sharedDate))
    }

    //Handle unshares
    const unshareGroupIds = shares
      .filter((share) => {
        return !data.groups.includes(share.groupId)
      })
      .map((share) => share.groupId)

    if (unshareGroupIds.length > 0) {
      console.log('unshareGroups', unshareGroupIds)
      dispatch(unshareDreamFromGroups(dreamIdToShare, unshareGroupIds))
    }

    navigation.goBack()
  }

  return (
    <Form
      name={'shareDreamForm'}
      form={shareDreamForm}
      onSubmit={onSubmitForm}
      prepopulateValues={{
        groups: shares.map((s) => s.groupId),
      }}
      headerTitle={t('common.share')}
      loading={!dreamToShare}
      loadingFormValues={!dreamToShare ? 'PROCESSING' : 'DONE'}
      loadingLabel={t('loading.loadingGeneric')}
    />
  )
}
