import React, { useEffect } from 'react'
import { MainStackScreenProps } from '../../../navigation/types'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { useSelector } from '../../../ducks/root-reducer'
import { selectFriendByGroupId } from '../../../ducks/friends/friends'
import {
  FastDream,
  FastDreamService,
  FastTag,
  FastTagService,
} from '../../../../../api/_openapi'
import TagViewComponent from '../../TagView/tag-view-component'
import { selectUser } from '../../../ducks/user/user'
import { selectGroupById } from '../../../ducks/groups/groups'
import { SansText } from '../../../components/common/copy/text-sans'

export default function FriendView({
  navigation,
  route,
}: MainStackScreenProps<'PrivateFriend'>) {
  return <SansText>Overview!</SansText>
}
