import React from 'react'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { Dimensions } from 'react-native'
import { GridDisplay } from '../../layout/grid-display'
import { getNumGridColsPerRow } from '../../../modules/ui-helpers/grid-helpers'
import { ImageSquare } from './image-square'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'

type ImageArrayEditableProps = {
  images: ImageOnObject[]
  onChange: (images: ImageOnObject[]) => void
  loading?: boolean
}

export const ImageArrayEditable = ({
  images,
  onChange,
  loading,
}: ImageArrayEditableProps) => {
  // Calculate page width and image width
  const windowWidth = Dimensions.get('window').width
  const innerWidth = windowWidth - PADDING_HORIZONTAL_PIXELS * 2
  const realWidth = Math.min(innerWidth, WEB_MAX_WIDTH)
  const numCols = getNumGridColsPerRow(realWidth)
  const imageWidth = realWidth / numCols

  const imageGridItems = images?.map((image, index) => ({
    id: image.id,
    item: (
      <ImageSquare
        image={image}
        onDelete={(imageToDelete) => {
          const newImages = images.filter((img) => img.id !== imageToDelete.id)
          onChange(newImages)
        }}
        width={imageWidth}
      />
    ),
  }))

  return (
    <GridDisplay
      mt={8}
      width={realWidth}
      items={imageGridItems}
      loading={loading}
    />
  )
}
