import React, { ReactNode } from 'react'
import { InfoText } from '../common/copy/text-info'
import { useTranslation } from 'react-i18next'
import { Row } from '../common/row/row'
import { useColorModeValue } from 'native-base'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../constants/ui-constants'

type LoadingLabelProps = {
  title?: string
  icon?: ReactNode
}

export const LoadingLabel = ({ title, icon }: LoadingLabelProps) => {
  const { t } = useTranslation()
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )
  const titleText = title || t('loading.loadingGeneric')

  return (
    <Row
      justifyContent={'center'}
      alignItems={'center'}
      color={mutedColor}
      mt={4}
    >
      <InfoText mr={2}>{titleText}</InfoText>
      {icon}
    </Row>
  )
}
