import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useColorModeValue } from 'native-base'
import { useAuth } from '../../contexts/auth-context'
import { useTranslation } from 'react-i18next'
import { DateRangeWithKey } from '../../../../api/frontend-types'
import { X_TICKS_PER_RANGE } from '../../config/frontend-config'
import { selectEarliestDreamDate } from '../../ducks/dream-tag/dream-tag'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import {
  BLACK,
  DARKER_CREAM,
  LIGHTEST_BLACK,
  WHITE,
} from '../../constants/ui-constants'
import {
  isHungarian,
  isJapanese,
  isPolish,
} from '../../modules/language-helpers/language-helpers'
import {
  ChartData,
  ChartPadding,
  TimelineChart,
} from '../../components/composite/chart/timeline-chart'
import { OpenAPI } from '../../../../api/_openapi'

type SentimentChartProps = {
  dateRangeWithKey: DateRangeWithKey
}

export type SentimentTimelineItem = {
  date: string
  negative: string
  positive: string
  sentiment: string
}

type SentimentTimelineResponse = {
  globalAverageSentiment: SentimentTimelineItem[]
  userAverageSentiment: SentimentTimelineItem[]
}
type dataKeys = 'global' | 'user'
type ColorMap = { [key in dataKeys]: string }

export type ChartDataArr = { x: string; y: number }[]

export const PADDING_RIGHT = 60

export const SentimentTimelineChart = ({
  dateRangeWithKey,
}: SentimentChartProps) => {
  // STATE
  const [loading, setLoading] = useState<boolean>(false)
  const [timelineData, setTimelineData] = useState<ChartData | null>(null)

  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()
  const userLineColor = useColorModeValue(BLACK, WHITE)
  const globalLineColor = useColorModeValue(DARKER_CREAM, LIGHTEST_BLACK)

  // SELECTORS
  const earliestDreamDate = useSelector(selectEarliestDreamDate) || new Date()
  const user = useSelector(selectUser)
  const userLanguage = user?.languageCode || 'en-US'

  // VARS
  const { range: dateRange } = dateRangeWithKey
  const colorMap: ColorMap = {
    global: globalLineColor,
    user: userLineColor,
  }
  const labelMap: { [key in dataKeys]: string } = {
    global: t('common.elsewhere'),
    user: t('common.you'),
  }

  const yTickFormat = (y: number) => {
    if (y === 0) {
      return t('chartsPage.chart.neutral')
    } else if (y > 0) {
      return `+${y * 100}%`
    } else {
      return `${y * 100}%`
    }
  }

  function getHorizontalPadding(lang: string) {
    if (isHungarian(lang) || isPolish(lang)) {
      return 60
    } else if (isJapanese(lang)) {
      return 40
    } else {
      return 50
    }
  }

  // EFFECTS

  useEffect(() => {
    setLoading(true)

    const params = {
      startDate: dateRange.start
        ? dateRange.start.toISOString()
        : earliestDreamDate.toISOString(),
      endDate: dateRange.end.toISOString(),
    }

    // Sentiment timeline
    // {"date": "2023-12-16", "negative": "-0.5500000119209301", "positive": "0", "sentiment": "-0.5500000119209301"},
    // {"date": "2023-12-18", "negative": "-0.400000005960465", "positive": "0.375", "sentiment": "-0.025000005960464977"}], "userAverageSentiment": [{"date": "2023-12-18", "negative": "-0.400000005960465", "positive": "0.30000001192093", "sentiment": "-0.09999999403953497"}]}
    axios({
      method: 'get',
      url: `${OpenAPI.BASE}/api/power-di/sentiment-timeline`,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authData?.token,
      },
    })
      .then(function (response) {
        const data: SentimentTimelineResponse = response.data
        const { chartData } = timelineRespToChartData(data, colorMap, labelMap)
        setTimelineData(chartData)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error getting sentiment timeline')
        console.log(err)
        setLoading(false)
      })
  }, [dateRangeWithKey])

  const chartPadding: ChartPadding = {
    left: getHorizontalPadding(userLanguage),
    right: getHorizontalPadding(userLanguage),
    top: 30,
  }

  return (
    <TimelineChart
      chartData={timelineData}
      loading={loading}
      chartPadding={chartPadding}
      tickCount={X_TICKS_PER_RANGE[dateRangeWithKey.key]}
      chartTitle={t('chartsPage.sentiment.moodChartHeader')}
      yTickFormat={yTickFormat}
      dateRangeWithKey={dateRangeWithKey}
      userLanguage={userLanguage}
    />
  )
}

function sentimentItemsToChartItems(
  items: SentimentTimelineItem[],
): { x: string; y: number }[] {
  return items.map((item) => {
    return {
      x: item.date,
      y: parseFloat(item.sentiment),
    }
  })
}

function timelineRespToChartData(
  sentimentTimelineResponse: SentimentTimelineResponse,
  colorMap: ColorMap,
  labelMap: { [key in dataKeys]: string },
): {
  chartData: ChartData
} {
  const { globalAverageSentiment, userAverageSentiment } =
    sentimentTimelineResponse

  const globalData = sentimentItemsToChartItems(globalAverageSentiment)

  const userData = sentimentItemsToChartItems(userAverageSentiment)

  const chartData: ChartData = {
    global: {
      data: globalData,
      color: colorMap.global,
      label: labelMap.global,
    },
    user: {
      data: userData,
      color: colorMap.user,
      label: labelMap.user,
    },
  }

  return {
    chartData,
  }
}
