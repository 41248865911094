import { FastDream } from '../../../../api/_openapi'
import { useSelector } from '../../ducks/root-reducer'
import {
  selectAllFastDreams,
  selectDreamsAreLoading,
} from '../../ducks/dream-tag/dream-tag'
import { useMemo } from 'react'
import { EntryType } from '../../../../api/frontend-types'

export type UseDreams = {
  loading: boolean
  dreams: FastDream[]
}

type UseUserDreamsOptions = {
  drafts?: boolean
  type?: EntryType
  favorites?: boolean
}

export function useUserDreams(
  {
    drafts = false,
    type = 'dream',
    favorites = false,
  }: UseUserDreamsOptions = {
    drafts: false,
    type: 'dream',
    favorites: false,
  },
): UseDreams {
  const allUserDreams = useSelector(selectAllFastDreams)
  const userDreamsLoading = useSelector(selectDreamsAreLoading)
  return useMemo(() => {
    const typedDreams = allUserDreams.filter((d) => d.type === type)
    const favoriteDreams = typedDreams.filter(
      (d) => !favorites || d.userReactionCount > 0,
    )
    return {
      loading: userDreamsLoading,
      dreams: userDreamsLoading
        ? []
        : drafts
        ? favoriteDreams.filter((d) => d.isDraft)
        : favoriteDreams.filter((d) => !d.isDraft),
    }
  }, [allUserDreams, userDreamsLoading, drafts, type, favorites])
}
