import React from 'react'
import { useColorModeValue } from 'native-base'
import { LINE_WIDTH } from '../../../constants/constants'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { HeadingSansWithOptionalButtonRow } from '../../layout/heading-sans-button-row'
import { ElsewhereIconType } from '../../../modules/ui-helpers/icon-map'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'

type TagSectionAreaProps = {
  heading: string
  buttonIconKey?: ElsewhereIconType
  hasButton?: boolean
  children?: React.ReactNode
  onButtonPress?: () => void
  paddingBottom?: number
}

// Wrapper component for a section of tags
// May wrap regular tags, or symbol tags, or color tags, etc
// Important thing is that it standardises the layout
export const TagSectionArea = ({
  heading,
  buttonIconKey,
  onButtonPress,
  children,
  paddingBottom = 4,
  hasButton,
}: TagSectionAreaProps) => {
  const color = useColorModeValue(BLACK, WHITE)
  const hasChildren = Boolean(children)

  return (
    <Column
      width={'100%'}
      borderTopWidth={LINE_WIDTH}
      borderColor={color}
      paddingBottom={paddingBottom}
    >
      <HeadingSansWithOptionalButtonRow
        heading={heading}
        buttonIconKey={buttonIconKey}
        onButtonPress={onButtonPress}
        hasButton={hasButton}
        marginTop={4}
        // If there's nothing in the section,
        // We don't want to effectively add extra bottom padding
        marginBottom={hasChildren ? 2 : 0}
      />
      <Box>{children}</Box>
    </Column>
  )
}
