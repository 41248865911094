import React, { useState } from 'react'
import { CheckboxField } from './field-checkbox'
import { InputField } from '../input-field'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { ColorType } from 'native-base/lib/typescript/components/types'
import { Row } from '../../row/row'
import { Column } from '../../column/column'
import { CheckboxGroupWithFreeOptionType } from '../../form/form-field'

type CheckboxGroupWithFreeOptionProps = {
  options: FormFieldOption[]
  onChange: (value: CheckboxGroupWithFreeOptionType) => void
  multiSelect?: boolean
  value: CheckboxGroupWithFreeOptionType
  defaultValue: CheckboxGroupWithFreeOptionType
  color?: ColorType
  disablePreSelected?: boolean
  freeOptionTextMaxLength?: number
}

// Possibly we should be using Checkbox.Group here
// But it doesn't seem to play nice with a free option
export const CheckboxGroupWithFreeOption = ({
  options,
  multiSelect = false,
  onChange,
  value,
  defaultValue,
  color,
  disablePreSelected = false,
  freeOptionTextMaxLength = 50,
}: CheckboxGroupWithFreeOptionProps) => {
  const { freeOption, values: preSelected } = defaultValue
  const freeOptionExists =
    options.find((o) => o.value === freeOption) && freeOption

  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [freeTextOption, setFreeTextOption] = useState<string>(
    (value.values.includes(value.freeOption) && value.freeOptionText) ||
      defaultValue.freeOptionText,
  )
  const { t } = useTranslation()

  const addToSelected = (valueToAdd: string) => {
    const valueLower = valueToAdd.toLocaleLowerCase()
    if (selectedValues.includes(valueLower)) return

    let newValues: string[] = []

    if (multiSelect) {
      newValues = [...selectedValues, valueLower]
    } else {
      newValues = [valueLower]
    }
    setSelectedValues(newValues)
    onChange({
      ...value,
      values: newValues,
      freeOptionText: newValues.includes(freeOption)
        ? value.freeOptionText
        : '',
    })
  }

  const removeFromSelected = (valueToRemove: string) => {
    const valueLower = valueToRemove.toLocaleLowerCase()
    let newValues: string[] = []
    if (multiSelect) {
      newValues = value.values.filter((item) => {
        return item.toLocaleLowerCase() !== valueLower
      })
    } else {
      newValues = []
    }
    setSelectedValues(newValues)
    onChange({
      ...value,
      values: newValues,
      freeOptionText: newValues.includes(freeOption)
        ? value.freeOptionText
        : '',
    })
  }

  const singleOption = options.length === 1

  const freeOptionLabel = t(
    options.find((o) => o.value === freeOption)?.label || 'Other',
  )

  const textRef = React.useRef(null)

  return (
    <Column w="100%" space={0}>
      {options
        .filter((o) => (freeOption ? o.value !== freeOption : true))
        .map((option) => {
          return (
            <CheckboxField
              _checked={{
                bg: color,
                borderColor: color,
              }}
              isInvalid={false}
              key={option.value}
              value={option.value}
              accessibilityLabel={option.label}
              my={singleOption ? 0 : 2}
              isDisabled={
                disablePreSelected ? preSelected.includes(option.value) : false
              }
              isChecked={value.values.includes(option.value)}
              onChange={(checked) => {
                if (checked) {
                  addToSelected(option.value)
                  if (freeOption && !multiSelect) {
                    setFreeTextOption('')
                  }
                } else {
                  removeFromSelected(option.value)
                }
              }}
              alignTop={singleOption}
            >
              {t(option.label)}
            </CheckboxField>
          )
        })}
      {freeOptionExists && (
        <Row>
          <CheckboxField
            _checked={{
              bg: color,
              borderColor: color,
            }}
            key={freeTextOption} // Needed to make sure freeTextOption is up-to-date in the onChange
            value={'freeOption'}
            accessibilityLabel={freeOptionLabel}
            my={2}
            isChecked={value.values.includes(freeOption)}
            onChange={(checked) => {
              const fieldEmpty = freeTextOption === ''

              console.log('freeOption', checked)

              // Otherwise set or unset the freeTextOption
              if (freeOption && !multiSelect && checked) {
                removeFromSelected(options[0].value)
                addToSelected(freeOption)
              } else if (freeOption && !multiSelect && !checked) {
                removeFromSelected(freeOption)
              } else {
                if (checked && !fieldEmpty) {
                  addToSelected(freeOption)
                } else if (!checked && !fieldEmpty) {
                  removeFromSelected(freeOption)
                  setFreeTextOption('')
                }
              }
            }}
          />
          <InputField
            placeholder={freeOptionLabel}
            autoFocus={false}
            wrapperWidth={200}
            value={freeTextOption}
            isRequired={true}
            isDisabled={!value.values.includes(freeOption)}
            onChange={(e) => {
              const { text = '' } = e.nativeEvent
              setFreeTextOption(text)
              onChange({ ...value, freeOptionText: text })
            }}
            maxLength={freeOptionTextMaxLength}
          />
        </Row>
      )}
    </Column>
  )
}
