import React from 'react'
import { IPressableProps } from 'native-base/lib/typescript/components/primitives/Pressable/types'
import { Pressable as NbPressable } from 'native-base'

export type PressableProps = IPressableProps

// This is a simple wrapper around native-base's View component
export const Pressable = ({ children, ...rest }: PressableProps) => {
  return <NbPressable {...rest}>{children}</NbPressable>
}
