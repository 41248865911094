import React from 'react'
import { Platform, BackHandler, Linking } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AlertPopup } from './alert-dialog'

type AlertPleaseUpdateProps = {
  isOpen: boolean
}

export const AlertPleaseUpdate = ({ isOpen }: AlertPleaseUpdateProps) => {
  const { t } = useTranslation()

  return (
    <AlertPopup
      header={t('dialog.pleaseUpdate.title')}
      description={t('dialog.pleaseUpdate.description')}
      hideCancel={true}
      isOpen={isOpen}
      onClose={() => {}}
      onConfirm={() => {
        BackHandler.exitApp()
        Linking.openURL(
          Platform.OS === 'android'
            ? 'https://play.google.com/store/apps/details?id=to.elsewhere'
            : 'https://apps.apple.com/us/app/elsewhere/id6445864345',
        )
      }}
      confirmLabel={t('dialog.pleaseUpdate.buttonLabel')}
      hideClose={true}
    />
  )
}
