import React, { useState } from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { basicDetailsForm } from '../../../forms/user-forms'
import { selectUser } from '../../../ducks/user/user'
import { updateUser } from '../../../ducks/user/thunks/user-thunks'
import { useTranslation } from 'react-i18next'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'

export type DreamerEditNavigationProp =
  RootStackNavigationProp<'PrivateDreamerEdit'>

export default function DreamerEdit({}: RootStackScreenProps<'PrivateDreamerEdit'>) {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  const onSubmitForm = async (data: any) => {
    setLoading(true)
    const newProfileImage = data.profileImage?.id

    const userPatch = {
      username: data.username,
      profileImageId: newProfileImage,
      bio: data.bio || '',
      pronouns: data.pronouns || '',
    }

    dispatch(updateUser(user?.id || '', userPatch)).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Form
      name={'basicDetailsForm'}
      form={basicDetailsForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        username: user?.username,
        profileImage: {
          id: user?.profileImageId,
          url: user?.profileImageUrl,
          alt: user?.profileImageAlt,
        },
        bio: user?.bio || '',
        pronouns: user?.pronouns || '',
      }}
      headerTitle={t('settings.editProfile')}
      avatarFallbackName={user?.username}
    />
  )
}
