import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { Friend } from '../../../../api/_openapi'
import { PURGE } from 'redux-persist'
import { createObjectMap } from '../dream-tag/modules/normalize'

export type FriendState = {
  friends: { [key: string]: Friend }
}

const initialState: FriendState = {
  friends: {},
}

const friendSlice = createSlice({
  name: 'friends',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
  reducers: {
    setFriends(state, action: PayloadAction<Friend[]>) {
      state.friends = createObjectMap(action.payload)
    },
    addFriend(state, action: PayloadAction<Friend>) {
      const friend = action.payload
      state.friends = {
        ...state.friends,
        [friend.id]: friend,
      }
    },
    updateFriend(
      state,
      action: PayloadAction<Partial<Friend> & { id: string }>,
    ) {
      const friend = action.payload
      state.friends = {
        ...state.friends,
        [friend.id]: {
          ...state.friends[friend.id],
          ...friend,
        },
      }
    },
    removeFriend(state, action: PayloadAction<string>) {
      delete state.friends[action.payload]
    },
  },
})

export const { setFriends, addFriend, updateFriend, removeFriend } =
  friendSlice.actions

export const selectFriends = (state: RootState): Friend[] => {
  return Object.values(state.friends.friends)
}

export const selectFriendById = (id: string) => {
  return (state: RootState): Friend | undefined => {
    return state.friends.friends[id]
  }
}

export const selectFriendByTagId = (id: string | undefined) => {
  return (state: RootState): Friend | undefined => {
    if (!id) {
      return undefined
    }
    return Object.values(state.friends.friends).find(
      (friend) => friend.friendTagId === id,
    )
  }
}

export const selectFriendByGroupId = (id: string | null) => {
  return (state: RootState): Friend | undefined => {
    return Object.values(state.friends.friends).find(
      (friend) => friend.groupId === id,
    )
  }
}

export const selectFriendByUsername = (username: string | null) => {
  return (state: RootState): Friend | undefined => {
    if (!username) {
      return undefined
    }
    return Object.values(state.friends.friends).find(
      (friend) => friend.username === username,
    )
  }
}

export default friendSlice
