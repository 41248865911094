import { SubscriptionTierKey } from '../../../../api/frontend-types'
import { FeatureMap } from '../../ducks/ui/ui'

const TIER_ORDER: SubscriptionTierKey[] = [
  'temp',
  'free',
  'premium',
  'supporter',
]

/**
 * Determines if the user's tier is at least a minimum tier
 * @param tierToInspect: The user's tier
 * @param minTier: The minimum tier
 * @returns {Boolean} True if the user's tier is at least the minimum tier
 */
export const isAtLeastTier = (
  tierToInspect: SubscriptionTierKey | undefined,
  minTier: SubscriptionTierKey | undefined,
): boolean => {
  // If there's no minimum tier, the user meets the requirement
  if (!minTier) return true
  // If there's no user tier, the user doesn't meet the requirement
  if (!tierToInspect) return false
  // Otherwise, compare the user's tier to the minimum tier
  return TIER_ORDER.indexOf(tierToInspect) >= TIER_ORDER.indexOf(minTier)
}

/**
 * Determines if the user's tier is at most a maximum tier
 * E.g. if we only want to show something to free and premium users, we pass in 'premium' as the maxTier
 * @param tierToInspect: The user's tier
 * @param maxTier: The maximum tier
 * @returns {Boolean} True if the user's tier is at most the maximum tier
 */
export const isAtMostTier = (
  tierToInspect: SubscriptionTierKey | undefined,
  maxTier: SubscriptionTierKey | undefined,
): boolean => {
  // If there's no maximum tier, the user meets the requirement
  if (!maxTier) return true
  // If there's no user tier, the user doesn't meet the requirement
  if (!tierToInspect) return false
  // Otherwise, compare the user's tier to the maximum tier
  return TIER_ORDER.indexOf(tierToInspect) <= TIER_ORDER.indexOf(maxTier)
}

/**
 * Determines the tiers that a given feature is available to
 * @param featureName: The name of the feature
 * @param featureMap: The FeatureMap keyed by tier
 * @returns {SubscriptionTierKey[]} The tiers that the feature is available to
 */
export const getTiersForFeature = (
  featureName: string,
  featureMap: FeatureMap,
): SubscriptionTierKey[] => {
  let tiers: SubscriptionTierKey[] = []
  const tiersAndFeatures = Object.entries(featureMap)
  for (const [key, featureList] of tiersAndFeatures) {
    const tierKey = key as SubscriptionTierKey
    const featureIsInTier = featureList.find(
      (f) => f.featureName === featureName,
    )
    if (featureIsInTier) {
      tiers.push(tierKey)
    }
  }
  return tiers
}
