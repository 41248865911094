export function gtag_report_conversion(url?: any) {
  console.log('Elsewhere gtag_report_conversion')

  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  try {
    // @ts-ignore
    gtag('event', 'conversion', {
      send_to: 'AW-16639427605/Nj0ZCLT-5cIZEJWIpv49',
      event_callback: callback,
    })
  } catch (error) {
    console.log('Error in gtag_report_conversion:', error)
  } finally {
    console.log('Finally in gtag_report_conversion')
  }

  return false
}
