import React, { useState } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { TabProps } from '../../components/layout/tab-view-tabs'
import { TabView } from '../../components/layout/tab-view'
import { useTranslation } from 'react-i18next'
import { AnswersTab as InsightTab } from './AnswersTab'
import { AskYourDreamsHeader } from './header'
import { useFocusEffect } from '@react-navigation/native'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import { Insight, InsightService } from '../../../../api/_openapi'

export type AskYourDreamsNavigationProp =
  MainStackNavigationProp<'PrivateAskYourDreams'>

export default function AskYourDreams({
  route,
}: MainStackScreenProps<'PrivateAskYourDreams'>) {
  // STATE
  const [insights, setInsights] = useState<Insight[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  // HOOKS
  const { t } = useTranslation()
  const user = useSelector(selectUser)

  // EFFECTS
  useFocusEffect(
    React.useCallback(() => {
      if (user) {
        InsightService.dreamSeriesInsights()
          .then((insights) => {
            setInsights(insights)
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return
    }, [user]),
  )

  // COMPONENTS
  const AnswersTab = () => (
    <InsightTab
      route={route}
      loading={loading}
      insights={insights}
      noInsightsMsg={t('askYourDreams.noInsightsMessage')}
      setInsights={setInsights}
    />
  )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Answers',
      children: <AnswersTab />,
      label: t('askYourDreams.tabLabel'),
    },
  ]

  return (
    <TabView
      header={() => (
        <AskYourDreamsHeader insights={insights} setInsights={setInsights} />
      )}
      tabs={tabs}
      scenes={{
        Answers: AnswersTab,
      }}
    />
  )
}
