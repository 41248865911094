import React, { useState } from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { formatDateTimeForDatabase } from '../../../modules/strings/string-helpers'
import { newGroupForm } from '../../../forms/group-forms'
import { decorateId } from '../../../../../api/decorate-ids'
import { addGroup } from '../../../ducks/groups/thunks/group-thunks'
import { FastGroup, Group } from '../../../../../api/_openapi'
import { useTranslation } from 'react-i18next'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'

export type GroupAddNavigationProp = RootStackNavigationProp<'GroupAdd'>

export default function GroupAdd({
  navigation,
  route,
}: RootStackScreenProps<'GroupAdd'>) {
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const [creatingGroup, setCreatingGroup] = useState<boolean>(false)

  const onSubmitForm = (data: any) => {
    setCreatingGroup(true)
    const groupImage = data?.image?.id

    const group: any = {
      name: data.name || '',
      imageId: groupImage || '',
      description: data.description || '',
    }

    dispatch(addGroup(group))
      .then(async (res: FastGroup | null) => {
        if (res) {
          navigation.replace('GroupProfileView', { groupId: res.id })
        } else {
          console.error('Error adding group')
        }

        setCreatingGroup(false)
      })
      .catch((err: any) => {
        console.log('Error adding group', err)
        setCreatingGroup(false)
      })
  }

  return (
    <Form
      name={'newGroupForm'}
      form={newGroupForm}
      onSubmit={onSubmitForm}
      headerTitle={t('addGroupForm.title')}
      prepopulateValues={{
        name: '',
        description: '',
      }}
      loading={creatingGroup}
      loadingLabel={t('loading.group')}
    />
  )
}
