import React, { useState } from 'react'
import i18n from 'i18next'
import { TextInput as RNTextInput } from 'react-native'
import { Input as NBTextInput, useColorModeValue } from 'native-base'
import {
  IInputProps,
  InterfaceInputProps,
} from 'native-base/lib/typescript/components/primitives/Input/types'
import { ButtonIcon } from '../buttons/button-icon'
import { RefCallBack } from 'react-hook-form'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { LINE_WIDTH } from '../../../constants/constants'
import { useNavigation } from '@react-navigation/core'
import { isStackNav } from '../../../navigation/types'
import { TextAreaNavigationProp } from './input-textarea'
import { LoginNavigationProp } from '../../../screens/Login'
import { FormFieldNavigationProp } from '../form/form-field'
import { InputFieldFakeNavigationProp } from './input-field-fake'
import {
  LanguageDirection,
  ScriptType,
} from '../../../../../api/frontend-types'
import { getSansFontKey } from '../../../modules/language-helpers/language-helpers'
import { Box } from '../box/box'

export type TextInputNavigationProp =
  | InputFieldFakeNavigationProp
  | TextAreaNavigationProp
  | LoginNavigationProp
  | FormFieldNavigationProp

// The regular props from NativeBase's Input component
// Plus a type prop, so we can say it's a password input
export type InputFieldProps = IInputProps & {
  type?: InterfaceInputProps['type']
  wrapperWidth?: number
  isInvalid?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  inputRef?: RefCallBack
  autoFocus?: boolean
  dir?: LanguageDirection
  scriptType?: ScriptType
}

export const TextInput = ({
  type,
  wrapperWidth,
  isInvalid,
  isDisabled,
  isReadOnly,
  inputRef,
  autoFocus,
  dir,
  scriptType,
  ...rest
}: InputFieldProps) => {
  // STATE
  const [show, setShow] = useState(!(type === 'password'))

  // HOOKS
  const navigation = useNavigation<TextInputNavigationProp>()
  const borderColor = useColorModeValue(BLACK, WHITE)
  const keyboardAppearance = useColorModeValue('light', 'dark')

  const focusRef = React.useRef<RNTextInput>(null)
  React.useEffect(() => {
    const unsubscribe = isStackNav(navigation)
      ? navigation.addListener('transitionEnd', (e: any) => {
          if (autoFocus) {
            focusRef?.current?.focus()
          }
        })
      : () => {}

    return unsubscribe
  }, [navigation])

  // VARS
  const userLanguage = i18n.resolvedLanguage || 'en'
  const direction = dir || i18n.dir()
  const fontKey = getSansFontKey(userLanguage, scriptType)

  return (
    <Box position={'relative'} width={wrapperWidth || '100%'}>
      <NBTextInput
        ref={inputRef || focusRef}
        textAlign={direction === 'rtl' ? 'right' : 'left'}
        keyboardAppearance={keyboardAppearance}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        _focus={{ borderColor }}
        _invalid={{ borderColor: 'red.500' }}
        fontSize={'md'}
        size={'md'}
        fontFamily={fontKey}
        fontWeight={400}
        variant={'underlined'}
        // Bottom border width
        borderBottomWidth={LINE_WIDTH}
        type={show ? 'text' : 'password'}
        {...rest}
      />
      {type === 'password' && (
        <Box position={'absolute'} right={0} top={4}>
          <ButtonIcon
            iconKey={show ? 'eye-open' : 'eye-closed'}
            onPress={() => {
              setShow(!show)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
