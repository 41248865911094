/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastTagLocation } from '../models/FastTagLocation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastTagLocationService {
  /**
   * Retrieves the collection of FastTagLocation resources.
   * Retrieves the collection of FastTagLocation resources.
   * @param id FastTagLocation identifier
   * @returns FastTagLocation FastTagLocation collection
   * @throws ApiError
   */
  public static tagLocations(
    id: string,
  ): CancelablePromise<Array<FastTagLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tags/{id}/locations',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Creates a FastTagLocation resource.
   * Creates a FastTagLocation resource.
   * @param id FastTagLocation identifier
   * @param requestBody The new FastTagLocation resource
   * @returns FastTagLocation FastTagLocation resource created
   * @throws ApiError
   */
  public static addTagLocation(
    id: string,
    requestBody: Partial<FastTagLocation>,
  ): CancelablePromise<FastTagLocation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/tags/{id}/locations',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Replaces the FastTagLocation resource.
   * Replaces the FastTagLocation resource.
   * @param id FastTagLocation identifier
   * @param locationId FastTagLocation identifier
   * @param requestBody The updated FastTagLocation resource
   * @returns FastTagLocation FastTagLocation resource updated
   * @throws ApiError
   */
  public static updateTagLocation(
    id: string,
    locationId: string,
    requestBody: Partial<FastTagLocation>,
  ): CancelablePromise<FastTagLocation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/tags/{id}/locations/{locationId}',
      path: {
        'id': id,
        'locationId': locationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastTagLocation resource.
   * Removes the FastTagLocation resource.
   * @param id FastTagLocation identifier
   * @param locationId FastTagLocation identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteTagLocation(
    id: string,
    locationId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/tags/{id}/locations/{locationId}',
      path: {
        'id': id,
        'locationId': locationId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
