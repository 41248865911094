import React, { useState } from 'react'
import { CheckboxField } from './field-checkbox'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { ColorType } from 'native-base/lib/typescript/components/types'
import { Column } from '../../column/column'

type CheckboxGroupProps = {
  options: FormFieldOption[]
  onChange: (value: string[]) => void
  multiSelect?: boolean
  values: string[]
  defaultValues: string[]
  color?: ColorType
  disablePreSelected?: boolean
}

// Possibly we should be using Checkbox.Group here
// But it doesn't seem to play nice with a free option
export const CheckboxGroup = ({
  options,
  multiSelect = false,
  onChange,
  values,
  defaultValues,
  color,
  disablePreSelected = false,
}: CheckboxGroupProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const { t } = useTranslation()

  const addToSelected = (value: string) => {
    const valueLower = value.toLocaleLowerCase()
    if (selectedValues.includes(valueLower)) return

    if (multiSelect) {
      setSelectedValues((prev) => [...prev, valueLower])
      const newValues = [...selectedValues, valueLower]
      onChange(newValues)
    } else {
      setSelectedValues([valueLower])
      onChange([valueLower])
    }
  }

  const removeFromSelected = (value: string) => {
    const valueLower = value.toLocaleLowerCase()
    if (multiSelect) {
      setSelectedValues((prev) =>
        prev.filter((item) => {
          return item.toLocaleLowerCase() !== valueLower
        }),
      )
    } else {
      setSelectedValues([])
      onChange([])
    }
  }

  const preSelected = defaultValues

  const singleOption = options.length === 1

  return (
    <Column w="100%" space={0}>
      {options.map((option) => {
        return (
          <CheckboxField
            _checked={{
              bg: color,
              borderColor: color,
            }}
            key={option.value}
            value={option.value}
            accessibilityLabel={option.label}
            my={singleOption ? 0 : 2}
            isDisabled={
              disablePreSelected ? preSelected.includes(option.value) : false
            }
            isChecked={values.includes(option.value)}
            onChange={(checked) => {
              if (checked) {
                addToSelected(option.value)
              } else {
                removeFromSelected(option.value)
              }
            }}
            alignTop={singleOption}
          >
            {t(option.label)}
          </CheckboxField>
        )
      })}
    </Column>
  )
}
