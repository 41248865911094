import { combineReducers, AnyAction } from '@reduxjs/toolkit'
import dreamSlice, { DataState } from './dream-tag/dream-tag'
import uiSlice, { UIState } from './ui/ui'
import userSlice, { UserState } from './user/user'
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux'
import { reducer as network } from 'react-native-offline'
import { NetworkState } from 'react-native-offline/dist/src/types'
import subscriptionSlice, {
  SubscriptionState,
} from './subscription/subscription'
import groupSlice, { GroupState } from './groups/groups'
import queueSlice, { QueueState } from './queue/queue'
import interpretationSlice, {
  InterpretationState,
} from './interpretations/interpretations'
import friendSlice, { FriendState } from './friends/friends'

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

type GlobalState =
  | {
      dream: DataState
      ui: UIState
      user: UserState
      groups: GroupState
      network: NetworkState
      subscription: SubscriptionState
      queue: QueueState
      interpretations: InterpretationState
      friends: FriendState
    }
  | undefined

const appReducer = combineReducers({
  dream: dreamSlice.reducer,
  ui: uiSlice.reducer,
  user: userSlice.reducer,
  groups: groupSlice.reducer,
  subscription: subscriptionSlice.reducer,
  queue: queueSlice.reducer,
  interpretations: interpretationSlice.reducer,
  friends: friendSlice.reducer,
  network,
})

export const rootReducer = (state: GlobalState, action: AnyAction) => {
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
