import React, { useState, useEffect } from 'react'
import { LoadingPage } from '../../../components/layout/loading-page'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { BodyText } from '../../../components/common/copy/text-body'
import { ButtonPill } from '../../../components/common/buttons/button-pill'
import { useSelector } from '../../../ducks/root-reducer'
import { useTranslation } from 'react-i18next'
import { FastGroup, FastGroupService } from '../../../../../api/_openapi'
import { AvatarCircleMedia } from '../../../components/common/avatar/avatar-circle-media'
import {
  selectGroupById,
  selectUserMembershipInGroup,
} from '../../../ducks/groups/groups'
import { InfoText } from '../../../components/common/copy/text-info'
import { DialogGroupCode } from '../../../components/common/dialog/dialog-group-code'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { selectUser } from '../../../ducks/user/user'
import { HeadingMain } from '../../../components/common/copy/heading-main'
import { Center } from '../../../components/common/center/center'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type GroupProfileNavigationProp =
  RootStackNavigationProp<'GroupProfileView'>

export default function GroupProfile({}: RootStackScreenProps<'GroupProfileView'>) {
  const [loading, setLoading] = useState<boolean>(true)
  const [codeDialogOpen, setCodeDialogOpen] = useState<boolean>(false)
  const [group, setGroup] = useState<FastGroup | null>(null)
  const { t } = useTranslation()

  const activeGroupId = useActiveGroupId()
  const activeGroup = useSelector(selectGroupById(activeGroupId))
  const user = useSelector(selectUser)

  // Get groups membership info
  const membershipInfo = useSelector(
    selectUserMembershipInGroup(user?.id || null, activeGroupId),
  )

  useEffect(() => {
    if (!activeGroupId) {
      return
    }

    if (activeGroup) {
      setGroup(activeGroup)
      setLoading(false)
    } else {
      FastGroupService.fastGroup(activeGroupId).then((fastGroup) => {
        setGroup(fastGroup)
        setLoading(false)
      })
    }
  }, [activeGroupId])

  if (loading || !group || !membershipInfo) {
    return <LoadingPage />
  } else {
    return (
      <>
        <ScrollView>
          <PaddedContentArea>
            <Center mt={4}>
              {group?.name && (
                <AvatarCircleMedia
                  source={group.imageUrl || undefined}
                  mb={2}
                  size={'xl'}
                  name={group.name}
                />
              )}
            </Center>
            <HeadingMain size={'2xl'} mb={4} mt={8}>
              {group.name}
            </HeadingMain>
            <InfoText textAlign={'center'} mb={8}>
              {membershipInfo.role === 'owner'
                ? t('groupProfilePage.ownerText')
                : t('groupProfilePage.memberText')}
            </InfoText>
            <BodyText mb={4}>{group?.description}</BodyText>
            {membershipInfo.role === 'owner' && (
              <ButtonPill
                onPress={() => {
                  setCodeDialogOpen(true)
                }}
                mb={4}
              >
                {t('common.getInviteCode')}
              </ButtonPill>
            )}
          </PaddedContentArea>
        </ScrollView>
        <DialogGroupCode
          isOpen={codeDialogOpen}
          groupId={group.id}
          onClose={() => setCodeDialogOpen(false)}
        />
      </>
    )
  }
}
