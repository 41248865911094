import React, { FC } from 'react'
import { SANS_BOOK_FONT_WEIGHT } from '../../../constants/constants'
import { TouchableOpacity } from 'react-native'
import { SansText } from './text-sans'
import { TextProps } from './text'
import { FormControl } from '../form-control/form-control'

type TextContentProps = TextProps & {
  onPress?: () => void
}

export const PressableFormControlText: FC<TextContentProps> = ({
  children,
  fontSize = 'sm',
  fontWeight = SANS_BOOK_FONT_WEIGHT,
  onPress,
  ...rest
}: TextContentProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <FormControl.HelperText>
        <SansText fontSize={'sm'} textTransform={'uppercase'} {...rest}>
          {children}
        </SansText>
      </FormControl.HelperText>
    </TouchableOpacity>
  )
}
