import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsSection } from '../../../../api/frontend-types'
import { SettingsList } from '../../components/composite/settings/settings'
import {
  IconEdit,
  IconInfo,
  IconGem,
  IconUserSettings,
  IconElsewhere,
  IconInstagram,
} from '../../assets/react-native-svg/icons/icons-ui'
import { Platform, Linking } from 'react-native'
import { getLocales } from 'react-native-localize'
import {
  APP_STORE_LINK_ID,
  GOOGLE_PLAY_LINK_ID,
} from '../../constants/constants'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../navigation/types'

export type AboutElsewhereNavigationProp =
  RootStackNavigationProp<'PrivateAboutElsewhere'>

export default function AboutElsewhere({
  navigation,
  route,
}: RootStackScreenProps<'PrivateAboutElsewhere'>) {
  // HOOKS
  let { t } = useTranslation()

  // VARS
  const isIos = Platform.OS === 'ios'
  const isAndroid = Platform.OS === 'android'
  const isWeb = Platform.OS === 'web'
  const userLocale = getLocales()[0]?.languageCode || 'en'

  const sections: SettingsSection[] = [
    {
      title: t('common.aboutElsewhere'),
      minTier: 'free',
      items: [
        {
          label: t('aboutElsewhere.header.elsewherePhilosophy'),
          subLabel: t('aboutElsewhere.header.elsewherePrinciples'),
          icon: IconElsewhere,
          internalLink: 'PrivateSubscriptionStatusView',
          onPress: () => {
            navigation.navigate('About')
          },
        },
        {
          label: t('settings.subscriptions.aboutSubscriptions'),
          subLabel: t('settings.subscriptions.aboutSubscriptions.subHeading'),
          icon: IconGem,
          internalLink: 'PrivateSubscriptionStatusView',
        },
        {
          label: t('common.team'),
          subLabel: t('aboutElsewhere.header.whoWeAre'),
          icon: IconUserSettings,
          internalLink: 'PrivateAboutElsewhereTeam',
        },
        {
          label: t('common.rateThisApp'),
          subLabel: t('common.shareFeedback'),
          icon: IconEdit,
          hideOnWeb: true,
          onPress: () => {
            if (isIos) {
              Linking.openURL(
                `itms-apps://itunes.apple.com/${userLocale}/app/id${APP_STORE_LINK_ID}?mt=8.`,
              ).catch((err) => {
                // If no app found, open in browser
                const urlToOpen = `https://apps.apple.com/${userLocale}/app/elsewhere/id${APP_STORE_LINK_ID}`
                console.log(err, urlToOpen)
                Linking.openURL(urlToOpen)
              })
            } else if (isAndroid) {
              Linking.openURL(
                `https://play.google.com/store/apps/details?id=${GOOGLE_PLAY_LINK_ID}`,
              ).catch((err) => {
                console.log(err)
              })
            }
          },
        },
      ],
    },
    {
      title: t('common.contact'),
      minTier: 'free',
      items: [
        {
          label: `${t('common.communityAndSocials')}, ${t('common.email')}`,
          subLabel: t('communitySocialPage.joinTheCommunity'),
          icon: IconInstagram,
          internalLink: 'Contact',
        },
      ],
    },
    {
      title: t('settings.terms'),
      items: [
        {
          label: t('settings.privacyAndTerms'),
          subLabel: t('settings.privacyAndTerms.subHeading'),
          icon: IconInfo,
          internalLink: 'PrivatePrivacyAndTerms',
        },
      ],
    },
  ]

  return (
    <SettingsList sections={sections} navigation={navigation} route={route} />
  )
}
