import React, { useEffect, useRef, memo } from 'react'
import { FlatList as RNFlatList, RefreshControl } from 'react-native'
import { EmptyFeedPage } from './page-empty-feed'
import { useSelector } from '../../../ducks/root-reducer'
import { selectFeedRefreshLoading } from '../../../ducks/ui/ui'
import { useColorModeValue } from 'native-base'
import { FastDream, FastUser } from '../../../../../api/_openapi'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { ADJUSTED_MARGIN_TOP } from '../../../constants/constants'
import {
  selectPrivateGroupId,
  selectUserGroups,
} from '../../../ducks/groups/groups'
import FeedItemDream from './feed-item-dream'
import {
  FeedNavigationProp,
  FeedRouteProp,
} from '../../../screens/Feed/GroupFeed'
import {
  PrivateDraftsFeedNavigationProp,
  PrivateDraftsFeedRouteProp,
} from '../../../screens/Drafts/DraftsFeed'
import { useNavigation, useRoute } from '@react-navigation/core'
import { dreamListsAreEqual } from '../../../ducks/equality'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'

export type DreamFeedListNavigationProp =
  | FeedNavigationProp
  | PrivateDraftsFeedNavigationProp
export type DreamFeedListRouteProp = FeedRouteProp | PrivateDraftsFeedRouteProp

type FeedProps = {
  entries: FastDream[]
  noEntriesMessage?: string
  simpleNoEntriesPage?: boolean
  refresh: () => void
  appUser: FastUser
  showType?: boolean
}

export const DreamFeedList = memo(
  ({
    entries,
    noEntriesMessage,
    simpleNoEntriesPage,
    refresh,
    appUser,
    showType = false,
  }: FeedProps) => {
    // SELECTORS
    const feedRefreshLoading = useSelector(selectFeedRefreshLoading)
    const privateGroupId = useSelector(selectPrivateGroupId)
    const allGroups = useSelector(selectUserGroups)

    // HOOKS
    const loaderColor = useColorModeValue(BLACK, WHITE)

    const navigation = useNavigation<DreamFeedListNavigationProp>()
    const route = useRoute<DreamFeedListRouteProp>()

    // VARS
    const activeGroupId = useActiveGroupId()

    // MEMOIZED VALUES
    const currentGroup = React.useMemo(() => {
      return allGroups.find((g) => g.id === activeGroupId)
    }, [appUser, activeGroupId, privateGroupId, allGroups, navigation, route])
    const hasDreams = entries.length > 0
    const flatListRef = useRef<RNFlatList>(null)

    useEffect(() => {
      if (flatListRef.current && route?.params?.from === 'BottomTabBar') {
        navigation.setParams({ from: undefined })
        flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 })
      }
    }, [route])

    // Empty feed if the user has no dreams
    // Or the user is not loaded
    return (
      <RNFlatList
        // @ts-ignore
        dataSet={{ scrollbarFlatListAdjust: true }}
        key={`${entries.length}`}
        ref={flatListRef}
        contentContainerStyle={{
          marginTop: ADJUSTED_MARGIN_TOP,
          flex: !hasDreams ? 1 : undefined,
          height: !hasDreams ? '100%' : undefined,
        }}
        ListEmptyComponent={() => {
          return (
            <EmptyFeedPage
              noDreamsText={noEntriesMessage}
              hideExtraContent={simpleNoEntriesPage}
            />
          )
        }}
        refreshing={feedRefreshLoading}
        onRefresh={refresh}
        refreshControl={
          <RefreshControl
            refreshing={feedRefreshLoading}
            onRefresh={refresh}
            tintColor={loaderColor}
          />
        }
        keyboardShouldPersistTaps="handled"
        style={{ width: '100%' }}
        initialNumToRender={5}
        data={entries}
        keyExtractor={(item) => item.id || ''}
        contentInsetAdjustmentBehavior="automatic"
        renderItem={({ item, index }) => {
          return (
            <FeedItemDream
              route={route}
              index={index}
              key={item.id}
              dream={item}
              privateGroupId={privateGroupId}
              currentGroup={currentGroup}
              appUser={appUser}
              activeGroupId={activeGroupId || ''}
              showType={showType}
            />
          )
        }}
      />
    )
  },
  propsAreEqual,
)

function propsAreEqual(prevProps: FeedProps, nextProps: FeedProps) {
  return dreamListsAreEqual(prevProps.entries, nextProps.entries)
}
