// To expand as needed
type IdType =
  | 'dreams'
  | 'tags'
  | 'genders'
  | 'users'
  | 'media_objects'
  | 'languages'
  | 'groups'
  | 'art_styles'
  | 'interpretation_styles'
  | 'analysis_results'
  | 'dream_shares'

/**
 * We can't usually just send the raw IDs to the API
 * We have to prefix them with the type of the ID
 * E.g. 'api/dreams/123' instead of just '123'
 * @param id The ID to decorate
 * @param route The type of the ID, e.g. 'dreams' or 'tags'
 * @returns The decorated ID
 */
export const decorateId = (
  id: string | null | undefined,
  route: IdType,
): string => {
  if (!id) {
    return ''
  }
  // If the ID is not a string
  else if (typeof id !== 'string') {
    console.log('Got a non-string ID', id)
    throw new Error(`ID ${id} is not a string`)
  }
  // If the ID is already decorated, return it
  else if (id.startsWith(`/api/${route}/`)) {
    return id
  }
  // If the ID is decorated with a different type, throw an error
  else if (id.startsWith('/api/')) {
    throw new Error(`ID ${id} is decorated with a different type, not ${route}`)
  }
  // Else decorate the ID
  else {
    return `/api/${route}/${id}`
  }
}

/**
 * Turn a decorated ID into an undecorated ID
 * @param id The ID to undecorate
 * @returns The undecorated ID
 */
export const undecorateId = (
  id: string | undefined | null | Object,
): string => {
  // If there's no ID, return an empty string
  // There may be cases where there's no ID yet.
  if (!id) {
    return ''
  }
  // This should never happen, but just in case
  // The dream tag type is an object, not a string
  else if (typeof id === 'object') {
    if (id.hasOwnProperty('id')) {
      return undecorateId((id as any).id)
    } else {
      throw new Error(`ID ${id} is an object without an id property`)
    }
  }
  // If the ID is already undecorated, return it
  else if (!id.startsWith('/api/')) {
    return id
  }
  // Else undecorate the ID
  else {
    return id.split('/').pop() || ''
  }
}

/**
 * Turn a decorated ID into an undecorated ID
 * @param id The ID to undecorate
 * @returns The undecorated ID
 */
export const undecorateIdKeepUndefined = (
  id: string | undefined | null,
): string | undefined | null => {
  // If there's no ID, return an empty string
  // There may be cases where there's no ID yet.
  if (!id) {
    return id
  }
  // If the ID is already undecorated, return it
  else if (!id.startsWith('/api/')) {
    return id
  }
  // Else undecorate the ID
  else {
    return id.split('/').pop() || ''
  }
}
