/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastShare } from '../models/FastShare';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastShareService {
  /**
   * Retrieves the collection of FastShare resources.
   * Retrieves the collection of FastShare resources.
   * @param id FastShare identifier
   * @returns FastShare FastShare collection
   * @throws ApiError
   */
  public static getDreamShares(
    id: string,
  ): CancelablePromise<Array<FastShare>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreams/{id}/shares',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Creates a FastShare resource.
   * Creates a FastShare resource.
   * @param id FastShare identifier
   * @param requestBody The new FastShare resource
   * @returns FastShare FastShare resource created
   * @throws ApiError
   */
  public static createDreamShare(
    id: string,
    requestBody: Partial<FastShare>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<FastShare> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreams/{id}/shares',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastShare resource.
   * Removes the FastShare resource.
   * @param dreamId FastShare identifier
   * @param groupId FastShare identifier
   * @returns void
   * @throws ApiError
   */
  public static unshareDream(
    dreamId: string,
    groupId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/unshare-dream/{dreamId}/{groupId}',
      path: {
        'dreamId': dreamId,
        'groupId': groupId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
