import React from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { useTranslation } from 'react-i18next'
import { editGroupForm } from '../../../forms/group-forms'
import { selectGroupById } from '../../../ducks/groups/groups'
import { updateGroup } from '../../../ducks/groups/thunks/group-thunks'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'

export type GroupProfileEditNavigationProp =
  RootStackNavigationProp<'GroupProfileEdit'>

export default function GroupProfileEdit({
  navigation,
  route,
}: RootStackScreenProps<'GroupProfileEdit'>) {
  const { t } = useTranslation()

  const groupIdFromRoute = useActiveGroupId()
  const group = useSelector(selectGroupById(groupIdFromRoute))
  const dispatch = useDispatch<any>()

  const onSubmitForm = async (data: any) => {
    const newGroupImageIdString = data?.image?.id

    const groupPatch = {
      name: data.name,
      imageId: newGroupImageIdString,
      description: data.description || '',
    }

    // Update group in state and DB
    dispatch(updateGroup(group?.id || '', groupPatch))

    // Go back
    navigation.goBack()
  }

  return (
    <Form
      name={'editGroupForm'}
      form={editGroupForm}
      onSubmit={onSubmitForm}
      prepopulateValues={{
        name: group?.name,
        image: {
          id: group?.imageId,
          url: group?.imageUrl,
          alt: group?.imageAlt,
        },
        description: group?.description,
      }}
      headerTitle={t('settings.editProfile')}
      avatarFallbackName={group?.name}
    />
  )
}
