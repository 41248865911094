import React, { ReactNode } from 'react'
import { BodyText } from './text-body'
import { Row } from '../row/row'
import { Box } from '../box/box'
import { Column } from '../column/column'
import { TextProps } from './text'

type BulletListProps = TextProps & {
  items: string[] | ReactNode[]
  itemSpacing?: number
}

export const BulletList = ({
  items,
  itemSpacing = 8,
  marginTop = 4,
  marginBottom = 4,
  ...rest
}: BulletListProps) => {
  return (
    <Box marginTop={marginTop} marginBottom={marginBottom} width={'100%'}>
      {items &&
        items.map((item, i) => {
          if (typeof item === 'string') {
            return (
              <Row
                width={'100%'}
                style={{ marginBottom: itemSpacing }}
                key={i}
                backgroundColor={'green'}
              >
                <Column minWidth={4}>
                  <BodyText>{`\u2022  `}</BodyText>
                </Column>
                <BodyText {...rest} pr={4}>{`${item}`}</BodyText>
              </Row>
            )
          }
          return item
        })}
    </Box>
  )
}
