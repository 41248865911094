import React from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  BODY_FONT_WEIGHT_STR,
  LARGE_LINE_HEIGHT,
  LG_FONTSIZE_PIXELS,
} from '../../../constants/constants'
import Markdown, {
  MarkdownProps,
} from '@ronradtke/react-native-markdown-display'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import i18n from '../../../i18n/i18nnext'
import {
  getBodyFont,
  isRtlLanguage,
} from '../../../modules/language-helpers/language-helpers'

export type BodyTextMarkdownProps = Omit<MarkdownProps, 'children'> & {
  color?: string
  textAlign?: 'left' | 'center' | 'right'
  children?: string | string[]
  language?: SupportedLanguage
}

export const BodyTextMarkdown = ({
  color,
  children = '',
  style,
  language,
  ...rest
}: BodyTextMarkdownProps) => {
  const defaultTextColor = useColorModeValue(BLACK, WHITE)
  const textColor = color || defaultTextColor
  const childrenAsString = `${children}`

  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const bodyFontFamily = getBodyFont(lang)
  const textAlign = isRtlLanguage(lang) ? 'right' : 'left'

  return (
    <Markdown
      style={{
        body: {
          fontFamily: bodyFontFamily,
          fontWeight: BODY_FONT_WEIGHT_STR,
          fontSize: LG_FONTSIZE_PIXELS,
          lineHeight: LARGE_LINE_HEIGHT,
          color: textColor,
          textAlign: textAlign,
          marginTop: -10,
          marginBottom: -10,
        },
        ...style,
      }}
      {...rest}
    >
      {childrenAsString}
    </Markdown>
  )
}
