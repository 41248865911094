/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionStatusResponse } from '../models/SubscriptionStatusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubscriptionStatusResponseService {
  /**
   * Retrieves a SubscriptionStatusResponse resource.
   * Retrieves a SubscriptionStatusResponse resource.
   * @param refresh
   * @returns SubscriptionStatusResponse SubscriptionStatusResponse resource
   * @throws ApiError
   */
  public static subscriptionStatus(
    refresh: boolean = false,
  ): CancelablePromise<SubscriptionStatusResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/subscription/status',
      query: {
        'refresh': refresh,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
