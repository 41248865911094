/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisResult } from '../models/AnalysisResult';
import type { AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read } from '../models/AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AnalysisResultService {
  /**
   * Retrieves the collection of AnalysisResult resources.
   * Retrieves the collection of AnalysisResult resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @param analysisRequest
   * @param analysisRequestArray
   * @param status
   * @param statusArray
   * @param generated
   * @param generatedArray
   * @param deleted
   * @param deletedArray
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult collection
   * @throws ApiError
   */
  public static apiAnalysisResultsGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
    analysisRequest?: string,
    analysisRequestArray?: Array<string>,
    status?: number,
    statusArray?: Array<number>,
    generated?: boolean,
    generatedArray?: Array<boolean>,
    deleted?: boolean,
    deletedArray?: Array<boolean>,
  ): CancelablePromise<Array<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/analysis_results',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
        'analysisRequest': analysisRequest,
        'analysisRequest[]': analysisRequestArray,
        'status': status,
        'status[]': statusArray,
        'generated': generated,
        'generated[]': generatedArray,
        'deleted': deleted,
        'deleted[]': deletedArray,
      },
    });
  }
  /**
   * Retrieves a AnalysisResult resource.
   * Retrieves a AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource
   * @throws ApiError
   */
  public static analysisResult(
    id: string,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/analysis_results/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the AnalysisResult resource.
   * Replaces the AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @param requestBody The updated AnalysisResult resource
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource updated
   * @throws ApiError
   */
  public static apiAnalysisResultsIdPut(
    id: string,
    requestBody: AnalysisResult,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/analysis_results/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Updates the AnalysisResult resource.
   * Updates the AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @param requestBody The updated AnalysisResult resource
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource updated
   * @throws ApiError
   */
  public static apiAnalysisResultsIdPatch(
    id: string,
    requestBody: AnalysisResult,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/analysis_results/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/merge-patch+json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a AnalysisResult resource.
   * Retrieves a AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource
   * @throws ApiError
   */
  public static dreamInterpretation(
    id: string,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreams/{id}/interpretation',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of AnalysisResult resources.
   * Retrieves the collection of AnalysisResult resources.
   * @param id AnalysisResult identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @param analysisRequest
   * @param analysisRequestArray
   * @param status
   * @param statusArray
   * @param generated
   * @param generatedArray
   * @param deleted
   * @param deletedArray
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult collection
   * @throws ApiError
   */
  public static dreamInterpretations(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
    analysisRequest?: string,
    analysisRequestArray?: Array<string>,
    status?: number,
    statusArray?: Array<number>,
    generated?: boolean,
    generatedArray?: Array<boolean>,
    deleted?: boolean,
    deletedArray?: Array<boolean>,
  ): CancelablePromise<Array<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreams/{id}/interpretations',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
        'analysisRequest': analysisRequest,
        'analysisRequest[]': analysisRequestArray,
        'status': status,
        'status[]': statusArray,
        'generated': generated,
        'generated[]': generatedArray,
        'deleted': deleted,
        'deleted[]': deletedArray,
      },
    });
  }
  /**
   * Retrieves the collection of AnalysisResult resources.
   * Retrieves the collection of AnalysisResult resources.
   * @param type
   * @param tagType
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @param analysisRequest
   * @param analysisRequestArray
   * @param status
   * @param statusArray
   * @param generated
   * @param generatedArray
   * @param deleted
   * @param deletedArray
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult collection
   * @throws ApiError
   */
  public static insights(
    type?: 'dream_interpretation_monitor' | 'tag_synthesis_monitor' | 'dream_series_analysis',
    tagType?: 'CHARACTER' | 'SETTING' | 'SYMBOL',
    page: number = 1,
    itemsPerPage: number = 30,
    analysisRequest?: string,
    analysisRequestArray?: Array<string>,
    status?: number,
    statusArray?: Array<number>,
    generated?: boolean,
    generatedArray?: Array<boolean>,
    deleted?: boolean,
    deletedArray?: Array<boolean>,
  ): CancelablePromise<Array<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/insights',
      query: {
        'type': type,
        'tagType': tagType,
        'page': page,
        'itemsPerPage': itemsPerPage,
        'analysisRequest': analysisRequest,
        'analysisRequest[]': analysisRequestArray,
        'status': status,
        'status[]': statusArray,
        'generated': generated,
        'generated[]': generatedArray,
        'deleted': deleted,
        'deleted[]': deletedArray,
      },
    });
  }
  /**
   * Retrieves a AnalysisResult resource.
   * Retrieves a AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource
   * @throws ApiError
   */
  public static insight(
    id: string,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/insights/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves a AnalysisResult resource.
   * Retrieves a AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource
   * @throws ApiError
   */
  public static publicDreamInterpretation(
    id: string,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/dreams/{id}/interpretation',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of AnalysisResult resources.
   * Retrieves the collection of AnalysisResult resources.
   * @param id AnalysisResult identifier
   * @param type
   * @param tagType
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @param analysisRequest
   * @param analysisRequestArray
   * @param status
   * @param statusArray
   * @param generated
   * @param generatedArray
   * @param deleted
   * @param deletedArray
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult collection
   * @throws ApiError
   */
  public static publicInsights(
    id: string,
    type?: 'dream_interpretation_monitor' | 'tag_synthesis_monitor' | 'dream_series_analysis',
    tagType?: 'CHARACTER' | 'SETTING' | 'SYMBOL',
    page: number = 1,
    itemsPerPage: number = 30,
    analysisRequest?: string,
    analysisRequestArray?: Array<string>,
    status?: number,
    statusArray?: Array<number>,
    generated?: boolean,
    generatedArray?: Array<boolean>,
    deleted?: boolean,
    deletedArray?: Array<boolean>,
  ): CancelablePromise<Array<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/insights/user/{id}', // Modified by scripts/modifyOpenApi.js
      path: {
        'id': id,
      },
      query: {
        'type': type,
        'tagType': tagType,
        'page': page,
        'itemsPerPage': itemsPerPage,
        'analysisRequest': analysisRequest,
        'analysisRequest[]': analysisRequestArray,
        'status': status,
        'status[]': statusArray,
        'generated': generated,
        'generated[]': generatedArray,
        'deleted': deleted,
        'deleted[]': deletedArray,
      },
    });
  }
  /**
   * Retrieves a AnalysisResult resource.
   * Retrieves a AnalysisResult resource.
   * @param id AnalysisResult identifier
   * @returns AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read AnalysisResult resource
   * @throws ApiError
   */
  public static publicInsight(
    id: string,
  ): CancelablePromise<AnalysisResult_AnalysisResult_read_id_trait_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/insights/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
