/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserStats } from '../models/UserStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserStatsService {
  /**
   * Retrieves the collection of UserStats resources.
   * Retrieves the collection of UserStats resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns UserStats UserStats collection
   * @throws ApiError
   */
  public static userStats(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<UserStats>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/stats/user-stats',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
}
