import React from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { SansText } from '../../common/copy/text-sans'
import { PurchasesPackage, PurchaseParams } from '../../../revenuecat/index.web'
import { useTranslation } from 'react-i18next'
import { Purchases } from '../../../revenuecat/index.web'
import { GoogleProductChangeInfo } from '../../../revenuecat'
import { BLACK, WHITE } from '../../../constants/ui-constants'

export const getIdentifier = (m: PurchasesPackage) => {
  return m?.rcBillingProduct.identifier
}

export const getGoogleProductChangeInfo = () => {
  return undefined
}

export const getOfferingsFromRevenueCat = async () => {
  return Purchases.getSharedInstance().getOfferings()
}

export const purchasePackage = async (
  packageToPurchase: PurchasesPackage,
  googleProductChangeInfo?: GoogleProductChangeInfo,
  customerEmail?: string,
) => {
  const purchaseParams: PurchaseParams = {
    rcPackage: packageToPurchase,
    customerEmail,
  }
  return Purchases.getSharedInstance().purchase(purchaseParams)
}

export function renderOptions(
  options: PurchasesPackage[] = [],
  feature = 'premium',
  value = '',
  setValue: any,
  isPurchasing: boolean,
  colorScheme: string,
) {
  const { t } = useTranslation()
  return (
    <>
      <FormControl>
        <RadioGroup
          key={feature + '-radio-group'}
          name="packages"
          value={value}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value: string,
          ) => {
            setValue(value)
          }}
        >
          {options.map((purchasePackage: PurchasesPackage) => {
            const { rcBillingProduct: pp } = purchasePackage
            const isPremium = feature === 'premium'
            const identifier = getIdentifier(purchasePackage)
            return (
              <FormControlLabel
                key={identifier + '-radio'}
                value={identifier}
                disabled={isPurchasing}
                control={
                  <Radio
                    style={{ color: colorScheme === 'dark' ? WHITE : BLACK }}
                  />
                }
                label={
                  <SansText pt={1}>
                    {(isPremium
                      ? t('subscriptions.tiers.premium')
                      : t('subscriptions.tiers.supporter')) +
                      ' (' +
                      t(
                        `subscriptions.rates.${
                          pp.identifier.includes('monthly')
                            ? 'perMonth'
                            : 'perYear'
                        }`,
                        { price: pp.currentPrice.formattedPrice },
                      ) +
                      ')'}
                  </SansText>
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </>
  )
}
