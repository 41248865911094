import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
} from 'react'

type DrawerContextData = {
  drawerVisible: boolean
  setDrawerVisible: (visible: boolean) => void
}

// Create the Drawer Context with the data type specified and an empty object
const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData)

const DrawerProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  // Start the DrawerContext start with loading equals true until data is loaded
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)

  return (
    // This component is used to encapsulate the App, so all components have access to the Context
    <DrawerContext.Provider value={{ drawerVisible, setDrawerVisible }}>
      {children}
    </DrawerContext.Provider>
  )
}

// A simple hook to facilitate access to the DrawerContext and permit components to subscribe to DrawerContext updates
function useDrawer(): DrawerContextData {
  const context = useContext(DrawerContext)

  if (!context) {
    throw new Error('useDrawer must be used within an DrawerProvider')
  }

  return context
}

export { DrawerContext, DrawerProvider, useDrawer }
