import React from 'react'
import { Button as NbButton } from 'native-base'
import { InterfaceButtonProps as NbButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types'

export type InterfaceButtonProps = NbButtonProps

// This is a simple wrapper around native-base's Button component
export const Button = ({ children, ...rest }: InterfaceButtonProps) => {
  return <NbButton {...rest}>{children}</NbButton>
}
