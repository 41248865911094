/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSignupRequest } from '../models/UserSignupRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserSignupRequestService {
  /**
   * Retrieves the collection of UserSignupRequest resources.
   * Retrieves the collection of UserSignupRequest resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns UserSignupRequest UserSignupRequest collection
   * @throws ApiError
   */
  public static apiUserSignupRequestsGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<UserSignupRequest>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user_signup_requests',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a UserSignupRequest resource.
   * Creates a UserSignupRequest resource.
   * @param requestBody The new UserSignupRequest resource
   * @returns UserSignupRequest UserSignupRequest resource created
   * @throws ApiError
   */
  public static signupRequest(
    requestBody: UserSignupRequest,
    sendCode: boolean = false, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<UserSignupRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      query: { 'sendCode': sendCode }, // Modified by scripts/modifyOpenApi.js
      url: '/login/signup-request',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
