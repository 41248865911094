import { FastDream } from '../../../../api/_openapi'
import { useSelector } from '../../ducks/root-reducer'
import { selectAllGroupDreams } from '../../ducks/dream-tag/dream-tag'
import { useMemo } from 'react'
import { EntryType } from '../../../../api/frontend-types'

export type UseDreams = {
  loading: boolean
  dreams: FastDream[]
}

type UseGroupDreamsOptions = {
  type?: EntryType
  favorites?: boolean
}

export function useGroupDreams(
  groupId: string | null | undefined,
  { type = 'dream', favorites = false }: UseGroupDreamsOptions = {
    type: 'dream',
    favorites: false,
  },
): UseDreams {
  const allGroupDreams = useSelector(selectAllGroupDreams)

  return useMemo(() => {
    if (!groupId) {
      return {
        loading: false,
        dreams: [],
      }
    }

    const groupDreams = allGroupDreams.filter((d) => d.groupId === groupId)
    const typedDreams = groupDreams.filter((d) => d.type === type)
    const favoriteDreams = typedDreams.filter(
      (d) => !favorites || d.userReactionCount > 0,
    )

    const loading = allGroupDreams.length > 0 && groupDreams.length === 0

    if (loading) {
      return {
        loading,
        dreams: [],
      }
    } else {
      return {
        loading,
        dreams: favoriteDreams,
      }
    }
  }, [groupId, allGroupDreams, favorites, type])
}
