import React, { useState, useEffect } from 'react'
import { useColorModeValue } from 'native-base'
import { Dimensions, View, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { fetchFamousDreamers } from '../../ducks/user/functions/user-functions'
import { TabView } from '../../components/layout/tab-view'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { BLACK, WHITE } from '../../constants/ui-constants'
import {
  UserListItemComponent,
  UserListItemProps,
} from '../../components/common/userlist-item'
import { Loading } from '../../components/layout/loading'
import {
  RootStackNavigationProp,
  RootStackParamList,
} from '../../navigation/types'
import { RouteProp } from '@react-navigation/native'
import { CentredListEmptyComponent } from '../../components/layout/centred-list-empty-component'
import { NbImage } from '../../components/common/image/nb-image'

export type LibraryNavigationProp = RootStackNavigationProp<'Library'>
export type LibraryRouteProp = RouteProp<RootStackParamList, 'Library'>

const libraryImg = require('../../assets/images/symbol-book-cream.png')

export default function Library() {
  const [famousDreamers, setFamousDreamers] = useState<UserListItemProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchFamousDreamers()
      .then((famousDreamers) => {
        if (famousDreamers) {
          const famousDreamersUserList = famousDreamers.map((dreamer) => {
            return {
              id: dreamer.id,
              displayName: dreamer.username,
              profileImage: dreamer.profileImageUrl || undefined,
              linkObject: {
                screen: 'LibraryDreamerView' as keyof RootStackParamList,
                params: {
                  username: dreamer.username,
                },
              },
            }
          })
          setFamousDreamers(famousDreamersUserList)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log('Error fetching famous dreamers in Library', err)
      })
  }, [])

  const pageWidth = Dimensions.get('window').width
  const { t } = useTranslation()

  const renderUserListItem = ({ item }: { item: UserListItemProps }) => {
    return <UserListItemComponent {...item} />
  }

  const FamousDreamersTabContents = () => (
    <Tabs.FlatList
      ListEmptyComponent={CentredListEmptyComponent(
        loading ? <Loading /> : null,
      )}
      data={famousDreamers}
      renderItem={renderUserListItem}
      keyExtractor={(item) => item.id}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16 }}
    />
  )

  const tabs: TabProps<string>[] = [
    {
      name: 'FamousDreamers',
      label: t('common.famousDreamers'),
      children: <FamousDreamersTabContents />,
    },
  ]

  const isWeb = Platform.OS === 'web'
  const backgroundColor = useColorModeValue(WHITE, BLACK)

  const LibraryHeader = () => {
    return (
      <View
        pointerEvents="none"
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor,
          maxWidth: WEB_MAX_WIDTH,
        }}
      >
        <NbImage
          alt={'Books'}
          source={libraryImg}
          width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        />
      </View>
    )
  }

  return (
    <TabView
      header={() => <LibraryHeader />}
      tabs={tabs}
      scenes={{
        FamousDreamers: FamousDreamersTabContents,
      }}
    />
  )
}
