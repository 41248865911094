/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastMembership } from '../models/FastMembership';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastMembershipService {
  /**
   * Retrieves the collection of FastMembership resources.
   * Retrieves the collection of FastMembership resources.
   * @returns FastMembership FastMembership collection
   * @throws ApiError
   */
  public static fastMemberships(): CancelablePromise<Array<FastMembership>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-memberships',
    });
  }
  /**
   * Removes the FastMembership resource.
   * Removes the FastMembership resource.
   * @param id FastMembership identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteMembership(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/fast-memberships/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
