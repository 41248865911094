import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useColorModeValue } from 'native-base'
import { FramedImage } from '../../common/image/framed-image'
import { HeadingMainWithDate } from '../../common/copy/heading-main-with-date'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  ADJUSTED_MARGIN_TOP,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import {
  selectNoCreditsMessageViewed,
  setNoCreditsMessageViewed,
} from '../../../ducks/ui/ui'
import { useSelector } from '../../../ducks/root-reducer'
import { useDispatch } from 'react-redux'
import { todayThisHourTenMinsString } from '../../../modules/date-helpers/date-helpers'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { getScriptTypeForLanguage } from '../../../modules/language-helpers/language-helpers'
import { Column } from '../../common/column/column'
import { selectUserPrefersNoImages } from '../../../ducks/user/user'

type DreamHeaderProps = {
  imageUrl: string | null
  title: string | null | undefined
  date: string
  displayDate: string | null | undefined
  note?: string
  description: string
  dreamId: string
  dreamImageLoading: boolean
  userId: string | undefined
  authorId: string | undefined
  flipHeaderTextOrder?: boolean
  isDraft?: boolean
  isDAFDream?: boolean
  isTempDream?: boolean
  language?: SupportedLanguage
}

const DreamHeader = ({
  imageUrl,
  title,
  date,
  displayDate,
  dreamImageLoading,
  dreamId,
  isDraft,
  isDAFDream,
  isTempDream,
  language,
}: DreamHeaderProps) => {
  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const backgroundColor = useColorModeValue(WHITE, BLACK)

  // SELECTORS
  const noCreditsMessageViewed = useSelector(selectNoCreditsMessageViewed)
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const userPrefersNoImages = useSelector(selectUserPrefersNoImages)

  // VARS
  const creditsRemaining = subscriptionStatus?.creditsRemaining || 0
  const imagesRemaining = subscriptionStatus?.imagesRemaining || 0
  const subscriptionLoading = subscriptionStatus.loading && !imageUrl
  const tier = subscriptionStatus?.tier || 'free'
  const todayThisHourStr = todayThisHourTenMinsString()
  const hasImage = Boolean(imageUrl)
  const imagesLeft = imagesRemaining || 0
  const creditsLeft = creditsRemaining || 0
  const hasImagesOrCredits = imagesLeft > 0 || creditsLeft > 0
  // No point showing loading if no image will generate
  const shouldShowLoading =
    (dreamImageLoading || subscriptionLoading || isTempDream) &&
    hasImagesOrCredits &&
    !userPrefersNoImages
  const noCreditsMessageShowing =
    noCreditsMessageViewed === dreamId + todayThisHourStr
  const showImage =
    (hasImage || shouldShowLoading || noCreditsMessageShowing) &&
    !isDAFDream &&
    !isDraft
  const resizedImageUrl = updateCloudinaryUrlForDisplay(imageUrl)
  const scriptType = getScriptTypeForLanguage(language)

  // EFFECTS
  useEffect(() => {
    if (!hasImagesOrCredits && !noCreditsMessageViewed) {
      dispatch(setNoCreditsMessageViewed(dreamId + todayThisHourStr))
    }
  }, [])

  return (
    <View
      pointerEvents="box-none"
      style={{
        backgroundColor,
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        alignItems: 'center',
        marginTop: ADJUSTED_MARGIN_TOP,
      }}
    >
      <Column
        alignItems="flex-start"
        justifyContent="flex-start"
        backgroundColor={backgroundColor}
        pb={4}
      >
        <Column width={'100%'}>
          {showImage && (
            <View pointerEvents="box-none">
              <FramedImage
                alt={title || t('common.image')}
                source={{ uri: resizedImageUrl || undefined }}
                loading={dreamImageLoading}
                loadingLabel={t('loading.dreamArtLoading')}
                imagesRemaining={imagesRemaining}
                creditsRemaining={creditsRemaining}
                tier={tier}
              />
            </View>
          )}
        </Column>
      </Column>
      <Column paddingX={4} pointerEvents="none">
        <HeadingMainWithDate
          date={date}
          displayDate={displayDate}
          scriptType={scriptType}
        >
          {title}
        </HeadingMainWithDate>
      </Column>
    </View>
  )
}

export default DreamHeader
