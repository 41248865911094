import { FastDream } from '../../../../api/_openapi'
import { useNavigation } from '@react-navigation/native'

type DreamNavigationAction = 'view' | 'edit' | 'feed'
type DreamNavigationMethod = 'push' | 'navigate' | 'replace'
export const navigateToDream = (
  navigation: any,
  id: string | undefined = undefined,
  isDraft: boolean,
  type: string | undefined = undefined,
  groupId?: string,
  action: DreamNavigationAction = 'view',
  method: DreamNavigationMethod = 'navigate',
  tab?: string,
) => {
  const navigationMethod = navigation[method]

  //view and feed are main
  if (action === 'view' || action === 'feed') {
    let screen: string
    if (action === 'view') {
      screen = groupId ? 'GroupDreamView' : 'PrivateDreamView'
      if (isDraft) {
        screen = 'PrivateDraftView'
      } else if (type === 'journal-entry') {
        screen = 'PrivateDiaryView'
      }
    } else {
      screen = groupId ? 'GroupFeed' : 'PrivateFeed'
      if (isDraft) {
        screen = 'PrivateDraftsFeed'
      } else if (type === 'journal-entry') {
        screen = 'PrivateDiaryFeed'
      }
    }

    navigationMethod('MainTabs', {
      screen: 'MainStack',
      params: {
        screen,
        params: {
          id,
          groupId,
          tab,
        },
      },
    })
  } else {
    let screen = 'PrivateDreamEdit'
    if (isDraft) {
      screen = 'PrivateDraftEdit'
    } else if (type === 'journal-entry') {
      screen = 'PrivateDiaryEdit'
    }
    navigationMethod(screen, {
      id,
      groupId,
    })
  }
}

export function useDreamNavigator(groupId: string | undefined = undefined) {
  const navigation = useNavigation<any>()

  const view = (
    dream: FastDream,
    method: DreamNavigationMethod = 'navigate',
    tab: string | undefined = undefined,
  ) => {
    navigateToDream(
      navigation,
      dream.id,
      dream.isDraft,
      dream.type,
      groupId,
      'view',
      method,
      tab,
    )
  }

  const edit = (
    dream: FastDream,
    method: DreamNavigationMethod = 'navigate',
  ) => {
    navigateToDream(
      navigation,
      dream.id,
      dream.isDraft,
      dream.type,
      groupId,
      'edit',
      method,
    )
  }

  const feed = (
    isDraft: boolean,
    type: string | undefined = undefined,
    method: DreamNavigationMethod = 'navigate',
  ) => {
    navigateToDream(
      navigation,
      undefined,
      isDraft,
      type,
      groupId,
      'feed',
      method,
    )
  }

  return {
    view,
    edit,
    feed,
  }
}
