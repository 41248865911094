import { useLinkProps as useLinkPropsOrig } from '@react-navigation/native'
import { RootStackParamList } from '../navigation/types'

export type UseLinkPropsProps = Parameters<
  typeof useLinkPropsOrig<RootStackParamList>
>[0]

// see https://github.com/react-navigation/react-navigation/issues/10847
export function useLinkProps({ to, ...props }: UseLinkPropsProps) {
  const { href } = useLinkPropsOrig({ to, ...props })
  return useLinkPropsOrig({ to: href, ...props })
}
