import React from 'react'
import { Svg, Path } from 'react-native-svg'

export function Menu({ color, size }: { color: string; size: number }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M2 12L22 12" stroke={color} strokeWidth="2" />
      <Path d="M2 5H22" stroke={color} strokeWidth="2" />
      <Path d="M2 19H22" stroke={color} strokeWidth="2" />
    </Svg>
  )
}
