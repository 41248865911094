/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DreamSeriesAnalysisRequest } from '../models/DreamSeriesAnalysisRequest';
import type { DreamSeriesAnalysisRequest_AnalysisResult } from '../models/DreamSeriesAnalysisRequest_AnalysisResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DreamSeriesAnalysisRequestService {
  /**
   * Creates a DreamSeriesAnalysisRequest resource.
   * Creates a DreamSeriesAnalysisRequest resource.
   * @param requestBody The new DreamSeriesAnalysisRequest resource
   * @returns DreamSeriesAnalysisRequest_AnalysisResult DreamSeriesAnalysisRequest resource created
   * @throws ApiError
   */
  public static dreamSeries(
    requestBody: DreamSeriesAnalysisRequest,
  ): CancelablePromise<DreamSeriesAnalysisRequest_AnalysisResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/insights/dream-series',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
