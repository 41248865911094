import React from 'react'
import { FastComment } from '../../../../../api/_openapi'
import { useColorModeValue } from 'native-base'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { SansText } from '../../../components/common/copy/text-sans'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import {
  OFFSET_WHITE,
  LIGHT_MID_GRAY,
  OFFSET_BLACK,
} from '../../../constants/ui-constants'
import { Keyboard } from 'react-native'
import { ElsewhereIcon } from '../../../components/common/elsewhere-icon/elsewhere-icon'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../../../components/common/action-sheet/action-sheet-menu'
import { SANS_MEDIUM_FONT_WEIGHT } from '../../../constants/constants'
import { parseISO } from 'date-fns'
import { FormattedTimeAgo } from '../../../components/common/time/formatted-time-ago'
import { Row } from '../../../components/common/row/row'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n/i18nnext'
import { Column } from '../../../components/common/column/column'
import { Button } from '../../../components/common/nb-button/nb-button'

type DreamCommentProps = {
  comment: FastComment
  deleteComment: (comment: FastComment) => void
}

export default function DreamComment({
  comment,
  deleteComment,
}: DreamCommentProps) {
  // STATE
  const [actionSheetOpen, setActionSheetOpen] = React.useState(false)

  // SELECTORS
  const user = useSelector(selectUser)

  // HOOKS
  const { t } = useTranslation()
  const textColor = useColorModeValue(OFFSET_BLACK, OFFSET_WHITE)

  // VARS
  const language = i18n.resolvedLanguage || 'en'
  const date = parseISO(comment.createdAt)
  const timeDifference = Math.floor((date.getTime() - Date.now()) / 1000)

  const formattedDate = new Intl.DateTimeFormat(language, {
    weekday: 'long',
    day: '2-digit',
    hour: 'numeric',
    month: 'short',
    minute: 'numeric',
    hour12: true,
    year: 'numeric',
  }).format(date)

  const actionMenuItems: ActionSheetMenuItem[] = [
    {
      label: formattedDate,
      closesMenu: true,
      iconKey: 'info',
      onPress: () => {},
    },
  ]

  if (user?.id === comment.userId) {
    actionMenuItems.unshift({
      label: t('common.delete'),
      closesMenu: true,
      iconKey: 'trash',
      onPress: () => deleteComment(comment),
    })
  }

  return (
    <>
      <Row alignItems="center" mb={4}>
        <Row alignItems="start" height={'100%'} ml={1} mr={2}>
          <AvatarCircle
            source={{ uri: comment.profileImageUrl || undefined }}
            name={comment.username || ''}
            loading={false}
            disablePress={false}
            size={'xs'}
          />
        </Row>
        <Column flex={1}>
          {/* USERNAME & TIME */}
          <Row mb={1} height={8} pt={2} alignItems={'center'}>
            <SansText
              fontWeight={SANS_MEDIUM_FONT_WEIGHT}
              color={textColor}
              language="en" // So we use a font with ASCII characters
            >
              {comment.username}
            </SansText>
            <SansText color={LIGHT_MID_GRAY} paddingX={3}>
              <FormattedTimeAgo timeAgoInSeconds={timeDifference} />
            </SansText>
          </Row>
          {/* COMMENT TEXT */}
          <Row>
            <SansText color={textColor}>{comment.description}</SansText>
          </Row>
        </Column>
        <Row alignItems="start" height={'100%'} ml={4} mt={2}>
          <Button
            backgroundColor={'transparent'}
            opacity={1}
            onPress={() => {
              Keyboard.dismiss()
              setActionSheetOpen(true)
            }}
            size={'xs'}
            pr={'14px'} // To line up with plus button below
          >
            <ElsewhereIcon
              color={textColor}
              variant="unstyled"
              iconKey="ellipsis"
              size={'xs'}
            />
          </Button>
        </Row>
      </Row>
      {actionSheetOpen && (
        <ActionSheetMenu
          menuItems={actionMenuItems}
          isOpen={actionSheetOpen}
          onClose={() => setActionSheetOpen(false)}
        />
      )}
    </>
  )
}
