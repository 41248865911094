import React, { PropsWithChildren } from 'react'
import { useColorModeValue } from 'native-base'
import { ViewStyle, StyleProp, StyleSheet } from 'react-native'
import MultiToggleSwitchItem from './MultiToggleSwitchItem'
import { LINE_WIDTH } from '../../../../constants/constants'
import { BLACK, WHITE } from '../../../../constants/ui-constants'
import { View } from '../../view/view'

export type IMultiToggleSwitch = {
  defaultActiveIndex: number
  primaryColor?: string
  secondaryColor?: string
  itemContainer?: StyleProp<ViewStyle>
  activeContainerStyle?: StyleProp<ViewStyle>
  itemsContainer?: StyleProp<ViewStyle>
  itemsContainerBackgroundStyle?: StyleProp<ViewStyle>
  onPress?: () => void
  children: React.ReactNode
}

export const RNMultiToggleSwitch = (
  props: PropsWithChildren<IMultiToggleSwitch>,
) => {
  const { children } = props
  const toggleButtons = !Array.isArray(children) ? [children] : children
  const [activeIdx, setActiveIndex] = React.useState(props.defaultActiveIndex)

  const primaryColor = useColorModeValue(BLACK, WHITE)
  const secondaryColor = useColorModeValue(WHITE, BLACK)

  const renderToggleItems = () => {
    return (
      <View
        style={{
          ...style.itemsContainer,
          borderColor: primaryColor,
          width: 'auto',
          padding: 4,
        }}
        pointerEvents={'box-none'}
      >
        {toggleButtons.map((multitoggleswitch, idx) => (
          <MultiToggleSwitchItem
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            key={idx}
            {...props}
            {...multitoggleswitch.props}
            active={idx === activeIdx ? true : false}
            onPress={() => {
              setActiveIndex(idx)
              multitoggleswitch.props.onPress()
            }}
            itemContainer={style.itemContainer}
          />
        ))}
      </View>
    )
  }
  return (
    <View style={props.itemsContainerBackgroundStyle}>
      {renderToggleItems()}
    </View>
  )
}

const style = StyleSheet.create({
  itemContainer: {
    // backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 36,
    height: 36,
    alignSelf: 'center',
    margin: 0,
    padding: 0,
    paddingRight: 16,
    paddingLeft: 16,
  },
  itemsContainer: {
    flexDirection: 'row',
    height: 46,
    borderWidth: LINE_WIDTH,
    borderRadius: 36,
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: 0,
    padding: 0,
  },
})

RNMultiToggleSwitch.Item = MultiToggleSwitchItem
