import React, { useEffect, useState, memo } from 'react'
import { Platform } from 'react-native'
import { useAuth } from './contexts/auth-context'
import { useDispatch } from 'react-redux'
import { useSelector } from './ducks/root-reducer'
import { selectUser } from './ducks/user/user'
import i18n from './i18n/i18nnext'
import Routes from './navigation/Routes'
import { ApiConfig, OpenAPI } from '../../api/_openapi'
import { apiBaseUrls } from '../../api/api-base'
import { useAppState } from './hooks/useAppState'
import { QueueHandler } from './queue-handler/queue-handler'
import notifee from '@notifee/react-native'
import {
  selectGenderOptions,
  selectLanguageOptions,
  selectLastSignInVersion,
  selectRequiresUpdate,
  setRequiresUpdate,
} from './ducks/ui/ui'
import { getApiConfig } from './ducks/ui/functions/ui-functions'
import packageInfo from '../../mobile/package.json'
import semver from 'semver'
import { getDeviceLanguageCode } from './modules/language-helpers/language-helpers'
import { AlertPleaseUpdate } from './components/common/dialog/alert-please-update'
import { SplashScreen } from './screens/SplashScreen'
import { formfieldOptionsAreEqual } from './ducks/equality'
import { loadGenders, loadLanguages } from './ducks/ui/thunks/ui-thunks'
import { languageDetector } from './i18n/i18nnext.web'

export const Initialise = memo(() => {
  const [checkDone, setCheckDone] = useState(false)
  const [apiConfig, setApiConfig] = useState<ApiConfig | null>(null)

  // HOOKS
  const dispatch = useDispatch<any>()
  const { signOut, authData, loading } = useAuth()
  const appState = useAppState()
  const { languageCode } = useSelector(selectUser) || {}

  // SELECTORS
  const genderOptions = useSelector(
    selectGenderOptions,
    formfieldOptionsAreEqual,
  )

  const languageOptions = useSelector(
    selectLanguageOptions,
    formfieldOptionsAreEqual,
  )

  const lastVersionOnSignIn = useSelector(selectLastSignInVersion)
  const updateRequired = useSelector(selectRequiresUpdate)
  OpenAPI.TOKEN = authData?.token

  // Load genders
  useEffect(() => {
    if (!checkDone) {
      return
    }
    if (genderOptions.length === 0) {
      dispatch(loadGenders())
    }
  }, [genderOptions.length, checkDone])

  // Load languages
  useEffect(() => {
    if (!checkDone) {
      return
    }
    if (languageOptions.length === 0) {
      dispatch(loadLanguages())
    }
  }, [languageOptions.length, checkDone])

  React.useEffect(() => {
    const subDomainLanguages =
      Platform.OS === 'web' && languageDetector.detect()
    const subDomainLanguage =
      subDomainLanguages && Array.isArray(subDomainLanguages)
        ? subDomainLanguages[0]
        : subDomainLanguages
    const deviceLanguage = getDeviceLanguageCode()
    if (Platform.OS === 'web') {
      document.documentElement.setAttribute('lang', subDomainLanguage || 'en')
    }
    i18n.changeLanguage(subDomainLanguage || languageCode || deviceLanguage)
  }, [])

  React.useEffect(() => {
    console.log('App version:', packageInfo.version)
  }, [])

  // Handle version checks
  useEffect(() => {
    if (!apiConfig) {
      return
    }

    const signOutIfVersionNotSupported = async () => {
      try {
        let {
          earliestSupportedVersion,
          earliestSupportedSignInVersion,
          useStagingFallback,
        } = apiConfig

        const shouldSignOut =
          authData?.token &&
          (!lastVersionOnSignIn ||
            (earliestSupportedSignInVersion &&
              semver.lt(lastVersionOnSignIn, earliestSupportedSignInVersion)))

        if (shouldSignOut) {
          await signOut()
        } else if (Platform.OS !== 'web') {
          if (useStagingFallback) {
            //forces the app to use staging if we haven't released the production backend yet
            //for android/ios reviews
            console.log('Falling back to staging', apiBaseUrls.staging)
            OpenAPI.BASE = apiBaseUrls.staging
          }

          const requiresUpdate = semver.lt(
            packageInfo.version,
            earliestSupportedVersion as string,
          )
          dispatch(setRequiresUpdate(requiresUpdate))
        }
      } catch (error) {
        console.error(error)
      } finally {
        setCheckDone(true)
      }
    }
    signOutIfVersionNotSupported()
  }, [
    apiConfig?.earliestSupportedVersion,
    apiConfig?.earliestSupportedSignInVersion,
    apiConfig?.useStagingFallback,
  ])

  const loadApiConfig = async () => {
    const config = await getApiConfig(packageInfo.version)
    setApiConfig(config)
  }

  // Load API config
  useEffect(() => {
    loadApiConfig()
  }, [])

  //Clear notifications and check for updates
  useEffect(() => {
    if (appState.hasEnteredForeground) {
      console.log('App has entered foreground in Initialise')
      notifee.setBadgeCount(0)
      loadApiConfig()
    }
  }, [appState.hasEnteredForeground])

  if (loading || apiConfig === null || !checkDone) {
    return <SplashScreen />
  }

  return (
    <>
      {checkDone ? <AlertPleaseUpdate isOpen={updateRequired} /> : null}
      <QueueHandler />
      <Routes />
    </>
  )
})
