import { FastDream } from '../../../../api/_openapi'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import {
  selectAllFastDreams,
  selectAllGroupDreams,
  selectDreamsAreLoading,
  selectGroupDreamsLoading,
} from '../../ducks/dream-tag/dream-tag'
import { useMemo } from 'react'

export type UseDreams = {
  loading: boolean
  dreams: FastDream[]
}

export function useDreams(
  activeGroupId: string | null | undefined,
  drafts: boolean = false,
  type = 'dream',
): UseDreams {
  //Memoized user dreams
  const allUserDreams = useSelector(selectAllFastDreams)
  const userDreamsLoading = useSelector(selectDreamsAreLoading)
  const userDreams = useMemo(() => {
    return {
      loading: userDreamsLoading,
      dreams: userDreamsLoading
        ? []
        : drafts
        ? allUserDreams.filter((d) => d.isDraft)
        : allUserDreams.filter((d) => !d.isDraft),
    }
  }, [allUserDreams, userDreamsLoading, drafts])

  //Memoized group dreams
  const allGroupDreams = useSelector(selectAllGroupDreams)
  const groupDreamsLoading = useSelector(selectGroupDreamsLoading)
  const groupDreams = useMemo(() => {
    return {
      loading: groupDreamsLoading,
      dreams: groupDreamsLoading
        ? []
        : drafts
        ? allGroupDreams.filter((d) => d.isDraft)
        : allGroupDreams.filter((d) => !d.isDraft),
    }
  }, [allGroupDreams, groupDreamsLoading, drafts])

  const user = useSelector(selectUser)
  const returnGroupDreams = activeGroupId && activeGroupId !== user?.userGroupId

  return useMemo(() => {
    return returnGroupDreams ? groupDreams : userDreams
  }, [userDreams, groupDreams, returnGroupDreams])
}
