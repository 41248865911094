import React from 'react'
import { Row } from '../../common/row/row'
import { SansText } from '../../common/copy/text-sans'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'

export type LegendItem = {
  label: string
  color: string
}

type ChartLegendProps = {
  items: LegendItem[]
}

export const ChartLegend = ({ items }: ChartLegendProps) => {
  return (
    <Row width={'100%'} justifyContent={'center'} marginBottom={16}>
      {items.map((item, i) => {
        return (
          <Row key={i} alignItems={'center'} marginX={3}>
            <Box
              width={3}
              height={3}
              borderRadius={50}
              backgroundColor={item.color}
              marginX={2}
            />
            <Column>
              <SansText>{item.label}</SansText>
            </Column>
          </Row>
        )
      })}
    </Row>
  )
}
