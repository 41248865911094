import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Screens from '../../../screens'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateSearch"
        component={Screens.Search}
        options={{
          title: t('common.search'),
        }}
      />
    </>
  )
}
