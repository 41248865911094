import store from '../ducks/store'
import { getInitialURL, subscribe } from '../services/notification-service'
import { getStateFromPath, LinkingOptions } from '@react-navigation/native'
import { RootStackParamList } from './types'

// https://elsewhereapp.slack.com/archives/C0653LGLPHB/p1729863360565979
// The screens directly under config.screens are the topmost screens (ie the modals).
// The main menu, bottom tabs and app shell are found under MainTabs.screens
// and the rest of the app screens are housed under MainStack.screens
// Wherever you put the screen in the linking object has to match
// where the screen component is located in the hierarchy. So if you have
// the screen under config, it should be a RootStack.Screen component, otherwise
// it should be a MainStack.Screen component
export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    'elsewhere://',
    'https://elsewhere.to',
    'elsewhere.beta://',
    'https://staging.elsewhere.to',
  ],
  config: {
    screens: {
      // RootStack: Root
      Invite: 'invite/:inviteCode',

      // Library
      Library: 'library',
      LibraryDreamerView: 'library/dreamer/:username',
      LibraryDreamView: {
        path: 'library/dreamer/:username/dream/:id',
      },
      LibraryInsightView: 'library/dreamer/:username/insights/:id',

      // Web-only
      Privacy: 'privacy',
      Terms: 'terms',
      Cookies: 'cookies',
      About: 'about',
      DeleteMyData: 'delete-my-data',
      Journal: 'journal',
      SymbolDictionary: 'symbol-dictionary',
      DreamAnimationFestival: 'dream-animation-festival',
      DreamAnimationFestivalDreamView: {
        path: 'dream-animation-festival/dream/:afDreamId',
      },

      // RootStack: AuthStack
      Home: '',
      Login: 'login',
      Signup: 'signup',
      MagicLogin: 'magic-login',

      // RootStack: MainStack

      // Private modals
      PrivateFriendPreferences: 'private/friends/:username/preferences',
      PrivateDreamAdd: 'private/dream/create',
      PrivateDreamScan: 'private/dream/scan',
      PrivateDreamEdit: 'private/dream/:id/edit',
      PrivateDraftEdit: 'private/drafts/:id/edit',
      PrivateDiaryEdit: 'private/diary/:id/edit',
      PrivateDreamShare: 'private/dream/:id/share',
      PrivateDreamerView: 'private/dreamer/:userId',
      // Private settings
      PrivateSettings: 'private/settings',
      // Subscriptions
      PrivateSubscriptionStatusView: 'private/settings/subscription-status',
      PrivateSubscriptionSettingsEdit: 'private/settings/subscriptions',
      PrivateCreditSettingsEdit: 'private/settings/credits',
      // App settings
      PrivateDreamerSettingsEdit: 'private/settings/user',
      PrivateDreamSettingsEdit: 'private/settings/dream',
      PrivateNotificationsSettingsEdit: 'private/settings/notifications',
      // Profile settings
      PrivateDreamerEdit: 'private/settings/profile',
      PrivateDreamerProfileEdit: 'private/settings/info',
      // Data
      PrivateDataDelete: 'private/settings/delete',
      PrivateDataImport: 'private/settings/import',
      PrivateDataExport: 'private/settings/export',
      // Support
      PrivateSupport: 'private/settings/support',
      // About Elsewhere
      PrivateAboutElsewhere: 'private/settings/about',
      PrivateAboutElsewhereTeam: 'private/settings/about/team',
      PrivatePrivacyAndTerms: 'private/settings/terms',
      // Group modals
      GroupAdd: 'g/create',
      GroupSettings: 'g/:groupId/settings',
      GroupProfileEdit: 'g/:groupId/profile/edit',
      GroupProfileView: 'g/:groupId/profile',
      GroupMembersView: 'g/:groupId/members',
      GroupDreamerView: 'g/:groupId/dreamer/:userId',
      // Other
      Contact: 'contact',
      InterpretationStyle: 'interpretationstyle/:style',

      MainTabs: {
        screens: {
          MainStack: {
            screens: {
              // GroupJournal
              PrivateFriends: 'private/friends',
              PrivateFriend: 'private/friends/:username',
              PrivateFavoritesFeed: 'private/bookmarks',
              GroupFavoritesFeed: 'g/:groupId/liked',
              PrivateFriendSharedDreams: 'private/friends/:username/shared',
              GroupFeed: 'g/:groupId', // Home
              GroupDreamView: 'g/:groupId/dream/:id',
              // PrivateAsk
              PrivateAskYourDreams: 'private/ask', // Home
              PrivateAskYourDreamsInsightView: 'private/ask/:id',
              // Charts
              PrivateCharts: 'private/charts', // Home
              // Drafts
              PrivateDraftsFeed: 'private/drafts', // Home
              PrivateDraftView: 'private/drafts/:id',
              // Diary entries
              PrivateDiaryFeed: 'private/diary',
              PrivateDiaryView: 'private/diary/:id',
              // Insights
              PrivateInsights: 'private/insights', // Home
              PrivateInsightList: 'private/insights/type/:type',
              PrivateInsightView: 'private/insights/:id',
              // PrivateJournal
              PrivateFeed: 'private', // Home
              PrivateDreamView: 'private/dream/:id',
              PrivateDashboard: 'private/dashboard',
              PrivateTagView: 'private/tag/:id',
              PrivateSymbolInterpretationView: 'private/dictionary/:id',
              // Search
              PrivateSearch: 'private/search', // Home
              // Symbols
              PrivateSymbols: 'private/symbols', // Home
              // Interpretation
              PrivateInterpretationView: 'private/interpretation/:id',
              PrivateDreamServicesView: 'private/dream-services',
              PrivateDreamServiceView: 'private/dream-services/:serviceId',
            },
          },
        },
      },
      // NotFound: '*',
    },
  },
  getStateFromPath: (path: any, options: any) => {
    const user = store.getState().user
    return getStateFromPath(
      user ? path.replace('login?inviteCode=', 'invite/') : path,
      options,
    )
  },
  getInitialURL,
  subscribe,
}
