import React from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { selectFastDreams } from '../../ducks/dream-tag/dream-tag'
import SearchComponent from './search-inner'
import { MainStackScreenProps } from '../../navigation/types'
import { FastDream } from '../../../../api/_openapi'

export default function Search({
  navigation,
  route,
}: MainStackScreenProps<'PrivateSearch'>) {
  const dreams: FastDream[] = useSelector(selectFastDreams)
  return (
    <SearchComponent dreams={dreams} route={route} navigation={navigation} />
  )
}
