import React from 'react'
import { useColorModeValue } from 'native-base'
import { Loading } from '../components/layout/loading'
import { BLACK, WHITE } from '../constants/ui-constants'
import { Center } from '../components/common/center/center'

export function SplashScreen() {
  const color = useColorModeValue(BLACK, WHITE)
  const bg = useColorModeValue(WHITE, BLACK)
  return (
    <Center h="100%" bg={bg}>
      <Loading color={color} />
    </Center>
  )
}
