import React, { useState } from 'react'
import { Platform } from 'react-native'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { ButtonPill } from '../buttons/button-pill'
import { uploadImages } from './modules/upload-images/upload-images'
import { ImageForUpload, UploadOptions } from './modules/upload-images/types'
import { onUploadButtonPress } from './modules/upload-images/on-onupload-button-press'
import { ImageArrayEditable } from '../image-array-editable/image-array-editable'
import { onTakePhotoButtonPress } from './modules/upload-images/on-take-photo-press'
import { useAuth } from '../../../contexts/auth-context'
import i18n from '../../../i18n/i18nnext'
import { useColorModeValue } from 'native-base'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../constants/ui-constants'

type FieldFieldMultiImagePickerWithCameraProps = {
  pickedImages: ImageOnObject[]
  onChange: (value: ImageOnObject[]) => void
  options?: UploadOptions
}

export const FieldMultiImagePickerWithCamera = ({
  pickedImages,
  onChange,
  options,
}: FieldFieldMultiImagePickerWithCameraProps) => {
  // STATE
  const [loading, setLoading] = useState(false)

  // SELECTORS
  const { authData } = useAuth()

  // HOOKS
  const { t } = useTranslation()
  const pickerBg = useColorModeValue(CREAM, LIGHT_BLACK)
  const pickerWindowBg = useColorModeValue(WHITE, BLACK)
  const pickerLinkColor = useColorModeValue(BLACK, WHITE)
  const activeTabIconColor = useColorModeValue(BLACK, WHITE)

  // VARIABLES
  const userLanguage = i18n.resolvedLanguage || 'en'
  const token = authData?.token
  const isWeb = Platform.OS === 'web'

  // STRINGS (For Cloudinary upload widget)
  // TODO: Duplicated code in field-image-upload.tsx
  const widgetText = {
    [userLanguage]: {
      // Or (e.g. drop files or upload)
      or: t('common.or'),
      menu: {
        // 'Camera'
        camera: t('symbol.Camera'),
        // 'My files'
        files: t('cloudinaryWidget.myFiles'),
      },
      camera: {
        // Take photo
        capture: t('common.takePhoto'),
        // '"Take a picture and upload it",'
        take_pic: t('cloudinaryWidget.takePic'),
        // "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.",
        explanation: t('cloudinaryWidget.takePicExplanation'),
        cancel: t('common.cancel'),
      },
      actions: {
        upload: t('common.upload'),
        next: t('common.next'),
      },
      crop: {
        // 'Crop'
        title: t('common.crop'),
        // 'Crop'
        crop_btn: t('common.crop'),
        // 'Yes'
        close_btn: t('common.yes'),
        // 'Skip'
        skip_btn: t('common.skip'),
        // 'Reset'
        reset_btn: t('common.reset'),
        // Are you sure you want to exit? Your progress has not been saved.
        close_prompt: t('warning.exitProgressNotSaved'),
        // 'Error loading image'
        image_error: t('toast.uploadImageError.title'),
        // 'either the file size exceeds the browser limit or the file may be corrupt.'
        image_data_error: t('cloudinaryWidget.imageDataError'),
        // 'Drag corner to resize'
        corner_tooltip: t('cloudinaryWidget.cornerTooltip'),
        // 'Drag handle to resize'
        handle_tooltip: t('cloudinaryWidget.handleTooltip'),
      },
      local: {
        // "Browse"
        browse: t('common.browse'),
        // 'Drag and Drop an asset here'
        dd_title_single: t('cloudinaryWidget.dragDropSingle'),
        // 'Drag and Drop assets here'
        dd_title_multi: t('cloudinaryWidget.dragDropMultiple'),
        // 'Drop a file to upload'
        drop_title_single: t('cloudinaryWidget.dropTitleSingle'),
        // 'Drop files to upload'
        drop_title_multiple: t('cloudinaryWidget.dropTitleMultiple'),
      },
      queue: {
        // "Uploading {{num}} assets",
        title_uploading_with_counter: t(
          'cloudinaryWidget.uploadingWithCounter',
        ),
        // "Processing {{num}} assets"
        title_processing_with_counter: t(
          'cloudinaryWidget.processingWithCounter',
        ),
        // "Done",
        done: t('common.done'),
        // 'Abort all'
        abort_all: t('cloudinaryWidget.abortAll'),
        // "Retry failed",
        retry_failed: t('cloudinaryWidget.retryFailed'),
      },
    },
  }

  // STYLES
  const arabicFont = {
    default: null,
    "'Noto Sans Arabic', sans-serif": {
      url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap',
      active: true,
    },
  }

  // Only Google fonts are supported, so no point doing Favorit here
  const defaultFont = {
    default: null,
    'sans-serif': {
      url: null,
      active: true,
    },
  }

  // TODO
  const font = userLanguage === 'fa' ? arabicFont : defaultFont

  const cloudinaryWidgetStyles = {
    palette: {
      window: pickerWindowBg,
      windowBorder: LIGHT_MID_GRAY,
      tabIcon: activeTabIconColor,
      menuIcons: LIGHT_MID_GRAY,
      textDark: BLACK,
      textLight: WHITE,
      link: pickerLinkColor,
      action: pickerLinkColor,
      inactiveTabIcon: LIGHT_MID_GRAY,
      error: '#F44235',
      inProgress: pickerLinkColor,
      complete: pickerLinkColor,
      sourceBg: pickerBg,
    },
    frame: {
      background: '#0E2F5B99',
    },
    fonts: font,
  }

  // FUNCTIONS
  const onImageSelected = async (images: ImageForUpload[]) => {
    if (!images) return
    const newPickedImages = await uploadImages(
      images,
      token || '',
      {
        fileSize: t('toast.uploadImageTooLargeError.description'),
        uploading: t('toast.uploadImageError.title'),
      },
      options,
    )
    const newFilesArray = [...pickedImages, ...newPickedImages]
    onChange(newFilesArray)
    setLoading(false)
  }

  const onDismissPickerOrCamera = () => {
    setLoading(false)
  }

  return (
    <>
      {!isWeb && (
        <ButtonPill
          onPress={() => {
            onTakePhotoButtonPress(
              options,
              onImageSelected,
              onDismissPickerOrCamera,
            )
            setLoading(true)
          }}
          mb={4}
        >
          {t('common.takePhoto')}
        </ButtonPill>
      )}
      <ButtonPill
        onPress={() => {
          onUploadButtonPress(
            options,
            onImageSelected,
            onDismissPickerOrCamera,
            widgetText,
            cloudinaryWidgetStyles,
          )
          setLoading(true)
        }}
      >
        {t('common.upload')}
      </ButtonPill>
      <ImageArrayEditable
        images={pickedImages}
        onChange={onChange}
        loading={loading}
      />
    </>
  )
}
