import { elsewhereToast } from '../../../components/common/toast/toast'
import {
  AnalysisResultService,
  InterpretationRequest,
  InterpretationRequestService,
  InterpretationRequest_AnalysisResult,
  FastDreamService,
  FastDream,
} from '../../../../../api/_openapi'
import {
  AnalysisResult,
  InterpretationBasic,
} from '../../../../../api/frontend-types'
import { DEFAULT_INTERPRETATION_STYLE_ID } from '../../../constants/constants'
import { undecorateId } from '../../../../../api/decorate-ids'

export function fetchFastDreamById(
  groupId: string,
  dreamId: string,
  shouldShowErrorToast = true,
): Promise<FastDream | null> {
  return FastDreamService.groupDream(groupId, dreamId).catch((err: any) => {
    console.log('Error getting dream in fetchDreamById', dreamId, err)
    if (shouldShowErrorToast) {
      elsewhereToast.showToast({
        description: `toast.errorGettingDream.title`,
        descriptionParam: dreamId,
        status: 'error',
      })
    } else {
      console.log('Error getting dream, no toast shown: ', dreamId, err)
    }
    return null
  })
}

// Get a list of dream interpretations
// Get a list of dream interpretations
export async function fetchDreamInterpretations(
  dreamId: string | undefined,
  isPublicUser: boolean,
): Promise<InterpretationBasic[]> {
  if (!dreamId) {
    console.log('No dreamId given for interpretations')
    return []
  }

  // PUBLIC USER
  if (isPublicUser) {
    const publicInterp = await getDreamInterpretationPublic(dreamId)
    if (publicInterp) {
      return [analysisResultToInterpretationBasic(publicInterp)]
    } else {
      return []
    }
  }
  // PRIVATE USER
  else {
    const res = await AnalysisResultService.dreamInterpretations(dreamId)
    return res
      .filter((x) => Boolean(x.results?.interpretation))
      .filter((x) => !x.deleted)
      .map((x) => analysisResultToInterpretationBasic(x))
  }
}

// Get dream interpretation
export async function generateDreamInterpretationPrivate(
  dreamId: string | undefined,
  interpretationRequest: InterpretationRequest,
): Promise<InterpretationRequest_AnalysisResult | null> {
  if (!dreamId) {
    console.log('No dreamId given for interpretation')
    return null
  }

  // Make sure style is not decorated
  const req = {
    ...interpretationRequest,
    style: undecorateId(interpretationRequest.style),
  }

  const response = await InterpretationRequestService.interpretDream(
    dreamId,
    req,
  ).catch((err: any) => {
    console.log('There was an error getting the interpretation', err)
  })

  // If response, return interpretation
  if (!response) {
    return null
  } else if (response.failed) {
    console.log('Interpretation failed', response)
    return null
  } else {
    return response
  }
}

// Use e.g. for Freud's dreams
async function getDreamInterpretationPublic(
  dreamId: string,
): Promise<AnalysisResult | null> {
  return AnalysisResultService.publicDreamInterpretation(dreamId)
    .then((ar) => {
      if (ar.deleted) {
        return null
      }
      return ar
    })
    .catch((err: any) => {
      console.log('Error getting public dream interpretation', err)
      return null
    })
}

// Convert AnalysisResult to InterpretationBasic
// The AnalysisResult is quite complex and used for many things,
// so we just extract the interpretation
// and put in some sensible defaults is anything's missing
export function analysisResultToInterpretationBasic(
  ar: AnalysisResult,
): InterpretationBasic {
  return {
    text: ar.results?.interpretation || '',
    diaryInterpretation: ar.results?.diaryInterpretation || '',
    id: ar.id,
    styleId: ar.results?.interpretationStyle || DEFAULT_INTERPRETATION_STYLE_ID,
  }
}
