// We use the Windows definition of a MB, which is 1024 * 1024 bytes
// Not because it's correct, but because it's the most generous
// Otherwise people could be confused why their Windows "1MB" file is too big
// For a 1000 * 1000 byte limit
// This function returns the size in MB with 1 decimal place
// e.g. '1.5'
// Unless it's 1.0 MB etc, then it's '1'
// TODO, this is not internationalized
export const bytesToMB = (bytes: number): string => {
  const mb = bytes / (1024 * 1024)
  return mb.toFixed(1).replace(/\.0$/, ' MB')
}
