import { undecorateId } from '../../../api/decorate-ids'

export function idEquals(
  a: string | null | undefined,
  b: string | null | undefined,
) {
  if (!a || !b) {
    return false
  }
  return undecorateId(a) === undecorateId(b)
}
