import React from 'react'
import parse, { DOMNode, Element } from 'html-react-parser'
import { Loading } from '../layout/loading'
import './iubenda.css'
import { Center } from '../common/center/center'

const htmlParserTransform = (domNode: DOMNode) => {
  const domElement: Element = domNode as Element
  if (domElement.type === 'tag' && domElement.name === 'a') {
    const { onclick } = domElement.attribs
    if (onclick) {
      delete domElement.attribs.onclick
    }
    return
  }
}
export const Iubenda = ({
  type,
  full,
}: {
  type: 'privacy' | 'cookies' | 'terms'
  full: boolean
}) => {
  const policyId = 25852860
  const [data, setData] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  let details = { title: '', url: '#' }
  switch (type) {
    case 'privacy':
      details = {
        title: 'Privacy Policy',
        url: `https://www.iubenda.com/${
          full ? 'api/' : ''
        }privacy-policy/${policyId}`,
      }
      break
    case 'cookies':
      details = {
        title: 'Cookie Policy',
        url: `https://www.iubenda.com/${
          full ? 'api/' : ''
        }privacy-policy/${policyId}/cookie-policy`,
      }
      break
    case 'terms':
      details = {
        title: 'Terms and Conditions',
        url: `https://www.iubenda.com/${
          full ? 'api/' : ''
        }terms-and-conditions/${policyId}`,
      }
      break
    default:
      details = { title: '', url: '#' }
  }

  React.useEffect(() => {
    if (loading) {
      fetch(details.url, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((response) => {
          let data: string = response.content
          if (type === 'privacy') {
            data = data.replace(
              '<h1>Privacy Policy of <strong>Elsewhere</strong></h1>',
              '<h1 style="text-align:center;">Privacy Policy</h1>',
            )
          } else if (type === 'cookies') {
            data = data.replace(
              `<h2 style=\"padding-top: 0;\">\n  Cookie Policy of Elsewhere\n</h2>`,
              '<h1 style="text-align:center;">Cookie Policy</h1>',
            )
          } else if (type === 'terms') {
            data = data.replace(
              `<h1>Terms and Conditions of <strong>Elsewhere</strong>\n</h1>`,
              '<h1 style="text-align:center;">Terms and Conditions</h1>',
            )
          }

          setData(data)
          setLoading(false)
        })
        .catch((error) => console.error(error))
    }
  }, [type, details, loading])

  if (!data && full && loading) {
    return (
      <Center h="85%">
        <Loading />
      </Center>
    )
  }
  if (data && full && !loading) {
    const result = parse(data, { replace: htmlParserTransform })

    if (typeof result === 'string') {
      return <span>{result}</span>
    } else {
      return <>{result}</>
    }
  }
  return (
    <a
      href={details.url}
      className={`iubenda-nostyle no-brand iubenda-embed ${
        full ? 'iub-legal-only iub-no-markup iub-body-embed' : ''
      }`}
      title={details.title}
    >
      {!full ? details.title : null}
    </a>
  )
}
