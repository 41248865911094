import React, { useEffect, useMemo, useCallback } from 'react'
import { Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../../components/layout/loading'
import { Tabs } from '../../../components/layout/tab-view-tabs'
import {
  FastTag,
  FastUser,
  Insight,
  InsightService,
  SubscriptionStatusResponse,
} from '../../../../../api/_openapi'
import { NoContentMessage } from '../../../components/layout/no-content-message'
import FeedItemInsight from '../../../components/composite/feed/feed-item-insight'
import { getTitleForInsight } from '../../../modules/analysis-helpers/insight-helpers'
import { TagTypeWithInsights } from '../../../../../api/frontend-types'
import { InsightTabRouteProp } from '../../../navigation/types'
import { fetchTagById } from '../../../ducks/dream-tag/functions/tag-functions'
import { CentredListEmptyComponent } from '../../../components/layout/centred-list-empty-component'
import { useFocusEffect } from '@react-navigation/native'

type AnalysisResultComparisonFunc = (a: Insight, b: Insight) => number

type InsightTabProps = {
  route: InsightTabRouteProp
  onlyMostRecent?: boolean
  showLocked?: boolean
  noInsightsMsg: string
  subscriptionStatus: SubscriptionStatusResponse | null
  user?: FastUser | null
  tagId?: string
  type?: TagTypeWithInsights
}

export const InsightTab = ({
  route,
  noInsightsMsg,
  showLocked,
  onlyMostRecent,
  subscriptionStatus,
  user,
  tagId,
  type,
}: InsightTabProps) => {
  // SELECTORS
  const [insightsLoading, setInsightsLoading] = React.useState<boolean>(true)
  const [insights, setInsights] = React.useState<Insight[]>([])
  const [tag, setTag] = React.useState<FastTag | null>(null)

  // HOOKS
  const { t } = useTranslation()

  // VARS
  // We need to know if the user has credits/insights remaining
  // So we can lock the insights if they don't
  const creditsRemaining = Boolean(subscriptionStatus?.creditsRemaining)
  const insightsRemaining = Boolean(subscriptionStatus?.insightsRemaining)
  const canRevealInsights = creditsRemaining || insightsRemaining

  useFocusEffect(
    useCallback(() => {
      if (tagId && !tag) {
        return
      }

      if (user) {
        setInsightsLoading(true)
        const localType = type || tag?.type
        InsightService.fastTagInsights(
          localType,
          tagId,
          localType ? undefined : 100,
          user.id,
        )
          .then((insights) => {
            setInsights(insights)
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setInsightsLoading(false)
          })
      }
    }, [tag, tagId, type, onlyMostRecent]),
  )

  //load tag
  useEffect(() => {
    if (tagId) {
      fetchTagById(tagId)
        .then((tag) => {
          setTag(tag)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [tagId])

  // MEMOIZED
  // 1)
  // We decide whether to show all insights, or just the latest ones
  // Only the latest ones are shown on the places page etc
  const allVisibleInsights: Insight[] = useMemo(() => {
    if (onlyMostRecent) {
      return onlyMostRecentInsights(insights)
    } else {
      return insights
    }
  }, [insights, onlyMostRecent])

  // 2)
  // Title the visible insights, so they can be sorted by name
  const insightsWithTitles: Insight[] = useMemo(() => {
    return allVisibleInsights.map((insight) => {
      const title = insight.tag?.name || getTitleForInsight(insight, t, true)
      return {
        ...insight,
        title: title,
      }
    })
  }, [allVisibleInsights, tag, insights])

  // 3)
  // Then we sort the titled insights
  // If we're only showing the most recent, we sort by title only
  // If we're showing all, we compare by date and title
  const sortedInsights = useMemo(() => {
    const comparisonFunc = onlyMostRecent
      ? compareByTitle
      : compareByDateAndTitle
    return sortInsights(insightsWithTitles, comparisonFunc)
  }, [insightsWithTitles, onlyMostRecent, insights])

  return (
    <Tabs.FlatList
      data={sortedInsights.filter((insight) => {
        return !(!showLocked && showLocked !== undefined && !insight.generated)
      })}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      keyExtractor={(item: Insight) => item.id}
      ListEmptyComponent={CentredListEmptyComponent(
        insightsLoading ? (
          <Loading />
        ) : (
          <NoContentMessage message={noInsightsMsg} />
        ),
      )}
      renderItem={({ item, index }) => {
        const insightIsLocked = !item.generated && !canRevealInsights
        return (
          <FeedItemInsight
            route={route}
            key={index}
            insight={item}
            isLocked={insightIsLocked}
          />
        )
      }}
    />
  )
}

function compareByTitle(a: Insight, b: Insight) {
  return (a.title || '').localeCompare(b.title || '')
}

function compareByDateAndTitle(a: Insight, b: Insight) {
  const dateA = new Date(a.date).getTime()
  const dateB = new Date(b.date).getTime()
  const dateDiff = dateB - dateA
  return dateDiff === 0 ? compareByTitle(a, b) : dateDiff
}

function sortInsights(
  insights: Insight[],
  comparisonFunc: AnalysisResultComparisonFunc,
): Insight[] {
  const insightsCopy = [...insights] // Avoid mutation
  return insightsCopy.sort(comparisonFunc)
}

// Takes an array of insights and returns only the most recent ones
function onlyMostRecentInsights(insights: Insight[]): Insight[] {
  const insightsMappedByTagId: { [key: string]: Insight } = {}
  for (let i = 0; i < insights.length; i++) {
    const currInsight = insights[i]
    if (!currInsight.generated) continue // Ignore non-generated insights

    const tagId = currInsight.tag?.id || '' // Key by tag id
    const existingInsightInMap = insightsMappedByTagId[tagId]
    // If the insight is generated, add it to the map if it's the most recent
    if (existingInsightInMap) {
      if (existingInsightInMap.date < currInsight.date) {
        insightsMappedByTagId[tagId] = currInsight
      }
    } else {
      insightsMappedByTagId[tagId] = currInsight
    }
  }
  return Object.values(insightsMappedByTagId)
}
