import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { TextProps } from '../copy/text'

type TimeRemaining = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

type CountdownProps = TextProps & {
  millisecondsRemaining: number
  onCountdownEnd?: () => void
}

export const Countdown = React.memo(
  ({ millisecondsRemaining, onCountdownEnd, ...rest }: CountdownProps) => {
    // STATE
    const [timeLeft, setTimeLeft] = useState<number>(millisecondsRemaining)

    // HOOKS
    const { t } = useTranslation()

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1000)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])

    useEffect(() => {
      if (timeLeft <= 0) {
        onCountdownEnd && onCountdownEnd()
      }
    }, [timeLeft, onCountdownEnd])

    return <SansText {...rest}>{prettyTime(msToDHMS(timeLeft), t)}</SansText>
  },
)

// Convert milliseconds to days, hours, minutes, and seconds
export function msToDHMS(ms: number): TimeRemaining {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  return {
    days: days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  }
}

// Convert days, hours, minutes, and seconds to a pretty string
// common.day_plural
// common.hour_plural
// common.minute_plural
// common.second_plural
function prettyTime(timeRemaining: TimeRemaining, t: any) {
  const { days, hours, minutes, seconds } = timeRemaining
  const dayString = t('common.day_plural', { count: days })
  const hourString = t('common.hour_plural', { count: hours })
  const minuteString = t('common.minute_plural', { count: minutes })
  const secondString = t('common.second_plural', { count: seconds })

  return `${days} ${dayString}, ${hours} ${hourString}, ${minutes} ${minuteString}, ${seconds} ${secondString}`
}
