import React from 'react'
import { FormControl as NbFormControl } from 'native-base'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl/types'

export type FormControlProps = IFormControlProps

// This is a simple wrapper around native-base's FormControl component
const FormControl = ({ children, ...rest }: FormControlProps) => {
  return <NbFormControl {...rest}>{children}</NbFormControl>
}

FormControl.HelperText = NbFormControl.HelperText
FormControl.ErrorMessage = NbFormControl.ErrorMessage

export { FormControl }
