import React from 'react'
import { Dimensions, Platform } from 'react-native'
import { SymbolGrid } from '../../components/composite/symbols/symbol-grid'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { CountedFastTag } from '../../../../api/_openapi'
import { View } from '../../components/common/view/view'
import { Row } from '../../components/common/row/row'

type SymbolsProps = {
  symbols: CountedFastTag[]
}

export const SymbolsList = ({ symbols }: SymbolsProps) => {
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  return (
    <View>
      <Row justifyContent="center" h={'100%'} w={'100%'}>
        <SymbolGrid
          width={pageWidth - (Platform.OS === 'web' ? 0 : 32)}
          symbols={symbols}
          showNumUses={true}
        />
      </Row>
    </View>
  )
}
