import React from 'react'
import { AlertPopup } from './alert-dialog'
import { useTranslation } from 'react-i18next'
import { Friend } from '../../../../../api/_openapi'
import { unfriend } from '../../../ducks/friends/thunks/friend-thunks'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'

type DialogUnfriendProps = {
  isOpen: boolean
  onClose: () => void
  friend: Friend | undefined
}

// Should use `https://elsewhere.to/join?code=${code}`?
export const DialogUnfriend = ({
  isOpen,
  onClose,
  friend,
}: DialogUnfriendProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<any>()
  const dispatch = useDispatch<any>()

  const removeFriend = () => {
    if (!friend) {
      return
    }

    dispatch(unfriend(friend)).then(() => {
      navigation.navigate('PrivateFeed')
    })
    onClose()
  }

  if (!friend) {
    return null
  }

  return (
    <AlertPopup
      header={'Unfriend ' + (friend.friendName || friend.username)}
      description={
        'Are you sure you want to unfriend ' +
        (friend.friendName || friend.username || 'your friend') +
        '?'
      }
      confirmLabel={t('common.confirm')}
      cancelLabel={t('common.close')}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={removeFriend}
    />
  )
}
