import { ImageForUpload, UploadOptions } from './types'

// Web version of the onTakePhotoButtonPress function
// This is a no-op function that does nothing
// This is because the web version of the app does not have a camera
export const onTakePhotoButtonPress = (
  options: UploadOptions | undefined,
  onImageSelectedCallback: (images: ImageForUpload[]) => Promise<void>,
  onDismiss: () => void,
) => {
  console.log(
    'onTakePhotoButtonPress',
    options,
    onImageSelectedCallback,
    onDismiss,
  )
}
