import React from 'react'
import { Dimensions } from 'react-native'
import { SquareDisplay } from '../../../components/composite/symbols/square-display'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { Row } from '../../../components/common/row/row'

type InsightListHeaderProps = {
  symbolKey: string
}

export const InsightListHeader = ({ symbolKey }: InsightListHeaderProps) => {
  const pageWidth = Dimensions.get('window').width
  const symbolSquareWidth = Math.min(300, pageWidth, WEB_MAX_WIDTH)

  return (
    <Row my={5} justifyContent={'space-around'} pointerEvents="none">
      <SquareDisplay
        label="Characters"
        symbolKey={symbolKey}
        width={symbolSquareWidth}
        hidden={false}
        showNumUses={false}
        clickable={false}
        editable={false}
        showTitle={false}
      />
    </Row>
  )
}
