import React from 'react'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { useTranslation } from 'react-i18next'
import { Column } from '../../components/common/column/column'
import { ListRenderItem, Platform } from 'react-native'
import { TabView } from '../../components/layout/tab-view'
import { DreamworkService } from '../../../../api/frontend-types'
import { dreamServices } from '../../i18n/dream-services'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
  RootStackParamList,
} from '../../navigation/types'
import { InfoText } from '../../components/common/copy/text-info'
import FeedEntryDreamService from '../../components/composite/feed/feed-entry-dreamservice'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'

export type PrivateDreamServicesViewNavigationProp =
  MainStackNavigationProp<'PrivateDreamServicesView'>

// PrivateDreamServicesView
export default function DreamServicesViewComponent({
  route,
}: MainStackScreenProps<'PrivateDreamServicesView'>) {
  // HOOKS
  const { t } = useTranslation()

  // I18N
  const { languageSpoken } = route.params

  const pageDescription = t('dreamGroupsAndServices.description')

  // VARS
  const dreamGroups = dreamServices.filter(
    (service) => service.serviceType === 'DREAM_GROUP',
  )
  const oneOnOneDreamwork = dreamServices.filter(
    (service) => service.serviceType === 'ONE_ON_ONE_DREAMWORK',
  )

  // RENDERERS
  const renderDreamServiceItem: ListRenderItem<DreamworkService> = ({
    item,
    index,
  }) => {
    const { serviceId } = item

    const linkObject = {
      screen: 'PrivateDreamServiceView' as keyof RootStackParamList,
      params: { serviceId: serviceId },
    }

    return (
      <FeedEntryDreamService
        title={item.name}
        subTitle={item.shortDescription}
        imageSource={{ uri: item.imageUrl }}
        linkProps={linkObject}
        location={item.location}
        deliveryMethods={item.deliveryMethod}
      />
    )
  }

  const DreamgroupTabContents = () => (
    <Tabs.FlatList
      data={dreamGroups}
      renderItem={renderDreamServiceItem}
      keyExtractor={(item: DreamworkService) => item.serviceId}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
    />
  )

  const OneOnOneDreamworkTabContents = () => (
    <Tabs.FlatList
      data={oneOnOneDreamwork}
      renderItem={renderDreamServiceItem}
      keyExtractor={(item: DreamworkService) => item.serviceId}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
    />
  )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'DreamGroups',
      children: <DreamgroupTabContents />,
      label: t('common.dreamGroups'),
    },
    {
      name: 'OneOnOneDreamwork',
      children: <OneOnOneDreamworkTabContents />,
      label: t('common.oneOnOneDreamwork'),
    },
  ]

  return (
    <TabView
      header={() => (
        <PaddedContentAreaConditional>
          <Column mt={8} mb={8}>
            <InfoText fontSize={16} textAlign={'left'}>
              {pageDescription}
            </InfoText>
          </Column>
        </PaddedContentAreaConditional>
      )}
      tabs={tabs}
      scenes={{
        DreamGroups: DreamgroupTabContents,
        OneOnOneDreamwork: OneOnOneDreamworkTabContents,
      }}
    />
  )
}
