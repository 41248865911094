import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap } from '../../../modules/strings/string-helpers'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateAskYourDreams"
        component={Screens.AskYourDreams}
        options={{
          title: cap(t('askYourDreams.title')),
        }}
      />
      <MainStack.Screen
        name="PrivateAskYourDreamsInsightView"
        component={Screens.InsightView}
        options={{
          title: cap(t('common.insight_plural', { count: 1 })),
          headerLeft: HeaderLeft,
        }}
      />
    </>
  )
}
