import React, { useState, useEffect } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { DreamerDashboardHeader } from './header'
import { UserRank, UserRankService } from '../../../../api/_openapi'
import { RouteProp } from '@react-navigation/native'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import { useAuth } from '../../contexts/auth-context'
import { SCROLLVIEW_PROPS } from '../../constants/ui-constants'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import {
  MainStackNavigationProp,
  MainStackParamList,
  MainStackScreenProps,
} from '../../navigation/types'

export type TagViewNavigationProp = MainStackNavigationProp<'PrivateDashboard'>
export type TagViewRouteProp = RouteProp<MainStackParamList, 'PrivateDashboard'>

export default function Dashboard({
  navigation,
  route,
}: MainStackScreenProps<'PrivateDashboard'>) {
  // STATE
  const [userRank, setUserRank] = useState<UserRank | null>(null)

  // SELECTORS
  const appUser = useSelector(selectUser)

  // HOOKS
  const { authData } = useAuth()

  //load user rank stats
  useEffect(() => {
    if (appUser && authData?.token) {
      UserRankService.userRank(appUser.id)
        .then((userRank) => {
          setUserRank(userRank)
        })
        .catch((error) => {
          console.error('Error getting user rank:', error)
        })
    }
  }, [appUser, authData])

  // RENDER HEADER
  const HeaderComponent = () => (
    <DreamerDashboardHeader
      displayName={appUser?.username}
      isUsersPrivateGroup={true}
      isLoggedInUser={true}
      imageSrc={{ uri: appUser?.profileImageUrl || undefined }}
      userRank={userRank}
    />
  )

  return (
    <ScrollView {...SCROLLVIEW_PROPS}>
      <PaddedContentAreaConditional webPadding={0}>
        <HeaderComponent />
      </PaddedContentAreaConditional>
    </ScrollView>
  )
}
