import { InterfaceIconProps } from 'native-base/lib/typescript/components/primitives/Icon/types'
import {
  IconEyeOpen,
  IconEyeClosed,
  IconCaretDown,
  IconPlus,
  IconSearch,
  IconImageUpload,
  IconCheck,
  OfflineIcon,
} from '../../assets/react-native-svg/icons/icons'
import {
  IconEllipsis,
  IconCross,
  IconEdit,
  IconRefresh,
  IconCopy,
  IconTrash,
  IconSettings,
  IconGem,
  IconLock,
  IconWand,
  IconCaretRight,
  IconInfo,
  IconElsewhere,
  IconLocation,
  IconCareti18nConditional,
  IconFilter,
  IconEmail,
  IconLink,
  IconOnline,
  IconUserOutline,
  IconMapPin,
} from '../../assets/react-native-svg/icons/icons-ui'
import { AlertStatus } from '../../../../api/frontend-types'
import { IconTheme } from '../../assets/react-native-svg/icons/icons-tag'

// ALL ICON KEYS USED IN THE APP
export type ElsewhereIconType =
  | 'eye-open'
  | 'eye-closed'
  | 'caret-down'
  | 'caret-right'
  | 'image-upload'
  | 'plus'
  | 'ellipsis'
  | 'close'
  | 'edit'
  | 'refresh'
  | 'search'
  | 'copy'
  | 'trash'
  | 'settings'
  | 'gem'
  | 'lock'
  | 'wand'
  | 'check'
  | 'info'
  | 'offline'
  | 'elsewhere'
  | 'location'
  | 'theme'
  | 'caret-i18n'
  | 'filter'
  | 'letter'
  | 'link'
  | 'in_person'
  | 'online'
  | 'map-pin'

// ALL ICONS USED IN THE APP
export const iconMap: {
  [key in ElsewhereIconType]: (props: InterfaceIconProps) => JSX.Element
} = {
  'eye-open': IconEyeOpen,
  'eye-closed': IconEyeClosed,
  'caret-down': IconCaretDown,
  'caret-right': IconCaretRight,
  'image-upload': IconImageUpload,
  plus: IconPlus,
  ellipsis: IconEllipsis,
  close: IconCross,
  edit: IconEdit,
  refresh: IconRefresh,
  search: IconSearch,
  copy: IconCopy,
  trash: IconTrash,
  settings: IconSettings,
  gem: IconGem,
  lock: IconLock,
  wand: IconWand,
  check: IconCheck,
  info: IconInfo,
  offline: OfflineIcon,
  elsewhere: IconElsewhere,
  location: IconLocation,
  theme: IconTheme,
  'caret-i18n': IconCareti18nConditional,
  filter: IconFilter,
  letter: IconEmail,
  link: IconLink,
  in_person: IconUserOutline,
  online: IconOnline,
  'map-pin': IconMapPin,
}

// ICON SETS FOR SPECIFIC PURPOSES

// Toasts / alerts
export const alertIconKeys: { [key in AlertStatus]: ElsewhereIconType } = {
  info: 'info',
  warning: 'eye-open',
  error: 'close',
  success: 'check',
}
