import React, { useState, useEffect } from 'react'
import DreamHeader from '../../../components/composite/feed/dream-header'
import { useDispatch } from 'react-redux'
import { Platform } from 'react-native'
import { FEW } from '../../../i18n/config'
import { Logotype } from '../../../assets/icons/Logotype'
import { useSelector } from '../../../ducks/root-reducer'
import { selectDreamFieldIsLoading } from '../../../ducks/ui/ui'
import { useTranslation } from 'react-i18next'
import { LoadingPage } from '../../../components/layout/loading-page'
import { TabProps } from '../../../components/layout/tab-view-tabs'
import { TabView } from '../../../components/layout/tab-view'
import { idEquals } from '../../../ducks/helpers'
import { refreshSubscriptionStatus } from '../../../ducks/subscription/thunks/subscription-thunks'
import { DreamTab } from '../../../components/composite/tabs/dream-tab'
import { TagsTab } from '../../../components/composite/tabs/tags-tab'
import { InterpretationTab } from '../../../components/composite/tabs/interpretation-tab/interpretation-tab'
import { selectUser } from '../../../ducks/user/user'
import {
  MainStackNavigationProp,
  MainStackParamList,
  MainStackScreenProps,
  RootStackNavigationProp,
  RootStackParamList,
  RootStackScreenProps,
} from '../../../navigation/types'
import { RouteProp } from '@react-navigation/core'
import DreamMap from '../DreamMap/DreamMap'
import {
  FastDream,
  FastDreamLocationService,
} from '../../../../../api/_openapi'
import {
  DreamPoint,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import { useDream } from '../../../hooks/useDream'
import { fetchFastDreamById } from '../../../ducks/dream-tag/functions/dream-functions'
import { Center } from '../../../components/common/center/center'
import { Link } from '../../../components/common/link/link'

export type DreamTabName = 'dream' | 'interpretation' | 'tags' | 'location'

export type DreamViewNavigationProp =
  | MainStackNavigationProp<'PrivateDreamView' | 'GroupDreamView'>
  | RootStackNavigationProp<
      'LibraryDreamView' | 'DreamAnimationFestivalDreamView'
    >
export type DreamViewRouteProp =
  | RouteProp<MainStackParamList, 'PrivateDreamView' | 'GroupDreamView'>
  | RouteProp<
      RootStackParamList,
      'LibraryDreamView' | 'DreamAnimationFestivalDreamView'
    >

export default function DreamView({
  navigation,
  route,
}:
  | MainStackScreenProps<'PrivateDreamView' | 'GroupDreamView'>
  | RootStackScreenProps<
      'LibraryDreamView' | 'DreamAnimationFestivalDreamView'
    >) {
  // ROUTE PARAMS
  const { id, tab, afDreamId } = route.params

  const groupId =
    route.params?.groupId ||
    //@ts-ignore
    route.params?.params?.groupId ||
    //@ts-ignore
    route.params?.params?.params?.groupId

  // STATE
  const { dream: localDream } = useDream(id)
  const [dream, setDream] = useState<FastDream | null>(null)
  const [dreamMapPoints, setDreamMapPoints] = useState<DreamPoint[]>([])

  // We need to keep track of the active tab so we can resize the map when it becomes visible
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

  const setDreamPoints = (data: any) => {
    setDreamMapPoints(data)
  }

  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()

  // SELECTORS
  const user = useSelector(selectUser)
  const imageIsLoading = useSelector(selectDreamFieldIsLoading(id, 'imageId'))

  // VARS
  const isDraft = Boolean(dream?.isDraft)
  const isUserDream = idEquals(user?.id, dream?.userId)
  const shouldShowInterpretation = isUserDream && !isDraft
  const isWeb = Platform.OS === 'web'
  const userCanSeeMapOnTier = Boolean(user?.subscriptionTier === 'supporter')

  // EFFECTS
  useEffect(() => {
    dispatch(refreshSubscriptionStatus())
  }, [])

  useEffect(() => {
    if (!localDream && groupId) {
      fetchFastDreamById(groupId, id).then((dream) => {
        setDream(dream)
      })
    } else {
      setDream(localDream)
    }
  }, [localDream, groupId])

  // Get dream locations
  useEffect(() => {
    if (dream && dream.shareId && isWeb && userCanSeeMapOnTier && isUserDream) {
      FastDreamLocationService.dreamLocations(dream.id)
        .then((res) => {
          setDreamMapPoints(res as DreamPoint[])
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [dream])

  // TABS
  const DreamTabContents = () => {
    if (activeTabIndex !== 0) {
      return null
    }

    return (
      <DreamTab
        inGroup={groupId && user && groupId !== user.userGroupId}
        dream={dream}
        showComments={true}
      />
    )
  }
  const InterpretationTabContents = () => {
    if (!dream) {
      return null
    }
    return <InterpretationTab dream={dream} isPublicUser={false} />
  }

  const TagsTabContents = () => <TagsTab dream={dream} />

  const LocationTabContents = () => (
    <DreamMap
      mode={'dream'}
      dream={dream}
      dreamPoints={dreamMapPoints}
      setDreamPoints={setDreamPoints}
      allowAdding={true}
      activeTabIndex={activeTabIndex}
    />
  )

  // Decide which tabs to render
  const tabsToRender: {
    [key in DreamTabName]: boolean
  } = {
    dream: true,
    interpretation: shouldShowInterpretation,
    tags: !afDreamId && !dream?.isDraft,
    location: isWeb && userCanSeeMapOnTier && isUserDream,
  }

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'dream',
      children: <DreamTabContents />,
      label: t('common.dream_plural', { count: 1 }),
    },
    {
      name: 'interpretation',
      children: <InterpretationTabContents />,
      label: t('common.interpretation_plural', { count: FEW }),
    },
    {
      name: 'tags',
      children: <TagsTabContents />,
      label: t('common.tag_plural', { count: FEW }),
    },
    {
      name: 'location',
      children: <LocationTabContents />,
      label: t('common.map'),
    },
  ].filter((tab) => tabsToRender[tab.name as DreamTabName])

  const isLibrary = route.name === 'LibraryDreamView'
  const isDAFDream = route.name === 'DreamAnimationFestivalDreamView'

  if (!dream) {
    return <LoadingPage />
  } else {
    return (
      <>
        {isDAFDream && (
          <Center m={5}>
            <Link href="/">
              <Logotype width={205} height={25} />
            </Link>
          </Center>
        )}
        <TabView
          onChangeTab={(index: number) => {
            setActiveTabIndex(index)
          }}
          hideTabHeader={Boolean(afDreamId)}
          paddingBottom={isLibrary ? 0 : undefined}
          header={() => (
            <DreamHeader
              dreamId={dream.id || ''}
              isTempDream={!dream.shareId}
              imageUrl={dream.imageUrl}
              title={dream.title}
              date={dream.date}
              displayDate={dream.displayDate}
              description={dream.description}
              note={dream.note}
              dreamImageLoading={imageIsLoading}
              userId={user?.id}
              authorId={dream.userId}
              flipHeaderTextOrder={Boolean(afDreamId)}
              isDAFDream={isDAFDream}
              isDraft={isDraft}
              language={dream.languageCode as SupportedLanguage}
            />
          )}
          tabs={tabs}
          scenes={{
            dream: DreamTabContents,
            interpretation: InterpretationTabContents,
            tags: TagsTabContents,
            location: LocationTabContents,
          }}
          initialTabName={tab}
        />
      </>
    )
  }
}
