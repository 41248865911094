export const l = {
  // Regular
  LIGHT: 71,
  MID: 53,
  DARK: 38,

  // Darker
  DARKER_LIGHT: 66,
  DARKER_MID: 47,
  DARKER_DARK: 32,

  // Darkest
  DARKEST_LIGHT: 64,
  DARKEST_MID: 45,
  DARKEST_DARK: 31,

  // Desaturated regular
  DESAT_LIGHT: 64,
  DESAT_MID: 50,
  DESAT_DARK: 36,

  // Custom values
  BLACK: 10,
  WHITE: 96,
  CREAM: 85,
  GOLD: 52,
  SILVER: 67,
  BRONZE: 50,
}

export const h = {
  MAGENTA: 300,
  DESAT_MAGENTA: 300,
  PINK: 335,
  DESAT_PINK: 335,
  RED: 0,
  DESAT_RED: 355,
  ORANGE_RED: 13,
  DESAT_ORANGE_RED: 15,
  ORANGE: 30,
  DESAT_ORANGE: 30,
  GOLD_YELLOW: 43,
  DESAT_GOLDEN_YELLOW: 43,
  YELLOW: 55,
  DESAT_YELLOW: 53,
  YELLOW_GREEN: 70,
  DESAT_YELLOW_GREEN: 80,
  GREEN: 115,
  DESAT_GREEN: 120,
  SPRING_GREEN: 153,
  DESAT_SPRING_GREEN: 153,
  TURQUOISE: 174,
  DESAT_TURQUOISE: 180,
  SKY_BLUE: 205,
  DESAT_SKY_BLUE: 205,
  BLUE: 230,
  DESAT_BLUE: 230,
  BLUE_PURPLE: 254,
  DESAT_BLUE_PURPLE: 245,
  PURPLE: 270,
  DESAT_PURPLE: 270,
  BLACK: 0,
  GRAY: 0,
  WHITE: 0,
  CREAM: 48,
  GOLD: 46,
  SILVER: 255,
  BRONZE: 30,
}

export const s = {
  MAGENTA: 80,
  DESAT_MAGENTA: 30,
  PINK: 80,
  DESAT_PINK: 33,
  RED: 75,
  DESAT_RED: 45,
  ORANGE_RED: 75,
  DESAT_ORANGE_RED: 40,
  ORANGE: 85,
  DESAT_ORANGE: 35,
  GOLD_YELLOW: 90,
  DESAT_GOLDEN_YELLOW: 40,
  YELLOW: 85,
  DESATURATED_YELLOW: 41,
  YELLOW_GREEN: 90,
  DESAT_YELLOW_GREEN: 35,
  GREEN: 72,
  DESAT_GREEN: 35,
  SPRING_GREEN: 80,
  DESAT_SPRING_GREEN: 40,
  TURQUOISE: 90,
  DESAT_TURQUOISE: 40,
  SKY_BLUE: 80,
  DESAT_SKY_BLUE: 30,
  BLUE: 80,
  DESAT_BLUE: 32,
  BLUE_PURPLE: 80,
  DESAT_BLUE_PURPLE: 30,
  PURPLE: 80,
  DESAT_PURPLE: 25,
  BLACK: 0,
  GRAY: 0,
  WHITE: 0,
  CREAM: 65,
  GOLD: 65,
  SILVER: 3,
  BRONZE: 61,
}
