/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterpretationStyle } from '../models/InterpretationStyle';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InterpretationStyleService {
  /**
   * Retrieves the collection of InterpretationStyle resources.
   * Retrieves the collection of InterpretationStyle resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns InterpretationStyle InterpretationStyle collection
   * @throws ApiError
   */
  public static apiInterpretationStylesGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<InterpretationStyle>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/interpretation_styles',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a InterpretationStyle resource.
   * Creates a InterpretationStyle resource.
   * @param requestBody The new InterpretationStyle resource
   * @returns InterpretationStyle InterpretationStyle resource created
   * @throws ApiError
   */
  public static apiInterpretationStylesPost(
    requestBody: InterpretationStyle,
  ): CancelablePromise<InterpretationStyle> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/interpretation_styles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a InterpretationStyle resource.
   * Retrieves a InterpretationStyle resource.
   * @param id InterpretationStyle identifier
   * @returns InterpretationStyle InterpretationStyle resource
   * @throws ApiError
   */
  public static apiInterpretationStylesIdGet(
    id: string,
  ): CancelablePromise<InterpretationStyle> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/interpretation_styles/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the InterpretationStyle resource.
   * Replaces the InterpretationStyle resource.
   * @param id InterpretationStyle identifier
   * @param requestBody The updated InterpretationStyle resource
   * @returns InterpretationStyle InterpretationStyle resource updated
   * @throws ApiError
   */
  public static apiInterpretationStylesIdPut(
    id: string,
    requestBody: InterpretationStyle,
  ): CancelablePromise<InterpretationStyle> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/interpretation_styles/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the InterpretationStyle resource.
   * Removes the InterpretationStyle resource.
   * @param id InterpretationStyle identifier
   * @returns void
   * @throws ApiError
   */
  public static apiInterpretationStylesIdDelete(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/interpretation_styles/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Updates the InterpretationStyle resource.
   * Updates the InterpretationStyle resource.
   * @param id InterpretationStyle identifier
   * @param requestBody The updated InterpretationStyle resource
   * @returns InterpretationStyle InterpretationStyle resource updated
   * @throws ApiError
   */
  public static apiInterpretationStylesIdPatch(
    id: string,
    requestBody: InterpretationStyle,
  ): CancelablePromise<InterpretationStyle> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/interpretation_styles/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/merge-patch+json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
}
