import { useEffect, useState } from 'react'
import { AppState, AppStateStatus } from 'react-native'

type AppStateMsg = {
  curr: AppStateStatus
  prev: AppStateStatus
  hasEnteredForeground: boolean
}

// Watch the app state
// Adapted from https://reactnative.dev/docs/appstate
export function useAppState() {
  const currentState = AppState.currentState
  const [appState, setAppState] = useState<AppStateMsg>({
    curr: currentState,
    prev: currentState,
    hasEnteredForeground: false,
  })

  useEffect(() => {
    function onChange(newState: AppStateStatus) {
      console.log('app state changed')
      setAppState((prev) => ({
        curr: newState,
        prev: prev.curr,
        hasEnteredForeground: newState === 'active' && prev.curr !== 'active',
      }))
    }

    const subscription = AppState.addEventListener('change', onChange)

    return () => {
      subscription.remove()
    }
  }, [])

  return appState
}
