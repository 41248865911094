import React, { useEffect } from 'react'
import { useColorModeValue } from 'native-base'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useAuth } from '../../contexts/auth-context'
import { CodeInput } from '../../components/common/inputs/input-code'
import { TextInput } from '../../components/common/inputs/text-input'
import { elsewhereToast } from '../../components/common/toast/toast'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { ElsewhereAlert } from '../../components/common/alert/alert'
import { FormScrollWrapper } from '../../components/common/form/form-scroll-wrapper'
import { getColorForStatus } from '../../modules/ui-helpers/ui-helpers'
import { HeadingSans } from '../../components/common/copy/heading-sans'
import { PressableFormControlText } from '../../components/common/copy/text-formcontrolhelper-pressable'
import Autolink from 'react-native-autolink'
import { useLinkProps } from '../../utilities/useLinkPropsFixed'
import { RouteProp } from '@react-navigation/native'
import { Column } from '../../components/common/column/column'
import { FormControl } from '../../components/common/form-control/form-control'
import {
  RootStackNavigationProp,
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/types'

export type LoginNavigationProp = RootStackNavigationProp<'Login'>
export type LoginRouteProp = RouteProp<RootStackParamList, 'Login'>

export default function Login({
  navigation,
  route,
}: RootStackScreenProps<'Login'>) {
  // HOOKS
  const { t } = useTranslation()
  const auth = useAuth()

  // VARS
  const routeEmail = route.params?.email || ''
  const codeSent = route.params?.codeSent || false
  const inviteCode = route.params?.inviteCode || ''

  // STATE
  const [email, setEmail] = React.useState(routeEmail)
  const [passcode, setPasscode] = React.useState('')
  const [emailIsSubmitted, setEmailIsSubmitted] = React.useState(
    codeSent || false,
  )
  const [errors, setErrors] = React.useState({} as any)
  const [isLoading, setIsLoading] = React.useState(false)
  const [passcodeAlertShowing, setPasscodeAlertShowing] = React.useState(
    codeSent || false,
  )

  // EFFECTS
  useEffect(() => {
    // @ts-ignore
    if (routeEmail || codeSent) {
      // @ts-ignore
      navigation.setParams({ email: undefined, codeSent: undefined })
    }
  }, [route])

  const validate = () => {
    if (!email) {
      setErrors({ ...errors, email: 'Email is required' })
      return false
    } else if (email.length < 3) {
      setErrors({ ...errors, email: 'Email is too short' })
      return false
    }
    console.log('Validation Passed')
    setErrors({})
    return true
  }

  const onSubmitEmail = () => {
    const sendPasscode = async () => {
      setIsLoading(true)
      const success = await auth.sendPasscode(email)
      if (success as any) {
        setEmailIsSubmitted(true)
        setPasscodeAlertShowing(true)
        setIsLoading(false)
        return
      } else {
        setErrors({ ...errors, email: 'Submission failed' })
        setIsLoading(false)
      }
    }
    const validated = validate()
    validated ? sendPasscode() : console.log('Validation Failed')
  }

  const signIn = async (passcodeFromInput?: string) => {
    setIsLoading(true)
    const success = await auth.signIn(
      email,
      passcodeFromInput || passcode,
      inviteCode,
    )
    if (success as any) {
      return
    } else {
      elsewhereToast.showToast({
        description: 'toast.invalidPasscode.description',
        status: 'error',
        duration: 4000,
      })
      setErrors({ ...errors, passcode: 'Submission failed' })
      setIsLoading(false)
    }
  }

  const onSubmitPasscode = () => {
    const validated = validate()
    validated ? signIn() : console.log('Validation Failed')
  }

  const submitPasscode = (passcode?: string) => {
    const validated = validate()
    validated ? signIn(passcode) : console.log('Validation Failed')
  }

  const color = useColorModeValue(BLACK, WHITE)

  const scrollViewRef = React.useRef<any>(null)

  const { onPress, ...rest } = useLinkProps({
    to: { screen: 'Signup', params: { inviteCode } },
  })

  return (
    <FormScrollWrapper
      scrollViewRef={scrollViewRef}
      name={'login'}
      contentContainerStyle={{
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        marginHorizontal: 'auto',
      }}
    >
      <Column
        style={{ width: '100%', height: '100%', alignItems: 'flex-start' }}
      >
        <PaddedContentArea>
          {inviteCode && !emailIsSubmitted ? (
            <ElsewhereAlert
              mb={4}
              px={3}
              py={5}
              maxW={WEB_MAX_WIDTH}
              status="info"
              title={t('login.alert.joinGroup.title')}
              bodyText={
                <Autolink
                  text={t('login.alert.joinGroup.description')}
                  matchers={[
                    {
                      pattern: /<linkTag>(.*)<\/linkTag>/g,
                      getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                      onPress: () => onPress(),
                    },
                  ]}
                  linkProps={{
                    style: {
                      textDecorationLine: 'underline',
                      color: getColorForStatus('info', true),
                    },
                    onPress,
                    ...rest,
                  }}
                />
              }
            ></ElsewhereAlert>
          ) : null}
          {emailIsSubmitted && passcodeAlertShowing ? (
            <ElsewhereAlert
              mb={4}
              maxW={WEB_MAX_WIDTH}
              status="success"
              onDismiss={() => setPasscodeAlertShowing(false)}
              title={t('toast.passcodeSent.title')}
              bodyText={t('toast.passcodeSent.description')}
            />
          ) : null}
          <FormControl
            isInvalid={'email' in errors}
            isDisabled={emailIsSubmitted}
            isReadOnly={emailIsSubmitted}
          >
            <HeadingSans mb={2} mt={4}>
              {t('common.email')}
            </HeadingSans>
            <TextInput
              autoFocus={true}
              autoComplete="email"
              keyboardType="email-address"
              textContentType="username"
              autoCorrect={false}
              placeholder={t('common.emailPlaceholder')}
              onChangeText={(email) => setEmail(email)}
              value={email}
              autoCapitalize="none"
              dir={'ltr'}
              scriptType={'latin'}
              onSubmitEditing={onSubmitEmail}
            />
            {emailIsSubmitted ? (
              <Column alignItems={'flex-end'}>
                <PressableFormControlText
                  onPress={() => {
                    setPasscode('')
                    setEmailIsSubmitted(false)
                  }}
                >
                  {t('common.reset')}
                </PressableFormControlText>
              </Column>
            ) : null}
          </FormControl>
          {!emailIsSubmitted ? (
            <ButtonPill
              isDisabled={!email.match(/^[^\s@]+@[^\s@]+\.(([^\s@]+){2,})$/i)}
              onPress={onSubmitEmail}
              mt="5"
              isLoading={isLoading}
            >
              {t('button.sendPasscode')}
            </ButtonPill>
          ) : null}
          {emailIsSubmitted && (
            <>
              <FormControl isInvalid={'passcode' in errors}>
                <HeadingSans mb={2}>{t('common.passcode')}</HeadingSans>
                <CodeInput
                  autoFocus={true}
                  value={passcode}
                  setValue={setPasscode}
                  color={color}
                  submitPasscode={submitPasscode}
                />
              </FormControl>
              <ButtonPill
                isDisabled={passcode.length !== 6}
                onPress={onSubmitPasscode}
                mt="5"
                isLoading={isLoading}
              >
                {t('common.login')}
              </ButtonPill>
            </>
          )}
        </PaddedContentArea>
      </Column>
    </FormScrollWrapper>
  )
}
