import React, { FC } from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SELECTION_COLOR } from '../../../constants/constants'
import i18n from '../../../i18n/i18nnext'
import { getHeadingFontKey } from '../../../modules/language-helpers/language-helpers'
import { TextProps, Text } from './text'
import {
  ScriptType,
  SimpleTextAlign,
  SupportedLanguage,
} from '../../../../../api/frontend-types'

type HeadingMainProps = TextProps & {
  textAlign?: SimpleTextAlign
  scriptType?: ScriptType
  language?: SupportedLanguage
}

export const HeadingMain: FC<HeadingMainProps> = ({
  children,
  textAlign = 'center',
  scriptType,
  language,
  ...rest
}: HeadingMainProps) => {
  // HOOKS
  const bgColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const fontKey = getHeadingFontKey(lang, scriptType)
  const textAlignValue = textAlign || 'center'

  return (
    <Text
      bgColor={bgColor}
      fontFamily={fontKey}
      fontWeight={300}
      style={{
        textTransform: 'uppercase',
        textAlign: textAlignValue,
      }}
      fontSize={'2xl'}
      lineHeight={'2xl'}
      selectionColor={SELECTION_COLOR}
      {...rest}
    >
      {children}
    </Text>
  )
}
