import React from 'react'
import { LoadingPage } from '../../../components/layout/loading-page'
import { undecorateId } from '../../../../../api/decorate-ids'
import { useSelector } from '../../../ducks/root-reducer'
import {
  selectGroupMemberships,
  selectPrivateGroupId,
} from '../../../ducks/groups/groups'
import { idEquals } from '../../../ducks/helpers'
import { UserList, UserListItem } from '../../../components/composite/user-list'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'

export type GroupMembersNavigationProp =
  RootStackNavigationProp<'GroupMembersView'>

export default function GroupMembers({}: RootStackScreenProps<'GroupMembersView'>) {
  // Hooks
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = useActiveGroupId()
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)
  const memberships = useSelector(selectGroupMemberships(activeGroupId))

  const membersList: UserListItem[] = memberships.map((m) => ({
    id: undecorateId(m.userId),
    displayName: m.displayName || '',
    profileImage: m.imageUrl || undefined,
    profileType: isUserPrivateGroup ? 'user' : 'group',
  }))

  if (memberships.length === 0) {
    return <LoadingPage />
  } else {
    return (
      <UserList
        users={membersList}
        activeGroupId={activeGroupId || undefined}
      />
    )
  }
}
