import React from 'react'
import {
  BODY_FONT,
  LARGE_FONT_SIZE,
  LARGE_LINE_HEIGHT,
} from '../../../constants/constants'
import { LIGHT_MID_GRAY } from '../../../constants/ui-constants'
import { LocationInputProps } from './location-input-props'
import AutoComplete from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'

export default function LocationInput({
  color,
  onSelected,
  language,
}: LocationInputProps) {
  const { t } = useTranslation()

  return (
    <AutoComplete
      apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY || ''}
      placeholder={t('addDreamPage.location.placeholder')}
      onPlaceSelected={(data: any) => {
        onSelected(data)
      }}
      lang={language}
      language={language}
      options={{
        types: [],
        fields: ['geometry', 'name', 'place_id', 'address_components'],
      }}
      style={{
        fontFamily: BODY_FONT,
        fontSize: LARGE_FONT_SIZE,
        lineHeight: LARGE_LINE_HEIGHT,
        letterSpacing: 0,
        fontWeight: '400',
        fontStyle: 'normal',
        color,
        verticalAlign: 'top',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 1,
        paddingBottom: 0,
        height: 30,
        border: 'none',
        borderBottom: '1px solid',
        borderBottomColor: LIGHT_MID_GRAY,
        outline: 'none',
        width: '100%',
      }}
    />
  )
}
