import { FastDream } from '../../../api/_openapi'
import { FormFieldOption } from '../../../api/frontend-types'

// Equality function to see if two lists of FastDreams are the same
export function dreamListsAreEqual(a: FastDream[], b: FastDream[]): boolean {
  return a === b
}

export function formfieldOptionsAreEqual(
  a: FormFieldOption[],
  b: FormFieldOption[],
): boolean {
  return a.length === b.length
}
