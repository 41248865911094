import React from 'react'
import { ILinkProps as NbLinkProps } from 'native-base/lib/typescript/components/primitives/Link/types'
import { Link as NbLink } from 'native-base'

export type ILinkProps = NbLinkProps

// This is a simple wrapper around native-base's View component
export const Link = ({ children, ...rest }: ILinkProps) => {
  return <NbLink {...rest}>{children}</NbLink>
}
