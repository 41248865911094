/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserRank } from '../models/UserRank';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserRankService {
  /**
   * Retrieves a UserRank resource.
   * Retrieves a UserRank resource.
   * @param id UserRank identifier
   * @returns UserRank UserRank resource
   * @throws ApiError
   */
  public static userRank(
    id: string,
  ): CancelablePromise<UserRank> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/users/{id}/rank',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
