import React from 'react'
import { View } from 'react-native'

export const CentredListEmptyComponent = (Component: any) => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        flex: 1,
      }}
    >
      {Component}
    </View>
  )
}
