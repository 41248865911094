export const apiBaseUrls = {
  dev: 'https://api.elsewherelocal.dev',
  staging: 'https://api.elsewhere-staging.k8s.elsewhere.to',
  prod: 'https://api.elsewhere.to',
}

const env: any = process.env.REACT_APP_ENV || 'prod'

// @ts-ignore
if (!apiBaseUrls[env]) {
  throw new Error(`Invalid env: ${env}`)
}

// @ts-ignore
export const API_BASE = apiBaseUrls[env]
console.debug('Connected temporarily to', API_BASE)
