/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastComment } from '../models/FastComment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastCommentService {
  /**
   * Removes the FastComment resource.
   * Removes the FastComment resource.
   * @param id FastComment identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteComment(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/comments/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastComment resources.
   * Retrieves the collection of FastComment resources.
   * @param id FastComment identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastComment FastComment collection
   * @throws ApiError
   */
  public static getComments(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastComment>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dream_shares/{id}/comments',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a FastComment resource.
   * Creates a FastComment resource.
   * @param id FastComment identifier
   * @param requestBody The new FastComment resource
   * @returns FastComment FastComment resource created
   * @throws ApiError
   */
  public static createComment(
    id: string,
    requestBody: Partial<FastComment>,
  ): CancelablePromise<FastComment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dream_shares/{id}/comments',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
