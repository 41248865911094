import React from 'react'
import { Size } from '../../../core/theme'
import { useColorModeValue } from 'native-base'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types'
import { IColors } from 'native-base/lib/typescript/theme/base/colors'
import { ColorValue } from 'react-native'
import { Loading } from '../../layout/loading'
import { Box } from '../box/box'
import { IconButton, IconButtonProps } from '../icon-button/icon-button'
import {
  BLACK,
  DARK_GRAY,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../constants/ui-constants'
import {
  ElsewhereIconType,
  iconMap,
} from '../../../modules/ui-helpers/icon-map'

type ButtonVariant = 'filled' | 'outlined' | 'ghost' | 'placeholder'

export type ButtonIconProps = IconButtonProps & {
  iconKey: ElsewhereIconType
  size?: Size
  color?: ColorValue
  iconFill?: ColorValue
  bgColor?: ColorValue
  iconStroke?: ResponsiveValue<IColors | (string & {})>
  unpad?: boolean
  variant?: ButtonVariant
  isDisabled?: boolean
  loading?: boolean
  outlineWidthOverride?: number
}

export const ButtonIcon = React.memo(
  ({
    iconKey,
    width,
    height,
    color,
    iconFill,
    bgColor,
    iconStroke,
    size = 'md',
    unpad,
    isDisabled,
    variant = 'filled',
    loading,
    outlineWidthOverride,
    ...rest
  }: ButtonIconProps) => {
    const ElsewhereIcon = iconMap[iconKey]
    const outlined = variant === 'outlined'
    const textColor = useColorModeValue(WHITE, BLACK)

    if (!color) {
      color = textColor
    }
    if (!iconFill) {
      iconFill = textColor
    }

    const outlineWidth = outlineWidthOverride || getStrokeWidth(size)

    // BACKGROUND COLOR
    const filledBgColor = useColorModeValue(BLACK, WHITE)
    const nonFilledBgColor = useColorModeValue(WHITE, BLACK)
    const modeBg = variant === 'filled' ? filledBgColor : nonFilledBgColor
    const backgroundColor =
      variant === 'ghost' ? 'transparent' : bgColor || modeBg

    // ICON COLOR
    const outlinedIconColor = iconStroke || useColorModeValue(BLACK, WHITE)
    const filledIconColor = iconFill || useColorModeValue(WHITE, BLACK)
    const placeholderIconColor = LIGHT_MID_GRAY // Probably opacity is applied to this
    const nonFilledIconColor =
      variant === 'placeholder' ? placeholderIconColor : outlinedIconColor
    const iconColor = isDisabled
      ? DARK_GRAY
      : variant === 'filled'
      ? filledIconColor
      : nonFilledIconColor
    const iconFillOverride = isDisabled ? LIGHT_MID_GRAY : iconColor

    if (loading) {
      return (
        <Box p={0}>
          <Loading size={size} />
        </Box>
      )
    }

    return (
      <IconButton
        isDisabled={isDisabled}
        icon={
          <ElsewhereIcon
            color={isDisabled ? placeholderIconColor : iconColor}
            fill={iconFillOverride}
            size={size}
          />
        }
        variant={outlined ? 'outline' : 'solid'}
        backgroundColor={backgroundColor}
        borderRadius={'full'}
        borderWidth={outlined ? outlineWidth : 0}
        borderColor={
          isDisabled
            ? placeholderIconColor
            : outlined
            ? iconColor
            : 'transparent'
        }
        _pressed={{
          opacity: 0.5,
        }}
        size={size}
        p={0}
        {...rest}
      />
    )
  },
)

// Return the stroke width for the outline
// based on the size of the button
// Smaller sizes should have smaller stroke widths
// TODO: This should be in the theme?
function getStrokeWidth(size: Size | undefined) {
  switch (size) {
    case '2xs':
      return 1
    case 'xs':
      return 1.5
    case 'sm':
      return 2
    case 'md':
      return 2
    case 'lg':
      return 3.5
    case 'xl':
      return 6.5
    case '2xl':
      return 10
    default:
      return 10
  }
}
