import React, { useMemo } from 'react'
import { SansText } from '../../copy/text-sans'
import { Dimensions, Platform } from 'react-native'
import { InputField } from '../input-field'
import { InputFieldFake } from '../input-field-fake'
import { useTranslation } from 'react-i18next'
import { DataExportNavigationProp } from '../../../../screens/Settings/DataExport'
import { DateRangeSelectNavigationProp } from '../../../composite/chart/date-range-select'
import { FormFieldNavigationProp } from '../../form/form-field'
import { CountrySelectNavigationProp } from './select-country'
import { getContentWidth } from '../../../../modules/ui-helpers/ui-helpers'
import { PADDING_HORIZONTAL_PIXELS } from '../../../../constants/constants'
import { ButtonIcon } from '../../buttons/button-icon'
import { InfoText } from '../../copy/text-info'
import { Row } from '../../row/row'
import { useDisclose, FlatList, useColorModeValue } from 'native-base'
import {
  BLACK,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../../constants/ui-constants'
import {
  FormFieldOption,
  ScriptType,
} from '../../../../../../api/frontend-types'
import { Center } from '../../center/center'
import { Column } from '../../column/column'
import { FormControl } from '../../form-control/form-control'
import { Actionsheet } from '../../action-sheet/action-sheet'

export type SelectWithActionSheetNavigationProp =
  | DateRangeSelectNavigationProp
  | DataExportNavigationProp
  | FormFieldNavigationProp
  | CountrySelectNavigationProp

type SelectWithActionSheetProps = {
  options: FormFieldOption[]
  placeholder?: string
  onChange: (value: FormFieldOption) => void
  value: string | undefined
  searchable?: boolean
  maintainSortOrder?: boolean
  fieldName?: string
}

type DisplayValue = {
  label: string
  scriptType?: ScriptType
}

export const SelectWithActionSheet = React.memo(
  ({
    options,
    placeholder,
    onChange,
    value,
    searchable = true,
    maintainSortOrder = false,
    fieldName,
  }: SelectWithActionSheetProps) => {
    const { isOpen, onOpen, onClose } = useDisclose()
    const { t } = useTranslation()
    const color = useColorModeValue(BLACK, WHITE)
    const lockedColor = LIGHT_MID_GRAY

    const translatedOptions = useMemo(() => {
      const translatedOptions = options.map((o) => {
        const lng = o.languageKey?.split('.')[1] || 'en'
        const translatedLabel = o.languageKey ? t(o.languageKey) : o.label
        const translatedSubLabel = t(o.subLabel as string, { lng })
        const isTheSame = translatedLabel === translatedSubLabel
        return {
          ...o,
          label: translatedLabel,
          subLabel: isTheSame ? undefined : translatedSubLabel,
        }
      })
      return maintainSortOrder
        ? translatedOptions
        : translatedOptions.sort((a, b) => {
            return a.label.localeCompare(b.label)
          })
    }, [options, t])

    const selectedOption = useMemo(() => {
      return options.find((option) => option.value === value)
    }, [value, translatedOptions])

    // Get page height
    const pageHeight = Dimensions.get('window').height

    const [searchValue, setSearchValue] = React.useState<string>('')

    // Todo: replace with Fuse.js
    const filteredOptions = React.useMemo(() => {
      if (searchValue === '') {
        return translatedOptions
      }
      return translatedOptions.filter((option) => {
        return (
          option.label.toLowerCase().includes(searchValue.toLowerCase()) ??
          false
        )
      })
    }, [searchValue, translatedOptions])

    // What shows as a selected option
    const displayValue: DisplayValue = useMemo(() => {
      if (selectedOption && selectedOption.languageKey) {
        return {
          label: t(selectedOption.languageKey),
          scriptType: selectedOption.scriptType,
        }
      } else if (selectedOption) {
        return {
          label: selectedOption.label,
          scriptType: selectedOption.scriptType,
        }
      }
      return {
        label: '',
      }
    }, [selectedOption, t])

    return (
      <Center width={'100%'}>
        <InputFieldFake
          onPress={() => {
            onOpen()
          }}
          placeholder={placeholder || t('common.select')}
          type={'select'}
          value={displayValue.label}
          scriptType={displayValue.scriptType}
        ></InputFieldFake>
        <Actionsheet
          isOpen={isOpen}
          onClose={() => {
            setSearchValue('')
            onClose()
          }}
        >
          {isOpen && (
            <Actionsheet.Content height={pageHeight * 0.7}>
              {searchable && (
                <FormControl>
                  <InputField
                    placeholder={t('common.search')}
                    onChangeText={(text: string) => {
                      setSearchValue(text)
                    }}
                    value={searchValue}
                    ml={4}
                    mr={4}
                    mb={4}
                    mt={4}
                    autoFocus
                  />
                </FormControl>
              )}
              <FlatList
                keyboardShouldPersistTaps="always"
                style={{ width: '100%' }}
                initialNumToRender={10}
                data={filteredOptions}
                keyExtractor={(item) => String(item.value)}
                contentInsetAdjustmentBehavior="automatic"
                renderItem={({ item }) => {
                  const contentWidth = getContentWidth(Platform.OS)
                  return (
                    <Actionsheet.Item
                      key={item.value}
                      onPress={() => {
                        setSearchValue('')
                        item.locked
                          ? item.lockedAction && item.lockedAction()
                          : onChange(item)
                        onClose()
                      }}
                      padding={2}
                    >
                      <Row
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          width: contentWidth - PADDING_HORIZONTAL_PIXELS * 2,
                        }}
                        minHeight={10}
                        paddingX={2}
                      >
                        <Column>
                          <SansText
                            size={'md'}
                            color={item.locked ? lockedColor : color}
                            style={{
                              lineHeight: 22, // Somehow this is needed to make the text vertically centered
                            }}
                            scriptType={item.scriptType}
                          >
                            {item.label}
                          </SansText>
                          {item?.subLabel && (
                            <InfoText
                              style={{ fontSize: 12 }}
                              scriptType={item.subLabelScriptType}
                            >
                              {item?.subLabel}
                            </InfoText>
                          )}
                        </Column>
                        {item.iconKey && (
                          <ButtonIcon
                            iconKey={item.iconKey || ''}
                            size={'xs'}
                            ml={2}
                            variant={'ghost'}
                            isDisabled={item.locked}
                          />
                        )}
                      </Row>
                    </Actionsheet.Item>
                  )
                }}
              />
            </Actionsheet.Content>
          )}
        </Actionsheet>
      </Center>
    )
  },
)
