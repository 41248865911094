import React from 'react'
import { MainStackType } from '../../MainStack'
import * as Screens from '../../../screens'
import { capitalize } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import { useTranslation } from 'react-i18next'

export default function PrivateFavoritesFeed(MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateFavoritesFeed"
        component={Screens.FavoritesFeed}
        options={{
          title: capitalize(t('common.bookmarks', { count: FEW })),
        }}
      />
    </>
  )
}
