import React from 'react'
import i18n from 'i18next'
import { View, TouchableOpacity, Keyboard } from 'react-native'
import { Menu } from '../assets/react-native-svg/Menu'
import { Add } from '../assets/react-native-svg/Add'
import { Home as HomeIcon } from '../assets/react-native-svg/Home'
import { useColorModeValue } from 'native-base'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { BLACK, WHITE } from '../constants/ui-constants'
import { SafeAreaView } from '../libs/safe-area-view'
import {
  BOTTOM_TAB_BAR_HEIGHT,
  LINE_WIDTH,
  WEB_MAX_WIDTH,
} from '../constants/constants'

type ElsewhereBottomTabBarProps = BottomTabBarProps & {
  openDrawer: () => void
}

type BottomTab = {
  name: string
  onPress: () => void
  icon: JSX.Element
}

export function BottomTabBar({
  state,
  navigation,
  openDrawer,
}: ElsewhereBottomTabBarProps) {
  const color = useColorModeValue(BLACK, WHITE)
  const borderTopColor = color

  // Reverse the tabs for RTL languages
  const isRtl = i18n.dir() === 'rtl'

  // Tabs
  const tabs: BottomTab[] = [
    {
      name: 'Menu',
      onPress: async () => {
        await Keyboard.dismiss()
        openDrawer()
      },

      icon: <Menu color={color} size={24} />,
    },
    {
      name: 'Add',
      onPress: async () => {
        navigation.navigate({
          name: 'PrivateDreamAdd',
          merge: true,
          params: {},
        })
      },
      icon: <Add color={color} size={24} />,
    },
    {
      name: 'MainTabs',
      onPress: async () => {
        navigation.navigate('MainTabs', {
          merge: true,
          screen: 'MainStack',
          params: {
            screen: 'PrivateFeed',
            params: {
              groupId: undefined,
              from: 'BottomTabBar',
            },
          },
        })
      },
      icon: <HomeIcon color={color} size={24} />,
    },
  ]
  const rtlTabs = [...tabs].reverse()
  const bottomTabs = isRtl ? rtlTabs : tabs

  return (
    <SafeAreaView style={{ paddingTop: 0 }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          height: BOTTOM_TAB_BAR_HEIGHT,
          borderTopWidth: LINE_WIDTH,
          borderTopColor,
          maxWidth: WEB_MAX_WIDTH,
          marginHorizontal: 'auto',
        }}
      >
        {bottomTabs.map((route, index) => {
          const icon = route.icon

          const isFocused = state.index === index

          const onPress = async () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.name,
              canPreventDefault: true,
            })

            // Avoid a press event in certain cases
            const avoidPreventDefault = event.defaultPrevented
            const avoidPress = avoidPreventDefault

            if (!avoidPress) {
              await route.onPress()
            }
          }

          return (
            <TouchableOpacity
              key={route.name}
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={route.name}
              onPress={onPress}
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {icon}
            </TouchableOpacity>
          )
        })}
      </View>
    </SafeAreaView>
  )
}
