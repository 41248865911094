import { Insight } from '../../../../api/_openapi'
import { truncateString } from '../strings/string-helpers'
import {
  FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE,
  FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE_SMALL,
  FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_WEB,
  WEB_MAX_WIDTH,
} from '../../constants/constants'

// Get the title of the insight
// Tag insights use the tag name
export function getTitleForInsight(
  insight: Insight | null,
  t?: any,
  truncate?: boolean,
  screenWidth?: number,
) {
  const tag = insight?.tag
  // Tag might be an empty object
  const hasTag = tag && tag.name

  // Basic title
  let rawTitle = ''
  if (hasTag && tag.type === 'SYMBOL' && t) {
    rawTitle = t(`symbol.${tag.name}`)
  } else if (hasTag) {
    rawTitle = tag.name
  } else if (insight?.title) {
    rawTitle = insight.title
  } else if (insight?.question) {
    rawTitle = insight.question
  } else {
    rawTitle = '?'
  }

  // Truncation
  if (!truncate) {
    return rawTitle
  } else {
    const labelLen = getMaxCharsForLabel(screenWidth || 0)
    return truncateString(rawTitle, labelLen)
  }
}

// Get maxChars for label based on screen width
function getMaxCharsForLabel(screenWidth: number): number {
  if (screenWidth <= 400) {
    return FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE_SMALL
  } else if (screenWidth < WEB_MAX_WIDTH) {
    return FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE
  } else {
    return FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_WEB
  }
}
