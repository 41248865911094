import { DeviceLocation, AccuracyMode, ALTITUDE_UNKNOWN } from './location'

export function useDeviceLocation() {
  const numNullToDefault = (
    num: number | null | undefined,
    defaultValue: any,
  ): any => {
    if (num === null) {
      return defaultValue
    }
    return num
  }

  return {
    getLocation: async (
      accuracy: AccuracyMode = 'high',
      timeout: number = 1500,
    ): Promise<DeviceLocation | null> => {
      return new Promise((resolve, reject) => {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                altitude: numNullToDefault(
                  position.coords.altitude,
                  ALTITUDE_UNKNOWN,
                ),
                accuracy: position.coords.accuracy,
                speed: position.coords.speed,
                time: position.timestamp,
                bearing: numNullToDefault(position.coords.heading, undefined),
                provider: -1,
                verticalAccuracy: numNullToDefault(
                  position.coords.altitudeAccuracy,
                  undefined,
                ),
                course: numNullToDefault(position.coords.heading, undefined),
              })
            },
            (error) => {
              console.error(error)
              resolve(null) // or reject(error) if you want to handle the error case
            },
            {
              enableHighAccuracy: accuracy === 'high',
              timeout: timeout,
              maximumAge: 0,
            },
          )
        } catch (e) {
          console.error(e)
          resolve(null)
        }
      })
    },
  }
}
