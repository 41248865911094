export const sName = {
  SATURATED: 'saturated',
  DESATURATED: 'desaturated',
  CUSTOM: '',
}

export const colorTemperatures = {
  COOL: 'cool',
  WARM: 'warm',
  NEUTRAL: 'neutral',
  METALLIC: 'metallic',
}

export const lName = {
  LIGHT: 'light',
  MID: 'mid',
  DARK: 'dark',
  CUSTOM: '',
}

export const PICKER_BACKGROUND_COLOR = 'hsl(213, 5%, 24%)'
export const BUTTON_TEXT_COLOR = 'hsl(213, 5%, 80%)'
export const INACTIVE_COLOR = 'hsla(213, 5%, 54%, 0.5)'
