import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PUBLIC_USER_INFO } from '../../../constants/ui-constants'
import { useUserDate } from '../../../hooks/useUserDate'
import { markInsightAsDeleted } from '../../../ducks/dream-tag/functions/insight-functions'
import { getTitleForInsight } from '../../../modules/analysis-helpers/insight-helpers'
import { copyToClipboard } from '../../../modules/ui-helpers/copy-to-clipboard'
import { LoadingPage } from '../../../components/layout/loading-page'
import { IconWand } from '../../../assets/react-native-svg/icons/icons-ui'
import { LoadingLabel } from '../../../components/layout/loading-label'
import { refreshSubscriptionStatus } from '../../../ducks/subscription/thunks/subscription-thunks'
import { Insight, InsightService } from '../../../../../api/_openapi'
import {
  RootStackScreenProps,
  MainStackScreenProps,
  MainStackNavigationProp,
  RootStackNavigationProp,
  MainStackParamList,
  RootStackParamList,
} from '../../../navigation/types'
import { RouteProp } from '@react-navigation/native'
import { FeedEntryGeneric } from '../../../components/composite/feed/feed-entry-generic'
import { ActionSheetMenuItem } from '../../../components/common/action-sheet/action-sheet-menu'
import i18n from '../../../i18n/i18nnext'
import { SupportedLanguage } from '../../../../../api/frontend-types'

export type InsightViewNavigationProp =
  | MainStackNavigationProp<
      'PrivateAskYourDreamsInsightView' | 'PrivateInsightView'
    >
  | RootStackNavigationProp<'LibraryInsightView'>
export type InsightViewRouteProp =
  | RouteProp<
      MainStackParamList,
      'PrivateAskYourDreamsInsightView' | 'PrivateInsightView'
    >
  | RouteProp<RootStackParamList, 'LibraryInsightView'>

// 403 if the user has no access to the insight

export type InsightPageProps = {}

export default function InsightView({
  navigation,
  route,
}:
  | MainStackScreenProps<
      'PrivateAskYourDreamsInsightView' | 'PrivateInsightView'
    >
  | RootStackScreenProps<'LibraryInsightView'>) {
  // STATE
  const [insightAndTagLoading, setInsightAndTagLoading] =
    useState<boolean>(true)
  const [generationLoading, setGenerationLoading] = useState<boolean>(true)
  const [insight, setInsight] = React.useState<Insight | null>(null)

  // ROUTE
  const { id, isRevealing } = route.params

  // HOOKS
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const { dateString } = useUserDate(insight?.date as string)

  // VARS
  const isLibrary = route.name === 'LibraryInsightView'
  const bodyTxt = insight?.insight || ''
  const insightAuthor = PUBLIC_USER_INFO['elsewhere-ai']
  const insightAuthorLabel = t(insightAuthor.i18nKey)
  const language = i18n.resolvedLanguage || 'en'

  // EFFECTS
  React.useEffect(() => {
    // Set the loading state to true
    setInsightAndTagLoading(true)
    setGenerationLoading(true)
    InsightService.fastInsight(id)
      .then((insight) => {
        setInsight(insight)
        if (!insight.generated) {
          InsightService.generateInsight(id).then((insight) => {
            dispatch(refreshSubscriptionStatus())
            setGenerationLoading(false)
            setInsight(insight)
          })
        } else {
          setGenerationLoading(false)
        }
        setInsightAndTagLoading(false)
      })
      .catch(() => setInsightAndTagLoading(false))
  }, [id])

  const pageTitle = getTitleForInsight(insight, t, false)
  const clipboardTxt = (pageTitle || dateString) + '\n\n' + bodyTxt

  const loadingLabel = (
    <LoadingLabel
      title={
        isRevealing
          ? t('insights.generationLoading')
          : t('loading.loadingGeneric')
      }
      icon={isRevealing ? <IconWand size={'xs'} /> : null}
    />
  )

  const actionSheetMenuItems: ActionSheetMenuItem[] = isLibrary
    ? []
    : [
        {
          label: t('common.copyToClipboard'),
          closesMenu: true,
          onPress: () => {
            copyToClipboard(clipboardTxt, t)
          },
        },
        {
          label: t('common.delete'),
          closesMenu: true,
          onPress: () => {
            if (insight) {
              markInsightAsDeleted(insight.id)
                .then((result) => {
                  if (result) {
                    navigation.goBack()
                  }
                })
                .catch((e) => {
                  console.log('Error deleting insight')
                  console.error(e)
                })
            }
          },
        },
        {
          label: t('common.cancel'),
          closesMenu: true,
          onPress: () => {},
        },
      ]

  if (insightAndTagLoading || generationLoading) {
    return <LoadingPage title={loadingLabel} />
  } else {
    return (
      <ScrollView>
        {insight && (
          <FeedEntryGeneric
            inGroup={false}
            title={pageTitle}
            date={insight.date}
            description={bodyTxt}
            avatarLabel={insightAuthorLabel}
            avatarCircleSrc={insightAuthor.img}
            actionSheetMenuItems={actionSheetMenuItems}
            avatarLabelLanguage={language as SupportedLanguage}
            titleLanguage={language as SupportedLanguage}
          />
        )}
      </ScrollView>
    )
  }
}
