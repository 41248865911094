import React, { useState, useRef } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useColorModeValue, Column } from 'native-base'
import { InputField } from '../../common/inputs/input-field'
import { SansText } from '../../common/copy/text-sans'
import { LINE_WIDTH } from '../../../constants/constants'
import { truncateString } from '../../../modules/strings/string-helpers'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { useTranslation } from 'react-i18next'
import { Row } from '../../common/row/row'
import {
  BLACK,
  DARKER_CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import { Button } from '../../common/nb-button/nb-button'

type PlacesAutocompleteProps = {
  width?: number
  onPlaceSelected: (place: any) => void
}

export const PlacesAutocomplete = ({
  width = 200,
  onPlaceSelected,
}: PlacesAutocompleteProps) => {
  // STATE
  const [inputValue, setInputValue] = useState<string>('')
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  // HOOKS
  const dropdownBgColor = useColorModeValue(WHITE, BLACK)
  const dropdownBorderColor = useColorModeValue(BLACK, WHITE)
  const dropdownHoverColor = useColorModeValue(DARKER_CREAM, LIGHT_BLACK)
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  })

  // REFS
  // Ref to register click outside of dropdown
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  // EFFECTS
  // Close dropdown when clicking outside of it
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef, setDropdownOpen])

  // VARS
  const hasInputValue = inputValue.length > 0

  const renderItem = (
    item: any,
    index: number,
    numItems: number,
    width: number,
  ) => {
    const isLastItem = index === numItems - 1
    const hPadding = 3
    const hPaddingPx = hPadding * 4
    return (
      <Button
        width={'100%'}
        backgroundColor={'transparent'}
        borderRadius={0}
        textAlign={'left'}
        justifyContent={'flex-start'}
        key={item.place_id}
        onPress={() => {
          setInputValue(item.description)
          setDropdownOpen(false)
          placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            (placeDetails: any) => onPlaceSelected(placeDetails),
          )
        }}
        paddingTop={index === 0 ? 3 : 2}
        paddingBottom={isLastItem ? 3 : 2}
        paddingLeft={hPadding}
        paddingRight={hPadding}
        _hover={{
          backgroundColor: dropdownHoverColor,
          _text: { color: 'secondary.400' },
        }}
      >
        <Row
          justifyContent={'space-between'}
          flex={1}
          width={width - 2 * hPaddingPx - 2 * LINE_WIDTH}
        >
          <SansText>{truncateBasedOnWidth(item.description, width)}</SansText>
          <ElsewhereIcon iconKey="plus" size={'xs'} />
        </Row>
      </Button>
    )
  }

  return (
    <Column
      style={{
        position: 'relative',
        marginBottom: 40,
        zIndex: 999,
      }}
    >
      <InputField
        placeholder={t('map.inputPlaceholder')}
        onChangeText={(text: string) => {
          if (text.length === 0) {
            setDropdownOpen(false)
          } else {
            setDropdownOpen(true)
          }
          setInputValue(text)
          getPlacePredictions({ input: text })
        }}
        width={width}
        value={inputValue}
        // loading={isPlacePredictionsLoading}
      />
      {/* CLEAR TEXT BUTTON */}
      {hasInputValue && (
        <ButtonIcon
          iconKey="close"
          onPress={() => {
            setInputValue('')
            setDropdownOpen(false)
          }}
          position="absolute"
          right={2}
          top={0}
          size={'xs'}
          variant={'ghost'}
        />
      )}

      {/* DROPDOWN */}
      {dropdownOpen && placePredictions.length > 0 && (
        <Column
          ref={dropdownRef}
          width={width}
          marginTop={-1}
          borderColor={dropdownBorderColor}
          borderWidth={LINE_WIDTH}
          style={{
            position: 'absolute',
            top: 40,
            left: 0,
            width: width,
            backgroundColor: dropdownBgColor,
            zIndex: 999,
          }}
        >
          {placePredictions.map((item, index, arr) =>
            renderItem(item, index, arr.length, width),
          )}
        </Column>
      )}
    </Column>
  )
}

// Bit of a hack, do this better
function truncateBasedOnWidth(text: string, width: number): string {
  const numChars = width / 9
  return truncateString(text, numChars)
}
