import * as React from 'react'
import { useState, cloneElement, useMemo } from 'react'
import { useControl } from 'react-map-gl'
import { createPortal } from 'react-dom'
import Draggable from 'react-draggable'
import type { MapboxMap, IControl } from 'react-map-gl'
import { DreamPoint } from '../../../../../api/frontend-types'

class OverlayControl implements IControl {
  _map: MapboxMap | null = null
  _container: HTMLElement
  _redraw: () => void

  constructor(redraw: () => void) {
    this._container = document.createElement('div')
    this._redraw = redraw
  }

  onAdd(map: any): HTMLElement {
    this._map = map
    map.on('move', this._redraw)
    this._container = document.createElement('div')
    this._redraw()
    return this._container as HTMLElement
  }

  onRemove() {
    if (!this._container || !this._map) {
      return
    }
    this._container.remove()
    this._map.off('move', this._redraw)
    this._map = null
  }

  getMap() {
    return this._map
  }

  getElement() {
    return this._container
  }
}

function DreamPointOverlay(props: { children: React.ReactElement }) {
  const [, setVersion] = useState(0)

  const ctrl = useControl<OverlayControl>(() => {
    const forceUpdate = () => setVersion((v) => v + 1)
    return new OverlayControl(forceUpdate)
  })

  const map = ctrl.getMap()

  return (
    map &&
    createPortal(cloneElement(props.children, { map }), ctrl.getElement())
  )
}

function DreamPointSvg({
  map,
  data,
  removeDatum,
  updateCoordinates,
}: {
  map?: MapboxMap
  data: DreamPoint[]
  removeDatum: (datum: DreamPoint) => void
  updateCoordinates: (
    datum: DreamPoint,
    newCoordinates: [number, number],
  ) => void
}) {
  const [hoveredDream, setHoveredDream] = useState<DreamPoint | null>(null)
  if (!map) {
    return null
  }

  const width = map.getContainer().clientWidth
  const height = map.getContainer().clientHeight

  // Create pie chart shapes for each row
  const pies = useMemo(
    () => data.map((d) => makeDreamPointSvg(d, setHoveredDream, removeDatum)),
    [map, data],
  )

  // Position each svg
  const [originLngLat, content] = useMemo(() => {
    /*const scale = 1;
    return [
      map.unproject([0, 0]),
      data.map((d, i) => {
        const centroid = map.project(d.coordinates);
        return (
          <g key={d.title} transform={`translate(${centroid.x},${centroid.y}) scale(${scale})`}>
            {pies[i]}
          </g>
        );
      })
    ];*/
    const scale = 1
    return [
      map.unproject([0, 0]),
      data.map((d, i) => {
        const centroid = map.project([d.longitude, d.latitude])
        return (
          <Draggable
            key={d.id}
            position={{ x: centroid.x, y: centroid.y }}
            onStop={(e, data) => {
              const newCoordinates = map.unproject([data.x, data.y])
              updateCoordinates(d, [newCoordinates.lng, newCoordinates.lat])
            }}
          >
            <g transform={`scale(${scale})`}>{pies[i]}</g>
          </Draggable>
        )
      }),
    ]
  }, [map.getZoom(), pies])
  const origin = map.project(originLngLat)

  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox={`${-origin.x} ${-origin.y} ${width} ${height}`}
      >
        {content}
      </svg>
      {/* <span
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: 0,
          top: 0,
          zIndex: 1,
          color: 'black',
        }}
      >
        {tooltip}
      </span> */}
    </>
  )
}

function makeDreamPointSvg(
  datum: DreamPoint,
  onHover: (target: DreamPoint | null) => void,
  removeDatum: (datum: DreamPoint) => void,
) {
  const fill = datum.dreamId ? '0.2' : '0.5'

  return (
    <g
      pointerEvents="painted"
      style={{
        cursor: 'grab',
      }}
      onMouseMove={() => onHover(datum)}
      onMouseOut={() => onHover(null)}
      onContextMenu={(e) => {
        e.preventDefault()
        removeDatum(datum)
        onHover(null)
      }}
    >
      <defs>
        <clipPath id={`clip-${datum.id}`}>
          <circle r={20} cx={0} cy={0} />
        </clipPath>
      </defs>
      <circle r={20} fill={'rgba(0,0,0,' + fill + ')'} />
      <image
        href={datum.imageUrl || ''}
        width="40"
        height="40"
        x="-20"
        y="-20"
        clipPath={`url(#clip-${datum.id})`}
      />
    </g>
  )
}

export { DreamPointOverlay, DreamPointSvg }

export default React.memo(DreamPointOverlay)
