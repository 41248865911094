import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../ducks/root-reducer'
import { removeQueueItem, selectQueueItems } from '../ducks/queue/queue'
import { useAppState } from '../hooks/useAppState'
import {
  RefetchFunction,
  queueDispatchActions,
  refetchFunctions,
} from '../ducks/queue-actions'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

export function QueueHandler() {
  // HOOKS
  const dispatch = useDispatch<any>()
  const appState = useAppState()

  // SELECTORS
  const queueItems = useSelector(selectQueueItems)

  useEffect(() => {
    if (appState.hasEnteredForeground) {
      console.log('Entered foreground. QueueItems:', queueItems.length)

      queueItems.forEach((item) => {
        const { id, refetchAction, onSuccess, onFailure } = item
        const { functionKey, args } = refetchAction
        const refreshFunc = refetchFunctions[functionKey]
        const successActn = queueDispatchActions[onSuccess.action]
        const failureActn = queueDispatchActions[onFailure.action]

        try {
          // @ts-ignore
          refreshFunc(...args)
            .then((resp: any) => {
              if (!resp || resp.status === 401) {
                console.log('No response to refresh')

                // Wait 5 seconds and try again
                setTimeout(() => {
                  console.log('Trying again')
                  refetchFunc(
                    id,
                    refreshFunc,
                    successActn,
                    failureActn,
                    args,
                    dispatch,
                  )
                }, 5000)

                return
              } else {
                dispatch(successActn(resp))
                dispatch(removeQueueItem(id))
              }
            })
            .catch((err) => {
              // TODO
              console.log('Error refreshing', err)
              dispatch(removeQueueItem(item.id))
            })
        } catch (error) {
          console.log('Error refreshing', error)
        }
      })
    }
  }, [appState])

  return <></>
}

function refetchFunc(
  queueItemId: string,
  refreshFunction: RefetchFunction,
  onSuccessDispatchAction: ActionCreatorWithPayload<any, any>,
  onFailureDispatchAction: ActionCreatorWithPayload<any, any>,
  args: any[],
  dispatch: any,
) {
  // @ts-ignore
  refreshFunction(...args)
    .then((resp: any) => {
      if (!resp || resp.status === 401) {
        dispatch(onFailureDispatchAction)
        dispatch(removeQueueItem(queueItemId))
        return
      } else {
        dispatch(onSuccessDispatchAction(resp))
        dispatch(removeQueueItem(queueItemId))
      }
    })
    .catch((err) => {
      console.log('Error in refetchFunc', err)
      dispatch(onFailureDispatchAction)
      dispatch(removeQueueItem(queueItemId))
    })
}
