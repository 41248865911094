import React from 'react'
import { Toast, useToken, useColorModeValue } from 'native-base'
import { InterfaceToastProps } from 'native-base/lib/typescript/components/composites/Toast'
import { View, Platform, Keyboard } from 'react-native'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { SubscriptionStatusText } from '../../composite/subscriptions/subscription-status-text'
import { ButtonIcon } from '../buttons/button-icon'
import { Autolink } from 'react-native-autolink'
import { alertIconKeys, iconMap } from '../../../modules/ui-helpers/icon-map'
import { AlertStatus } from '../../../../../api/frontend-types'
import { Row } from '../row/row'
import i18n from '../../../i18n/i18nnext'
import { Column } from '../column/column'
import { Box } from '../box/box'
import {
  DARK_GRAY,
  LIGHTER_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import {
  BOTTOM_TAB_BAR_HEIGHT,
  LINE_WIDTH,
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import {
  getColorForStatus,
  getContentWidth,
} from '../../../modules/ui-helpers/ui-helpers'

export type ToastPopupProps = InterfaceToastProps & {
  status?: AlertStatus
  labelComponent?: React.ReactNode
  title?: string
  titleParam?: string // string that's interpolated into the title
  description?: string
  descriptionParam?: string // string that's interpolated into the description
  subscriptionStatus?: any
}

export const ToastPopup = ({
  title,
  description,
  descriptionParam,
  status = 'warning',
  titleParam,
  labelComponent,
  px = 4,
  py = 3,
  mt = 8,
  id,
  duration,
  ...rest
}: ToastPopupProps) => {
  // STATUS
  // Just an extra var for easier testing
  const statusToShow: AlertStatus = status

  // HOOKS
  const { t } = useTranslation()
  const toastBgColor = useColorModeValue(DARK_GRAY, LIGHTER_BLACK)
  const colorFromToken = useToken('colors', getColorForStatus(statusToShow))

  // VARS
  const contentWidth = getContentWidth(Platform.OS)
  const isWeb = Platform.OS === 'web'
  const toastPadding = isWeb ? 0 : PADDING_HORIZONTAL_PIXELS
  const toastWidth = contentWidth - toastPadding * 2
  const hasComponent = labelComponent !== undefined
  const hasTitle = title !== undefined
  const hasDescription = description !== undefined
  const textAlign = i18n.dir() === 'rtl' ? 'right' : 'left'

  return (
    <Row
      backgroundColor={toastBgColor}
      px={px}
      py={py}
      mt={mt}
      width={toastWidth}
      alignItems={'center'}
      maxWidth={WEB_MAX_WIDTH}
      justifyContent={'space-between'}
      height="auto"
      {...rest}
    >
      <ToastIcon status={statusToShow} />
      <Column flexGrow={1} marginX={3}>
        {hasComponent && labelComponent}
        {hasTitle && (
          <SansText color={WHITE} fontWeight="bold" textAlign="center" mb={2}>
            {(title && t(title, { titleParam: titleParam })) || ''}
          </SansText>
        )}
        {duration === null && (
          <ButtonIcon
            iconKey={'close'}
            onPress={() => Toast.close(id)}
            size={'sm'}
            variant={'ghost'}
            unpad={true}
            iconStroke={colorFromToken}
            bgColor={'transparent'}
            style={{
              position: 'absolute',
              top: -3,
              right: 0,
            }}
          />
        )}
        <View>
          {hasDescription && (
            <SansText
              color={WHITE}
              textAlign={textAlign}
              width={toastWidth - 80}
            >
              <Autolink
                email
                text={
                  (description &&
                    t(description, {
                      descriptionParam,
                    })) ||
                  ''
                }
                linkProps={{
                  style: {
                    textDecorationLine: 'underline',
                    color: WHITE,
                  },
                }}
              />
            </SansText>
          )}
        </View>
      </Column>
    </Row>
  )
}

// The icon that appears on the right of the toast
const ToastIcon = ({ status }: { status: AlertStatus }) => {
  // Get the icon for the status
  const iconKey = alertIconKeys[status]
  const Icon = iconMap[iconKey]

  return (
    <Box
      height={8}
      width={8}
      backgroundColor={'transparent'}
      borderRadius={50}
      justifyContent={'center'}
      alignItems={'center'}
      borderColor={WHITE}
      borderWidth={LINE_WIDTH}
    >
      <Icon fill={'transparent'} size={'xs'} color={WHITE} />
    </Box>
  )
}

export const elsewhereToast = {
  showToast: ({
    description,
    descriptionParam,
    duration = 2000,
    title,
    titleParam,
    status = 'info',
    placement = 'bottom',
    id,
  }: ToastPopupProps) => {
    if (!Toast.isActive(id)) {
      Toast.show({
        duration: duration,
        zIndex: 9999,
        placement: placement,
        id: id,
        render: ({ id }) => {
          return (
            <ToastPopup
              title={title}
              titleParam={titleParam}
              description={description}
              descriptionParam={descriptionParam}
              status={status}
              placement={placement}
              id={id}
              duration={duration}
              marginBottom={
                Platform.OS === 'ios' && Keyboard.isVisible()
                  ? Keyboard.metrics()?.height
                  : BOTTOM_TAB_BAR_HEIGHT
              }
            />
          )
        },
      })
    }
  },

  showSubscriptionStatusToast: ({
    duration = 2000,
    subscriptionStatus,
    status = 'info',
    placement = 'top',
  }: ToastPopupProps) => {
    Toast.show({
      duration: duration,
      zIndex: 9999,
      placement: placement,
      render: ({ id }) => {
        return (
          <ToastPopup
            status={status}
            placement={placement}
            labelComponent={
              <SubscriptionStatusText
                tier={subscriptionStatus?.tier || 'free'}
                creditsRemaining={subscriptionStatus?.creditsRemaining || 0}
                itemsRemaining={subscriptionStatus?.imagesRemaining || 0}
                statusToShow="imagesRemaining"
                color={getColorForStatus(status)}
                id={id}
              />
            }
          />
        )
      },
    })
  },
}
