import React from 'react'
import {
  DefaultTheme,
  NavigationContainer as OriginalNavigationContainer,
  NavigationContainerProps,
  DocumentTitleOptions,
  LinkingOptions,
  Theme,
} from '@react-navigation/native'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../constants/ui-constants'

export const NavigationContainer = React.forwardRef(
  (
    props: NavigationContainerProps & {
      theme?: Theme
      linking?: LinkingOptions<any>
      fallback?: React.ReactNode
      documentTitle?: DocumentTitleOptions
      onReady?: () => void
    },
    ref: any,
  ) => {
    const { linking, fallback, documentTitle, onReady, onStateChange } = props
    const bg = useColorModeValue(WHITE, BLACK)
    const dark = useColorModeValue(false, true)

    const navTheme = {
      ...DefaultTheme,
      dark,
      colors: {
        ...DefaultTheme.colors,
        background: bg,
      },
    }

    return (
      <OriginalNavigationContainer
        theme={navTheme}
        linking={linking}
        fallback={fallback}
        documentTitle={documentTitle}
        onReady={onReady}
        ref={ref}
        onStateChange={onStateChange}
      >
        {props.children}
      </OriginalNavigationContainer>
    )
  },
)
