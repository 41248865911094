import React, { FC, ReactNode } from 'react'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Dimensions } from 'react-native'
import { Box } from '../common/box/box'

type ContentAreaProps = InterfaceBoxProps & {
  children?: ReactNode
}

// Full width padded content area
export const PaddedContentArea: FC<ContentAreaProps> = ({
  pt = 4,
  pb = 4,
  pl = 4,
  pr = 4,
  children,
  ...rest
}: ContentAreaProps) => {
  const pageWidth = Dimensions.get('window').width
  const maxWidth = Math.min(pageWidth, WEB_MAX_WIDTH)
  return (
    <Box
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      style={{
        width: '100%',
        marginHorizontal: 'auto',
        maxWidth,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
