import React from 'react'
import { IIconButtonProps } from 'native-base/lib/typescript/components/composites/IconButton/types'
import { IconButton as NbIconButton } from 'native-base'

export type IconButtonProps = IIconButtonProps

// This is a simple wrapper around native-base's IconButton component
export const IconButton = ({ children, ...rest }: IconButtonProps) => {
  return <NbIconButton {...rest}>{children}</NbIconButton>
}
