import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { useTranslation } from 'react-i18next'
import { TextArea } from '../../components/common/inputs/input-textarea'
import { askYourDreams } from '../../ducks/dream-tag/functions/tag-functions'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { refreshSubscriptionStatus } from '../../ducks/subscription/thunks/subscription-thunks'
import { AskYourDreamsHeaderNavigationProp } from './header'
import { elsewhereToast } from '../../components/common/toast/toast'
import { useAuth } from '../../contexts/auth-context'
import { Row } from '../../components/common/row/row'
import { View } from '../../components/common/view/view'

export type AskYourDreamsInputNavigationProp = AskYourDreamsHeaderNavigationProp

type AskYourDreamsInputProps = {
  id: string
  tagName: string
  insights: any
  setInsights: any
  disabled?: boolean
}

export const AskYourDreamsInput = ({
  id,
  tagName,
  insights,
  setInsights,
  disabled,
}: AskYourDreamsInputProps) => {
  // STATE
  const [description, setDescription] = React.useState<string>('')
  const [saving, setSaving] = React.useState<boolean>(false)

  // SELECTORS
  const user = useSelector(selectUser)

  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()
  const dispatch = useDispatch<any>()

  const onSubmit = async () => {
    setSaving(true)
    if (description) {
      await askYourDreams(description, null)
        .then((response) => {
          response && setInsights([response].concat(insights))
          setDescription('')
          // Decrement the number of questions remaining
          dispatch(refreshSubscriptionStatus(false))
        })
        .catch((error) => {
          console.log('Error asking your dreams', error)
          elsewhereToast.showToast({
            description: 'common.errorGeneric',
            status: 'error',
          })
        })
      setSaving(false)
    }
  }

  return (
    <View my={4}>
      <TextArea
        // @ts-ignore
        // role="input"
        // autoFocus={true}
        fieldName="ask-your-dreams-input"
        id={id}
        placeholder={t('askYourDreams.placeholder', {
          tagName,
        })}
        value={description}
        onChangeText={setDescription}
        minRows={2}
        isDisabled={saving}
      />
      <Row space={3} alignItems="center" flexGrow={1} mt={6}>
        <ButtonPill
          id={'askYourDreamsSubmitButton'}
          isDisabled={disabled || !description}
          isLoading={saving}
          onPress={() => {
            onSubmit()
          }}
          width={'100%'}
        >
          {t('askYourDreams.submitButton.label')}
        </ButtonPill>
      </Row>
    </View>
  )
}
