import React from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { AboutSection } from '../../../../api/frontend-types'
import { Dimensions } from 'react-native'
import { Img } from '../../components/common/image/image'
import { AboutList } from '../../components/composite/about-list/about-list'
import { RootStackNavigationProp } from '../../navigation/types'
import { aboutTeam, aboutTranslators } from '../../config/about'
import { ScrollView } from '../../components/common/scrollview/scrollview'

export type AboutElsewhereTeamNavigationProp =
  RootStackNavigationProp<'PrivateAboutElsewhereTeam'>

const flyingImg = require('../../assets/images/flying-transparent.png')

export default function AboutElsewhereTeam() {
  const screenWidth = Dimensions.get('window').width
  const imageWidth = screenWidth * 0.5
  const sections: AboutSection[] = [aboutTeam, aboutTranslators]

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea alignItems={'center'}>
        <Img
          source={flyingImg}
          width={imageWidth}
          height={imageWidth}
          alt={'Black and white illustration of a person flying'}
        />
        <AboutList sections={sections} />
      </PaddedContentArea>
    </ScrollView>
  )
}
