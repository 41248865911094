import React from 'react'
import { Dimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { ButtonPill } from '../common/buttons/button-pill'
import { SansText } from '../common/copy/text-sans'
import { PaddedContentArea } from './content-area-padded'
import { useAuth } from '../../contexts/auth-context'
import { Column } from '../common/column/column'

type FetchFailedPageProps = {}

export const FetchFailedPage = ({}: FetchFailedPageProps) => {
  // HOOKS
  const { t } = useTranslation()
  const { signOut } = useAuth()

  // VARS
  const pageHeight = Dimensions.get('window').height
  const approximateHeaderHeight = 60
  const approximateFooterHeight = 60
  const componentHeight =
    pageHeight - approximateHeaderHeight - approximateFooterHeight

  return (
    <Column
      style={{
        width: '100%',
        height: componentHeight,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PaddedContentArea>
        <SansText textAlign={'center'} mb={8}>
          {t('common.errorGeneric')}
        </SansText>
        <ButtonPill
          onPress={() => {
            signOut()
          }}
        >
          {t('settings.signOut')}
        </ButtonPill>
      </PaddedContentArea>
    </Column>
  )
}
