import React, { Component, PropsWithChildren } from 'react'
import { ViewStyle, TouchableOpacity, StyleProp } from 'react-native'

export type IMultiToggleSwitchItem = {
  active?: boolean
  iconName?: string
  primaryColor?: string
  secondaryColor?: string
  itemContainer?: StyleProp<ViewStyle>
  activeContainerStyle?: StyleProp<ViewStyle>
  onPress: () => void
}

export default class MultiToggleSwitchItem extends Component<
  PropsWithChildren<IMultiToggleSwitchItem>
> {
  constructor(props: PropsWithChildren<IMultiToggleSwitchItem>) {
    super(props)
  }

  render() {
    return (
      <TouchableOpacity
        style={[
          this.props.itemContainer,
          this.props.active === true
            ? this.props.activeContainerStyle
            : undefined,
          this.props.active === true
            ? { backgroundColor: this.props.primaryColor }
            : undefined,
        ]}
        onPress={() => this.props.onPress()}
      >
        {/* @ts-ignore */}
        {React.cloneElement(this.props.children, {
          color: this.props.active
            ? this.props.secondaryColor
            : this.props.primaryColor,
        })}
      </TouchableOpacity>
    )
  }
}
