import React from 'react'
import { Column } from '../../components/common/column/column'
import { dreamServices } from '../../i18n/dream-services'
import { Profile } from '../../components/composite/profile/profile-basic'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import { ScrollView } from '../../components/common/scrollview/scrollview'

export type PrivateDreamServiceViewNavigationProp =
  MainStackNavigationProp<'PrivateDreamServiceView'>

// PrivateDreamServicesView
export default function DreamServiceViewComponent({
  route,
}: MainStackScreenProps<'PrivateDreamServiceView'>) {
  // I18N
  const { serviceId } = route.params

  const service = dreamServices.find(
    (service) => service.serviceId === serviceId,
  )

  if (!service) {
    return null
  } else {
    return (
      <ScrollView>
        <Column>
          <Profile
            title={service.name}
            location={service.location}
            img={{ uri: service.imageUrl }}
            description={service.description}
            email={service.contactEmail}
            website={service.infoUrl}
            deliveryMethods={service.deliveryMethod}
          />
        </Column>
      </ScrollView>
    )
  }
}
