import React from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { SansText } from '../../common/copy/text-sans'
import { useNavigation } from '@react-navigation/native'
import { useColorModeValue } from 'native-base'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { i18nNumber, snakeCase } from '../../../modules/strings/string-helpers'
import { SymbolComponent, SymbolComponentMap } from '../../../assets/symbols'
import { LINE_WIDTH } from '../../../constants/constants'
import { TextBadge } from '../../common/text-badge/text-badge'
import { MainStackNavigationProp } from '../../../navigation/types'
import { PressableFixed } from '../../../utilities/PressableFixed'
import { CountedFastTag } from '../../../../../api/_openapi'
import { HeadingMain } from '../../common/copy/heading-main'
import { Row } from '../../common/row/row'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../ducks/user/user'
import { Box } from '../../common/box/box'
import { Column } from '../../common/column/column'

type SymbolSquareProps = {
  width: number
  symbol: CountedFastTag
  hidden?: boolean
  hiddenText?: string
  showNumUses?: boolean
  clickable?: boolean
  onPress?: () => void
  editable?: boolean
  showTitle?: boolean
  titleTop?: string
  addBorder?: boolean
  onDeleteSymbol?: (tagId: string) => void
  manualTitle?: string
}

export const SymbolSquare = React.memo(
  ({
    width,
    symbol,
    hidden,
    hiddenText,
    showNumUses,
    clickable = true,
    showTitle = true,
    editable,
    titleTop = '',
    addBorder = false,
    onDeleteSymbol,
    onPress,
    manualTitle,
  }: SymbolSquareProps) => {
    // HOOKS
    const { t } = useTranslation()
    const navigation =
      useNavigation<MainStackNavigationProp<'PrivateTagView'>>()

    // SELECTORS
    const user = useSelector(selectUser)

    // COLORS
    const color = useColorModeValue(LIGHT_BLACK, CREAM)
    const backgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)
    const borderColorStrong = useColorModeValue(BLACK, WHITE)

    // VARS
    const symbolText = t(`symbol.${symbol.name}`)
    const symbolWidth = Math.floor((width / 100) * 55) // 55% of the square width
    const userLanguage = user?.languageCode || 'en'

    // ELEMENTS

    // 1) Symbol illustration
    const SymbolComponent: SymbolComponent | null =
      SymbolComponentMap[snakeCase(symbol.name)] || null
    const SymbolElement = SymbolComponent ? (
      <SymbolComponent size={symbolWidth} color={color} />
    ) : null

    // 2) Symbol title
    // React native doesn't support borders on nested text elements (May 2023)
    // And we need this to outline numUses with a circle
    // So we have to split the text by word and wrap each word in a box
    // So we can do a flex-wrap layout
    const symbolTextSplit = symbolText.split(' ')
    let symbolTitle: JSX.Element | null = null
    if (showTitle) {
      symbolTitle = hidden ? (
        <Box>
          <SansText fontSize={'sm'}>?</SansText>
        </Box>
      ) : (
        <Box>
          {symbolTextSplit.map((word, i) => {
            return (
              <Box key={i} style={styles.wordBox}>
                <SansText fontSize={'sm'}>{manualTitle || word}</SansText>
              </Box>
            )
          })}
        </Box>
      )
    }

    return (
      <PressableFixed
        onPress={
          editable || !clickable
            ? undefined
            : () => {
                if (!clickable) {
                  console.log('TODO: symbols in groups not implemented yet')
                  return undefined
                } else if (hidden) {
                  return
                } else {
                  if (onPress) {
                    onPress()
                  } else {
                    navigation.push('PrivateTagView', {
                      id: symbol.id,
                    })
                  }
                }
              }
        }
        _web={{
          // @ts-ignore
          cursor: editable || !clickable ? 'default' : 'pointer',
        }}
      >
        <Column
          style={{
            ...styles.square,
            width: width,
            height: width,
            backgroundColor: backgroundColor,
            borderColor: addBorder ? borderColorStrong : undefined,
            borderWidth: addBorder ? LINE_WIDTH : undefined,
          }}
        >
          {/* CROSS ICON TO DELETE SYMBOL TAG */}
          {editable && (
            <Row
              justifyContent={'flex-end'}
              style={{
                width: '100%',
                position: 'absolute',
                top: 0,
              }}
            >
              <ButtonIcon
                iconKey="close"
                variant={'ghost'}
                size={'2xs'}
                bgColor={'transparent'}
                onPress={() => {
                  if (onDeleteSymbol) {
                    onDeleteSymbol(symbol.id)
                  }
                }}
              />
            </Row>
          )}
          {titleTop ? (
            <Box style={styles.symbolLabel} mt={2} mb={-4}>
              {titleTop}
            </Box>
          ) : null}
          {hidden || !symbol.imageUrl ? (
            <>
              <Box
                height={`${symbolWidth}px`}
                width={`${symbolWidth}px`}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <HeadingMain mt={4}>{hiddenText}</HeadingMain>
              </Box>
            </>
          ) : (
            <>{SymbolElement}</>
          )}

          <Box style={styles.symbolLabel} mt={2}>
            {symbolTitle}

            {!hidden && Boolean(showNumUses) && (
              <TextBadge label={i18nNumber(symbol.count || 0, userLanguage)} />
            )}
          </Box>
        </Column>
      </PressableFixed>
    )
  },
)

const styles = StyleSheet.create({
  square: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  symbolLabel: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wordBox: {
    marginRight: 4,
  },
})
