import React from 'react'
import { useColorModeValue, useDisclose } from 'native-base'
import {
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
  SANS_BOOK_FONT_WEIGHT,
} from '../../../constants/constants'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../../constants/ui-constants'
import { useTranslation } from 'react-i18next'
import { Link } from '../../common/link/link'
import { Platform } from 'react-native'

export function renderManageSubscriptions(
  currentPlatform: string,
  purchasedFrom: string,
  feature: string,
  subscriptionTier: string,
  subscriptionInfo: any,
) {
  const { t } = useTranslation()
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )
  const PURCHASED_FROM = purchasedFrom && purchasedFrom.toUpperCase()

  const purchasedFromAndroid = PURCHASED_FROM === 'PLAY_STORE'
  const purchasedFromIOS = PURCHASED_FROM === 'APP_STORE'
  const purchasedFromWeb = PURCHASED_FROM === 'RC_BILLING'

  const getPurchasePlatformURL = () => {
    if (purchasedFromWeb) {
      return subscriptionInfo?.managementURL
    } else if (purchasedFromAndroid) {
      return `https://play.google.com/store/account/subscriptions?package=to.elsewhere`
    } else if (purchasedFromIOS) {
      return 'https://apps.apple.com/account/subscriptions'
    }
  }

  const getPurchasePlatformFriendly = () => {
    if (purchasedFromWeb) {
      return t('common.web')
    } else if (purchasedFromAndroid) {
      return 'Google Play'
    } else if (purchasedFromIOS) {
      return 'App Store'
    }
  }

  const isOnPurchasePlatform =
    (purchasedFromAndroid && currentPlatform === 'android') ||
    (purchasedFromIOS && currentPlatform === 'ios') ||
    (purchasedFromWeb && currentPlatform === 'web')

  const { isOpen, onOpen, onClose } = useDisclose()

  const shouldShowLink =
    feature === subscriptionTier && subscriptionTier !== 'free'

  return (
    shouldShowLink && (
      <>
        <Link
          mt={4}
          href={
            isOnPurchasePlatform && getPurchasePlatformURL()
              ? getPurchasePlatformURL()
              : undefined
          }
          onPress={
            !isOnPurchasePlatform || !getPurchasePlatformURL()
              ? onOpen
              : undefined
          }
          isExternal
          _text={{
            fontFamily: 'sans',
            fontWeight: SANS_BOOK_FONT_WEIGHT,
            fontSize: MEDIUM_FONT_SIZE,
            lineHeight: MD_LINE_HEIGHT,
            color: mutedColor,
          }}
          // @ts-ignore
          style={{
            ...Platform.select({
              web: {
                cursor: 'pointer',
              },
            }),
          }}
        >
          {t('subscriptions.cta.manageSubscription')}
        </Link>

        <AlertPopup
          header={t('subscriptions.manage.unavailable.title')}
          description={t('subscriptions.manage.unavailable.description', {
            platform: getPurchasePlatformFriendly(),
          })}
          hideConfirm={true}
          cancelLabel={t('common.close')}
          isOpen={isOpen}
          onClose={onClose}
        />
      </>
    )
  )
}
