import 'react-native-gesture-handler'
import '../../components/src/assets/fonts/index.css'
import React from 'react'
import { App } from '@elsewhere/components/src/App'
import appJson from '../app.json'
import { createRoot } from 'react-dom/client'
import GlobalStyle from './theme/globalStyles'
// Added to make the menu work -  https://github.com/prisma/prisma/issues/8558#issuecomment-1102176746
global.setImmediate =
  global.setImmediate ||
  ((fn: any, ...args: any) => global.setTimeout(fn, 0, ...args))

const { AppRegistry } = require('react-native-web')

AppRegistry.registerComponent(appJson.name, () => App)
const root = createRoot(document.getElementById('root')!)
root.render(
  <>
    <GlobalStyle />
    <App />
  </>,
)
