import React from 'react'
import {
  NativeBaseProvider as OriginalNativeBaseProvider,
  extendTheme,
  NativeBaseProviderProps,
  ColorMode,
  StorageManager,
} from 'native-base'
import { theme } from '../../core/theme'
import { Appearance } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from '../platform'

const config = {
  dependencies: {
    'linear-gradient': require('react-native-linear-gradient').default,
  },
}

const colorModeManager: StorageManager = {
  get: async () => {
    try {
      let val = (await AsyncStorage.getItem('@ColorMode')) || 'auto'
      let userDevicePref = await Appearance.getColorScheme()
      const value =
        val === 'auto'
          ? userDevicePref || 'light'
          : ((val || 'light') as ColorMode)
      if (Platform.OS === 'web') {
        document.body.className = value || 'auto'
      }
      return value
    } catch (e) {
      console.log('error getting colorMode', e)
      return 'light'
    }
  },
  set: async (value: ColorMode | 'auto') => {
    let strValue = (value || 'auto') as ColorMode | 'auto'
    try {
      await AsyncStorage.setItem('@ColorMode', strValue || 'auto')
      if (Platform.OS === 'web') {
        document.body.className = strValue || 'auto'
      }
    } catch (e) {
      console.log('error setting colorMode', e)
    }
  },
}

export function NativeBaseProvider(props: NativeBaseProviderProps) {
  return (
    <OriginalNativeBaseProvider
      colorModeManager={colorModeManager}
      config={config}
      theme={extendTheme(theme)}
    >
      {props.children}
    </OriginalNativeBaseProvider>
  )
}
