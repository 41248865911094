import React, { ReactNode } from 'react'
import { ButtonIcon } from '../buttons/button-icon'
import { AlertStatus } from '../../../../../api/frontend-types'
import { getColorForStatus } from '../../../modules/ui-helpers/ui-helpers'
import { SansText } from '../copy/text-sans'
import { LINE_WIDTH } from '../../../constants/constants'
import { Row } from '../row/row'
import { Column } from '../column/column'
import { Box } from '../box/box'
import { Alert, IAlertProps } from '../nb-alert/nb-alert'

type ElsewhereAlertProps = IAlertProps & {
  onDismiss?: () => void
  title?: string
  bodyText: string | ReactNode
}

export const ElsewhereAlert = React.memo(
  ({ onDismiss, title, bodyText, ...props }: ElsewhereAlertProps) => {
    const hasTitle = Boolean(title)
    const hasOnDismiss = Boolean(onDismiss)
    const hasHeaderRow = hasTitle || hasOnDismiss
    const status = (props.status || 'info') as AlertStatus
    const color = getColorForStatus(status, true)
    return (
      <Alert
        {...props}
        variant="outline"
        style={{
          backgroundColor: 'transparent',
          borderWidth: LINE_WIDTH,
        }}
      >
        <Column width={'100%'} alignItems={'center'} justifyContent={'center'}>
          {hasHeaderRow && (
            <Row
              alignItems={'center'}
              justifyContent={hasOnDismiss ? 'space-between' : 'center'}
              width={'100%'}
            >
              {/* Empty content the same size as the 'X' button, because I can't flexbox? */}
              {hasOnDismiss && <Box width={10} height={10} />}
              {/* Title of the alert */}
              {hasTitle && (
                <SansText fontWeight={500} color={color} textAlign={'center'}>
                  {title}
                </SansText>
              )}
              {/* 'X' button */}
              {hasOnDismiss && (
                <ButtonIcon
                  iconKey={'close'}
                  onPress={onDismiss}
                  size={'sm'}
                  variant={'ghost'}
                  unpad={true}
                  iconStroke={color}
                  bgColor={'transparent'}
                />
              )}
            </Row>
          )}
          {/* Body of the alert */}
          <SansText mt={2} width={'100%'} color={color} textAlign={'center'}>
            {bodyText}
          </SansText>
        </Column>
      </Alert>
    )
  },
)
