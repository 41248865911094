import React from 'react'
import { IViewProps } from 'native-base/lib/typescript/components/primitives/View/types'
import { View as NbView } from 'native-base'

export type ViewProps = IViewProps

// This is a simple wrapper around native-base's View component
export const View = ({ children, ...rest }: ViewProps) => {
  return <NbView {...rest}>{children}</NbView>
}
