import React from 'react'
import { TagState } from './tag-pill'
import { useState } from 'react'
import { useColorModeValue } from 'native-base'
import { colorMap } from '../../../modules/color-definitions/colorList'
import { TagWrapper } from './tag-wrapper'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { FastTag } from '../../../../../api/_openapi'
import { Box } from '../../common/box/box'

type ColorTagProps = {
  tag: FastTag
  tagState: TagState
  onDeleteTag: () => void
}

// Color tag is a circular color swatch
// When clicked, it animates to a pill
// with an 'X' to delete the tag on the right
// And the color swatch on the left
// Like (o) => (o X)
export const ColorTag = ({ tag, tagState, onDeleteTag }: ColorTagProps) => {
  // STATE
  const [editingState, setEditingState] = useState<TagState>(tagState)

  // HOOKS
  const invertedColor = useColorModeValue(WHITE, BLACK)

  // VARS
  const isEditing = editingState === 'editing'
  const { color } = extractColorTagParts(tag)
  const colorValue = colorMap[color.toLocaleLowerCase()]
  const hslArray = colorValue ? colorValue.hsl : [0, 0, 0]
  const hsl = hslArrayToString(hslArray)

  return (
    <TagWrapper
      pb={1}
      pt={1}
      pl={1}
      pr={1}
      tagState={editingState}
      onEditableTagPress={() => {
        setEditingState('editing')
      }}
    >
      <Box
        style={{
          backgroundColor: hsl,
          borderRadius: 100,
        }}
        w={9}
        h={9}
      />
      {/* Delete button */}
      {isEditing && (
        <ButtonIcon
          iconKey="close"
          size={'xs'}
          p={0}
          borderRadius={'full'}
          onPress={onDeleteTag}
          color={invertedColor}
          variant={'ghost'}
        />
      )}
    </TagWrapper>
  )
}

// Color tags are currently in the form "<color> (<parent color>) <hex>"
// We want to extract these parts
// And then use the color to render the tag
function extractColorTagParts(tag: FastTag) {
  const [color, _rest] = tag.name.split(' (')
  return { color }
}

function hslArrayToString(hsl: number[]) {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
}
