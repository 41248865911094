import React, { useEffect } from 'react'
import { useSelector } from '../../../ducks/root-reducer'
import { DreamFeedList } from '../../../components/composite/feed/feed-list-dream'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { syncGroupDreams } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { selectUser } from '../../../ducks/user/user'
import { MainStackScreenProps } from '../../../navigation/types'
import {
  selectDiaryEntries,
  selectLastSyncTime,
} from '../../../ducks/dream-tag/dream-tag'

export default function PrivateDiaryFeed({}: MainStackScreenProps<'PrivateDiaryFeed'>) {
  // HOOKS

  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const diaryEntries = useSelector(selectDiaryEntries)

  // SELECTORS
  const user = useSelector(selectUser)
  const lastSyncTime = useSelector(selectLastSyncTime)

  const onRefreshUserDreams = () => {
    if (!user || !user.userGroupId) return
    const time = lastSyncTime ? new Date(lastSyncTime) : new Date('1900-01-01')
    dispatch(syncGroupDreams(user.userGroupId, time.toISOString()))
  }

  useEffect(() => {
    onRefreshUserDreams()
  }, [user?.userGroupId])

  if (!user) return null

  return (
    <DreamFeedList
      entries={diaryEntries}
      refresh={onRefreshUserDreams}
      noEntriesMessage={t('No diary entries')}
      simpleNoEntriesPage={true}
      appUser={user}
    />
  )
}
