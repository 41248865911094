import React, { useMemo, useState } from 'react'
import { useColorModeValue } from 'native-base'
import { ButtonPill } from '../../common/buttons/button-pill'
import { PaddedContentArea } from '../../layout/content-area-padded'
import { useNavigation } from '@react-navigation/native'
import { NoContentMessage } from '../../layout/no-content-message'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { useSelector } from '../../../ducks/root-reducer'
import { idEquals } from '../../../ducks/helpers'
import { BodyText } from '../../common/copy/text-body'
import { DialogGroupCode } from '../../common/dialog/dialog-group-code'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { InfoText } from '../../common/copy/text-info'
import { SansText } from '../../common/copy/text-sans'
import Autolink from 'react-native-autolink'
import { useRoute } from '@react-navigation/core'
import { isRootStackNav } from '../../../navigation/types'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'
import { DreamFeedListNavigationProp } from './feed-list-dream'
import { DreamerViewNavigationProp } from '../../../screens/Dreamer/DreamerView'
import { TagViewNavigationProp } from '../../../screens/TagView'
import { useAuth } from '../../../contexts/auth-context'
import { HeadingMain } from '../../common/copy/heading-main'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { Center } from '../../common/center/center'
import { Row } from '../../common/row/row'
import { Column } from '../../common/column/column'
import {
  selectPrivateGroupId,
  selectUserMembershipInGroup,
} from '../../../ducks/groups/groups'

export type EmptyFeedPageNavigationProp =
  | DreamFeedListNavigationProp
  | DreamerViewNavigationProp
  | TagViewNavigationProp

type EmptyFeedPageProps = {
  noDreamsText?: string
  hideExtraContent?: boolean
  h?: string
}

export const EmptyFeedPage = ({
  noDreamsText,
  hideExtraContent = false,
  h = '90%',
}: EmptyFeedPageProps = {}) => {
  const [codeDialogOpen, setCodeDialogOpen] = useState<boolean>(false)

  const navigation = useNavigation<EmptyFeedPageNavigationProp>()
  const color = useColorModeValue(BLACK, WHITE)

  const { authData } = useAuth()
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = useActiveGroupId()
  const { t } = useTranslation()

  // VARS
  const noDreamsLabel = noDreamsText || t('common.welcomeToElsewhere')

  const isPrivateGroup = useMemo(() => {
    return idEquals(activeGroupId, privateGroupId)
  }, [activeGroupId, privateGroupId])
  const membershipInfo = useSelector(
    selectUserMembershipInGroup(authData?.user, activeGroupId),
  )
  const userIsGroupOwner = membershipInfo && membershipInfo.role === 'owner'

  const shouldShowButton =
    Boolean(isPrivateGroup || userIsGroupOwner) && !hideExtraContent

  const { onPress: onPressLogin, ...restLogin } = useLinkProps({
    to: { screen: 'Login' },
  })

  const { onPress: onPressAbout, ...restAbout } = useLinkProps({
    to: { screen: 'About' },
  })

  return (
    <>
      <PaddedContentArea h={h}>
        <Center h="100%">
          <Column maxWidth={WEB_MAX_WIDTH} p={3}>
            <NoContentMessage message="" />
            <HeadingMain mb={10} pb={4}>
              {!authData?.token
                ? t('common.exclusive')
                : isPrivateGroup
                ? noDreamsLabel
                : t('emptyGroupPage.title')}
            </HeadingMain>
            {!authData?.token ? (
              <PaddedContentArea
                flexDirection={'column'}
                justifyContent={'flex-end'}
                style={{
                  flex: 1,
                }}
              >
                <BodyText
                  alignSelf="center"
                  mt={-10}
                  mb={10}
                  textAlign={'center'}
                >
                  {t('common.signUpOrLogin')}
                </BodyText>
                <ButtonPill
                  type={'primary'}
                  onPress={() => {
                    isRootStackNav(navigation) && navigation.navigate('Signup')
                  }}
                >
                  {t('common.signUp')}
                </ButtonPill>
                <Row justifyContent={'center'} mt={5}>
                  <InfoText>{t('startPage.haveAccount')} </InfoText>
                  <SansText color={color}>
                    <Autolink
                      text={t('common.login')}
                      linkStyle={{ textDecorationLine: 'underline' }}
                      matchers={[
                        {
                          pattern: RegExp(t('common.login'), 'g'),
                          onPress: () => onPressLogin(),
                        },
                      ]}
                      linkProps={{
                        style: {
                          textDecorationLine: 'underline',
                          color,
                        },
                        onPress: onPressLogin,
                        ...restLogin,
                      }}
                    />
                  </SansText>
                </Row>
              </PaddedContentArea>
            ) : isPrivateGroup ? (
              !hideExtraContent && (
                <BodyText
                  color={color}
                  textAlign={'center'}
                  mb={10}
                  alignSelf="center"
                >
                  <Autolink
                    text={t('common.aPlaceForYourDreams')}
                    linkStyle={{ textDecorationLine: 'underline' }}
                    matchers={[
                      {
                        pattern: RegExp(t('common.aPlaceForYourDreams'), 'g'),
                        onPress: () => onPressAbout(),
                      },
                    ]}
                    linkProps={{
                      style: {
                        textDecorationLine: 'underline',
                        color,
                      },
                      onPress: onPressAbout,
                      ...restAbout,
                    }}
                  />
                </BodyText>
              )
            ) : (
              !hideExtraContent && (
                <BodyText alignSelf="center" mb={10} textAlign={'center'}>
                  {t('emptyGroupPage.description')}
                </BodyText>
              )
            )}
          </Column>
          {shouldShowButton && (
            <ButtonPill
              onPress={() => {
                if (isPrivateGroup) {
                  navigation.push('PrivateDreamAdd')
                } else {
                  setCodeDialogOpen(true)
                }
              }}
              mt={4}
              width="100%"
            >
              {isPrivateGroup
                ? t('common.addADream')
                : t('common.getInviteCode')}
            </ButtonPill>
          )}
        </Center>
      </PaddedContentArea>
      <DialogGroupCode
        isOpen={codeDialogOpen}
        groupId={activeGroupId || ''}
        onClose={() => setCodeDialogOpen(false)}
      />
    </>
  )
}
