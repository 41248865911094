import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectWithActionSheet } from './select-with-action-sheet'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import { FormFieldNavigationProp, Value } from '../../form/form-field'
import { countryCodes } from '../../../../modules/select-options/country-codes'
import { selectUser } from '../../../../ducks/user/user'
import { useSelector } from '../../../../ducks/root-reducer'
import { getDeviceLanguageCode } from '../../../../modules/language-helpers/language-helpers'

export type CountrySelectNavigationProp = FormFieldNavigationProp

type CountrySelectProps = {
  value: string | undefined
  onChange: (value: Value) => void
  placeholder?: string
}

export const CountrySelect = React.memo(
  ({ value, onChange, placeholder }: CountrySelectProps) => {
    // HOOKS
    const { t } = useTranslation()
    const user = useSelector(selectUser)

    // Get current language
    const deviceLanguage = getDeviceLanguageCode()
    // Prefer the user's language, if available
    const languageCode = user?.languageCode || deviceLanguage

    const countries = countryCodes.map((country) => {
      return {
        label: `${country.flag}  ${
          country.name[languageCode] || country.name.en
        }`,
        sortLabel: country.name[languageCode] || country.name.en,
        value: country.code,
      }
    })

    // Add empty option
    countries.unshift({
      label: t('Prefer not to answer'),
      sortLabel: '',
      value: '',
    })

    return (
      <SelectWithActionSheet
        options={countries}
        placeholder={(placeholder && t(placeholder)) || ''}
        onChange={(option: FormFieldOption) => {
          onChange(option.value)
        }}
        value={value}
        maintainSortOrder={true}
      />
    )
  },
)
