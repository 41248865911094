/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastUser } from '../models/FastUser';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastUserService {
  /**
   * Retrieves the collection of FastUser resources.
   * Retrieves the collection of FastUser resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastUser FastUser collection
   * @throws ApiError
   */
  public static fastUsers(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-users',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Retrieves a FastUser resource.
   * Retrieves a FastUser resource.
   * @param id FastUser identifier
   * @returns FastUser FastUser resource
   * @throws ApiError
   */
  public static fastUser(
    id: string,
  ): CancelablePromise<FastUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-users/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the FastUser resource.
   * Replaces the FastUser resource.
   * @param id FastUser identifier
   * @param requestBody The updated FastUser resource
   * @returns FastUser FastUser resource updated
   * @throws ApiError
   */
  public static fastUserUpdate(
    id: string,
    requestBody: Partial<FastUser>,
  ): CancelablePromise<FastUser> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/fast-users/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastUser resource.
   * Removes the FastUser resource.
   * @param id FastUser identifier
   * @returns void
   * @throws ApiError
   */
  public static fastUserDelete(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/fast-users/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the FastUser resource.
   * Replaces the FastUser resource.
   * @param id FastUser identifier
   * @param requestBody The updated FastUser resource
   * @returns FastUser FastUser resource updated
   * @throws ApiError
   */
  public static updateUserSubscription(
    id: string,
    requestBody: Partial<FastUser>,
  ): CancelablePromise<FastUser> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/users/{id}/subscription',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
}
