import React from 'react'
import {
  ScrollView as RNScrollView,
  FlatList as RNFlatList,
  View,
} from 'react-native'

const Tabs = ({ children }: any) => {
  let subComponentList = Object.keys(Tabs)

  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) =>
      child.type.name === key ? child : null,
    )
  })

  return <>{subComponents.map((component) => component)}</>
}

const FlatList: any = React.memo((props: any) => {
  return (
    <View style={{ flex: 1 }}>
      <RNFlatList style={{ flex: 1 }} {...props} />
    </View>
  )
})

Tabs.ScrollView = RNScrollView
Tabs.FlatList = FlatList

export { Tabs }
