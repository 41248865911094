import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import {
  fetchTagById,
  fetchTagDescriptionById,
} from '../../ducks/dream-tag/functions/tag-functions'
import { FastTagDescription, FastUser } from '../../../../api/_openapi'
import { useTranslation } from 'react-i18next'
import {
  MainStackScreenProps,
  RootStackParamList,
} from '../../navigation/types'
import { FeedEntryGeneric } from '../../components/composite/feed/feed-entry-generic'
import { LoadingPage } from '../../components/layout/loading-page'
import { fetchFastUserById } from '../../ducks/user/functions/user-functions'
import {
  GlobalUserInfo,
  PublicUserKey,
  SupportedLanguage,
} from '../../../../api/frontend-types'
import {
  PUBLIC_USER_INFO,
  moonTransparentImg,
} from '../../constants/ui-constants'
import { useLinkProps } from '../../utilities/useLinkPropsFixed'
import i18n from '../../i18n/i18nnext'

export type PageProps = { date?: string; title?: string }

export default function SymbolInterpretationView({
  navigation,
  route,
}: MainStackScreenProps<'PrivateSymbolInterpretationView'>) {
  // STATE
  const [loading, setLoading] = useState<boolean>(true)
  const [title, setTitle] = useState<string>('')
  const [tagDescription, setTagDescription] =
    useState<FastTagDescription | null>(null)
  const [authorInfo, setAuthorInfo] = useState<GlobalUserInfo | null>(null)
  const [author, setAuthor] = useState<FastUser | null>(null)

  // HOOKS
  const { t } = useTranslation()

  // ROUTE
  const { id } = route.params

  // VARS
  const authorName = t(authorInfo?.i18nKey || '')
  const userLanguage = i18n.resolvedLanguage || 'en'

  useEffect(() => {
    fetchTagDescriptionById(id)
      .then((tagDescriptionRes: FastTagDescription | null) => {
        if (tagDescriptionRes) {
          setTagDescription(tagDescriptionRes)
        }
        return tagDescriptionRes
      })
      .then((tagDescriptionRes: FastTagDescription | null) => {
        if (tagDescriptionRes && tagDescriptionRes.tagId) {
          // Fetch tag (for title)
          fetchTagById(tagDescriptionRes.tagId)
            .then((tag) => {
              if (tag) {
                setTitle(t(`symbol.${tag?.name}`))
              }
            })
            .then(() => setLoading(false))

          // Fetch author (for user info 'row')
          fetchFastUserById(tagDescriptionRes.authorId as string).then(
            (user) => {
              if (user) {
                setAuthor(user)
                const globalUser: GlobalUserInfo | undefined =
                  PUBLIC_USER_INFO[user.username as PublicUserKey]
                if (globalUser) {
                  setAuthorInfo(globalUser)
                } else {
                  setAuthorInfo({
                    img: moonTransparentImg,
                    i18nKey: 'common.errorGeneric',
                  })
                }
              }
            },
          )
        } else {
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }, [id])

  const userInfoLinkObject = {
    screen: 'PrivateDreamerView' as keyof RootStackParamList,
    params: {
      groupId: undefined,
      userId: author?.id,
    },
  }

  const userInfoLinkProps = {
    to: userInfoLinkObject,
  }

  // @ts-ignore
  const { onPress: avatarOnPress } = useLinkProps(userInfoLinkProps)

  if (loading) {
    return <LoadingPage />
  } else {
    return (
      <ScrollView>
        {tagDescription && authorInfo && (
          <FeedEntryGeneric
            inGroup={false}
            language="en" // Not translated yet
            avatarLabelLanguage={userLanguage as SupportedLanguage} // Symbol dictionary author names are translated
            avatarLabel={authorName}
            hasTopDivider={false}
            title={title}
            description={tagDescription.description || ''}
            linkObject={null}
            avatarCircleSrc={authorInfo.img}
            truncate={false}
            authorImgLoading={!authorInfo}
            userInfoLinkObject={userInfoLinkObject}
            avatarOnPress={avatarOnPress}
          />
        )}
      </ScrollView>
    )
  }
}
