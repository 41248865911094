import React from 'react'
import { Platform } from 'react-native'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { BodyText } from '../../components/common/copy/text-body'
import { AboutSection } from '../../../../api/frontend-types'
import { Logotype } from '../../assets/icons/Logotype'
import { BulletList } from '../../components/common/copy/bullet-list'
import { Img } from '../../components/common/image/image'
import { Dimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { AboutList } from '../../components/composite/about-list/about-list'
import { useAuth } from '../../contexts/auth-context'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Row } from '../../components/common/row/row'
import { fireImg, flyingImg, moonImg } from '../../constants/ui-constants'
import { Center } from '../../components/common/center/center'
import {
  aboutContactDetails,
  aboutLegal,
  aboutTeam,
  aboutTranslators,
  aboutVersion,
} from '../../config/about'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { Link } from '../../components/common/link/link'
import { Text } from '../../components/common/copy/text'

export default function About() {
  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()

  // VARS
  const screenWidth = Dimensions.get('window').width
  const imageWidth = screenWidth * 0.5

  const sections: AboutSection[] = [
    aboutTeam,
    aboutContactDetails,
    aboutVersion,
    aboutLegal,
    aboutTranslators,
  ]

  return (
    <ScrollView
      contentContainerStyle={{
        width: '100%',
        maxWidth: WEB_MAX_WIDTH,
        marginHorizontal: 'auto',
      }}
    >
      {Platform.OS === 'web' && !Boolean(authData?.token) && (
        <Center m={5}>
          <Link href="/">
            <Logotype width={205} height={25} />
          </Link>
        </Center>
      )}
      <PaddedContentArea alignItems={'center'}>
        {Platform.OS !== 'web' ? (
          <>
            <Img
              source={moonImg}
              width={imageWidth}
              height={imageWidth}
              alt={'Black and white illustration of a moon'}
            />
            <Row justifyContent={'center'} mb={8} mt={6}>
              <Logotype width={170} height={25} />
            </Row>
          </>
        ) : (
          <>
            <Img
              source={moonImg}
              width={imageWidth}
              height={imageWidth}
              alt={'Black and white illustration of a moon'}
            />
            <HeadingMain mb={8} mt={8}>
              {t('common.aboutElsewhere')}
            </HeadingMain>
          </>
        )}

        <BodyText>
          {[
            'aboutElsewherePage.description.paragraph1',
            'aboutElsewherePage.description.paragraph2',
            'aboutElsewherePage.description.paragraph3',
          ].map((key, i) => (
            <Text key={key}>
              {t(key)}
              {i < 2 && `\n\n`}
            </Text>
          ))}
        </BodyText>

        <BulletList
          items={[
            t(`aboutElsewherePage.features1`),
            t(`aboutElsewherePage.features2`),
            t(`aboutElsewherePage.features3`),
            t(`aboutElsewherePage.features4`),
          ]}
        />
        <Img
          source={flyingImg}
          width={imageWidth}
          height={imageWidth}
          alt={'Girl flying with a coat on'}
        />
        <HeadingSmall>{t('aboutElsewhere.header.otherFeatures')}</HeadingSmall>
        <BulletList
          mt={0}
          items={[
            t(`aboutElsewherePage.features5`),
            t(`aboutElsewherePage.features6`),
            t(`aboutElsewherePage.features7`),
            t(`aboutElsewherePage.features8`),
          ]}
        />
        <HeadingSmall>
          {t('aboutElsewhere.header.elsewherePrinciples')}
        </HeadingSmall>
        <BodyText>
          {[...new Array(4)].map((_, i) => {
            const key = `aboutElsewherePage.elsewherePrinciples${i + 1}`
            return (
              <Text key={key}>
                <Text fontFamily="body" fontWeight={400} fontStyle={'italic'}>
                  {t(key + '.title')}:{' '}
                </Text>
                <Text>
                  {t(key + '.description')}
                  {`\n\n`}
                </Text>
              </Text>
            )
          })}
        </BodyText>
        <Img
          source={fireImg}
          width={imageWidth}
          height={imageWidth}
          alt={'Black and white illustration of a fire'}
        />
        <HeadingSmall>
          {t('aboutElsewhere.header.elsewherePhilosophy')}
        </HeadingSmall>
        <BodyText>{t('aboutElsewhere.whyElsewhereNow')}</BodyText>
        <HeadingSmall>
          {t('aboutElsewhere.header.elsewhereDisclaimer')}
        </HeadingSmall>
        <BodyText>{t('aboutPage.disclaimer')}</BodyText>
        <HeadingSmall>{t('aboutElsewhere.header.whoWeAre')}</HeadingSmall>
        <BodyText>{t('aboutPage.whoWeAre')}</BodyText>
        {/* Team etc */}
        <AboutList sections={sections} />
      </PaddedContentArea>
    </ScrollView>
  )
}
