import {
  FastGroup,
  FastGroupService,
  FastMembershipService,
  OpenAPI,
} from '../../../../../api/_openapi'
import { undecorateId } from '../../../../../api/decorate-ids'
import { elsewhereToast } from '../../../components/common/toast/toast'
import axios from 'axios'

// Add a new group to the DB
export async function createGroupInDb(
  group: Partial<FastGroup>,
): Promise<FastGroup | null> {
  const response = await FastGroupService.createGroup(group).catch(
    (err: any) => {
      console.error('Error creating group', err)
      elsewhereToast.showToast({
        title: 'toast.addGroupError.title',
        description: err.message,
        status: 'error',
      })
    },
  )
  if (response) {
    elsewhereToast.showToast({
      description: 'toast.groupCreated.description',
      status: 'success',
    })
    return response
  } else {
    return null
  }
}

export async function fetchGroupById(id: string): Promise<FastGroup> {
  return FastGroupService.fastGroup(undecorateId(id))
}

// Fetch invite info from DB
export async function fetchInviteInfoByCode(
  inviteCode: string | undefined,
): Promise<FastGroup | null> {
  if (!inviteCode) {
    console.log('Error fetching invite info: no inviteCode')
    return null
  }
  const info = await FastGroupService.getGroupInviteCode(inviteCode).catch(
    (err: any) => {
      console.log('Error getting group', inviteCode, err)
    },
  )
  return info || null
}

export async function fetchUserGroups(): Promise<FastGroup[]> {
  return FastGroupService.fastGroups()
}

// Delete a membership
export async function deleteMembership(membershipId: string): Promise<void> {
  await FastMembershipService.deleteMembership(membershipId).catch(
    (err: any) => {
      console.log('Error deleting membership', membershipId, err)
    },
  )
}

// Delete a group
export async function deleteGroup(groupId: string): Promise<void> {
  await FastGroupService.deleteGroup(groupId).catch((err: any) => {
    console.log('Error deleting group', groupId, err)
  })
}

// Get an invite code for a group
// /api/groups/{id}/invite-code
// There's no OpenAPI spec for this endpoint, so we have to use axios
type InviteCodeResponse = {
  inviteCode: string
}
export async function getInviteCode(
  id: string,
  token: string,
): Promise<InviteCodeResponse | null> {
  const route = `${OpenAPI.BASE}/api/groups/${id}/invite-code`

  const response = await axios({
    method: 'post',
    url: route,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).catch((err: any) => {
    console.log('Error getting invite code', id, err?.data?.message)

    elsewhereToast.showToast({
      title: 'toast.getInviteCodeError.title',
      description: err.message,
      status: 'error',
    })
  })
  if (response) {
    return response.data
  } else {
    console.log('Error getting invite code', id)
    return null
  }
}
