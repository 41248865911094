export type DeviceLocation = {
  latitude: number
  /**
   * The longitude, in degrees
   */
  longitude: number
  /**
   * The altitude if available, in meters above the WGS 84 reference ellipsoid
   */
  altitude: number
  /**
   * The estimated horizontal accuracy of this location, radial, in meters
   */
  accuracy: number
  /**
   * The speed if it is available, in meters/second over ground
   */
  speed: any
  /**
   * The UTC time of this fix, in milliseconds since January 1, 1970.
   */
  time: number
  /**
   * (Android only) The bearing, in degrees
   */
  bearing?: number
  /**
   * (Android only) The name of the provider that generated this fix
   */
  provider?: number
  /**
   * (iOS only) The vertical accuracy of the location. Negative if the altitude is invalid
   */
  verticalAccuracy?: number
  /**
   * (iOS only) The course of the location in degrees true North. Negative if course is invalid. (0.0 - 359.9 degrees, 0 being true North)
   */
  course?: number
}

export type AccuracyMode = 'high' | 'low'

export const ALTITUDE_UNKNOWN = -21120000

export const processPlace = (placeResult: any) => {
  if (!placeResult) {
    return {
      latitude: null,
      longitude: null,
      placeId: null,
      place: '',
      city: null,
      province: null,
      country: null,
      placeData: null,
    }
  }

  const getPlaceTag = (addressComponents: string[]): string => {
    //1. remove empty values
    //2. ensure array is unique
    //3. join with comma
    return addressComponents
      .filter((c) => c)
      .filter((value, index, self) => {
        return self.indexOf(value) === index
      })
      .join(', ')
  }

  //place is a google place result
  const latitude = placeResult.geometry?.location?.lat
  const longitude = placeResult.geometry?.location?.lng
  const placeId = placeResult.place_id || null
  const place = placeResult.name || null

  //find city in order of precedence
  const cityFields = [
    'locality',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'colloquial_area',
    'postal_town',
  ]
  let city: string | null = null
  for (let i = 0; i < cityFields.length; i++) {
    const cityField = cityFields[i]
    city =
      placeResult.address_components?.find((c: any) => {
        return c.types.includes(cityField)
      })?.short_name || null
    if (city) {
      break
    }
  }
  const province =
    placeResult.address_components?.find((c: any) => {
      return c.types.includes('administrative_area_level_1')
    })?.short_name || null
  const country =
    placeResult.address_components?.find((c: any) => {
      return c.types.includes('country')
    })?.long_name || null

  return {
    latitude: latitude === undefined ? null : latitude,
    longitude: longitude === undefined ? null : longitude,
    placeId,
    place: getPlaceTag([place, city, province, country]),
    city,
    province,
    country,
    placeData: placeResult,
  }
}
