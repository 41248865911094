import { StreakInfo, SupportedLanguage } from '../../../../api/frontend-types'
import { dateFromString } from '../../modules/strings/string-helpers'
import { DateType } from './streak'

export const getStreakInfo = (
  dates: DateType[],
  userLanguage: SupportedLanguage,
): StreakInfo[] => {
  const streakInfo = []
  // Get days from start of week (Sunday) to week from Sunday
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const day = today.getDay()
  const startOfWeek = new Date(today)
  startOfWeek.setDate(today.getDate() - day)
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 7)
  // Get dreams for each day
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek)
    date.setDate(startOfWeek.getDate() + i)
    const dream = dates.find((dream) => {
      const dreamDate = dateFromString(dream.date)
      return dreamDate?.toDateString() === date.toDateString()
    })
    const letter = date
      .toLocaleString(userLanguage, { weekday: 'narrow' })
      .charAt(0)

    streakInfo.push({
      letter: letter,
      date: date,
      hasDream: dream !== undefined,
      isFreeze: dream?.type === 'streak',
      isProtected: false,
    })
  }
  return streakInfo
}

export const findLatestStreakLength = (dreams: DateType[]): number => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  if (!dreams || dreams.length === 0) return 0

  // Extract dates and use a Set to handle multiple dreams on the same day
  const dateSet = new Set(dreams.map((dream) => dream.date))
  const sortedDates = Array.from(dateSet).sort()

  // Check if the latest streak ends today or yesterday
  const lastDate = dateFromString(sortedDates[sortedDates.length - 1])
  if (lastDate) {
    const diffToLastDate =
      (today.getTime() - lastDate.getTime()) / (1000 * 60 * 60 * 24)

    if (diffToLastDate > 1) {
      return 0
    }
  }

  let latestStreak = 1
  let currentStreak = 1

  for (let i = 1; i < sortedDates.length; i++) {
    const prevDate = new Date(sortedDates[i - 1])
    const currDate = new Date(sortedDates[i])
    const diff =
      (currDate.getTime() - prevDate.getTime()) / (1000 * 60 * 60 * 24)

    if (diff === 1) {
      currentStreak++
    } else if (diff > 1) {
      currentStreak = 1
    }

    latestStreak = currentStreak
  }

  return latestStreak
}

export const isStreakInDanger = (dreams: DateType[]): boolean => {
  const today = new Date()
  const latestStreak = findLatestStreakLength(dreams)

  // We have a streak and it's < 6 hours before midnight and there's no dream today
  const userHasStreak = latestStreak > 0
  const isAfter6pm = today.getHours() > 18
  const noDreamToday = !dreams.find(
    (d) => dateFromString(d.date)?.toDateString() === today.toDateString(),
  )

  return userHasStreak && isAfter6pm && noDreamToday
}
