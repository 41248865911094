import React from 'react'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useTranslation } from 'react-i18next'
import { Dimensions, Platform, ImageSourcePropType } from 'react-native'
import {
  PADDING_HORIZONTAL_PIXELS,
  PUBLIC_USERS_DISPLAY_NAME_MAP,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import ConditionalLink from '../../utilities/ConditionalLink'
import { FastDreamLocationService, UserRank } from '../../../../api/_openapi'
import { RootStackParamList } from '../../navigation/types'
import { GridDisplay } from '../../components/layout/grid-display'
import { Factoid } from '../../components/composite/stats/factoid'
import { CatIcon } from '../../assets/react-native-svg/illustrations/Cat'
import { CloudIcon } from '../../assets/react-native-svg/illustrations/Cloud'
import DreamMap from '../Dreams/DreamMap/DreamMap'
import { DreamPoint } from '../../../../api/frontend-types'
import { useSelector } from '../../ducks/root-reducer'
import { selectIsElsewhereTeam, selectUser } from '../../ducks/user/user'
import Streak from './streak'
import { FireIcon } from '../../assets/react-native-svg/illustrations/Fire'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import WeeklyReportBox from '../../components/composite/weekly-report/weekly-report-box'
import { Loading } from '../../components/layout/loading'
import useContentWidth from '../../hooks/useContentWidth'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Row } from '../../components/common/row/row'
import { Box } from '../../components/common/box/box'
import { View } from '../../components/common/view/view'

export const DreamerDashboardHeader = ({
  displayName = '',
  isLoggedInUser,
  isUsersPrivateGroup,
  imageSrc,
  userRank,
}: {
  displayName: string | null | undefined
  isLoggedInUser: boolean
  isUsersPrivateGroup: boolean
  imageSrc: ImageSourcePropType
  userRank: UserRank | null
}) => {
  // HOOKS
  const { t } = useTranslation()
  const { contentWidth } = useContentWidth()
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)

  // SELECTORS
  const user = useSelector(selectUser)

  // VARS
  const dreamCount = userRank?.dreamCount || 0
  const isWeb = Platform.OS === 'web'
  const showProfile = contentWidth > 800 && isWeb

  // STATE
  const [dreamMapPoints, setDreamMapPoints] = React.useState<
    DreamPoint[] | null
  >(null)

  // VARS
  const nameStr = displayName || ''
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)
  const isPublicUser = Boolean(PUBLIC_USERS_DISPLAY_NAME_MAP[nameStr])
  const nameKey = `globalUser.${nameStr}.name`
  const nameText: string = isPublicUser ? t(nameKey) : nameStr

  // MEMOS

  // EFFECTS
  React.useEffect(() => {
    if (isLoggedInUser && user) {
      FastDreamLocationService.userLocations(user.id)
        .then((dreamLocations) => {
          setDreamMapPoints(dreamLocations)
        })
        .catch((error) => {
          console.log('Error fetching user locations', error)
        })
    }
  }, [user])

  return (
    <>
      <PaddedContentArea alignItems={'center'}>
        {isElsewhereTeam && <WeeklyReportBox />}
        <Row alignItems={'start'} justifyContent={'space-between'}>
          {showProfile && (
            <View alignItems={'center'} justifyContent={'center'}>
              <ConditionalLink
                to={{
                  screen: 'PrivateDreamerEdit' as keyof RootStackParamList,
                }}
                condition={isLoggedInUser && isUsersPrivateGroup}
              >
                <AvatarCircle
                  source={imageSrc}
                  size={'xl'}
                  name={nameText}
                  disablePress={!isLoggedInUser}
                />
              </ConditionalLink>
              <HeadingMain mb={4} mt={2}>
                {nameText}
              </HeadingMain>
            </View>
          )}
          <Row mt={5}>
            <Streak />
          </Row>
        </Row>
        <HeadingSmall mt={12} mb={4}>
          {t('dashboard.stats')}
        </HeadingSmall>

        {userRank && (
          <GridDisplay
            mb={6}
            maxWidth={WEB_MAX_WIDTH}
            width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
            items={[
              {
                id: 'factoid-user-rank',
                item: (
                  <Factoid
                    key={'factoid-user-rank'}
                    Icon={CatIcon}
                    factoidFact={userRank.rank?.toString() || 'N/A'}
                    textLineOne={t('dashboard.rank')}
                    textLineTwo={t('')}
                    layout="fact-text-text"
                  />
                ),
              },
              {
                id: 'factoid-num-dreams',
                item: (
                  <Factoid
                    key={'factoid-num-dreams'}
                    Icon={CloudIcon}
                    factoidFact={userRank.dreamCount?.toString() || 'N/A'}
                    textLineOne={t('common.dream_plural', {
                      count: dreamCount,
                    })}
                    textLineTwo={''}
                    layout="fact-text-text"
                  />
                ),
              },
              {
                id: 'factoid-num-streak',
                item: (
                  <Factoid
                    key={'factoid-num-streak'}
                    Icon={FireIcon}
                    factoidFact={userRank.longestStreak?.toString() || 'N/A'}
                    textLineOne={t('dashboard.longestStreak')}
                    textLineTwo={''}
                    layout="fact-text-text"
                  />
                ),
              },
            ]}
          />
        )}
        {!userRank && <Loading />}
      </PaddedContentArea>

      {user && isElsewhereTeam && isWeb && (
        <Box>
          <HeadingSmall>{t('common.map')}</HeadingSmall>
          <DreamMap
            dream={null}
            mode={'dream'}
            dreamPoints={dreamMapPoints || []}
            useCurrentLocation={true}
            showNearbyDreams={true}
          />
        </Box>
      )}
    </>
  )
}
