/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagStatsResponse } from '../models/TagStatsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagStatsResponseService {
  /**
   * Retrieves a TagStatsResponse resource.
   * Retrieves a TagStatsResponse resource.
   * @param id TagStatsResponse identifier
   * @returns TagStatsResponse TagStatsResponse resource
   * @throws ApiError
   */
  public static apiTagStats(
    id: string,
  ): CancelablePromise<TagStatsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tags/{id}/stats',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
