import React from 'react'
import { RNMultiToggleSwitch } from './MultiToggleSwitch'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { DatePicker } from '../inputs/date-picker/date-picker'
import { Platform } from 'react-native'
import { Row } from '../row/row'
import { Column } from '../column/column'

type SwitchWithValueProps = {
  value?: [boolean, string]
  onToggle: (value: [boolean, string]) => void
}

export const SwitchWithValue = React.memo(
  ({ value, onToggle }: SwitchWithValueProps) => {
    const { t } = useTranslation()

    const defaultOptions = [
      { label: t('common.off'), value: false },
      { label: t('common.on'), value: true },
    ]

    const defaultBooleanActiveIndex = value && value[0] ? true : false

    return (
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        // @ts-ignore
        space={3}
      >
        <Column alignItems="flex-start">
          <RNMultiToggleSwitch
            defaultActiveIndex={defaultBooleanActiveIndex ? 1 : 0}
          >
            {defaultOptions.map((option) => {
              return (
                <RNMultiToggleSwitch.Item
                  key={option.label}
                  onPress={() => {
                    const stringValue = (value && value[1]) || ''
                    onToggle([option.value, stringValue])
                  }}
                >
                  <SansText fontWeight={400}>{option.label}</SansText>
                </RNMultiToggleSwitch.Item>
              )
            })}
          </RNMultiToggleSwitch>
        </Column>
        <Column flexShrink={1} flexGrow={Platform.OS === 'web' ? 1 : undefined}>
          {value && value[0] && (
            <DatePicker
              onChangeDate={(date: string) => {
                const boolValue = (value && value[0]) || false
                onToggle([boolValue, date])
              }}
              date={value && value[1]}
              time={true}
            />
          )}
        </Column>
      </Row>
    )
  },
)
