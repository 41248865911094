import React from 'react'
import { ColorValue, Platform, SafeAreaView, Text } from 'react-native'
import { StyleSheet } from 'react-native'
import { useColorModeValue } from 'native-base'
import {
  PLACEHOLDER_COLOR_LIGHT,
  PLACEHOLDER_COLOR_DARK,
} from '../../../constants/ui-constants'
import {
  CodeField,
  useBlurOnFulfill,
  useClearByFocusCell,
  Cursor,
} from 'react-native-confirmation-code-field'

import { LINE_WIDTH } from '../../../constants/constants'
import { View } from '../view/view'

const CELL_COUNT = 6

type CodeInputProps = {
  codeLength?: number
  value: string
  setValue: (code: string) => void
  color?: ColorValue
  submitPasscode?: (code: string) => void
  autoFocus?: boolean
}

export const CodeInput = React.memo(
  ({
    codeLength = CELL_COUNT,
    value,
    setValue,
    color,
    autoFocus = false,
    submitPasscode,
  }: CodeInputProps) => {
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
      value,
      setValue,
    })

    const borderBottomColorFocus = color
    const borderBottomColor = useColorModeValue(
      PLACEHOLDER_COLOR_LIGHT,
      PLACEHOLDER_COLOR_DARK,
    )
    const keyboardAppearance = useColorModeValue('light', 'dark')
    const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })

    React.useEffect(() => {
      setTimeout(() => ref.current?.focus(), 150)
    }, [])

    return (
      <SafeAreaView style={styles.container}>
        <CodeField
          {...props}
          ref={ref}
          autoFocus={autoFocus}
          value={value}
          onChangeText={(text) => {
            if (text.length === codeLength) {
              submitPasscode && submitPasscode(text)
            }
          }}
          onChange={({ nativeEvent: { eventCount, target, text } }) => {
            setValue(text.replace(/[^0-9]/g, ''))
          }}
          cellCount={codeLength}
          rootStyle={styles.codeFiledRoot}
          keyboardAppearance={keyboardAppearance}
          keyboardType={Platform.OS === 'android' ? 'phone-pad' : 'number-pad'}
          textContentType="oneTimeCode"
          autoComplete="one-time-code"
          returnKeyType="done"
          renderCell={({ index, symbol, isFocused }) => {
            return (
              <View
                // Make sure that you pass onLayout={getCellOnLayoutHandler(index)} prop to root component of "Cell"
                onLayout={getCellOnLayoutHandler(index)}
                key={index}
                style={[
                  { ...styles.cellRoot, borderBottomColor },
                  isFocused || symbol
                    ? {
                        ...styles.focusCell,
                        borderBottomColor: borderBottomColorFocus,
                      }
                    : {},
                ]}
              >
                <Text style={{ ...styles.cellText, color }}>
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              </View>
            )
          }}
        />
      </SafeAreaView>
    )
  },
)

const styles = StyleSheet.create({
  root: { padding: 20, minHeight: 300 },
  container: {
    height: 60,
  },
  codeFiledRoot: {
    width: '100%',
  },
  cellRoot: {
    width: 50,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
  },
  cellText: {
    fontSize: 36,
    fontFamily: 'ABCFavorit-Book',
    fontWeight: '300',
    textAlign: 'center',
  },
  focusCell: {
    borderBottomWidth: LINE_WIDTH,
  },
})
