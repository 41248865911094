import React from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useTranslation } from 'react-i18next'
import { BodyTextMarkdown } from '../../components/common/copy/text-body-markdown'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../navigation/types'

export type InterpretationStyleNavigationProp =
  RootStackNavigationProp<'InterpretationStyle'>

export default function InterpretationStyle({
  navigation,
  route,
}: RootStackScreenProps<'InterpretationStyle'>) {
  // ROUTE
  const { style } = route.params

  // HOOKS
  const { t } = useTranslation()

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea>
        <BodyTextMarkdown>
          {t(`interpretationStyles.${style}.description`)}
        </BodyTextMarkdown>
      </PaddedContentArea>
    </ScrollView>
  )
}
