import { NUM_MILLISECONDS_IN_WEEK } from '../../../constants/constants'
import { getMinMaxDate } from '../../../modules/date-helpers/date-helpers'
import {
  DateIntervalKey,
  DateRangeWithKey,
} from '../../../../../api/frontend-types'
import {
  BucketizeMode,
  ChartData,
  ChartDataArr,
  LineData,
} from './timeline-chart'

type BucketMap = { [key: string]: { count: number; total: number } }

export function bucketizeChartData(
  chartData: ChartData,
  dateInterval: DateIntervalKey,
  dateRangeWithKey: DateRangeWithKey | null,
  bucketizeMode: BucketizeMode,
  chartTitle: string,
): ChartData {
  // Sometimes there will be no user data for a given period
  // or no Elsewhere data, or both
  const noData = Object.values(chartData).every((d) => !d.data.length)
  if (noData || !dateRangeWithKey) {
    return {}
  }

  // We need to decide what the start and end dates are
  // In the case of a fixed date range, we use the start and end dates
  // For 'all time' we use the data itself
  let startDate: Date
  let endDate: Date
  if (dateRangeWithKey.key === 'ALL_TIME') {
    // In the case of 'all time', we want the LATEST start date in the data
    // (which will be the user start date)
    // We also want the EARLIEST end date in the data
    // (which will be the user end date)
    const dateStringArrays = Object.values(chartData)
      .map((d) => d.data.map((d) => d.x))
      .filter(Boolean)

    // Now we have an array of arrays of date strings
    // We want to get the min and max of all of them
    const dateStrings = dateStringArrays.map((arr) => {
      const { min, max } = getMinMaxDate(arr)
      return { min, max }
    })

    // Now we have an array of objects with min and max dates
    // We want the "highest" min date and the "lowest" max date
    const min = new Date(Math.max(...dateStrings.map((d) => d.min.getTime())))
    const max = new Date(Math.min(...dateStrings.map((d) => d.max.getTime())))

    startDate = min
    endDate = max
  } else {
    startDate = dateRangeWithKey.range.start || new Date()
    endDate = dateRangeWithKey.range.end
  }

  const bucketized: [string, LineData][] = Object.entries(chartData).map(
    ([key, lineData]) => {
      // Filter based on start/end dates
      let realLineData = [...lineData.data]

      if (dateRangeWithKey.key === 'ALL_TIME') {
        realLineData = lineData.data.filter((d) => {
          const date = new Date(d.x)
          return date >= startDate && date <= endDate
        })
      }

      return [
        key,
        {
          data: bucketizeChartDataArr(
            startDate,
            endDate,
            realLineData,
            dateInterval,
            bucketizeMode,
            chartTitle,
          ),
          color: lineData.color,
          label: lineData.label,
        },
      ]
    },
  )
  return Object.fromEntries(bucketized)
}

function createEmptyBucketMap(
  startDate: Date,
  endDate: Date,
  dateIntervalKey: DateIntervalKey,
): BucketMap {
  const bucketMap: BucketMap = {}

  let currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    const key = getBucketKey(dateIntervalKey, currentDate.toISOString())
    bucketMap[key] = { count: 0, total: 0 }

    switch (dateIntervalKey) {
      case 'DAY':
        currentDate.setDate(currentDate.getDate() + 1)
        break
      case 'WEEK':
        currentDate = new Date(currentDate.getTime() + NUM_MILLISECONDS_IN_WEEK)
        break
      case 'MONTH':
        currentDate.setMonth(currentDate.getMonth() + 1)
        break
      case 'YEAR':
        currentDate.setFullYear(currentDate.getFullYear() + 1)
        break
      default:
        currentDate.setMonth(currentDate.getMonth() + 1)
        break
    }
  }

  return bucketMap
}

function fillBucketMap(
  items: ChartDataArr,
  bucketMap: BucketMap,
  dateIntervalKey: DateIntervalKey,
): BucketMap {
  items.forEach((item) => {
    const key = getBucketKey(dateIntervalKey, item.x)
    if (!bucketMap[key]) {
      bucketMap[key] = {
        count: 1,
        total: item.y,
      }
    } else {
      bucketMap[key].count += 1
      bucketMap[key].total += item.y
    }
  })

  return bucketMap
}

function bucketizeChartDataArr(
  startDate: Date,
  endDate: Date,
  items: ChartDataArr,
  dateIntervalKey: DateIntervalKey,
  bucketizeMode: BucketizeMode, // 'average' or 'total'
  chartTitle: string,
): ChartDataArr {
  // If no items, early return
  if (items.length === 0) {
    console.log(`No items for ${chartTitle}`)
    return []
  }

  const bMap = createEmptyBucketMap(startDate, endDate, dateIntervalKey)
  const filledBMap = fillBucketMap(items, bMap, dateIntervalKey)

  // Get the average for each bucket
  // Or just the total if we're bucketizing by total
  const averageMap: { [key: string]: number } = {}
  // @ts-ignore
  Object.entries(filledBMap).forEach(([month, { count, total }]) => {
    let value = 0
    if (count === 0) {
      value = 0
    } else if (bucketizeMode === 'average') {
      value = total / count
    } else if (bucketizeMode === 'sum') {
      value = total
    }
    averageMap[month] = value
  })

  // Convert to chart data format
  const data = Object.entries(averageMap).map(([key, value]) => {
    return {
      x: key,
      y: value,
    }
  })

  // We need to sort the data by x value
  // x is always a string, but it might be hiding a number (e.g. -1 for weeks)
  // or a string (e.g. "2020-01" for months)
  data.sort((a, b) => {
    const aX = a.x
    const bX = b.x
    const aXnum = Number(aX)
    const bXnum = Number(bX)

    if (!isNaN(aXnum) && !isNaN(bXnum)) {
      return aXnum - bXnum
    } else {
      return aX.localeCompare(bX)
    }
  })

  return data
}

// Takes a date key and a date string, and
// returns a unique key representing the bucket
function getBucketKey(
  dateRangeKey: DateIntervalKey,
  dateString: string,
): string | number {
  switch (dateRangeKey) {
    case 'DAY':
      return getDayKey(dateString)
    case 'WEEK':
      return getWeekKey(dateString, new Date())
    case 'MONTH':
      return getMonthKey(dateString)
    case 'YEAR':
      return getYearKey(dateString)
    default:
      return getMonthKey(dateString)
  }
}

//////////////////////////////////////////////////////
// BUCKETISERS
// Given a date string,
// return a key representing the bucket
//////////////////////////////////////////////////////

// DAY
// Should just be able to return the date string
// E.g. 2020-01-01 -> 2020-01-01
// But if the date string is long, e.g. "2023-12-20T00:00:00.000Z" we can slice it
function getDayKey(date: string) {
  if (date.length > 10) {
    return date.slice(0, 10)
  } else {
    return date
  }
}

// WEEK
// Given a date and a start date
// Return a number representing the offset in weeks
// e.g. -1 = up to a week before the start date
// 0 = within one week after the start date
// 1 = within two weeks after the start date
function getWeekKey(dateString: string, startDate: Date): number {
  const d = new Date(dateString)
  const numWeeks = Math.round(
    (d.getTime() - startDate.getTime()) / NUM_MILLISECONDS_IN_WEEK,
  )
  return numWeeks
}

// MONTH
// Given a date string, return the month
// Faster to do it from the original string here, since we just slice it
// E.g. 2020-01-01 -> 2020-01
function getMonthKey(date: string) {
  return date.slice(0, 7)
}

// YEAR
// Given a date string, return the year
// Faster to do it from the original string here, since we just slice it
// E.g. 2020-01-01 -> 2020
function getYearKey(date: string) {
  return date.slice(0, 4)
}
