import React from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { AboutSection } from '../../../../api/frontend-types'
import { Dimensions } from 'react-native'
import { Img } from '../../components/common/image/image'
import { AboutList } from '../../components/composite/about-list/about-list'
import { RootStackNavigationProp } from '../../navigation/types'
import { fireImg } from '../../constants/ui-constants'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import {
  aboutCommunityAndSocials,
  aboutDirectContact,
} from '../../config/about'

export type ContactNavigationProp = RootStackNavigationProp<'Contact'>

export default function Contact() {
  const screenWidth = Dimensions.get('window').width
  const imageWidth = screenWidth * 0.4

  const sections: AboutSection[] = [
    aboutCommunityAndSocials,
    aboutDirectContact,
  ]

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea alignItems={'center'}>
        <Img
          source={fireImg}
          width={imageWidth}
          height={imageWidth}
          alt={'Black and white illustration of a fire'}
        />
        <AboutList sections={sections} />
      </PaddedContentArea>
    </ScrollView>
  )
}
