import React from 'react'
import { Path } from 'react-native-svg'
import { Icon } from '../../components/common/icon/icon'

export function ArrowLeft({
  color = 'currentColor',
  size = 24,
}: {
  color: string
  size: number
}) {
  return (
    <Icon width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M23 12L3 12" strokeWidth="2" stroke={color} />
      <Path
        d="M10 20L2 12L10 4"
        strokeWidth="2"
        strokeLinejoin="round"
        stroke={color}
        fill="none"
      />
    </Icon>
  )
}
