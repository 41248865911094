import React from 'react'
import AppProviders from './AppProviders'
import { Initialise } from './Initialise'

export function App() {
  return (
    <AppProviders>
      <Initialise />
    </AppProviders>
  )
}
