import React from 'react'
import { FeatureCarousel } from '../../../components/composite/subscriptions/features-carousel'
import { RootStackNavigationProp } from '../../../navigation/types'

export type CreditsNavigationProp =
  RootStackNavigationProp<'PrivateCreditSettingsEdit'>

export default function Credits() {
  return <FeatureCarousel offeringsToShow={['credits']} />
}
