import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import DreamEllipsis from '../../../components/composite/feed/dream-ellipsis'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateDraftsFeed"
        component={Screens.DraftsFeed}
        options={{
          title: cap(t('common.draft_plural', { count: FEW })),
        }}
      />
      <MainStack.Screen
        name="PrivateDraftView"
        component={Screens.DraftView}
        options={{
          title: cap(t('common.draft_plural', { count: 1 })),
          headerLeft: HeaderLeft,
          headerRight: () => <DreamEllipsis />,
        }}
      />
    </>
  )
}
