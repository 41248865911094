import * as React from 'react'
import { useColorModeValue } from 'native-base'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { LINE_WIDTH } from '../../constants/constants'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { Row } from '../common/row/row'

type ContentDividerProps = InterfaceBoxProps & {}

export const ContentDivider = React.memo(
  ({ mt = 8, mb = 6, width = '100%', ...rest }: ContentDividerProps) => {
    const bgColor = useColorModeValue(BLACK, WHITE)
    // const bgColor = 'black'

    return (
      <Row
        bgColor={bgColor}
        color={bgColor}
        style={{
          height: LINE_WIDTH,
        }}
        width={width}
        mt={mt}
        mb={mb}
        {...rest}
      />
    )
  },
)
