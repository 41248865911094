import { AppThunk } from '../../store'
import { Friend, FriendService } from '../../../../../api/_openapi'
import { removeFriend, setFriends } from '../friends'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { removeUserGroup } from '../../groups/groups'

export function loadFriends(): AppThunk {
  return async (dispatch: any) => {
    try {
      const friends = await FriendService.getFriends()
      dispatch(setFriends(friends))
    } catch (e) {
      console.log('Failed to load friends')
    }
  }
}

export function unfriend(friend: Friend): AppThunk {
  return async (dispatch: any) => {
    try {
      await FriendService.unfriend(friend.id)
      dispatch(removeFriend(friend.id))
      dispatch(removeUserGroup(friend.groupId))
      elsewhereToast.showToast({
        description:
          'Unfriended ' + (friend.friendName || friend.username || ''),
        status: 'success',
      })
    } catch (e) {
      console.error(e)
      elsewhereToast.showToast({
        description:
          'Failed to unfriend ' + (friend.friendName || friend.username || ''),
        status: 'error',
      })
    }
  }
}
