import React, { useState, useEffect } from 'react'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { SansText } from '../../../components/common/copy/text-sans'
import { AboutSection } from '../../../../../api/frontend-types'
import { AboutList } from '../../../components/composite/about-list/about-list'
import { cap } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import { HeadingSmall } from '../../../components/common/copy/heading-small'
import { Countdown } from '../../../components/common/countdown/countdown'
import { LoadingPage } from '../../../components/layout/loading-page'
import {
  SubscriptionStatusResponse,
  SubscriptionStatusResponseService,
} from '../../../../../api/_openapi'
import { BodyTextTranslated } from '../../../components/common/copy/text-body-translated'
import { BodyTextMarkdown } from '../../../components/common/copy/text-body-markdown'
import { BLACK, PARAGRAPH_MARGIN, WHITE } from '../../../constants/ui-constants'
import { RootStackNavigationProp } from '../../../navigation/types'
import { Platform } from 'react-native'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type SubscriptionStatusNavigationProp =
  RootStackNavigationProp<'PrivateSubscriptionStatusView'>

export default function SubscriptionStatus() {
  // STATE
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatusResponse | null>(null)
  const [errMsg, setErrMsg] = useState<string | null>(null)

  // HOOKS
  const { t } = useTranslation()
  const color = useColorModeValue(BLACK, WHITE)

  // VARS
  const loading = subscriptionStatus === null
  const subscriptionStatusObj = subscriptionStatus || {}
  const timeToEndOfMonth = getEndOfMonthUTC()
  const {
    imagesRemaining,
    insightsRemaining,
    reportsRemaining,
    creditsRemaining,
    interpretationsRemaining,
    tier,
  } = subscriptionStatusObj

  // I18N
  const shortTierKey = `subscriptions.tiers.${tier}.short`
  const shortTierName = t(shortTierKey).toLocaleLowerCase()

  // Hard refresh of subscription status
  // To make sure it's the source of truth
  useEffect(() => {
    SubscriptionStatusResponseService.subscriptionStatus()
      .then((response) => {
        setSubscriptionStatus(response)
      })
      .catch((err) => {
        console.error(err)
        setErrMsg('Error fetching subscription status')
        setSubscriptionStatus(null)
      })
  }, [])

  const sections: AboutSection[] = [
    {
      heading: t('settings.subscriptionStatus'),
      members: [
        {
          heading: cap(t('subscriptions.yourCurrentTier', { count: FEW })),
          members: [{ name: t(`subscriptions.tiers.${tier}`) }],
        },
        {
          heading: cap(t('common.credit_plural', { count: FEW })),
          members: [{ name: `${creditsRemaining}` }],
        },
      ],
    },
    {
      heading: t('settings.subscriptionStatus.remainingMonthlyAllowances'),
      members: [
        {
          heading: cap(t('common.imageAI_plural', { count: FEW })),
          members: [{ name: `${imagesRemaining}` }],
        },
        {
          heading: cap(t('common.interpretation_plural', { count: FEW })),
          members: [{ name: `${interpretationsRemaining}` }],
        },
        {
          heading: cap(t('common.insight_plural', { count: FEW })),
          members: [{ name: `${insightsRemaining}` }],
        },
        {
          heading: cap(t('askYourDreams.title')),
          members: [{ name: `${reportsRemaining}` }],
        },
      ],
    },
  ]

  if (loading) {
    return <LoadingPage />
  }
  return (
    <ScrollView width="100%" height="100%">
      <PaddedContentArea>
        <>
          <HeadingSmall textAlign={'center'} mb={6} mt={8}>
            {t('subscriptions.about.header')}
          </HeadingSmall>
          <BodyTextTranslated
            color={color}
            i18nKey="subscriptions.about.tiers"
            linkProps={{
              screen: 'PrivateSubscriptionSettingsEdit',
            }}
            values={{
              currentTier: shortTierName,
            }}
            marginY={PARAGRAPH_MARGIN}
          />
          <BodyTextTranslated
            color={color}
            i18nKey="subscriptions.about.credits"
            linkProps={{
              screen: 'PrivateCreditSettingsEdit',
            }}
          />
          <BodyTextMarkdown>
            {t('subscriptions.about.quotaReset', {
              currentTier: shortTierName,
            })}
          </BodyTextMarkdown>
          <AboutList sections={sections} />
          <SansText textAlign={'center'}>
            {t('settings.subscriptionStatus.utcExplainer')}
          </SansText>
          <Countdown
            millisecondsRemaining={timeToEndOfMonth}
            my={4}
            textAlign={'center'}
            fontWeight={600}
          />
        </>
        {errMsg && <SansText>{errMsg}</SansText>}
      </PaddedContentArea>
    </ScrollView>
  )
}

// Get minutes remaining to the end of this month
// (In UTC time)
function getEndOfMonthUTC(): number {
  const msToEndOfMonthUserTZ = getMillisecondsUntilEndOfMonth()
  const offset = new Date().getTimezoneOffset() * 1000 * 60
  return msToEndOfMonthUserTZ - offset
}

// Get minutes remaining to the end of this month
// (In user's timezone)
function getMillisecondsUntilEndOfMonth() {
  // Get the current date
  const now = new Date()

  // Create a new date object for the first day of the next month
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

  // Calculate the difference in milliseconds between the end of the month and now
  const millisecondsRemaining = endOfMonth.getTime() - now.getTime()

  return millisecondsRemaining
}
