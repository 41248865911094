import React, { useMemo } from 'react'
import { useNavigation } from '@react-navigation/core'
import { useColorModeValue } from 'native-base'
import { Platform, GestureResponderEvent } from 'react-native'
import { ArrowLeft } from '../assets/react-native-svg/ArrowLeft'
import { BLACK, WHITE } from '../constants/ui-constants'
import { useSelector } from '../ducks/root-reducer'
import { selectUser } from '../ducks/user/user'
import { selectPrivateGroupId, selectUserGroups } from '../ducks/groups/groups'
import { SansText } from '../components/common/copy/text-sans'
import { AvatarCircleMedia } from '../components/common/avatar/avatar-circle-media'
import { HEADER_TITLE_FONT_SIZE } from '../constants/constants'
import {
  RootStackNavigationProp,
  RootStackParamList,
  MainTabsParamList,
} from './types'
import { HeaderTitleProps } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { LoginNavigationProp, LoginRouteProp } from '../screens/Login'
import { SignupNavigationProp, SignupRouteProp } from '../screens/Signup'
import { LibraryNavigationProp, LibraryRouteProp } from '../screens/Library'
import {
  DreamerViewNavigationProp,
  DreamerViewRouteProp,
} from '../screens/Dreamer/DreamerView'
import {
  InsightViewNavigationProp,
  InsightViewRouteProp,
} from '../screens/Insights/InsightView'
import {
  DreamViewNavigationProp,
  DreamViewRouteProp,
} from '../screens/Dreams/DreamView'
import { findFocusedRoute, useNavigationState } from '@react-navigation/core'
import { useAuth } from '../contexts/auth-context'
import { Row } from '../components/common/row/row'
import { Button } from '../components/common/nb-button/nb-button'
import { IconButton } from '../components/common/icon-button/icon-button'

export type HeaderLeftNavigationProp =
  | LoginNavigationProp
  | SignupNavigationProp
  | LibraryNavigationProp
  | DreamerViewNavigationProp
  | InsightViewNavigationProp
  | DreamViewNavigationProp

export type HeaderLeftRouteProp =
  | LoginRouteProp
  | SignupRouteProp
  | LibraryRouteProp
  | DreamerViewRouteProp
  | InsightViewRouteProp
  | DreamViewRouteProp

export const HeaderLeft = ({ ml }: any) => {
  const navigation = useNavigation<HeaderLeftNavigationProp>()
  const color = useColorModeValue(BLACK, WHITE)

  const { authData } = useAuth()
  const navState = useNavigationState((state) => state)
  const currentRoute: any = findFocusedRoute(navState)
  const groupIdFromRoute =
    currentRoute?.params?.params?.params?.groupId ||
    currentRoute?.params?.params?.groupId ||
    currentRoute?.params?.groupId

  return (
    <IconButton
      size="sm"
      ml={ml}
      alignSelf="flex-start"
      variant="unstyled"
      onPress={() => {
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          try {
            if (authData?.token && groupIdFromRoute) {
              navigation.replace('MainTabs', {
                screen: 'MainStack',
                params: {
                  screen: 'GroupFeed',
                  params: { groupId: groupIdFromRoute },
                },
              })
            } else if (authData?.token && !groupIdFromRoute) {
              navigation.replace('MainTabs', {
                screen: 'MainStack',
                params: { screen: 'PrivateFeed' },
              })
            } else {
              navigation.push('Home')
            }
          } catch (e) {
            if (Platform.OS === 'web') {
              window.history.back()
            }
          }
        }
      }}
      icon={<ArrowLeft size={24} color={color} />}
      _pressed={{
        opacity: 0.5,
      }}
    />
  )
}

export type HeaderRightNavigationProp = RootStackNavigationProp<
  keyof RootStackParamList
>

export const HeaderRight = ({
  text,
  onPress,
}: {
  text: string
  onPress?: (event: GestureResponderEvent) => void
}) => {
  const navigation = useNavigation<HeaderRightNavigationProp>()
  const { authData } = useAuth()
  const navState = useNavigationState((state) => state)
  const currentRoute: any = findFocusedRoute(navState)
  const groupIdFromRoute =
    currentRoute?.params?.params?.params?.groupId ||
    currentRoute?.params?.params?.groupId ||
    currentRoute?.params?.groupId
  const color = useColorModeValue(BLACK, WHITE)

  return (
    <Button
      variant="unstyled"
      onPress={(e) => {
        if (onPress) {
          onPress(e)
        } else {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            try {
              if (authData?.token && groupIdFromRoute) {
                navigation.replace('MainTabs', {
                  screen: 'MainStack',
                  params: {
                    screen: 'GroupFeed',
                    params: { groupId: groupIdFromRoute },
                  },
                })
              } else if (authData?.token && !groupIdFromRoute) {
                navigation.replace('MainTabs', {
                  screen: 'MainStack',
                  params: { screen: 'PrivateFeed' },
                })
              } else {
                navigation.push('Home')
              }
            } catch (e) {
              if (Platform.OS === 'web') {
                window.history.back()
              }
            }
          }
        }
      }}
      size="sm"
      color={color}
      _pressed={{
        opacity: 0.5,
      }}
    >
      <SansText>{text}</SansText>
    </Button>
  )
}

type HeaderTitlesProps = HeaderTitleProps & {
  route: RouteProp<MainTabsParamList, 'MainStack'>
  navigation: any
}

export const HeaderTitle = ({
  children,
  route,
  navigation,
}: HeaderTitlesProps) => {
  // SELECTORS
  const user = useSelector(selectUser)
  const joinedGroups = useSelector(selectUserGroups)
  const privateGroupId = useSelector(selectPrivateGroupId)

  // HOOKS
  const navState = useNavigationState((state) => state)

  // VARS
  const currentRoute: any = findFocusedRoute(navState)
  const groupIdFromRoute =
    currentRoute?.params?.params?.params?.groupId ||
    currentRoute?.params?.params?.groupId ||
    currentRoute?.params?.groupId
  const activeGroupId = groupIdFromRoute || privateGroupId

  // MEMOS
  const currentGroup = useMemo(() => {
    return joinedGroups.find((g) => g.id === activeGroupId)
  }, [
    user,
    groupIdFromRoute,
    privateGroupId,
    joinedGroups,
    activeGroupId,
    navigation,
    route,
  ])

  const activeGroupIsUser = currentGroup?.type === 'user'
  const imageUrl = activeGroupIsUser
    ? user?.profileImageUrl
    : currentGroup?.imageUrl

  return (
    <Row alignItems={'center'}>
      <AvatarCircleMedia
        size="xs"
        source={imageUrl || undefined}
        name={
          activeGroupIsUser
            ? (user?.username as string)
            : (currentGroup?.name as string)
        }
        disablePress={true}
        loading={
          (activeGroupIsUser && !user?.username) ||
          (!activeGroupIsUser && !currentGroup?.name)
        }
      />
      <SansText fontSize={HEADER_TITLE_FONT_SIZE} mt={2} ml={1}>
        {children}
      </SansText>
    </Row>
  )
}
