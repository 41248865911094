import React, { FC, ReactNode } from 'react'
import { XXL_LINE_HEIGHT } from '../../../constants/constants'
import { DisplayDate } from './display-date'
import { useUserDate } from '../../../hooks/useUserDate'
import { HeadingMain } from './heading-main'
import {
  ScriptType,
  SimpleTextAlign,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import { TextProps } from './text'
import { Column } from '../column/column'

type HeadingMainWithDateProps = TextProps & {
  children: ReactNode
  date?: string
  displayDate?: string | null
  textAlign?: SimpleTextAlign
  scriptType?: ScriptType
  language?: SupportedLanguage
}

export const HeadingMainWithDate: FC<HeadingMainWithDateProps> = ({
  children,
  lineHeight = XXL_LINE_HEIGHT,
  mt = 4,
  mb = 5,
  date,
  displayDate,
  textAlign,
  scriptType,
  language,
  ...rest
}) => {
  const { dateString } = useUserDate(date as string)
  const title = children as string
  const hasTitle = Boolean(title)
  const hasDate = Boolean(date)

  if (!hasTitle && !hasDate) {
    return null
  } else {
    return (
      <Column mt={mt} mb={mb} justifyContent={'center'}>
        {/* If there's a title, show that. Otherwise, show the date as the title */}
        <HeadingMain
          selectable={true}
          textAlign={textAlign}
          scriptType={scriptType}
          language={language}
          {...rest}
        >
          {!children && date ? dateString : children}
        </HeadingMain>
        {/* If there's a title, show the date in small text. */}
        {/* Otherwise if the date has been used in the title, show nothing */}
        {date && children ? (
          <DisplayDate date={date} mt={2} displayDate={displayDate} />
        ) : null}
      </Column>
    )
  }
}
