import React from 'react'
import { GeolocateControl } from 'react-map-gl'
import { haversineDistance } from './map-helpers'
import { DreamPoint } from '../../../../../api/frontend-types'
import { HighlightBox } from '../../../components/layout/highlight-box'
import { BodyText } from '../../../components/common/copy/text-body'
import { HeadingSmall } from '../../../components/common/copy/heading-small'
import { useTranslation } from 'react-i18next'
import { Row } from '../../../components/common/row/row'
import { Center } from '../../../components/common/center/center'

type GeolocateControlComponentProps = {
  dreamPoints: DreamPoint[]
  setPointsNearby: (points: DreamPoint[]) => void
}

export const GeolocateControlComponent = ({
  dreamPoints,
  setPointsNearby,
}: GeolocateControlComponentProps) => {
  return (
    <GeolocateControl
      trackUserLocation={true}
      onGeolocate={(geo) => {
        //find all of the dreamPoints within a 500ft radius of the current location
        const lng = geo.coords.longitude
        const lat = geo.coords.latitude
        const radiusInFeet = 100

        const filteredPoints = dreamPoints.filter((point) => {
          const pointLat = point.latitude
          const pointLng = point.longitude

          if (pointLat && pointLng) {
            const distance = haversineDistance(lat, lng, pointLat, pointLng)
            return distance < radiusInFeet
          }
          return false
        })
        setPointsNearby(filteredPoints)
      }}
    />
  )
}

export const PointsNearby = ({
  pointsNearby,
}: {
  pointsNearby: DreamPoint[]
}) => {
  const { t } = useTranslation()
  return (
    <Row justifyContent={'center'} alignItems={'center'}>
      <Center>
        <HeadingSmall>{t('dashboard.nearbyDreams')}</HeadingSmall>
        {pointsNearby.map((point) => {
          return (
            <HighlightBox width={300} key={point.id}>
              <BodyText fontWeight={'bold'}>{point.title}</BodyText>
              <BodyText key={point.id}>{point.description}</BodyText>
            </HighlightBox>
          )
        })}
      </Center>
    </Row>
  )
}
