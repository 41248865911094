import React from 'react'
import { Platform } from 'react-native'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { BodyText } from '../../components/common/copy/text-body'
import { Logotype } from '../../assets/icons/Logotype'
import { Img } from '../../components/common/image/image'
import { Dimensions } from 'react-native'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { SansText } from '../../components/common/copy/text-sans'
import { DARKER_CREAM, LIGHTEST_BLACK } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '@elsewhere/components/src/constants/constants'
import { useColorModeValue } from 'native-base'
import { ScrollTo, Target, ScrollView } from '@nandorojo/anchor'

const daf = require('../../assets/images/symbol-dictionary-temp.webp')

import './index.css'
import axios from 'axios'
import { OpenAPI } from '../../../../api/_openapi'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Row } from '../../components/common/row/row'
import { Center } from '../../components/common/center/center'
import { Column } from '../../components/common/column/column'
import { Link } from '../../components/common/link/link'

export default function SymbolDictionary() {
  const screenWidth = Dimensions.get('window').width
  const imageWidth = screenWidth * 0.5

  const bg = useColorModeValue(DARKER_CREAM, LIGHTEST_BLACK)
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  const [symbolData, setSymbolData] = React.useState<any>([])

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${OpenAPI.BASE}/api/public/symbol-dictionary`,
        )
        setSymbolData(response.data)
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }
    fetchData()
  }, [])

  let symbolFirstLetters: string[] = []
  const symbolFirstLetterMap: any = {}
  symbolData.forEach((symbol: any) => {
    if (symbol.entries.length === 0) {
      return
    }
    symbolFirstLetters.push(symbol.name[0])
    if (!symbolFirstLetterMap[symbol.name[0]]) {
      symbolFirstLetterMap[symbol.name[0]] = []
    }
    symbolFirstLetterMap[symbol.name[0]].push(symbol)
  })

  //unique and order the letters
  symbolFirstLetters = [...new Set(symbolFirstLetters)].sort()

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      {Platform.OS === 'web' && (
        <Center m={5}>
          <Link href="/">
            <Logotype width={205} height={25} />
          </Link>
        </Center>
      )}
      <PaddedContentArea alignItems={'center'} maxWidth={pageWidth}>
        {Platform.OS !== 'web' ? (
          <>
            <Img
              source={daf}
              width={imageWidth}
              height={imageWidth}
              alt={'Dream Animation Festival logo'}
            />
            <Row justifyContent={'center'} mb={8} mt={6}>
              <Logotype width={170} height={25} />
            </Row>
          </>
        ) : (
          <>
            <Img
              source={daf}
              width={imageWidth}
              height={imageWidth}
              alt={'Black and white illustration of a moon'}
            />
            <HeadingMain mt={8}>Symbol Dictionary</HeadingMain>
            <SansText mb={8} mt={8} textAlign="center" italic={true}>
              A collection of historical and contemporary perspectives.
            </SansText>
          </>
        )}
        <PaddedContentArea alignItems={'center'}>
          <BodyText pb={4}>
            Have you ever wondered what the symbols in your dreams mean?
          </BodyText>
          <BodyText pb={4}>
            While we all possess a unique dream language and personal symbology,
            there are some symbolic patterns that are shared across cultures and
            time periods. It can be helpful to explore these symbols and their
            meanings to better understand our dreams and how they relate to our
            waking lives.
          </BodyText>
          <BodyText pb={4}>
            At Elsewhere, we've worked with dream researchers to collect a
            variety of symbols and their meanings from different authors,
            historical time periods, and cultural backgrounds. Here we're
            sharing a selection of these symbols from Kelly Bulkeley and David
            Fontana with more to come soon!
          </BodyText>
          <HeadingSmall>The Symbols</HeadingSmall>
          <Row
            mb={10}
            justifyContent={'space-between'}
            w={'100%'}
            maxWidth={pageWidth}
            nativeID={'symbol-letters'}
          >
            {symbolFirstLetters.map((letter: string, index: number) => {
              return (
                <ScrollTo target={letter} key={index}>
                  {letter}
                </ScrollTo>
              )
            })}
          </Row>
          {symbolFirstLetters.map((letter: string, index: number) => {
            const symbols = symbolFirstLetterMap[letter]
            return (
              <Column key={index} mb={8}>
                <Target name={letter} />
                <HeadingSmall>{letter}</HeadingSmall>
                {symbols.map((symbol: any, index: number) => {
                  const entries = symbol.entries
                  return (
                    <Column key={index} mb={4}>
                      <HeadingSmall>{symbol.name}</HeadingSmall>
                      {entries.map((entry: any, index: number) => {
                        return (
                          <Center
                            bg={bg}
                            p={4}
                            pt={6}
                            pb={3}
                            mb={6}
                            maxWidth={pageWidth}
                            w="100%"
                          >
                            <Center maxWidth={pageWidth}>
                              <Column key={index} mb={4} pl={4}>
                                <BodyText>{entry.description}</BodyText>
                                <BodyText italic={true} textAlign="right">
                                  {entry.author
                                    .split('-')
                                    .map(
                                      (author: string) =>
                                        author.charAt(0).toUpperCase() +
                                        author.slice(1),
                                    )
                                    .join(' ')}
                                </BodyText>
                              </Column>
                            </Center>
                          </Center>
                        )
                      })}
                    </Column>
                  )
                })}
              </Column>
            )
          })}
        </PaddedContentArea>
      </PaddedContentArea>
    </ScrollView>
  )
}
