import React from 'react'
import { useFriend } from '../../../hooks/useFriend'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../ducks/user/user'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { Dimensions } from 'react-native'
import { FastDream } from '../../../../../api/_openapi'
import { useTranslation } from 'react-i18next'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { HeadingSmall } from '../../../components/common/copy/heading-small'
import { IconPlus } from '../../../assets/react-native-svg/icons/icons'
import { Row } from '../../../components/common/row/row'
import { Box } from '../../../components/common/box/box'

type SharedDreamsHeaderProps = {
  yourDreams: FastDream[]
  friendsDreams: FastDream[]
}

export function SharedDreamsHeader({
  yourDreams,
  friendsDreams,
}: SharedDreamsHeaderProps) {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const friend = useFriend()

  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  if (!friend || !user) {
    return null
  }

  return (
    <Row>
      <Row width={'45%'} justifyContent={'center'}>
        <Box>
          <PaddedContentArea alignItems={'center'} pl={20}>
            <AvatarCircle
              size={'xl'}
              source={{
                uri: user.profileImageUrl || undefined,
              }}
              name={'friend-avatar'}
            />
            <HeadingSmall mt={4} mb={0}>
              You
            </HeadingSmall>
          </PaddedContentArea>
        </Box>
      </Row>
      <Row width={'10%'} justifyContent={'center'} alignItems={'center'}>
        <IconPlus mt={'100px'} />
      </Row>
      <Row width={'45%'} justifyContent={'center'}>
        <Box>
          <PaddedContentArea alignItems={'center'} pr={20}>
            <AvatarCircle
              size={'xl'}
              source={{
                uri: friend.imageUrl || undefined,
              }}
              name={'friend-avatar'}
            />
            <HeadingSmall mt={4} mb={0}>
              {friend.friendName}
            </HeadingSmall>
          </PaddedContentArea>
        </Box>
      </Row>
    </Row>
  )
}
