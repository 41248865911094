import React, { useState } from 'react'
import { FramedImage } from '../image/framed-image'
import {
  MediaObjectWithDream,
  ImageOnObject,
  SubscriptionStatus,
} from '../../../../../api/frontend-types'
import { DreamImageService } from '../../../../../api/_openapi'
import { DreamImage_read } from '../../../../../api/_openapi'
import { elsewhereToast } from '../toast/toast'
import { useSelector } from '../../../ducks/root-reducer'
import { selectDefaultArtStyle } from '../../../ducks/user/user'
import { undecorateId } from '../../../../../api/decorate-ids'
import { useWatch } from 'react-hook-form'
import { showImageToast } from '../../../modules/image-helpers/image-helpers'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { refreshSubscriptionStatus } from '../../../ducks/subscription/thunks/subscription-thunks'
import { useDispatch } from 'react-redux'

type InputImageProps = {
  dreamId: string
  imageUrl: string | undefined
  onChangeImage: (image: MediaObjectWithDream) => void
  loading?: boolean
  control?: any
}

export const InputImage = React.memo(
  ({ dreamId, imageUrl, onChangeImage, loading, control }: InputImageProps) => {
    // STATE
    const [imageLoading, setImageLoading] = useState(loading)
    const [loadingLabel, setLoadingLabel] = useState('')

    // SELECTORS
    const defaultArtStyle = useSelector(selectDefaultArtStyle)

    const [title, description] = useWatch({
      control,
      name: ['title', 'description'],
    })

    const subscriptionStatus = useSelector(selectSubscriptionStatus)
    const { creditsRemaining = null, imagesRemaining = null } =
      subscriptionStatus
    const dispatch = useDispatch<any>()
    React.useEffect(() => {
      dispatch(refreshSubscriptionStatus())
    }, [imageUrl])

    return (
      <FramedImage
        regenDisabled={!title && !description}
        alt={''}
        source={{ uri: imageUrl }}
        loading={imageLoading}
        loadingLabel={loadingLabel}
        editable={true}
        setImageLoading={setImageLoading}
        setLoadingLabel={setLoadingLabel}
        onRegenerateImage={(
          userUploaded = false,
          image?: ImageOnObject | null,
          artStyle?: string,
          prompt?: string,
        ) => {
          setImageLoading(true)
          onRegenerateImage(
            { dreamId, title, description },
            onChangeImage,
            setImageLoading,
            userUploaded,
            image,
            artStyle || defaultArtStyle,
            subscriptionStatus,
            prompt,
          )
        }}
        imagesRemaining={subscriptionStatus.imagesRemaining}
        creditsRemaining={subscriptionStatus.creditsRemaining}
      />
    )
  },
)

function onRegenerateImage(
  dream: { dreamId: string; title: string; description: string },
  onChangeImage: (image: MediaObjectWithDream) => void,
  setImageLoading: (loading: boolean) => void,
  userUploaded = false,
  userUploadedImage: ImageOnObject | null = null,
  artStyle: string,
  subscriptionStatus: SubscriptionStatus,
  prompt?: string,
) {
  if (userUploaded && userUploadedImage) {
    onChangeImage({
      id: userUploadedImage.id,
      url: userUploadedImage.url,
    })
    setImageLoading(false)
    return
  } else if (userUploaded && !userUploadedImage) {
    onChangeImage({
      id: '',
      url: '',
    })
    setImageLoading(false)
    return
  } else {
    if (dream) {
      console.log('Dream title: ' + dream.title)
    } else {
      console.log('Dream is undefined')
    }
  }

  // Generate the image
  DreamImageService.dreamImage({
    style: undecorateId(artStyle),
    dream: dream.dreamId,
    dreamTitle: dream.title || undefined,
    dreamText: dream.description || undefined,
    // @ts-ignore ~ this should be in there just not documented
    prompt: prompt,
  })
    .then((resImage: DreamImage_read) => {
      // Change the image to a DreamImage
      const img = {
        // @ts-ignore
        id: resImage.id || '', // image field is the ID
        // @ts-ignore
        url: resImage.url,
      }
      showImageToast(subscriptionStatus)
      onChangeImage(img)
      setImageLoading(false)
    })
    .catch((err) => {
      setImageLoading(false)
      console.log('image error', err.body)

      if (err.status === 403) {
        elsewhereToast.showSubscriptionStatusToast({
          status: 'error',
          duration: 10000,
          subscriptionStatus,
        })
      } else if (err.body === 'usage_limit_reached') {
        elsewhereToast.showSubscriptionStatusToast({
          status: 'error',
          duration: 10000,
          subscriptionStatus,
        })
      } else if (err.body === 'censored') {
        elsewhereToast.showToast({
          title: 'toast.errorGeneratingImage.title',
          description: 'toast.errorGeneratingImage.description',
          status: 'error',
          duration: 5000,
        })
      } else {
        elsewhereToast.showToast({
          title: 'Failed to generate image',
          description: 'Failed to generate image. Please try again.',
          status: 'error',
          duration: 5000,
        })
      }
    })
}
