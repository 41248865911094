import { ChartData } from '../timeline-chart'
import { TagDateCount } from '../../../../../../api/_openapi'

export function dreamsToChartData(
  tagDateCount: TagDateCount,
  color: string,
  legendLabel: string,
): ChartData {
  const data = Object.keys(tagDateCount.dateCounts).map((date) => {
    return {
      x: date,
      y: tagDateCount.dateCounts[date],
    }
  })

  return {
    dreams: {
      data,
      color,
      label: legendLabel,
    },
  }
}
