import {
  DeviceRefreshService,
  DreamerProfile,
  DreamerProfileService,
  FastMembership,
  FastUser,
  FastUserService,
  UserService,
} from '../../../../../api/_openapi'
import { decorateId, undecorateId } from '../../../../../api/decorate-ids'
import {
  DownloadFormat,
  DreamerProfileEditType,
} from '../../../../../api/frontend-types'
import {
  formatDateTimeForDatabase,
  truncateString,
} from '../../../modules/strings/string-helpers'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { fetchFastUserById } from '../functions/user-functions'
import store, { AppThunk } from '../../store'
import { DeviceRegistrationService } from '../../../../../api/_openapi/services/DeviceRegistrationService'
import {
  ExportLink,
  setCurrentUserProfile,
  setExportLink,
  setNotificationToken,
  setUser,
} from '../user'
import { loadUserGroups } from '../../groups/thunks/group-thunks'
import {
  addOrUpdateMembership,
  selectUserMembershipInGroup,
} from '../../groups/groups'
import {
  DEFAULT_ART_STYLE_ID,
  DEFAULT_INTERPRETATION_STYLE_ID,
} from '../../../constants/constants'

/*
 * Load user from API and set in store
 */
export function loadUser(userId: string): AppThunk {
  return async (dispatch: any) => {
    // 1)
    // Get user from API
    const user = await fetchFastUserById(userId).catch((err) => {
      console.error('apiUsersIdGet error: ', err)
      elsewhereToast.showToast({
        title: 'common.errorGeneric',
        description: truncateString(err.message, 100),
        status: 'error',
      })
    })

    // 2)
    // Set user in store
    if (user) {
      dispatch(setUser(user))
    }
  }
}

/*
 * Load user from API, set in store and do otther stuff
 */
export function loadUserProfileAndGroups(user: FastUser): AppThunk {
  return async (dispatch: any) => {
    // 1)
    // Load user's dreamerProfile if available
    if (user && user?.activeDreamerProfileId) {
      DreamerProfileService.apiDreamerProfilesIdGet(
        undecorateId(user.activeDreamerProfileId),
      )
        .then((dreamerProfile) => {
          dispatch(setCurrentUserProfile(dreamerProfile))
        })
        .catch((err) => {
          console.log('apiDreamerProfilesIdGet error: ', err)
          elsewhereToast.showToast({
            title: 'toast.loadingDreamerProfileError.title',
            description: truncateString(err.message, 100),
            status: 'error',
          })
        })
    }

    // 2) Load groups and memberships
    if (user) {
      dispatch(loadUserGroups())
    }
  }
}

// If a user is out of credits, reset their default styles to free styles
export function resetUserToFreeStyles(userId: string): AppThunk {
  return async (dispatch: any) => {
    const userPatch = {
      artStyleId: DEFAULT_ART_STYLE_ID,
      interpretationStyleId: DEFAULT_INTERPRETATION_STYLE_ID,
    }

    const updatedUser = await FastUserService.fastUserUpdate(
      userId,
      userPatch,
    ).catch((err) => {
      console.log('apiUsersIdPut error: ', err)
      elsewhereToast.showToast({
        description: 'common.errorGeneric',
        status: 'error',
      })
    })

    if (updatedUser) {
      const fastUser = await FastUserService.fastUser(userId)
      dispatch(setUser(fastUser))
    } else {
      elsewhereToast.showToast({
        description: 'common.errorGeneric',
        status: 'error',
      })
    }
  }
}

// Update user
export function updateUser(
  userId: string,
  patchData: Partial<FastUser>,
  showToast: boolean = true,
): AppThunk {
  return async (dispatch: any, getState) => {
    FastUserService.fastUserUpdate(userId, patchData)
      .then(async (updatedUser) => {
        dispatch(setUser(updatedUser))
        const state = await getState()
        const membership: FastMembership | undefined =
          selectUserMembershipInGroup(userId, updatedUser.userGroupId)(state)
        if (membership) {
          dispatch(
            addOrUpdateMembership({
              ...membership,
              displayName: updatedUser?.username,
              bio: updatedUser?.bio,
              updatedAt: updatedUser?.updatedAt,
              imageId: updatedUser?.profileImageId,
              imageUrl: updatedUser?.profileImageUrl,
              imageAlt: updatedUser?.profileImageAlt,
            }),
          )
        }
        if (showToast) {
          elsewhereToast.showToast({
            description: 'toast.updateUserSuccess.description',
            status: 'success',
          })
        }
      })
      .catch((err: any) => {
        console.log('apiUsersIdPut error: ', err)
        if (showToast) {
          elsewhereToast.showToast({
            title: 'toast.updateUserError.title',
            description: truncateString(err.message, 100),
            status: 'error',
          })
        }
      })
  }
}

// Put download code on user
export function putDownloadCodeOnUser(
  userId: string,
  format: DownloadFormat,
  downloadCode: string,
): AppThunk {
  return async (dispatch: any) => {
    // @ts-ignore, we don't need to pass the whole user object
    FastUserService.fastUserUpdate(userId, { downloadCode })
      .then((updatedUser) => {
        dispatch(setUser(updatedUser))
        // Set export link in store
        // 1) Get current time plus 5 minutes (as number)
        const currentTime = new Date()
        const fiveMinutesFromNow = new Date(
          currentTime.getTime() + 5 * 60000,
        ).getTime()

        const exportLink: ExportLink = {
          code: downloadCode,
          format: format,
          expiry: fiveMinutesFromNow,
          loading: false,
        }
        dispatch(setExportLink(exportLink))
      })
      .catch((err) => {
        console.log('apiUsersIdPut error: ', err)
        elsewhereToast.showToast({
          title: 'toast.updateUserError.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })
  }
}

// Update dreamer profile if it exists
// If it doesn't exist, create a new one
export function upsertDreamerProfile(
  oldDreamerProfileId: string,
  patchData: DreamerProfileEditType,
  userId: string,
  showToast: boolean = true,
): AppThunk {
  return async (dispatch: any) => {
    // 1)
    // First, we need to update the old dreamer profile
    // With a new updatedAt date. This 'closes' the old profile
    const oldProfilePatch = {
      updatedAt: formatDateTimeForDatabase(new Date()),
    }
    const oldProfile = await DreamerProfileService.apiDreamerProfilesIdPut(
      oldDreamerProfileId,
      // @ts-ignore, whole object is not needed
      oldProfilePatch,
    ).catch((err) => {
      console.log(`We probably didn't have a profile yet: `, err)
    })

    // 2)
    // Create a new profile
    const oldProfileData = oldProfile || ({} as DreamerProfile)
    const { id, updatedAt, createdAt, ...basicOldData } = oldProfileData
    const newProfileData = {
      ...basicOldData,
      ...patchData,
      user: decorateId(userId, 'users'),
    } as DreamerProfile
    const newProfile = await DreamerProfileService.apiDreamerProfilesPost(
      newProfileData,
    ).catch((err) => {
      elsewhereToast.showToast({
        title: 'toast.createDreamerProfileError.title',
        description: truncateString(err.message, 100),
        status: 'error',
      })
    })

    // 3)
    // Put the new profile in the store
    if (newProfile) {
      dispatch(setCurrentUserProfile(newProfile))

      if (showToast) {
        elsewhereToast.showToast({
          description: 'toast.updateDreamerProfileSuccess.description',
          status: 'success',
        })
      }
    }
  }
}

// Delete user
export function deleteUser(userId: string | undefined): AppThunk {
  return async (dispatch: any) => {
    if (!userId) {
      elsewhereToast.showToast({
        title: 'toast.deleteUserError.title',
        description: 'No user id provided',
        status: 'error',
      })
      return
    }
    FastUserService.fastUserDelete(userId)
      .then(() => {
        elsewhereToast.showToast({
          description: 'toast.deleteUserSuccess.description',
          status: 'success',
        })
      })
      .catch((err) => {
        console.log('apiUsersIdDelete error: ', err)
        elsewhereToast.showToast({
          title: 'toast.deleteUserError.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })
  }
}

export function notificationRegisterDevice(token: string) {
  if (!token) {
    console.log('notificationRegisterDevice error: no token provided')
    return
  }
  DeviceRegistrationService.notificationRegisterDevice({ token })
    .then(() => {
      store.dispatch(setNotificationToken(token))
      console.log('notificationRegisterDevice success')
    })
    .catch((err) => {
      console.log('notificationRegisterDevice error: ', err)
    })
}

export function notificationUnregisterDevice(token: string) {
  if (!token) {
    console.log('notificationUnregisterDevice error: no token provided')
    return
  }
  DeviceRegistrationService.notificationUnregisterDevice({ token })
    .then(() => {
      console.log('notificationUnregisterDevice success')
    })
    .catch((err) => {
      console.log('notificationUnregisterDevice error: ', err)
    })
}

export function notificationRefreshDevice(
  old_token: string,
  new_token: string,
) {
  if (!old_token || !new_token) {
    elsewhereToast.showToast({
      title: 'toast.errorRefreshingDeviceToken.title',
      description: 'Both tokens must be provided',
      status: 'error',
    })
    return
  }
  DeviceRefreshService.notificationRefreshDevice({
    old_token,
    new_token,
  })
    .then(() => {
      elsewhereToast.showToast({
        description: 'toast.refreshingDeviceTokenSuccess.description',
        status: 'success',
      })
    })
    .catch((err) => {
      console.log('notificationRefreshDevice error: ', err)
      elsewhereToast.showToast({
        description: 'toast.errorRefreshingDeviceToken.title',
        status: 'error',
      })
    })
}
