import React, { memo, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { FastTagDescription, FastUser } from '../../../../../api/_openapi'
import { useTranslation } from 'react-i18next'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { FeedEntryGeneric } from './feed-entry-generic'
import { fetchFastUserById } from '../../../ducks/user/functions/user-functions'
import {
  FeedItemInterpretationRouteProp,
  RootStackParamList,
} from '../../../navigation/types'
import {
  GlobalUserInfo,
  PublicUserKey,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import {
  PUBLIC_USER_INFO,
  moonTransparentImg,
} from '../../../constants/ui-constants'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'
import i18n from '../../../i18n/i18nnext'

type FeedItemInterpretationProps = {
  route: FeedItemInterpretationRouteProp
  tagDescription: FastTagDescription
  maxChars?: number
  index: number
}
const FeedItemSymbolInterpretation = ({
  route,
  tagDescription,
  index,
}: FeedItemInterpretationProps) => {
  // STATE
  const [authorInfo, setAuthorInfo] = useState<GlobalUserInfo | null>(null)
  const [author, setAuthor] = useState<FastUser | null>(null)

  // HOOKS
  const { t } = useTranslation()

  // VARS
  const authorLabel = t(authorInfo?.i18nKey || '')
  const userLanguage = i18n.resolvedLanguage || 'en'

  useEffect(() => {
    if (tagDescription.authorId) {
      fetchFastUserById(tagDescription.authorId).then((user) => {
        if (user) {
          setAuthor(user)
          const globalUser: GlobalUserInfo | undefined =
            PUBLIC_USER_INFO[user.username as PublicUserKey]
          if (globalUser) {
            setAuthorInfo(globalUser)
          } else {
            setAuthorInfo({
              img: moonTransparentImg,
              i18nKey: 'common.errorGeneric',
            })
          }
        } else {
          setAuthorInfo({
            img: moonTransparentImg,
            i18nKey: 'common.errorGeneric',
          })
        }
      })
    }
  }, [tagDescription.authorId])

  const linkObject = {
    screen: 'PrivateSymbolInterpretationView' as keyof RootStackParamList,
    params: { id: tagDescription.id },
  }

  const userInfoLinkObject = {
    screen: 'PrivateDreamerView' as keyof RootStackParamList,
    params: {
      groupId: undefined,
      userId: author?.id,
    },
  }

  const userInfoLinkProps = {
    to: linkObject,
  }

  const { onPress: avatarOnPress } = useLinkProps(userInfoLinkProps)

  return (
    <ConditionalLink to={linkObject} condition={Platform.OS !== 'web'}>
      <FeedEntryGeneric
        inGroup={false}
        language="en" // Not translated yet
        avatarLabelLanguage={userLanguage as SupportedLanguage}
        description={tagDescription.description}
        avatarLabel={authorLabel}
        linkObject={linkObject}
        hasTopDivider={index !== 0}
        truncate={true}
        avatarCircleSrc={authorInfo?.img}
        authorImgLoading={!authorInfo}
        userInfoLinkObject={userInfoLinkObject}
        avatarOnPress={avatarOnPress}
      />
    </ConditionalLink>
  )
}

export default FeedItemSymbolInterpretation
