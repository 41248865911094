import { useEffect } from 'react'
import { useSelector } from './ducks/root-reducer'
import { selectNotificationToken, selectUser } from './ducks/user/user'
import { initialiseNotifications } from './services/notification-service'

export function NotificationsInit({ children }: any) {
  const user = useSelector(selectUser)
  const notificationToken = useSelector(selectNotificationToken)

  useEffect(() => {
    if (user && !notificationToken) {
      try {
        initialiseNotifications()
      } catch (err) {
        console.log('Error initialising notifications', err)
      }
    }
  }, [user])
  return children
}
