import React from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { dreamerProfileForm } from '../../../forms/user-forms'
import { selectCurrentUserProfile, selectUser } from '../../../ducks/user/user'
import {
  updateUser,
  upsertDreamerProfile,
} from '../../../ducks/user/thunks/user-thunks'
import { decorateId, undecorateId } from '../../../../../api/decorate-ids'
import { selectGenderOptions } from '../../../ducks/ui/ui'
import { shallowEqualsUser } from '../../../ducks/shallowEqual'
import { useTranslation } from 'react-i18next'
import { DreamerProfileEditType } from '../../../../../api/frontend-types'
import { RootStackScreenProps } from '../../../navigation/types'

export default function DreamerProfileEdit({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDreamerProfileEdit'>) {
  const [loading, setLoading] = React.useState(false)
  const user = useSelector(selectUser, shallowEqualsUser)
  const currentUserProfile = useSelector(selectCurrentUserProfile)
  const { t } = useTranslation()
  const genderOptions = useSelector(selectGenderOptions)
  const defaultGenderId =
    genderOptions?.find((x) => x.label.includes('not to answer'))?.value || ''

  const dispatch = useDispatch<any>()

  const onSubmitForm = (data: any) => {
    setLoading(true)

    const { gender, country, personalityType, birthdate } = data

    let userGender,
      genderDisplay = ''
    const { values = [], freeOption = '', freeOptionText = '' } = gender

    if (gender) {
      if (values[0] === freeOption && freeOptionText) {
        userGender = freeOption
        genderDisplay = freeOptionText
      } else {
        userGender = values[0] || defaultGenderId
      }
    }

    const dreamerProfilePatch = {
      ...{ country },
      ...{ personalityType },
      gender: decorateId(userGender as string, 'genders'),
      genderDisplay,
    } as DreamerProfileEditType
    const promises = []
    promises.push(
      dispatch(
        updateUser(
          user?.id || '',
          {
            birthdate,
          },
          false,
        ),
      ),
    )
    promises.push(
      dispatch(
        upsertDreamerProfile(
          currentUserProfile?.id || '',
          dreamerProfilePatch,
          user?.id || '',
        ),
      ),
    )

    Promise.all(promises).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Form
      name={'dreamerProfileForm'}
      form={dreamerProfileForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        birthdate: user?.birthdate || '',
        country: currentUserProfile?.country || '',
        personalityType: (currentUserProfile?.personalityType || '').trim(),
        gender: {
          freeOption: '0186c294-124d-7022-8c29-e523505a42d0',
          freeOptionText: currentUserProfile?.genderDisplay || '',
          values: [undecorateId(currentUserProfile?.gender)],
        },
      }}
      headerTitle={t('settings.editPersonalInfo')}
    />
  )
}
