import Clipboard from '@react-native-clipboard/clipboard'
import { elsewhereToast } from '../../components/common/toast/toast'

export const copyToClipboard = (str: string, t: any) => {
  Clipboard.setString(str)
  elsewhereToast.showToast({
    description: t('toast.copyToClipboard.title'),
    status: 'success',
  })
}
