import React from 'react'
import { Path } from 'react-native-svg'
import { Icon } from '../../components/common/icon/icon'

export function Scan({
  color = 'currentColor',
  size = 24,
}: {
  color: string
  size: number
}) {
  return (
    <Icon width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 16V21H3V16H5V19H19V16H21ZM3 11H21V13H3V11ZM21 8H19V5H5V8H3V3H21V8Z"
        fill={color}
      />
    </Icon>
  )
}
