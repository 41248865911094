import React, { useState, useEffect } from 'react'
import { TabProps } from '../../components/layout/tab-view-tabs'
import { TabView } from '../../components/layout/tab-view'
import { useTranslation } from 'react-i18next'
import { Tabs } from '../../components/layout/tab-view-tabs'
import { OverviewCharts } from './overview-charts'
import { useSelector } from '../../ducks/root-reducer'
import { selectUserIsPremium } from '../../ducks/user/user'
import { SentimentCharts } from './sentiment-charts'
import { DateRangeKey, DateRangeWithKey } from '../../../../api/frontend-types'
import { View } from 'react-native'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { DateRangeSelect } from '../../components/composite/chart/date-range-select'
import { chooseDateRange, dateRangeFromKey } from './chart-helpers'
import { TagCharts } from './tag-charts'
import { FEW } from '../../i18n/config'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import { useDreams } from '../../hooks/useDreams'
import { LoadingPage } from '../../components/layout/loading-page'
import { selectPrivateGroupId } from '../../ducks/groups/groups'

export type ChartsNavigationProp = MainStackNavigationProp<'PrivateCharts'>

export default function Charts({
  navigation,
  route,
}: MainStackScreenProps<'PrivateCharts'>) {
  const groupIdFromRoute = route.params?.groupId
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = groupIdFromRoute || privateGroupId

  // STATE
  const [dateRangeKey, setDateRangeKey] =
    useState<DateRangeKey>('LAST_THREE_MONTHS')
  const [dateRangeWithKey, setDateRangeWithKey] = useState<DateRangeWithKey>({
    range: dateRangeFromKey(dateRangeKey),
    key: dateRangeKey,
  })

  // HOOKS
  const { t } = useTranslation()
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const { dreams, loading } = useDreams(activeGroupId, false)

  // SELECTORS
  const userIsPremium = useSelector(selectUserIsPremium)
  // Set date range key based on dreams
  useEffect(() => {
    if (loading) return
    const bestDateRange = chooseDateRange(dreams)
    setDateRangeKey(bestDateRange)
  }, [dreams, loading])

  // Set date range with key
  // Based on dateRangeKey
  useEffect(() => {
    setDateRangeWithKey({
      range: dateRangeFromKey(dateRangeKey),
      key: dateRangeKey,
    })
  }, [dateRangeKey])

  // COMPONENTS
  const OverviewTab = () => (
    <Tabs.ScrollView>
      <OverviewCharts dateRangeWithKey={dateRangeWithKey} />
    </Tabs.ScrollView>
  )

  const TagsTab = () => (
    <Tabs.ScrollView>
      <TagCharts dateRangeWithKey={dateRangeWithKey} />
    </Tabs.ScrollView>
  )

  const SentimentTab = () => (
    <Tabs.ScrollView>
      <SentimentCharts dateRangeWithKey={dateRangeWithKey} />
    </Tabs.ScrollView>
  )

  const tabsToRender: {
    [key: string]: boolean
  } = {
    Overview: true,
    Tags: true,
    Sentiment: userIsPremium,
  }

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Overview',
      children: <OverviewTab />,
      label: t('common.overview'),
    },
    {
      name: 'Tags',
      children: <TagsTab />,
      label: t('common.tag_plural', { count: FEW }),
    },
    {
      name: 'Sentiment',
      children: <SentimentTab />,
      label: t('common.sentiment'),
    },
  ].filter((tab) => tabsToRender[tab.name])

  // HEADER
  const StatsPageHeader = () => {
    return (
      <View
        style={{
          backgroundColor,
          maxWidth: WEB_MAX_WIDTH,
          width: '100%',
          alignItems: 'center',
          padding: 16,
        }}
      >
        <DateRangeSelect
          onChange={(dateRangeWithKey: any) => {
            setDateRangeKey(dateRangeWithKey.key)
          }}
          value={dateRangeWithKey}
        />
      </View>
    )
  }

  if (loading) {
    return <LoadingPage hasQuote={true} />
  }

  return (
    <TabView
      header={() => <StatsPageHeader />}
      tabs={tabs}
      scenes={{
        Overview: OverviewTab,
        Tags: TagsTab,
        Sentiment: SentimentTab,
      }}
    />
  )
}
