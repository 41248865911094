import React from 'react'
import { useColorModeValue } from 'native-base'
import { StyleSheet } from 'react-native'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../../common/copy/text-sans'
import { Box } from '../../common/box/box'
import { Column } from '../../common/column/column'
import { ScriptType } from '../../../../../api/frontend-types'

export type FactoidLayout = 'fact-text' | 'text-fact-text' | 'fact-text-text'

type FactoidProps = {
  Icon: ({ color, size }: { color: string; size: number }) => JSX.Element
  factoidFact: string
  textLineOne: string
  textLineTwo?: string
  layout: FactoidLayout
  factoidFactScriptType?: ScriptType // If it's a regular number etc, we use latin font
}

export const Factoid = ({
  Icon,
  factoidFact,
  textLineOne,
  textLineTwo,
  layout,
  factoidFactScriptType,
}: FactoidProps) => {
  const color = useColorModeValue(BLACK, WHITE)

  // The factoid "fact", e.g. "20%"
  const factComponent = (
    <SansText
      fontSize={'md'}
      style={styles.factLine}
      scriptType={factoidFactScriptType}
    >
      {factoidFact}
    </SansText>
  )

  // Line 1 component
  const lineOneComponent = (
    <SansText
      fontSize={'2xs'}
      lineHeight={'2xs'}
      style={styles.textLine}
      paddingX={2}
    >
      {textLineOne.toLocaleUpperCase()}
    </SansText>
  )

  // Line 2 component
  const lineTwoComponent = (
    <SansText
      fontSize={'2xs'}
      lineHeight={'2xs'}
      style={styles.textLine}
      paddingX={2}
    >
      {textLineTwo?.toLocaleUpperCase()}
    </SansText>
  )

  return (
    <Column
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      // backgroundColor={'amber.600'}
      paddingBottom={2}
    >
      <Box
        height={10}
        alignItems={'center'}
        // backgroundColor={'amber.200'}
        marginTop={2}
        marginBottom={2}
      >
        <Icon color={color} size={35} />
      </Box>
      <Column>
        {layout === 'fact-text' && (
          <>
            {factComponent}
            {lineOneComponent}
          </>
        )}

        {layout === 'text-fact-text' && (
          <>
            {lineOneComponent}
            {factComponent}
            {lineTwoComponent}
          </>
        )}

        {layout === 'fact-text-text' && (
          <>
            {factComponent}
            {lineOneComponent}
            {lineTwoComponent}
          </>
        )}
      </Column>
    </Column>
  )
}

const styles = StyleSheet.create({
  textLine: {
    textAlign: 'center',
  },
  factLine: {
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 6,
  },
})
