import React from 'react'
import { IActionsheetProps } from 'native-base/lib/typescript/components/composites/Actionsheet/types'
import { Actionsheet as NbActionSheet } from 'native-base'

export type ActionsheetProps = IActionsheetProps

// This is a simple wrapper around native-base's IconButton component
const Actionsheet = ({ children, ...rest }: ActionsheetProps) => {
  return <NbActionSheet {...rest}>{children}</NbActionSheet>
}

Actionsheet.Content = NbActionSheet.Content
Actionsheet.Item = NbActionSheet.Item

export { Actionsheet }
