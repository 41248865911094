import React, { useState } from 'react'
import { FlatList, Platform, Pressable, Keyboard } from 'react-native'
import { useTranslation } from 'react-i18next'
import { RouteProp } from '@react-navigation/native'
import { Loading } from '../../components/layout/loading'
import { NoContentMessage } from '../../components/layout/no-content-message'
import { InputSearch } from '../../components/common/inputs/input-search'
import { Quote } from '../../components/layout/quote'
import { LINE_WIDTH, WEB_MAX_WIDTH } from '../../constants/constants'
import { DreamSearchService, FastDream } from '../../../../api/_openapi'
import FeedItemDream from '../../components/composite/feed/feed-item-dream'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { useIsConnected } from '../../hooks/useIsConnected'
import Fuse from 'fuse.js'
import { Row } from '../../components/common/row/row'
import { ButtonIcon } from '../../components/common/buttons/button-icon'
import useContentWidth from '../../hooks/useContentWidth'
import {
  selectPrivateGroupId,
  selectUserPrivateGroup,
} from '../../ducks/groups/groups'
import {
  MainStackNavigationProp,
  MainStackParamList,
  MainStackScreenProps,
} from '../../navigation/types'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../../components/common/action-sheet/action-sheet-menu'

export type SearchComponentNavigationProp =
  MainStackNavigationProp<'PrivateSearch'>
export type SearchComponentRouteProp = RouteProp<
  MainStackParamList,
  'PrivateSearch'
>

type SearchComponentProps = MainStackScreenProps<'PrivateSearch'> & {
  dreams: FastDream[]
}

type SearchOrder = 'relevance' | 'newest' | 'oldest'

function SearchComponent({ navigation, route, dreams }: SearchComponentProps) {
  // STATE
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOrder, setSortOrder] = useState<SearchOrder>('relevance')
  const [hasSearched, setHasSearched] = useState(false)
  const [searchRes, setSearchRes] = useState<FastDream[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState(false)
  const isConnected = useIsConnected()

  // HOOKS
  const { t } = useTranslation()

  // SELECTORS
  const appUser = useSelector(selectUser)
  const privateGroupId = useSelector(selectPrivateGroupId)
  const privateGroup = useSelector(selectUserPrivateGroup)
  const contentWidth = useContentWidth()

  // VARS
  const searchBarAreaWidth = contentWidth.paddedContentWidth
  const filterButtonWidth = 60
  const isFiltering = sortOrder !== 'relevance'
  const hasDreams = dreams.length > 0
  const cheekyMsg = t('searchPage.cheekyMessage')
  const basicNoResMsg = t('searchPage.noResultsMessage', {
    queryParam: searchTerm,
  })
  const noResMsg = hasDreams ? basicNoResMsg : basicNoResMsg + cheekyMsg

  const fuseOptions = {
    keys: [
      { name: 'description', weight: 0.75 },
      { name: 'note', weight: 0.5 },
      'title',
      { name: 'tags.name', weight: 0.5 },
    ],
    threshold: 0.1,
    minMatchCharLength: 2,
    ignoreLocation: true,
  }

  const handleSearch = async (sortOrder: string) => {
    setIsLoading(true)
    setHasSearched(true)
    try {
      const results = await searchAsync(searchTerm, sortOrder)
      setSearchRes(results)
    } catch (error) {
      console.error('Search error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchAsync = async (
    term: string,
    sortOrder: string,
  ): Promise<FastDream[]> => {
    if (isConnected) {
      let sortBy = ''
      if (sortOrder !== 'relevance') {
        sortBy = 'date'
      }

      let sortDir = ''
      if (sortBy === 'date') {
        sortDir = sortOrder === 'newest' ? 'desc' : 'asc'
      }

      // test search and log results
      const esResults = await DreamSearchService.dreamSearch({
        searchText: [term],
        sortBy,
        sortDir,
      })
      // @ts-ignore
      const esDreamIds = esResults.dreams?.map((dream) => dream._id)

      const searchResults: FastDream[] = []

      // check every esDreamId and add the dream to the searchResults
      esDreamIds?.forEach((id) => {
        const dream = dreams.find((dream) => dream.id === id)
        if (dream) {
          searchResults.push(dream)
        }
      })

      return searchResults
    } else {
      const fuse = new Fuse(dreams, fuseOptions)
      return fuse.search(term).map((result) => result.item)
    }
  }

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term)
    setHasSearched(false)
  }

  const shouldShowQuote = searchTerm === '' || !hasSearched
  const shouldShowResults = hasSearched && !isLoading && searchRes.length > 0

  // SEARCH ORDER
  const sortOrderOptions: SearchOrder[] = ['relevance', 'newest', 'oldest']
  const optionTranslations: { [key in SearchOrder]: string } = {
    relevance: t('searchPage.sortBy.relevance'),
    newest: t('searchPage.sortBy.newest'),
    oldest: t('searchPage.sortBy.oldest'),
  }
  const options: ActionSheetMenuItem[] = sortOrderOptions.map((option) => ({
    label: optionTranslations[option],
    onPress: () => {
      setSortOrder(option)
      handleSearch(option)
      setOptionsMenuIsOpen(false)
    },
  }))

  return (
    <>
      <Pressable
        style={{
          flex: 1,
        }}
        onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      >
        <Row
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={6}
          mb={6}
          mx="auto"
          width={searchBarAreaWidth}
        >
          <InputSearch
            value={searchTerm}
            onChangeText={handleSetSearchTerm}
            placeholder={t('searchPage.searchPlaceholder')}
            onSubmit={() => handleSearch(sortOrder)}
            onSubmitEditing={() => handleSearch(sortOrder)}
            onClear={() => {
              setSearchTerm('')
              setHasSearched(false)
            }}
            showClearButton={searchTerm !== ''}
            wrapperWidth={searchBarAreaWidth - filterButtonWidth}
            maxWidth={WEB_MAX_WIDTH}
            autoFocus
          />
          <Row
            justifyContent="flex-end"
            alignItems="center"
            style={{
              width: filterButtonWidth,
            }}
          >
            <ButtonIcon
              iconKey="filter"
              onPress={() => {
                setOptionsMenuIsOpen(true)
              }}
              variant={isFiltering ? 'filled' : 'placeholder'}
            />
          </Row>
        </Row>

        {isLoading && (
          <Row paddingLeft={4} paddingRight={4} justifyContent="center">
            <Loading />
          </Row>
        )}
        {shouldShowQuote && (
          <Quote
            quote={t('searchPage.quote.text')}
            author={t('searchPage.quote.author')}
            source={t('searchPage.quote.source')}
            mt={32}
          />
        )}
        {!isLoading && searchRes.length === 0 && hasSearched && (
          <Row
            paddingLeft={4}
            paddingRight={4}
            marginTop={12}
            justifyContent="center"
          >
            <NoContentMessage message={noResMsg} />
          </Row>
        )}
        {shouldShowResults && (
          <FlatList
            data={searchRes}
            keyExtractor={(item, i) => (item?.id || '').toString() + i}
            contentContainerStyle={{
              marginTop: LINE_WIDTH * 3,
              flex: !hasDreams ? 1 : undefined,
              height: !hasDreams ? '100%' : undefined,
            }}
            renderItem={({ item, index }) =>
              appUser ? (
                <FeedItemDream
                  route={route}
                  dream={item}
                  index={index}
                  privateGroupId={privateGroupId}
                  currentGroup={privateGroup}
                  appUser={appUser}
                  activeGroupId={privateGroupId || ''}
                />
              ) : null
            }
            initialNumToRender={5}
            // @ts-ignore
            dataSet={{ scrollbarFlatListAdjust: true }}
          />
        )}
      </Pressable>
      {optionsMenuIsOpen && (
        <ActionSheetMenu
          isOpen={optionsMenuIsOpen}
          onClose={() => setOptionsMenuIsOpen(false)}
          heading={t('searchPage.sortBy.header')}
          menuItems={options}
        />
      )}
    </>
  )
}

export default SearchComponent
