import { WEB_MAX_WIDTH } from '@elsewhere/components/src/constants/constants'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  span[data-focus-scope-start='true'] + div {\n
    max-width: ${WEB_MAX_WIDTH}px;\n
    margin: auto;\n
  }\n
`

export default GlobalStyle
