import React from 'react'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { TabProps } from '../../../components/layout/tab-view-tabs'
import { TabView } from '../../../components/layout/tab-view'
import { useTranslation } from 'react-i18next'
import { InsightTab } from './InsightTab'
import { InsightMenuGrid } from './InsightMenuGrid'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { MainStackScreenProps } from '../../../navigation/types'

export default function InsightsMain({
  navigation,
  route,
}: MainStackScreenProps<'PrivateInsights'>) {
  // HOOKS
  const { t } = useTranslation()
  const user = useSelector(selectUser)

  // SELECTORS
  const subscriptionStatus = useSelector(selectSubscriptionStatus)

  const headerKey = `s${subscriptionStatus?.creditsRemaining}-${subscriptionStatus?.insightsRemaining}`

  // COMPONENTS
  const RecentInsightTab = () => (
    <InsightTab
      user={user}
      route={route}
      noInsightsMsg={t('insightsPage.emptyInsightsCharacter')}
      showLocked={true}
      subscriptionStatus={subscriptionStatus}
    />
  )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Recent',
      children: <RecentInsightTab />,
      label: t('insights.recent'),
    },
  ]

  const header = (
    <InsightMenuGrid
      key={headerKey}
      subscriptionStatus={subscriptionStatus}
      navigation={navigation}
    />
  )

  return (
    <TabView
      header={() => {
        return header
      }}
      tabs={tabs}
      scenes={{
        Recent: RecentInsightTab,
      }}
    />
  )
}
