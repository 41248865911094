import React from 'react'
import { Keyboard, BackHandler } from 'react-native'
import { Close } from '../../../assets/react-native-svg/Close'
import { useTranslation } from 'react-i18next'
import { UseFormGetValues } from 'react-hook-form'
import { SansText } from '../copy/text-sans'
import { useFocusEffect } from '@react-navigation/native'
import { Button } from '../nb-button/nb-button'
import { IconButton } from '../icon-button/icon-button'

type HeaderLeftProps = {
  isFirstPage: boolean
  onPreviousPage: () => void
  navigation: any
  color: string
  name: string | undefined
  reset?: () => void
  isDisabled?: boolean
  confirmCancelMessage?: string
  setCancelMsgDialogOpen?: (cancelMsgDialogOpen: boolean) => void
  onCancelAndExitForm?: () => void
  getValues?: UseFormGetValues<any>
  checkOnCancelFields?: string[]
  defaultValues?: any
}

export const HeaderLeft = React.memo(
  ({
    isFirstPage,
    onPreviousPage,
    color,
    isDisabled,
    setCancelMsgDialogOpen,
    onCancelAndExitForm,
    getValues,
    confirmCancelMessage,
    checkOnCancelFields = [],
    defaultValues,
  }: HeaderLeftProps) => {
    // HOOKS
    const { t } = useTranslation()

    // use getValues and checkOnCancelFields to check if there is content in the form
    const formHasLosableContent = (checkOnCancelFields: string[]) => {
      if (getValues && checkOnCancelFields && Boolean(confirmCancelMessage)) {
        const formValues = getValues()
        const formHasLosableContent = checkOnCancelFields.some(
          (field: string) => {
            return (
              Boolean(formValues[field]) &&
              formValues[field] !== defaultValues[field]
            )
          },
        )
        return formHasLosableContent
      } else {
        return false
      }
    }

    function checkForLosableContent() {
      if (formHasLosableContent(checkOnCancelFields || [])) {
        setCancelMsgDialogOpen && setCancelMsgDialogOpen(true)
      } else {
        onCancelAndExitForm && onCancelAndExitForm()
      }
    }

    useFocusEffect(
      React.useCallback(() => {
        const onBackPress = () => {
          checkForLosableContent()
          return true
        }

        const subscription = BackHandler.addEventListener(
          'hardwareBackPress',
          onBackPress,
        )

        return () => subscription.remove()
      }, [setCancelMsgDialogOpen]),
    )

    // If it's the first page, show the cancel button
    // Otherwise, show the back button
    if (isFirstPage) {
      return (
        <IconButton
          color={color}
          variant="unstyled"
          size="sm"
          ml={-3}
          onPress={() => {
            Keyboard.dismiss()
            checkForLosableContent()
          }}
          _pressed={{
            opacity: 0.5,
          }}
          icon={<Close color={color} size={24} />}
          isDisabled={isDisabled}
        />
      )
    } else {
      return (
        <Button
          ml={-3}
          variant="unstyled"
          onPress={onPreviousPage}
          _pressed={{
            opacity: 0.5,
          }}
          isDisabled={isDisabled}
        >
          <SansText fontSize={'sm'}>{t('common.previous')}</SansText>
        </Button>
      )
    }
  },
)
