/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArtStyle } from '../models/ArtStyle';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ArtStyleService {
  /**
   * Retrieves the collection of ArtStyle resources.
   * Retrieves the collection of ArtStyle resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns ArtStyle ArtStyle collection
   * @throws ApiError
   */
  public static apiArtStylesGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<ArtStyle>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/art_styles',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a ArtStyle resource.
   * Creates a ArtStyle resource.
   * @param requestBody The new ArtStyle resource
   * @returns ArtStyle ArtStyle resource created
   * @throws ApiError
   */
  public static apiArtStylesPost(
    requestBody: ArtStyle,
  ): CancelablePromise<ArtStyle> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/art_styles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a ArtStyle resource.
   * Retrieves a ArtStyle resource.
   * @param id ArtStyle identifier
   * @returns ArtStyle ArtStyle resource
   * @throws ApiError
   */
  public static apiArtStylesIdGet(
    id: string,
  ): CancelablePromise<ArtStyle> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/art_styles/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the ArtStyle resource.
   * Replaces the ArtStyle resource.
   * @param id ArtStyle identifier
   * @param requestBody The updated ArtStyle resource
   * @returns ArtStyle ArtStyle resource updated
   * @throws ApiError
   */
  public static apiArtStylesIdPut(
    id: string,
    requestBody: ArtStyle,
  ): CancelablePromise<ArtStyle> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/art_styles/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the ArtStyle resource.
   * Removes the ArtStyle resource.
   * @param id ArtStyle identifier
   * @returns void
   * @throws ApiError
   */
  public static apiArtStylesIdDelete(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/art_styles/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Updates the ArtStyle resource.
   * Updates the ArtStyle resource.
   * @param id ArtStyle identifier
   * @param requestBody The updated ArtStyle resource
   * @returns ArtStyle ArtStyle resource updated
   * @throws ApiError
   */
  public static apiArtStylesIdPatch(
    id: string,
    requestBody: ArtStyle,
  ): CancelablePromise<ArtStyle> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/art_styles/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/merge-patch+json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
}
