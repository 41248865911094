import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'
import { ColorMode } from '../../../../../api/frontend-types'
import { Row } from '../../common/row/row'

const blackPhoneFrame = require('../../../assets/images/frame-black-iphone12.png')
const whitePhoneFrame = require('../../../assets/images/frame-white-iphone12.png')
const symbolsBlack = require('../../../assets/images/symbols-black.png')
const interpBlack = require('../../../assets/images/interp-black.png')
const chartsBlack = require('../../../assets/images/charts-black.png')
const chartsWhite = require('../../../assets/images/charts-white.png')
const feedBlack = require('../../../assets/images/feed-black.png')
const feedWhite = require('../../../assets/images/feed-white.png')
const interpWhite = require('../../../assets/images/interp-white.png')
const sidebarBlack = require('../../../assets/images/sidebar-black.png')
const sidebarWhite = require('../../../assets/images/sidebar-white.png')
const symbolsWhite = require('../../../assets/images/symbols-white.png')

export type FeatureScreen = 'symbols' | 'interp' | 'charts' | 'feed' | 'sidebar'

type ImageMap = {
  [key in FeatureScreen]: {
    light: ImageSourcePropType
    dark: ImageSourcePropType
  }
}

const imageMap: ImageMap = {
  symbols: {
    light: symbolsWhite,
    dark: symbolsBlack,
  },
  interp: {
    light: interpWhite,
    dark: interpBlack,
  },
  charts: {
    light: chartsWhite,
    dark: chartsBlack,
  },
  feed: {
    light: feedWhite,
    dark: feedBlack,
  },
  sidebar: {
    light: sidebarWhite,
    dark: sidebarBlack,
  },
}

const frameMap: Record<ColorMode, ImageSourcePropType> = {
  light: blackPhoneFrame,
  dark: whitePhoneFrame,
}

type ScreenshotInPhoneProps = {
  imageKey: keyof typeof imageMap
  width?: number
  colorMode: 'light' | 'dark'
}
// Inner image dimensions: 591 × 1279
// Phone frame dimensions: 990 × 2006

export const ScreenshotInPhone = ({
  imageKey,
  width = 200,
  colorMode,
}: ScreenshotInPhoneProps) => {
  // Phone frame
  const frameRatio = 990 / 2006
  const frameHeight = width / frameRatio

  // Inner image (on the phone screen)
  const innerImgWidth = width * 0.9
  const innerImgHPadding = (width - innerImgWidth) / 2
  const innerImgRatio = 591 / 1279
  const innerImgHeight = innerImgWidth / innerImgRatio
  const innerImgVPadding = (frameHeight - innerImgHeight) / 2

  return (
    <Row
      style={{
        position: 'relative',
      }}
    >
      {/* IMAGE ON SCREEN */}
      <Image
        source={imageMap[imageKey][colorMode]}
        style={{
          position: 'absolute',
          width: innerImgWidth,
          height: innerImgHeight,
          left: innerImgHPadding,
          top: innerImgVPadding,
        }}
      />
      {/* PHONE FRAME */}
      <Image
        source={frameMap[colorMode]}
        height={innerImgHeight}
        width={width}
        style={{
          height: frameHeight,
          width: width,
        }}
      />
    </Row>
  )
}
