import { ArtStyle, InterpretationStyle } from '../../../../../api/_openapi'
import { SubscriptionTierKey } from '../../../../../api/frontend-types'

type ArtStyleIdMap = { [key in SubscriptionTierKey]: string[] }

export function bucketArtStylesByTier(artStyles: ArtStyle[]) {
  const tierMap: ArtStyleIdMap = {
    free: [],
    premium: [],
    supporter: [],
    temp: [],
  }

  let defaultArtStyleId = ''

  artStyles.forEach((artStyle) => {
    if (artStyle.name === 'default') {
      defaultArtStyleId = artStyle.id
    }

    if (artStyle.model === 'dall-e-3') {
      tierMap.premium.push(artStyle.id)
      tierMap.supporter.push(artStyle.id)
    } else {
      tierMap.free.push(artStyle.id)
    }
  })

  return {
    ...tierMap,
    default: defaultArtStyleId,
  }
}

export function bucketInterpretationStylesByTier(
  interpretationStyles: InterpretationStyle[],
) {
  const tierMap: { [key in SubscriptionTierKey]: string[] } = {
    free: [],
    premium: [],
    supporter: [],
    temp: [],
  }

  let defaultInterpretationStyleId = ''

  interpretationStyles.forEach((interpretationStyle) => {
    if (interpretationStyle.key === 'standard') {
      tierMap.free.push(interpretationStyle.id)
      defaultInterpretationStyleId = interpretationStyle.id
    } else {
      tierMap.premium.push(interpretationStyle.id)
      tierMap.supporter.push(interpretationStyle.id)
    }
  })

  return {
    ...tierMap,
    default: defaultInterpretationStyleId,
  }
}
