import React from 'react'
import { StyleSheet } from 'react-native'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { Logotype } from '../../../assets/icons/Logotype'
import { Iubenda } from '../../../components/other/iubenda'
import { Center } from '../../../components/common/center/center'
import { View } from '../../../components/common/view/view'
import { ScrollView } from '../../../components/common/scrollview/scrollview'
import { Link } from '../../../components/common/link/link'

export default function Privacy() {
  return (
    <View
      style={[
        styles.container,
        {
          flex: 1,
        },
      ]}
    >
      <ScrollView
        style={[styles.scrollView]}
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <Center m={5}>
          <Link href="/">
            <Logotype width={205} height={25} />
          </Link>
        </Center>
        <PaddedContentArea h="100%">
          <Iubenda type="privacy" full={true} />
        </PaddedContentArea>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    textTransform: 'uppercase',
    // textAlign: 'center',
    marginBottom: 20,
    marginTop: 30,
  },
  tableLeft: {
    fontWeight: 'bold',
    width: '30%',
  },
  link: {
    textDecorationLine: 'underline',
  },
  tableRight: {
    width: '70%',
  },
  container: {
    alignSelf: 'stretch',
  },
  scrollView: {
    maxHeight: '100%',
  },
  item: {
    margin: 5,
    padding: 5,
    // backgroundColor: '#cccccc',
    borderRadius: 3,
    minWidth: 96,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 5,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 16,
  },
  divider: {
    width: 16,
  },
})
