import { Platform } from 'react-native'
import { Purchases } from '../../revenuecat/index.web'

export async function getCustomerInfo(appUserID: string) {
  try {
    if (Platform.OS === 'web') {
      Purchases.configure(
        process.env.REACT_APP_REVENUECAT_BILLING_PUBLIC_API_KEY as string,
        appUserID,
      )
    }
    const customerInfo = await Purchases.getSharedInstance().getCustomerInfo()
    return customerInfo
  } catch (e: any) {
    console.log('ERROR', e)
  }
}
