import { FirebaseMessagingTypes } from '@react-native-firebase/messaging'

export async function initialiseNotifications() {
  return
}

export async function deactivateNotifications() {
  return
}

export async function bootstrap(detailNotification?: any) {
  return
}

// set iOS categories
async function setCategories() {
  return
}
setCategories()

// Note that an async function or a function that returns a Promise
// is required for both subscribers.
export async function messageHandler(
  message: FirebaseMessagingTypes.RemoteMessage & {
    data: { fcm_options: { image: string } }
  },
) {
  return
}

export async function buildDeepLinkFromNotificationData(data: any) {
  return
}

export async function getInitialURL(): Promise<string | null | undefined> {
  return
}
export function subscribe(listener: any) {
  return
}
