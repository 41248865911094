import React, { useRef } from 'react'
import { GestureResponderEvent, Platform } from 'react-native'
import { navigationRef } from '../navigation/RootNavigation'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { View } from '../components/common/view/view'
import { Pressable } from '../components/common/pressable/pressable'

export const PressableFixed = ({
  onPress,
  onPressIn,
  onPressOut,
  ...props
}: any) => {
  const _touchActivatePositionRef = useRef<any>(null)
  function _onPressIn(e: GestureResponderEvent) {
    if (e) {
      const { pageX, pageY } = e.nativeEvent

      _touchActivatePositionRef.current = {
        pageX,
        pageY,
      }

      onPressIn?.(e)
    } else {
      onPressIn && onPressIn()
    }
  }

  function _onPress(e: GestureResponderEvent) {
    if (navigationRef?.current?.getCurrentRoute()?.name === props.toScreen) {
      return
    }
    if (e) {
      const { pageX, pageY } = e.nativeEvent

      const absX = Math.abs(_touchActivatePositionRef.current.pageX - pageX)
      const absY = Math.abs(_touchActivatePositionRef.current.pageY - pageY)

      const dragged = absX > 2 || absY > 2
      if (!dragged) {
        onPress?.(e)
      }
    } else {
      onPress && onPress()
    }
  }

  if (Platform.OS === 'android') {
    return (
      <TouchableOpacity
        onPressIn={onPressIn}
        onPress={onPress}
        onPressOut={onPressOut}
      >
        <View {...props}>{props.children}</View>
      </TouchableOpacity>
    )
  }

  return (
    <Pressable
      onPressIn={_onPressIn}
      onPress={_onPress}
      onPressOut={onPressOut}
      _pressed={{
        opacity: 0.5,
      }}
      {...props}
    >
      {props.children}
    </Pressable>
  )
}
