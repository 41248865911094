import { configureStore, Action } from '@reduxjs/toolkit'
import rootReducer, { RootState } from './root-reducer'
import { ThunkAction } from 'redux-thunk'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
// @ts-ignore
import storage from 'redux-persist-indexeddb-storage'

// Persist config
const persistConfig = {
  key: 'root',
  storage: storage('elsewhere'),
}

// Create a new reducer with our existing reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export default store
