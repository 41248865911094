/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Friend } from '../models/Friend';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FriendService {
  /**
   * Retrieves the collection of Friend resources.
   * Retrieves the collection of Friend resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns Friend Friend collection
   * @throws ApiError
   */
  public static getFriends(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<Friend>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/friends',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a Friend resource.
   * Creates a Friend resource.
   * @param requestBody The new Friend resource
   * @returns Friend Friend resource created
   * @throws ApiError
   */
  public static searchFriends(
    requestBody: Partial<Friend>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Friend> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/friends/search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Replaces the Friend resource.
   * Replaces the Friend resource.
   * @param id Friend identifier
   * @param requestBody The updated Friend resource
   * @returns Friend Friend resource updated
   * @throws ApiError
   */
  public static updateFriend(
    id: string,
    requestBody: Partial<Friend>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Friend> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/friends/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Creates a Friend resource.
   * Creates a Friend resource.
   * @param id Friend identifier
   * @param requestBody The new Friend resource
   * @returns Friend Friend resource created
   * @throws ApiError
   */
  public static sendRequest(
    id: string,
    requestBody: Partial<Friend>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Friend> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/friends/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the Friend resource.
   * Removes the Friend resource.
   * @param id Friend identifier
   * @returns void
   * @throws ApiError
   */
  public static unfriend(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/friends/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Creates a Friend resource.
   * Creates a Friend resource.
   * @param id Friend identifier
   * @param requestBody The new Friend resource
   * @returns Friend Friend resource created
   * @throws ApiError
   */
  public static acceptRequest(
    id: string,
    requestBody: Partial<Friend>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Friend> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/friends/{id}/accept',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Creates a Friend resource.
   * Creates a Friend resource.
   * @param id Friend identifier
   * @param requestBody The new Friend resource
   * @returns Friend Friend resource created
   * @throws ApiError
   */
  public static rejectRequest(
    id: string,
    requestBody: Partial<Friend>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Friend> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/friends/{id}/reject',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
