import React from 'react'
import { Link } from '@react-navigation/native'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import { useTranslation } from 'react-i18next'
import { PUBLIC_USERS_DISPLAY_NAME_MAP } from '../../../constants/constants'
import { RootStackParamList } from '../../../navigation/types'
import {
  useLinkProps,
  UseLinkPropsProps,
} from '../../../utilities/useLinkPropsFixed'
import { Row } from '../row/row'

export type ProfileType = 'user' | 'group' | 'public'

export type UserListItemProps = {
  id: string
  displayName: string
  profileImage?: string
  linkObject: {
    screen: keyof RootStackParamList
    params: {
      username: string
    }
  }
}

export const UserListItemComponent = ({
  id,
  linkObject,
  displayName,
  profileImage,
}: UserListItemProps) => {
  // HOOKS
  const { t } = useTranslation()

  // VARS
  const isPublicUser = Boolean(PUBLIC_USERS_DISPLAY_NAME_MAP[displayName])
  const listItemLabel = isPublicUser
    ? t(`globalUser.${displayName}.name`)
    : displayName

  const linkProps = {
    to: linkObject,
  } as UseLinkPropsProps
  const { onPress } = useLinkProps(linkProps)

  return (
    <Link key={id} {...linkProps}>
      <Row alignItems={'center'}>
        <Row width={16} height={16} marginRight={2}>
          <AvatarCircle
            size={'md'}
            source={{
              uri: profileImage || undefined,
            }}
            name={listItemLabel}
            onPress={onPress}
          />
        </Row>
        <SansText>{listItemLabel}</SansText>
      </Row>
    </Link>
  )
}
