import React, { useMemo } from 'react'
import { AnalysisResult } from '../../../../api/frontend-types'
import { Tabs } from '../../components/layout/tab-view-tabs'
import { Platform, Dimensions } from 'react-native'
import { Loading } from '../../components/layout/loading'
import { NoContentMessage } from '../../components/layout/no-content-message'
import FeedItemInsight from '../../components/composite/feed/feed-item-insight'
import { getTitleForInsight } from '../../modules/analysis-helpers/insight-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { RouteProp } from '@react-navigation/core'
import { MainStackParamList } from '../../navigation/types'
import { CentredListEmptyComponent } from '../../components/layout/centred-list-empty-component'
import { Insight } from '../../../../api/_openapi'

type InsightTabProps = {
  route: RouteProp<MainStackParamList, 'PrivateAskYourDreams'>
  insights: Insight[]
  loading: boolean
  noInsightsMsg: string
  setInsights: any
}

export const AnswersTab = ({
  route,
  insights,
  loading,
  noInsightsMsg,
}: InsightTabProps) => {
  // First we title the insights
  const screenWidth = Math.min(Dimensions.get('window').width, WEB_MAX_WIDTH)
  const titledInsights: Insight[] = useMemo(() => {
    return insights.map((insight) => {
      const title = getTitleForInsight(insight, null, true, screenWidth)
      return {
        ...insight,
        title: title,
      }
    })
  }, [insights, screenWidth])

  return (
    <Tabs.FlatList
      data={titledInsights}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      keyExtractor={(item: Insight, i) => item.id + i}
      ListEmptyComponent={CentredListEmptyComponent(
        loading ? <Loading /> : <NoContentMessage message={noInsightsMsg} />,
      )}
      renderItem={({ item, index }) => {
        return <FeedItemInsight route={route} key={index} insight={item} />
      }}
    />
  )
}
