import React from 'react'
import { useTranslation } from 'react-i18next'
import { LINE_WIDTH } from '../../constants/constants'
import { SocialLinks } from '../../components/composite/social-links/social-links'
import { Row } from '../../components/common/row/row'
import { LinkSans } from '../../components/common/copy/link-sans'
import { Box } from '../../components/common/box/box'
import { Column } from '../../components/common/column/column'

export const HomepageFooter = ({
  isSmallScreen,
  color,
}: {
  isSmallScreen: boolean
  color: string
}) => {
  const { t } = useTranslation()

  return (
    <Box
      w="100%"
      h={isSmallScreen ? 100 : 50}
      borderTopWidth={LINE_WIDTH}
      borderColor={color}
      paddingX={4}
    >
      <Row
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
        h="100%"
      >
        <Box
          alignItems={isSmallScreen ? 'center' : 'flex-start'}
          minWidth={isSmallScreen ? '100%' : 250}
          width={isSmallScreen ? '100%' : undefined}
        >
          <Row>
            <Column
              py={4}
              space={4}
              alignItems={isSmallScreen ? 'center' : 'flex-start'}
              flexDirection={isSmallScreen ? 'column' : 'row'}
            >
              <LinkSans
                variant="link"
                href="/about"
                mx={5}
                color={color}
                textAlign="center"
              >
                {t('common.aboutElsewhere')}
              </LinkSans>
              <LinkSans
                variant="link"
                href="/privacy"
                mx={5}
                color={color}
                textAlign="center"
              >
                {t('common.privacyPolicy')}
              </LinkSans>
              <LinkSans
                variant="link"
                href="/terms"
                mx={5}
                color={color}
                textAlign="center"
              >
                {t('common.termsOfService')}
              </LinkSans>
              <LinkSans
                variant="link"
                href="/symbol-dictionary"
                mx={5}
                color={color}
                textAlign="center"
              >
                {t('common.symbolDictionary')}
              </LinkSans>
            </Column>
          </Row>
        </Box>
        <Box
          minWidth={isSmallScreen ? '100%' : 250}
          alignItems={isSmallScreen ? 'center' : 'flex-end'}
        >
          <SocialLinks />
        </Box>
      </Row>
    </Box>
  )
}
