import { AnalysisResult } from '../../../../../api/frontend-types'
import { truncateString } from '../../../modules/strings/string-helpers'
import { elsewhereToast } from '../../../components/common/toast/toast'
import {
  AnalysisResultService,
  DreamSeriesAnalysisRequestService,
  FastTag,
  FastTagDescription,
  FastTagDescriptionService,
  FastTagService,
  Insight,
  InsightService,
} from '../../../../../api/_openapi'

// Create tag
// Really the 'language' param is probably on the tagDetails object,
// just being explicit
export async function createTag(
  tagDetails: Partial<FastTag>,
  languageCode: string | null,
): Promise<FastTag | null> {
  // Make sure tag name is trimmed
  tagDetails.name = tagDetails.name?.trim() || ''
  const sendTagDetails = {
    ...tagDetails,
    languageCode,
  }

  // @ts-ignore ~ something funky with the images here
  const tag = await FastTagService.createTag(sendTagDetails).catch(
    (err: any) => {
      elsewhereToast.showToast({
        title: 'toast.errorCreatingTag.title',
        description: truncateString(err.message, 100),
        status: 'error',
      })
    },
  )
  return tag || null
}

// Get tag by ID
export async function fetchTagById(tagId: string): Promise<FastTag | null> {
  return await FastTagService.fastTag(tagId)
}

// Get insights
export async function fetchInsights(
  type:
    | 'tag_synthesis_monitor'
    | 'dream_series_analysis' = 'tag_synthesis_monitor',
  tagType?: 'CHARACTER' | 'SETTING' | 'SYMBOL',
): Promise<AnalysisResult[] | null> {
  const insights = await AnalysisResultService.insights(type, tagType)
    .then((ar) => {
      return ar.filter((ar) => !ar.deleted)
    })
    .catch((err: any) => {
      console.log('Error getting insights', err)
      return null
    })
  if (insights) {
    return insights
  } else {
    return null
  }
}

// Get insights
export async function askYourDreams(
  question: string,
  language: string | null,
): Promise<Insight | null> {
  if (!question) {
    console.log('Error getting answer: no question')
    return null
  }

  try {
    const result = await DreamSeriesAnalysisRequestService.dreamSeries({
      question,
      language,
    })
    return InsightService.fastInsight(result.id)
  } catch (e) {
    console.error('Error getting answer', e)
    return null
  }
}

// Get tag descriptions for a tag
export async function fetchSymbolDescriptions(
  tagId: string,
): Promise<FastTagDescription[]> {
  const tagDescriptions =
    await FastTagDescriptionService.getTagDescriptionsByTag(tagId).catch(
      (err: any) => {
        console.log('Error getting symbol descriptions', tagId, err)
        return null
      },
    )
  if (tagDescriptions) {
    return tagDescriptions
  } else {
    return []
  }
}

export async function fetchUserTagAssociation(
  tagId: string,
  userId: string,
): Promise<FastTagDescription | null> {
  return await FastTagDescriptionService.getUserTagAssociation(
    userId,
    tagId,
  ).catch((err: any) => {
    console.log('Error getting tag descriptions', tagId, err)
    return null
  })
}

export async function saveUserTagAssociation(
  userId: string,
  association: Partial<FastTagDescription>,
): Promise<FastTagDescription | null> {
  const savedUserTagAssociation =
    await FastTagDescriptionService.createUserTagAssociation(
      userId,
      association,
    )
  if (savedUserTagAssociation) {
    // Alert user
    elsewhereToast.showToast({
      description: 'common.saved',
      status: 'success',
    })
    return savedUserTagAssociation
  } else {
    console.log('error saving dream', savedUserTagAssociation)
    elsewhereToast.showToast({
      title: 'toast.errorSavingDream.title',
      description: 'toast.errorSavingDream.description',
      status: 'error',
    })
    return null
  }
}

export async function updateUserTagAssociation(
  userId: string,
  tagDescriptionId: string,
  association: Partial<FastTagDescription>,
): Promise<FastTagDescription | null> {
  const savedUserTagAssociation =
    await FastTagDescriptionService.updateUserTagAssociation(
      userId,
      tagDescriptionId,
      association,
    )
  if (savedUserTagAssociation) {
    // Alert user
    elsewhereToast.showToast({
      description: 'common.saved',
      status: 'success',
    })
    return savedUserTagAssociation
  } else {
    console.log('error saving dream', savedUserTagAssociation)
    elsewhereToast.showToast({
      title: 'toast.errorSavingDream.title',
      description: 'toast.errorSavingDream.description',
      status: 'error',
    })
    return null
  }
}

// Get tag descriptions for a specific tag description id
export async function fetchTagDescriptionById(
  id: string,
): Promise<FastTagDescription | null> {
  let tagDescription: FastTagDescription | null = null
  try {
    tagDescription = await FastTagDescriptionService.getTagDescription(id)
  } catch (err: any) {
    console.log('Error getting tag description', id, err)
    /*elsewhereToast.showToast({
      title: 'toast.errorGettingTagDescription.title',
      titleParam: id,
      description: truncateString(err.message, 100),
      status: 'error',
    })*/
    return null
  }
  return tagDescription
}
