import AsyncStorage from '@react-native-async-storage/async-storage'

export const setUserHasSeenWelcomeMessage = async (
  hasSeen: boolean,
): Promise<void> => {
  await AsyncStorage.setItem('@welcomeMsgSeen', hasSeen.toString())
}

export const getUserHasSeenWelcomeMessage = async (): Promise<boolean> => {
  const value = await AsyncStorage.getItem('@welcomeMsgSeen')
  return value === 'true'
}
