import React, { useState, useEffect } from 'react'
import { HighlightBox } from '../../layout/highlight-box'
import { SansText } from '../../common/copy/text-sans'
import {
  AnalysisResult,
  WeeklyReportListService,
} from '../../../../../api/_openapi'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { getContentWidth } from '../../../modules/ui-helpers/ui-helpers'
import { Platform } from 'react-native'
import { PADDING_HORIZONTAL_PIXELS } from '../../../constants/constants'
import { HeadingSans } from '../../common/copy/heading-sans'
import { ButtonPill } from '../../common/buttons/button-pill'
import { undecorateId } from '../../../../../api/decorate-ids'
import { Link } from '../../common/link/link'

export default function WeeklyReportBox() {
  const [weeklyReport, setWeeklyReport] = useState<AnalysisResult | null>(null)
  const user = useSelector(selectUser)
  const contentWidth = getContentWidth(Platform.OS)
  const boxWidth = contentWidth - PADDING_HORIZONTAL_PIXELS * 2

  useEffect(() => {
    if (user) {
      WeeklyReportListService.weeklyReportList().then((weeklyReports) => {
        console.log('weeklyReports', weeklyReports)
        if (weeklyReports.length > 0) {
          setWeeklyReport(weeklyReports[0])
        }
      })
    }
  }, [user])

  if (!weeklyReport) {
    return null
  }
  return (
    <HighlightBox
      width={boxWidth}
      padding={4}
      paddingTop={4}
      paddingBottom={4}
      mb={10}
    >
      <HeadingSans>Weekly Dream Report</HeadingSans>
      <SansText mb={3}>
        Your weekly report is ready to view! Here you'll find some insights and
        stats about your dreaming this week, then a 3-2-1 breakdown of your
        dreams.
      </SansText>
      <SansText mb={2}>
        You'll get 3 themes from your dreams this week, 2 quotes from your
        dreams, and 1 question to be thinking about for the upcoming week.
      </SansText>
      <SansText mb={3}>3 Themes from your dreams...</SansText>
      <Link href={'/private/insights/' + undecorateId(weeklyReport.id)}>
        <ButtonPill buttonSize={'sm'}>View Report</ButtonPill>
      </Link>
    </HighlightBox>
  )
}
