import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { undecorateId } from '../../../../api/decorate-ids'
import { FastGroup, FastMembership } from '../../../../api/_openapi'
import { FormFieldOption } from '../../../../api/frontend-types'
import { PURGE } from 'redux-persist'
import { createObjectMap } from '../dream-tag/modules/normalize'
import { createSelector } from 'reselect'

export type GroupState = {
  userGroups: { [key: string]: FastGroup }
}

const initialState: GroupState = {
  userGroups: {},
}

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
  reducers: {
    initialSetUserGroups(state, action: PayloadAction<FastGroup[]>) {
      const groups = action.payload || []
      state.userGroups = createObjectMap(groups)
    },
    setUserGroups(state, action: PayloadAction<FastGroup[]>) {
      state.userGroups = createObjectMap(action.payload)
    },
    addUserGroup(state, action: PayloadAction<FastGroup>) {
      // When you add a new group, that becomes the active group
      const newGroup = action.payload
      state.userGroups = {
        ...state.userGroups,
        [newGroup.id]: newGroup,
      }
    },
    removeUserGroup(state, action: PayloadAction<string>) {
      state.userGroups = Object.fromEntries(
        Object.entries(state.userGroups).filter(
          ([key]) => key !== action.payload,
        ),
      )
    },
    updateUserGroup(state, action: PayloadAction<FastGroup>) {
      const group = action.payload
      state.userGroups = {
        ...state.userGroups,
        [group.id]: group,
      }
    },
    addOrUpdateMembership(state, action: PayloadAction<FastMembership>) {
      const membership = action.payload
      const group = { ...state.userGroups[membership.groupId] }
      state.userGroups = {
        ...state.userGroups,
        [group.id]: {
          ...group,
          memberships: {
            ...group.memberships,
            [membership.id]: membership,
          },
        },
      }
    },
  },
})

export const {
  setUserGroups,
  addUserGroup,
  addOrUpdateMembership,
  removeUserGroup,
  updateUserGroup,
} = groupSlice.actions

const getUserGroups = (state: RootState) => state.groups.userGroups || {}
export const selectUserGroups = createSelector([getUserGroups], (userGroups) =>
  Object.values(userGroups),
)

export const selectGroupOptions = (state: RootState): FormFieldOption[] => {
  const userGroups = selectUserGroups(state)
  return userGroups.map((g) => ({ value: g.id, label: g.name || '' }))
}

export const selectShareGroupOptions = (
  state: RootState,
): FormFieldOption[] => {
  const userGroups = selectUserGroups(state)
  // We need to filter out the user's private group
  // And the public group for now
  return userGroups
    .filter((g) => g.type !== 'user' && g.name !== 'Public')
    .map((g) => {
      return {
        value: g.id,
        label: g.name || '',
        image: g.imageUrl || undefined,
      }
    })
}

export const selectUserPrivateGroup = (
  state: RootState,
): FastGroup | undefined => {
  if (!state.user.user?.userGroupId) {
    return undefined
  }
  return state.groups.userGroups[state.user.user.userGroupId]
}

export const selectPrivateGroupId = (state: RootState): string | undefined => {
  return state.user.user?.userGroupId || undefined
}

export const selectGroupMemberships =
  (groupId: string | null | undefined) =>
  (state: RootState): FastMembership[] => {
    if (!groupId) {
      return []
    }
    const undecoratedId = undecorateId(groupId)
    const group = state.groups.userGroups[undecoratedId]
    if (!group) {
      return []
    }
    return Object.values(group.memberships)
  }

export const selectUserMembershipInGroup =
  (userId: string | null | undefined, groupId: string | null | undefined) =>
  (state: RootState): FastMembership | undefined => {
    if (!userId || !groupId) {
      return undefined
    }

    userId = undecorateId(userId)
    groupId = undecorateId(groupId)
    const group = state.groups.userGroups[groupId]
    if (!group) {
      return undefined
    }
    return Object.values(group.memberships).find((m) => m.userId === userId)
  }

export const selectGroupById =
  (id: string | null | undefined) =>
  (state: RootState): FastGroup | undefined => {
    const undecoratedId = undecorateId(id)
    return state.groups.userGroups[undecoratedId]
  }

export default groupSlice
