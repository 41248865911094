import React from 'react'
import { useColorModeValue } from 'native-base'
import { GooglePlay } from '../../assets/react-native-svg/GooglePlay'
import { AppStore } from '../../assets/react-native-svg/AppStore'
import { Pressable } from 'react-native'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { Row } from '../../components/common/row/row'
import { gtag_report_conversion } from '../../modules/google-ads/google-ads-helpers'
import { Box } from '../../components/common/box/box'
import { Center } from '../../components/common/center/center'

export const AppLinks = () => {
  const color = useColorModeValue(BLACK, WHITE)
  const invertedColor = useColorModeValue(WHITE, BLACK)
  const invertedBackground = color

  return (
    <Box width="100%">
      <Row
        w={'100%'}
        flexWrap={'wrap'}
        alignItems="center"
        justifyContent="center"
      >
        <Pressable
          onPress={() => {
            gtag_report_conversion(undefined)
            window.open(
              'https://apps.apple.com/us/app/elsewhere/id6445864345',
              '_blank',
            )
          }}
        >
          <Center w={140} h={50} marginX={2}>
            <AppStore
              size={140}
              color={invertedColor}
              background={invertedBackground}
            />
          </Center>
        </Pressable>
        <Pressable
          onPress={() => {
            gtag_report_conversion(undefined)
            window.open(
              'https://play.google.com/store/apps/details?id=to.elsewhere',
              '_blank',
            )
          }}
        >
          <Center w={150} h={50} marginX={2}>
            <GooglePlay
              size={155}
              color={invertedColor}
              background={invertedBackground}
            />
          </Center>
        </Pressable>
      </Row>
    </Box>
  )
}
