import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Screens from '../../../screens'
import { capitalize } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateFriends"
        component={Screens.FriendsMain}
        options={{
          title: t('common.friends'),
        }}
      />
      {/*<MainStack.Screen
        name="PrivateInsightList"
        component={Screens.FriendsList}
        options={{
          title: capitalize(t('common.insight_plural', { count: MANY })),
          headerLeft: HeaderLeft,
        }}
      />
      <MainStack.Screen
        name="PrivateInsightView"
        component={Screens.InsightView}
        options={{
          title: cap(t('common.insight_plural', { count: 1 })),
          headerLeft: HeaderLeft,
        }}
      />*/}
    </>
  )
}
