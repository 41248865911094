import React from 'react'
import { useColorModeValue } from 'native-base'
import { Discord } from '../../../assets/react-native-svg/Socials/Discord'
import { Instagram } from '../../../assets/react-native-svg/Socials/Instagram'
import { X } from '../../../assets/react-native-svg/Socials/X'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { Facebook } from '../../../assets/react-native-svg/Socials/Facebook'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Row } from '../../common/row/row'
import { Link } from '../../common/link/link'

type SocialLinksProps = InterfaceBoxProps & {}

export const SocialLinks = ({ ...props }: SocialLinksProps) => {
  const color = useColorModeValue(BLACK, WHITE)

  return (
    <Row
      py={4}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      {...props}
    >
      <Link
        variant="link"
        href="https://discord.gg/3aJeSgc6T9"
        mx={4}
        color={color}
        textAlign="center"
        _text={{ fontFamily: 'sans' }}
      >
        <Discord size={20} />
      </Link>
      <Link
        variant="link"
        href="https://instagram.com/elsewheredreams"
        mx={4}
        color={color}
        textAlign="center"
        _text={{ fontFamily: 'sans' }}
      >
        <Instagram size={20} />
      </Link>
      <Link
        variant="link"
        href="https://x.com/elsewheredreams"
        mx={4}
        color={color}
        textAlign="center"
        _text={{ fontFamily: 'sans' }}
      >
        <X size={20} />
      </Link>
      <Link
        variant="link"
        href="https://www.facebook.com/elsewheredreams"
        mx={4}
        color={color}
        textAlign="center"
        _text={{ fontFamily: 'sans' }}
      >
        <Facebook size={20} />
      </Link>
    </Row>
  )
}
