import React, { useState } from 'react'
import { useColorModeValue } from 'native-base'
import { truncateString } from '../../../modules/strings/string-helpers'
import { ToastPopupProps } from '../../common/toast/toast'
import { Platform, ImageURISource } from 'react-native'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { PaddedContentAreaConditional } from '../../layout/content-area-padded-conditional'
import { HeadingMainWithDate } from '../../common/copy/heading-main-with-date'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import { BLACK, IMG_RES, WHITE } from '../../../constants/ui-constants'
import { FramedImage } from '../../common/image/framed-image'
import { PostUserInfoRow } from './post-user-inforow'
import { ReactionRow } from './reaction-row'
import { ButtonPill } from '../../common/buttons/button-pill'
import { EntryType, SupportedLanguage } from '../../../../../api/frontend-types'
import { useSelector } from '../../../ducks/root-reducer'
import { selectIsElsewhereTeam } from '../../../ducks/user/user'
import { Row } from '../../common/row/row'
import { BodyText } from '../../common/copy/text-body'
import { getScriptTypeForLanguage } from '../../../modules/language-helpers/language-helpers'
import { Column } from '../../common/column/column'
import { View } from '../../common/view/view'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../../common/action-sheet/action-sheet-menu'
import {
  FALLBACK_IMG_URI,
  FEED_ITEM_MAX_CHARS,
  LINE_WIDTH,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'

type FeedEntryGenericProps = {
  // CONTENT
  title?: string
  description: string
  descriptionComponent?: JSX.Element
  avatarCircleSrc?: ImageURISource
  avatarLabel: string
  avatarSubLabel?: string | null
  imgLoadingLabel?: string
  imageUrl?: string | null

  // i18n
  language?: SupportedLanguage
  titleLanguage?: SupportedLanguage
  avatarLabelLanguage?: SupportedLanguage

  // ACTION SHEET
  actionSheetMenuItems?: ActionSheetMenuItem[]
  actionSheetCanOpen?: boolean
  actionSheetCannotOpenMessage?: ToastPopupProps // E.g. "Please wait for the dream to save"

  // TRUNCATION
  truncate?: boolean
  truncationLength?: number
  hasTopDivider?: boolean
  authorImgLoading?: boolean

  // DISABLE THINGS
  disableUserInfoRowPress?: boolean

  // LINKS
  linkObject?: any
  userInfoLinkObject?: any
  avatarOnPress?: () => void

  // DATE
  date?: string
  displayDate?: string | null

  // LOADING
  imageIsLoading?: boolean

  // REACTIONS
  commentCount?: number
  reactionCount?: number
  userReactionCount?: number
  showReactionRow?: boolean
  inGroup?: boolean
  onReactionPress?: () => void
  type?: EntryType
  showTypeLabel?: boolean

  // EXTRA FOOTER (e.g. link to dreamworker)
  extraFooter?: JSX.Element
}

// "Dumb component" that just has the layout of a feed item
export const FeedEntryGeneric = ({
  //   route,
  type,
  title,
  description,
  descriptionComponent,
  imageUrl,
  language,
  titleLanguage,
  avatarLabelLanguage,
  actionSheetCanOpen = true,
  actionSheetMenuItems = [],
  linkObject,
  userInfoLinkObject,
  truncationLength,
  avatarCircleSrc,
  truncate,
  avatarLabel,
  hasTopDivider,
  authorImgLoading,
  avatarSubLabel,
  imgLoadingLabel,
  date,
  displayDate,
  imageIsLoading,
  commentCount = 0,
  reactionCount = 0,
  userReactionCount = 0,
  avatarOnPress,
  showReactionRow = false,
  showTypeLabel = false,
  inGroup = false,
  onReactionPress,
  extraFooter,
}: FeedEntryGenericProps) => {
  // STATE
  const [actionSheetIsOpen, setActionSheetIsOpen] = useState(false)

  // HOOKS
  const borderColor = useColorModeValue(BLACK, WHITE)

  // SELECTORS
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)

  // VARS
  const isWeb = Platform.OS === 'web'
  const isMobile = !isWeb
  const typeMap = {
    dream: 'Dream',
    'journal-entry': 'Diary',
  }
  const typeLabel = type ? typeMap[type] : ''
  const scriptType = getScriptTypeForLanguage(language)
  const titleScriptType = getScriptTypeForLanguage(titleLanguage)

  // Truncate description if required
  const shouldTruncate = Boolean(truncate || truncationLength)
  const len: number = truncationLength || FEED_ITEM_MAX_CHARS
  const desc = shouldTruncate ? truncateString(description, len) : description

  // Check if we should link from the entry
  // Only happens if there is a link object and we are truncating
  const shouldLink = Boolean(linkObject && linkObject.screen && shouldTruncate)

  // Resize image for display
  const resizedImageUrl = updateCloudinaryUrlForDisplay(imageUrl || '', IMG_RES)

  const GenericDescription = ({
    language,
  }: {
    language?: SupportedLanguage
  }) => {
    if (shouldTruncate || !descriptionComponent) {
      return <BodyText language={language}>{desc}</BodyText>
    } else if (descriptionComponent) {
      return descriptionComponent
    } else {
      return null
    }
  }

  return (
    <>
      <View maxWidth={WEB_MAX_WIDTH} mx="auto" w="100%" pb={10}>
        {/* On mobile, the whole card is a link */}
        {/* On web, the individual parts of the card are links */}
        <Column
          borderTopWidth={hasTopDivider ? LINE_WIDTH : 0}
          borderTopColor={borderColor}
        >
          <PostUserInfoRow
            avatarCircleSrc={avatarCircleSrc}
            avatarLabel={avatarLabel}
            avatarLabelLanguage={avatarLabelLanguage}
            avatarSubLabel={avatarSubLabel}
            authorImgLoading={authorImgLoading}
            actionSheetOpenable={actionSheetCanOpen}
            hasActions={actionSheetMenuItems.length > 0}
            userInfoLinkObject={userInfoLinkObject}
            avatarOnPress={avatarOnPress}
            ellipsisOnPress={() => {
              setActionSheetIsOpen(true)
            }}
          />
          <ConditionalLink to={linkObject} condition={isMobile && shouldLink}>
            {/* IMAGE */}
            {imageUrl && (
              <ConditionalLink to={linkObject} condition={isWeb && shouldLink}>
                <FramedImage
                  linkObject={linkObject}
                  alt={title || 'image'}
                  source={{ uri: resizedImageUrl || FALLBACK_IMG_URI }}
                  loading={imageIsLoading}
                  loadingLabel={imgLoadingLabel}
                />
              </ConditionalLink>
            )}
            <PaddedContentAreaConditional pt={4}>
              <ConditionalLink to={linkObject} condition={isWeb && shouldLink}>
                <HeadingMainWithDate
                  date={date}
                  displayDate={displayDate}
                  scriptType={titleScriptType}
                  language={titleLanguage}
                >
                  {title}
                </HeadingMainWithDate>
              </ConditionalLink>
              <ConditionalLink to={linkObject} condition={isWeb && shouldLink}>
                <GenericDescription language={language} />
              </ConditionalLink>
            </PaddedContentAreaConditional>
          </ConditionalLink>
        </Column>
        <Column>
          {showReactionRow && (
            <PaddedContentAreaConditional pt={6}>
              <ReactionRow
                inGroup={inGroup}
                commentCount={commentCount}
                reactionCount={reactionCount}
                userReactionCount={userReactionCount}
                linkObject={linkObject}
                onReactionPress={onReactionPress}
              />
            </PaddedContentAreaConditional>
          )}
          {extraFooter && extraFooter}
        </Column>
        {isElsewhereTeam && showTypeLabel && type && (
          <Row justifyContent={'end'} alignItems={'end'} width={'100%'}>
            <ButtonPill width={100} buttonSize={'xs'}>
              {typeLabel}
            </ButtonPill>
          </Row>
        )}
      </View>
      {actionSheetIsOpen && (
        <ActionSheetMenu
          isOpen={actionSheetIsOpen}
          onClose={() => {
            setActionSheetIsOpen(false)
          }}
          menuItems={actionSheetMenuItems || []}
        />
      )}
    </>
  )
}
