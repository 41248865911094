import React from 'react'
import { Platform } from 'react-native'
import { useColorModeValue } from 'native-base'
import {
  SubscriptionStatusTextProps,
  SubscriptionStatusText,
} from './subscription-status-text'
import { CREAM, LIGHT_BLACK } from '../../../constants/ui-constants'
import { Loading } from '../../layout/loading'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Box } from '../../common/box/box'

type SubscriptionStatusAlertProps = Omit<
  SubscriptionStatusTextProps,
  'color'
> & {
  loading?: boolean
  mt?: number
  mb?: number
}
export const SubscriptionStatusAlert = ({
  itemsRemaining,
  creditsRemaining,
  statusToShow,
  tier,
  loading,
  mt = 8,
  mb = 4,
}: SubscriptionStatusAlertProps) => {
  // HOOKS
  const textColor = useColorModeValue(LIGHT_BLACK, CREAM)
  const boxBgColor = useColorModeValue(CREAM, LIGHT_BLACK)

  // VARS
  const isWeb = Platform.OS === 'web'

  return (
    <Box
      bg={boxBgColor}
      paddingX={4}
      paddingY={4}
      marginTop={mt}
      marginBottom={mb}
      marginX={isWeb ? 0 : 4}
      textAlign={'center'}
    >
      {loading ? (
        <Loading />
      ) : (
        <SubscriptionStatusText
          statusToShow={statusToShow}
          itemsRemaining={itemsRemaining}
          creditsRemaining={creditsRemaining}
          tier={tier}
          color={textColor}
          fontSize={'sm'}
        />
      )}
    </Box>
  )
}
