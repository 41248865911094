import React from 'react'
import { useColorModeValue } from 'native-base'
import { FEW } from '../../../i18n/config'
import { Dimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import {
  SubscriptionStatus,
  TagTypeWithInsights,
} from '../../../../../api/frontend-types'
import { SansText } from '../../../components/common/copy/text-sans'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { SquareDisplay } from '../../../components/composite/symbols/square-display'
import { capitalize } from '../../../modules/strings/string-helpers'
import {
  CREAM,
  LIGHT_BLACK,
  TAGTYPE_ICON_MAP,
} from '../../../constants/ui-constants'
import {
  MainStackParamList,
  RootStackParamList,
} from '../../../navigation/types'
import { CompositeNavigationProp } from '@react-navigation/native'
import { SubscriptionStatusAlert } from '../../../components/composite/subscriptions/subscription-status-alert'
import { Row } from '../../../components/common/row/row'
import { Box } from '../../../components/common/box/box'

const INSIGHT_TAG_TYPES: TagTypeWithInsights[] = [
  'CHARACTER',
  'SETTING',
  'SYMBOL',
]

type InsightMenuGridProps = {
  navigation: CompositeNavigationProp<
    NativeStackNavigationProp<MainStackParamList, 'PrivateInsights'>,
    NativeStackNavigationProp<RootStackParamList, 'MainTabs'>
  >
  subscriptionStatus: SubscriptionStatus
}

export const InsightMenuGrid = ({
  navigation,
  subscriptionStatus,
}: InsightMenuGridProps) => {
  // HOOKS
  const { t } = useTranslation()
  const textColor = useColorModeValue(LIGHT_BLACK, CREAM)
  const boxBgColor = useColorModeValue(CREAM, LIGHT_BLACK)

  // VARS
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)
  const insightsLeft = subscriptionStatus?.insightsRemaining
  const creditsLeft = subscriptionStatus?.creditsRemaining
  const userTier = subscriptionStatus?.tier

  return (
    <Box pointerEvents="box-none">
      <SubscriptionStatusAlert
        statusToShow={'insightsRemaining'}
        itemsRemaining={insightsLeft}
        creditsRemaining={creditsLeft}
        tier={userTier}
        fontSize={'sm'}
      />
      <SansText
        textTransform={'uppercase'}
        fontSize={'sm'}
        textAlign={'center'}
        marginTop={8}
      >
        {t('insights.generated')}
      </SansText>
      <Row
        my={12}
        paddingX={4}
        justifyContent={'space-around'}
        pointerEvents="box-none"
      >
        {INSIGHT_TAG_TYPES.map((s: TagTypeWithInsights) => {
          const translationKey = `tagType.${s.toLowerCase()}_plural`
          const labelWord = t(translationKey, { count: FEW })
          const label = capitalize(labelWord)
          return (
            <SquareDisplay
              key={s}
              width={pageWidth / 3.5}
              showNumUses={false}
              clickable={true}
              onPress={() => {
                navigation.navigate('MainTabs', {
                  screen: 'MainStack',
                  params: {
                    screen: 'PrivateInsightList',
                    params: {
                      type: s.toLocaleLowerCase() as TagTypeWithInsights,
                    },
                  },
                })
              }}
              editable={false}
              label={label}
              symbolKey={TAGTYPE_ICON_MAP[s]}
            />
          )
        })}
      </Row>
    </Box>
  )
}
