import React, { useEffect } from 'react'
import { Form, FormDetails } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { updateUser } from '../../../ducks/user/thunks/user-thunks'
import { useColorMode } from 'native-base'
import { selectLanguageOptions } from '../../../ducks/ui/ui'
import { useTranslation } from 'react-i18next'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { RootStackScreenProps } from '../../../navigation/types'

export default function DreamerSettingsEdit({
  navigation,
}: RootStackScreenProps<'PrivateDreamSettingsEdit'>) {
  const user = useSelector(selectUser)
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch<any>()
  const { colorMode, languageCode } = user || {}

  const { t } = useTranslation()

  const [headerTitle, setHeaderTitle] = React.useState<string>(
    t('settings.userSettings') || '',
  )

  const onSubmitForm = async (data: any) => {
    setLoading(true)

    const userPatch = {
      colorMode: data.colorMode,
      languageCode: data.languageCode as SupportedLanguage,
    }
    dispatch(updateUser(user?.id || '', userPatch)).finally(() => {
      setLoading(false)
    })
  }

  const { setColorMode } = useColorMode()

  const userSettingsForm: FormDetails = {
    pages: [
      {
        fields: [
          {
            label: 'common.darkMode',
            name: 'colorMode',
            type: 'multiswitch',
            options: [
              {
                label: 'common.auto',
                value: 'auto',
                onPress: () => {
                  setColorMode('auto')
                },
              },
              {
                label: 'common.on',
                value: 'dark',
                onPress: () => {
                  setColorMode('dark')
                },
              },
              {
                label: 'common.off',
                value: 'light',
                onPress: () => {
                  setColorMode('light')
                },
              },
            ],
          },
          {
            label: 'userForm.language',
            name: 'languageCode',
            type: 'select',
            optionsSelector: selectLanguageOptions,
          },
        ],
      },
    ],
  }

  useEffect(() => {
    setHeaderTitle(t('settings.userSettings'))
  }, [t])

  return (
    <Form
      name={'userSettingsForm'}
      form={userSettingsForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        colorMode,
        languageCode,
      }}
      headerTitle={headerTitle}
    />
  )
}
