import React from 'react'
import { Checkbox } from 'native-base'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import { ColorType } from 'native-base/lib/typescript/components/types'
import { AvatarCircle } from '../../avatar/avatar-circle'
import { SansText } from '../../copy/text-sans'
import { Row } from '../../row/row'
import { Column } from '../../column/column'
import { updateCloudinaryUrlForDisplay } from '../../../../modules/image-helpers/image-helpers'
import { SM_THUMB_RES } from '../../../../constants/ui-constants'

type CheckboxGroupWithImagesProps = {
  options: FormFieldOption[]
  onChange: (value: string[]) => void
  multiSelect?: boolean
  values: string[]
  defaultValues: string[]
  color?: ColorType
  disablePreSelected?: boolean
}

// Possibly we should be using Checkbox.Group here
// But it doesn't seem to play nice with a free option
export const CheckboxGroupWithImages = React.memo(
  ({
    options,
    multiSelect = false,
    onChange,
    values,
    defaultValues,
    color,
    disablePreSelected = false,
  }: CheckboxGroupWithImagesProps) => {
    const addToSelected = (value: string, values: string[]) => {
      // 1) If the value is already in the array, do nothing
      // This shouldn't happen, but just in case
      if (values.includes(value)) {
        return
      }
      // 2) If it's a multi-select, add the value to the array
      else if (multiSelect) {
        const newValues = [...values, value]
        onChange(newValues)
      }
      // Else, it's a single select, so replace the array with the value
      else {
        onChange([value])
      }
    }

    const removeFromSelected = (value: string, values: string[]) => {
      const newValues = values.filter((item) => {
        return item !== value
      })
      onChange(newValues)
    }

    const preSelected = defaultValues

    // We need a key on the VStack so that it re-renders when the number of values changes
    // Since react-hook-form has its own state and somehow doesn't trigger a re-render
    return (
      <Column w="100%" space={0} key={`${values.length}`}>
        {options.map((option) => {
          const imageSrc = option.image
            ? updateCloudinaryUrlForDisplay(option.image, SM_THUMB_RES)
            : undefined

          return (
            <Row
              key={option.value}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Row alignItems={'center'}>
                <AvatarCircle
                  size={'md'}
                  source={{ uri: imageSrc }}
                  name={option.label}
                />
                <SansText ml={4} maxWidth={'70%'}>
                  {option.label}
                </SansText>
              </Row>
              <Checkbox
                _checked={{
                  bg: color,
                  borderColor: color,
                }}
                borderRadius={0}
                value={option.value}
                accessibilityLabel={option.label}
                my={2}
                isDisabled={
                  disablePreSelected
                    ? preSelected.includes(option.value)
                    : false
                }
                isChecked={values.includes(option.value)}
                onChange={(checked) => {
                  if (checked) {
                    addToSelected(option.value, values)
                  } else {
                    removeFromSelected(option.value, values)
                  }
                }}
                // @ts-ignore
                label={option.label}
              />
            </Row>
          )
        })}
      </Column>
    )
  },
)
