import React, { useState, useEffect } from 'react'
import { VStack, HStack, useColorModeValue } from 'native-base'
import { Link } from '@react-navigation/native'
import {
  fetchGroupById,
  fetchInviteInfoByCode,
} from '../../ducks/groups/functions/groupFunctions'
import { Loading } from '../../components/layout/loading'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { FastGroup, FastUser, JoinGroupService } from '../../../../api/_openapi'
import { idEquals } from '../../ducks/helpers'
import { useDispatch } from 'react-redux'
import {
  joinGroup,
  loadUserGroups,
} from '../../ducks/groups/thunks/group-thunks'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { Platform } from 'react-native'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { elsewhereToast } from '../../components/common/toast/toast'
import { useTranslation } from 'react-i18next'
import { InfoText } from '../../components/common/copy/text-info'
import { MAX_GROUPS } from '../../constants/constants'
import { BodyText } from '../../components/common/copy/text-body'
import { useSelector } from '../../ducks/root-reducer'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../navigation/types'
import { fetchFastUserById } from '../../ducks/user/functions/user-functions'
import { selectUserGroups } from '../../ducks/groups/groups'
import { useAuth } from '../../contexts/auth-context'
import { selectIsElsewhereTeam } from '../../ducks/user/user'
import { Alert } from '../../components/common/nb-alert/nb-alert'
import { Center } from '../../components/common/center/center'

export type InviteNavigationProp = RootStackNavigationProp<'Invite'>

export default function Invite({
  navigation,
  route,
}: RootStackScreenProps<'Invite'>) {
  const { authData } = useAuth()
  const groups = useSelector(selectUserGroups)
  const dispatch = useDispatch<any>()
  const color = useColorModeValue(BLACK, WHITE)
  const { t } = useTranslation()

  // @ts-ignore
  const inviteCode = route.params?.inviteCode
  console.log('inviteCode', inviteCode)
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<FastUser | null>(null)
  const [invite, setInvite] = useState<FastGroup | null>(null)
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)

  const navigateToGroup = (groupId: string) => {
    navigation.navigate('MainTabs', {
      screen: 'MainStack',
      params: {
        screen: 'GroupFeed',
        params: {
          groupId,
        },
      },
    })
  }

  useEffect(() => {
    if (authData?.user) {
      fetchInviteInfoByCode(inviteCode)
        .then(async (group) => {
          if (!group) {
            setLoading(false)
            return
          }

          const user = await fetchFastUserById(authData?.user)
          setUser(user)
          const isOwner = idEquals(authData?.user, group?.ownerId)
          const memberships = Object.values(group?.memberships || [])
          const isMember = memberships.find((m) =>
            idEquals(m.userId, authData?.user),
          )
          // if user is group owner or member and they are logged in, redirect to group feed
          if (isOwner || isMember) {
            navigateToGroup(group?.id)
            elsewhereToast.showToast({
              title: isOwner
                ? t('groupProfilePage.ownerText')
                : t('groupProfilePage.memberText'),
              status: 'info',
              duration: 3000,
            })
          }
          setInvite(group)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log('err', err)
        })
    } else {
      // if user is not logged in, redirect to login
      navigation.replace('Login', { inviteCode })
      setLoading(false)
    }
  }, [loading])

  const onSubmit = async (data: any) => {
    // if logged in
    JoinGroupService.groupJoin({
      inviteCode,
    })
      .then((membership) => {
        if (membership && membership.groupId) {
          fetchGroupById(membership.groupId)
            .then((group) => {
              dispatch(joinGroup(group))
              dispatch(loadUserGroups())
            })
            .then(() => {
              if (membership.groupId) {
                navigateToGroup(membership.groupId)
              }
            })
            .catch((err) => {
              console.log('err', err)
              elsewhereToast.showToast({
                title: 'toast.checkInviteCodeWarning.title',
                description: `backend_errors.${err?.response?.data}`,
                status: 'warning',
              })
            })
        }
      })
      .catch((err) => {
        console.log('err', err)
        elsewhereToast.showToast({
          title: 'toast.checkInviteCodeWarning.title',
          description: `backend_errors.${err?.response?.data}`,
          status: 'warning',
        })
      })
  }

  const FailureWrapper = ({ message }: { message: string }) => {
    return (
      <VStack alignItems="center" w="100%" justifyContent="center" h="100%">
        <PaddedContentArea w="100%" alignItems="center">
          {/* TODO: Convert into Elsewhere Alert */}
          <Alert status="error" variant="outline" mb={4} p={4} w="100%">
            <VStack alignItems="center" w="100%" space={8}>
              <VStack alignItems="center" space={4} w="100%">
                <BodyText
                  textAlign={'center'}
                  fontFamily="body"
                  fontWeight={400}
                >
                  {message}
                </BodyText>
              </VStack>
              {Platform.OS === 'web' ? (
                <HStack justifyContent={'center'} mt={4}>
                  <Link
                    to={{
                      screen: 'MainTabs',
                      params: {
                        screen: 'MainStack',
                        params: { screen: 'PrivateFeed' },
                      },
                    }}
                  >
                    <InfoText>{t('common.close')}</InfoText>
                  </Link>
                </HStack>
              ) : null}
            </VStack>
          </Alert>
        </PaddedContentArea>
      </VStack>
    )
  }

  if (!invite && loading) {
    return (
      <Center h="100%">
        <Loading />
      </Center>
    )
  }

  if (groups.length >= MAX_GROUPS && !isElsewhereTeam && !loading) {
    return (
      <FailureWrapper
        message={t('toast.maxGroupsWarning.description', {
          maxGroups: MAX_GROUPS,
        })}
      />
    )
  }

  if (!invite && !loading) {
    return <FailureWrapper message={t('invite.invalid')} />
  }

  return (
    <VStack alignItems="center" w="100%" justifyContent="center" h="100%">
      <PaddedContentArea w="100%" alignItems="center">
        <Alert
          status="info"
          style={{ borderColor: color }}
          variant="outline"
          mb={4}
          p={4}
          w="100%"
        >
          <VStack alignItems="center" w="100%" space={8}>
            <VStack alignItems="center" space={4} w="100%">
              <AvatarCircle
                source={{ uri: invite?.ownerProfileImageUrl || undefined }}
                mb={1}
                size={'md'}
                disablePress={true}
                name={invite?.ownerUsername as string}
              />
              <BodyText textAlign={'center'} fontFamily="body" fontWeight={400}>
                {t('invite.description', { user: invite?.ownerUsername })}
              </BodyText>
            </VStack>

            <VStack w="100%" space={2} alignItems="center">
              <AvatarCircle
                source={{ uri: invite?.imageUrl || undefined }}
                m={3}
                size={'lg'}
                disablePress={true}
                name={invite?.name as string}
              />
              <BodyText size="lg" textAlign="center">
                {invite?.name}
              </BodyText>
              <BodyText textAlign="center">
                <>
                  {invite?.memberships?.length || 1}{' '}
                  {t('common.member_plural', {
                    count: Object.keys(invite?.memberships || {}).length || 1,
                  })}
                </>
              </BodyText>
            </VStack>
            <ButtonPill w="100%" onPress={onSubmit}>
              {t('common.joinGroup')}
            </ButtonPill>
            {Platform.OS === 'web' ? (
              <HStack justifyContent={'center'} mt={4}>
                <Link
                  to={{
                    screen: 'MainTabs',
                    params: {
                      screen: 'MainStack',
                      params: { screen: 'PrivateFeed' },
                    },
                  }}
                >
                  <InfoText>{t('common.cancel')}</InfoText>
                </Link>
              </HStack>
            ) : null}
          </VStack>
        </Alert>
      </PaddedContentArea>
    </VStack>
  )
}
