import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { title } from '../../../modules/strings/title-case'
import { SansText } from '../../common/copy/text-sans'
import { SANS_BOLD_FONT_WEIGHT } from '../../../constants/constants'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { SocialLinks } from '../social-links/social-links'
import { Row } from '../../common/row/row'

const SUPPORTED_MSG_I18NKEYS = ['standard', 'biblical']

type WelcomeMsgProps = {
  isOpen: boolean
  onClose: () => void
  source?: string // in the form e.g. l:en:biblical
}

export const WelcomeMsg = ({
  isOpen,
  onClose,
  source = 's:en:standard',
}: WelcomeMsgProps) => {
  // HOOKS
  const { t } = useTranslation()

  // VARS
  const sourceI18nKey: string = source.split(':')[2] || ''
  const key = SUPPORTED_MSG_I18NKEYS.includes(sourceI18nKey)
    ? sourceI18nKey
    : 'standard'

  return (
    <AlertPopup
      isOpen={isOpen}
      hideCancel={true}
      headerComponent={
        <Row alignItems="center">
          <ElsewhereIcon size={'xs'} iconKey={'elsewhere'} />
          <SansText ml={3} fontWeight={SANS_BOLD_FONT_WEIGHT}>
            {title(t('welcomeMessage.greeting'))}
          </SansText>
        </Row>
      }
      descriptionElement={
        <>
          <SansText mb={3}>{t(`welcomeMessage.${key}.p1`)}</SansText>
          <SansText mb={3}>{t(`welcomeMessage.${key}.p2`)}</SansText>
          <SansText mb={3}>{t('welcomeMessage.lookAround')}</SansText>
          <SansText>{t('welcomeMessage.signOff')}</SansText>
          <SocialLinks mt={4} />
        </>
      }
      confirmLabel={t('common.close')}
      onConfirm={() => {
        onClose()
      }}
      onClose={onClose}
    />
  )
}
