import React from 'react'
import { RootStackScreenProps } from '../../../navigation/types'
import { Form } from '../../../components/common/form/form'
import { friendForm } from '../../../forms/friend-forms'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { useSelector } from '../../../ducks/root-reducer'
import {
  addFriend,
  selectFriendByGroupId,
  updateFriend,
} from '../../../ducks/friends/friends'
import { useTranslation } from 'react-i18next'
import { FriendService } from '../../../../../api/_openapi'
import { useDispatch } from 'react-redux'

export default function FriendPreferences({
  navigation,
  route,
}: RootStackScreenProps<'PrivateFriendPreferences'>) {
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()

  const activeGroupId = useActiveGroupId()
  const friend = useSelector(selectFriendByGroupId(activeGroupId))

  const onSubmitForm = (data: any) => {
    if (!friend) {
      return
    }

    console.log('friend data', data)
    if (data.friendName && data.friendName !== friend?.friendName) {
      FriendService.updateFriend(friend.id, {
        friendName: data.friendName,
      }).then((friend) => {
        console.log('updated friend', friend)
        dispatch(updateFriend(friend))
      })
    }
    navigation.goBack()
  }

  return (
    <>
      <Form
        name={'friendForm'}
        form={friendForm}
        onSubmit={onSubmitForm}
        prepopulateValues={{
          friendName: friend?.friendName || '',
        }}
        headerTitle={t('Preferences')}
        loading={!friend}
        loadingFormValues={!friend ? 'PROCESSING' : 'DONE'}
        loadingLabel={t('loading.loadingGeneric')}
      />
    </>
  )
}
