import React from 'react'
import { useColorModeValue } from 'native-base'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { BodyText } from '../../../components/common/copy/text-body'
import { Autolink } from 'react-native-autolink'
import { RootStackNavigationProp } from '../../../navigation/types'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type SupportNavigationProp = RootStackNavigationProp<'PrivateSupport'>

export default function Support() {
  const { t } = useTranslation()

  const color = useColorModeValue(BLACK, WHITE)

  return (
    <ScrollView width="100%" height="100%">
      <PaddedContentArea>
        <BodyText color={color}>
          <Autolink
            email
            text={t(`settings.support.description`)}
            matchers={[
              {
                pattern: /#feature-requests/g,
                getLinkText: (replacerArgs) => `${replacerArgs[0]}`,
                getLinkUrl: () =>
                  `https://discord.com/channels/1067146189146951690/1067146190103248993`,
              },
              {
                pattern: /#bugs-and-issues/g,
                getLinkText: (replacerArgs) => `${replacerArgs[0]}`,
                getLinkUrl: () =>
                  `https://discord.com/channels/1067146189146951690/1119020572790247435`,
              },
            ]}
            linkProps={{
              style: {
                textDecorationLine: 'underline',
                color,
              },
            }}
          />
        </BodyText>
      </PaddedContentArea>
    </ScrollView>
  )
}
