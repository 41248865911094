/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastGroup } from '../models/FastGroup';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastGroupService {
  /**
   * Retrieves the collection of FastGroup resources.
   * Retrieves the collection of FastGroup resources.
   * @returns FastGroup FastGroup collection
   * @throws ApiError
   */
  public static fastGroups(): CancelablePromise<Array<FastGroup>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-groups',
    });
  }
  /**
   * Creates a FastGroup resource.
   * Creates a FastGroup resource.
   * @param requestBody The new FastGroup resource
   * @returns FastGroup FastGroup resource created
   * @throws ApiError
   */
  public static createGroup(
    requestBody: Partial<FastGroup>,
  ): CancelablePromise<FastGroup> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/fast-groups',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a FastGroup resource.
   * Retrieves a FastGroup resource.
   * @param id FastGroup identifier
   * @returns FastGroup FastGroup resource
   * @throws ApiError
   */
  public static getGroupInviteCode(
    id: string,
  ): CancelablePromise<FastGroup> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-groups/invite-code/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves a FastGroup resource.
   * Retrieves a FastGroup resource.
   * @param id FastGroup identifier
   * @returns FastGroup FastGroup resource
   * @throws ApiError
   */
  public static fastGroup(
    id: string,
  ): CancelablePromise<FastGroup> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-groups/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the FastGroup resource.
   * Replaces the FastGroup resource.
   * @param id FastGroup identifier
   * @param requestBody The updated FastGroup resource
   * @returns FastGroup FastGroup resource updated
   * @throws ApiError
   */
  public static updateGroup(
    id: string,
    requestBody: Partial<FastGroup>,
  ): CancelablePromise<FastGroup> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/fast-groups/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastGroup resource.
   * Removes the FastGroup resource.
   * @param id FastGroup identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteGroup(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/fast-groups/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
