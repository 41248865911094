import { PixelRatio } from 'react-native'

export const CHIP_HEIGHT = {
  sm: PixelRatio.roundToNearestPixel(32),
  md: PixelRatio.roundToNearestPixel(40),
}
export const CHIP_RADIUS = {
  sm: PixelRatio.roundToNearestPixel(16),
  md: PixelRatio.roundToNearestPixel(20),
}
export const CHIP_MARGIN = {
  sm: PixelRatio.roundToNearestPixel(4),
  md: PixelRatio.roundToNearestPixel(5),
}
export const CHIP_TEXT_SIZE = {
  sm: PixelRatio.roundToNearestPixel(14),
  md: PixelRatio.roundToNearestPixel(18),
}
export const CHIP_TEXT_MARGIN = {
  sm: PixelRatio.roundToNearestPixel(16),
  md: PixelRatio.roundToNearestPixel(20),
}
export const CHIP_LEFT_ICON_SIZE = {
  sm: PixelRatio.roundToNearestPixel(24),
  md: PixelRatio.roundToNearestPixel(32),
}
export const CHIP_LEFT_ICON_RADIUS = {
  sm: PixelRatio.roundToNearestPixel(12),
  md: PixelRatio.roundToNearestPixel(16),
}
export const CHIP_RIGHT_ICON_SIZE = {
  sm: PixelRatio.roundToNearestPixel(24),
  md: PixelRatio.roundToNearestPixel(32),
}
export const CHIP_RIGHT_ICON_RADIUS = {
  sm: PixelRatio.roundToNearestPixel(6),
  md: PixelRatio.roundToNearestPixel(8),
}
