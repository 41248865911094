import React from 'react'
import { Form } from '../../components/common/form/form'
import { useTranslation } from 'react-i18next'
import { dreamscanForm } from '../../forms/dreamscan-form'
import { VisionRequestService } from '../../../../api/_openapi'
import { IconWand } from '../../assets/react-native-svg/icons/icons-ui'
import { elsewhereToast } from '../../components/common/toast/toast'
import { getLocales } from 'react-native-localize'

export default function DreamScanScreen({ route, navigation }: any) {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const onSubmitForm = async (data: any) => {
    setLoading(true)
    const imageUrls = data.images.map((image: any) => image.url)
    const regionLanguage = getLocales()[0].languageTag || 'en-US'
    try {
      const res = await VisionRequestService.dreamVision({
        imageUrls,
        regionLanguage,
      })
      route.params.onGoBack(res)
      navigation.goBack()
    } catch (e) {
      elsewhereToast.showToast({
        description: t('common.error'),
        status: 'error',
        duration: 10000,
      })
      console.log('Error', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      name={'dreamscanForm'}
      form={dreamscanForm}
      onSubmit={onSubmitForm}
      prepopulateValues={{
        images: [],
      }}
      headerTitle={t('dreamscan.header')}
      loading={loading}
      loadingLabel={t('dreamscan.loading')}
      loadingIcon={<IconWand size={'xs'} />}
    />
  )
}
