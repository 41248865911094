import React from 'react'
import { StyleSheet } from 'react-native'
import { SansText } from '../copy/text-sans'
import { useColorModeValue } from 'native-base'
import { CREAM, LIGHT_BLACK } from '../../../constants/ui-constants'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Box } from '../box/box'

type TextBadgeProps = InterfaceBoxProps & {
  label: string
}

export const TextBadge = ({ label, ...boxProps }: TextBadgeProps) => {
  // COLORS
  const backgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const borderColor = useColorModeValue(LIGHT_BLACK, CREAM)

  return (
    <Box
      style={{
        ...styles.numSymbolUsesBox,
        backgroundColor,
        borderColor,
      }}
      borderRadius={'full'}
      {...boxProps}
    >
      <SansText
        style={{ ...styles.numUses, color: borderColor }}
        fontSize={'sm'}
        lineHeight={'sm'}
      >
        {label}
      </SansText>
    </Box>
  )
}

const styles = StyleSheet.create({
  numSymbolUsesBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    minHeight: 20,
    minWidth: 20,
    paddingHorizontal: 4,
    top: -1,
  },
  wordBox: {
    marginRight: 4,
  },
  numUses: {
    fontSize: 12,
  },
})
