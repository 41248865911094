import React from 'react'
import useContentWidth from '../../../hooks/useContentWidth'
import { useColorModeValue, Avatar } from 'native-base'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { ImageURISource } from 'react-native'
import { Loading } from '../../layout/loading'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../../constants/ui-constants'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Row } from '../../common/row/row'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { Column } from '../../common/column/column'

type PostUserInfoRowGenericProps = InterfaceBoxProps & {
  // CONTENT
  avatarCircleSrc?: ImageURISource
  avatarLabel: string
  avatarSubLabel?: string | null
  // LOADING
  authorImgLoading?: boolean
  //  ACTION SHEET
  hasActions: boolean
  actionSheetOpenable?: boolean
  ellipsisOnPress?: () => void
  // LINKS
  userInfoLinkObject?: any
  avatarOnPress?: () => void
  // I18N
  avatarLabelLanguage?: SupportedLanguage
}

// This component contains everything on a post (dream, interpretation, etc)
// that needs to know about the user/author (avatar, action sheet, etc)
export const PostUserInfoRow = ({
  avatarCircleSrc,
  avatarLabel,
  avatarSubLabel,
  authorImgLoading,
  hasActions,
  actionSheetOpenable,
  ellipsisOnPress,
  userInfoLinkObject,
  avatarLabelLanguage,
  avatarOnPress,
  ...rest
}: PostUserInfoRowGenericProps) => {
  // HOOKS
  const { contentWidth, paddedImageWidth } = useContentWidth()
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  // VARS
  const loading = !!authorImgLoading
  const hasAuthorImg = !!avatarCircleSrc
  const isUriSrc = checkIsUriSrc(avatarCircleSrc)
  const isLocalSrc = hasAuthorImg && !isUriSrc

  // "SQUARE"
  // This is an imaginary square on web, formed by the upper and lower lines of post-user-info-row,
  // the left side of the content area, and the first vertical line of the frame,
  // extended upwards imaginarily. The avatar circle is in the middle of this.
  const frameOuterWidth = Math.floor((contentWidth - paddedImageWidth) / 2)
  const squareWidth = Math.max(frameOuterWidth, 40)
  const isMobileLayout = frameOuterWidth < 40
  const rowPaddingLeft = isMobileLayout ? 4 : 0
  const rowPaddingRight = isMobileLayout ? '6px' : 0

  return (
    <Row
      justifyContent={actionSheetOpenable ? 'space-between' : 'flex-start'}
      alignItems={'center'}
      paddingLeft={rowPaddingLeft}
      paddingRight={rowPaddingRight}
      paddingTop={2}
      paddingBottom={2}
      {...rest}
    >
      <ConditionalLink to={userInfoLinkObject} condition={true}>
        <Row
          justifyContent={'center'}
          alignItems={'center'}
          width={`${squareWidth}px`}
        >
          {loading && (
            // Make sure the loading spinner is the same size as the avatar
            <Row mr={2} mt={1}>
              <Loading />
            </Row>
          )}
          {!loading && isUriSrc && (
            <AvatarCircle
              source={avatarCircleSrc}
              size={'sm'}
              name={avatarLabel}
            />
          )}
          {!loading && isLocalSrc && (
            <Row p={1}>
              <Avatar source={avatarCircleSrc} size={'sm'} />
            </Row>
          )}
        </Row>
      </ConditionalLink>

      {/* 2) AVATAR TEXT */}
      <Row flex={1} paddingX={isMobileLayout ? 3 : 1}>
        <ConditionalLink to={userInfoLinkObject} condition={true}>
          <Column>
            <Row>
              <SansText
                fontWeight={500}
                language={avatarLabelLanguage || 'en'} // So we use a font with ASCII characters if required
              >
                {avatarLabel}
              </SansText>
            </Row>
            {avatarSubLabel && (
              <SansText fontSize={12} color={mutedColor}>
                {avatarSubLabel}
              </SansText>
            )}
          </Column>
        </ConditionalLink>
      </Row>
      {/* 3) ELLIPSIS ICON */}
      <Row width={`${squareWidth}px`} justifyContent={'center'}>
        {hasActions && (
          <ButtonIcon
            iconKey="ellipsis"
            size={'xs'}
            disabled={false}
            variant={'ghost'}
            onPress={() => {
              ellipsisOnPress && ellipsisOnPress()
            }}
          />
        )}
      </Row>
    </Row>
  )
}

function checkIsUriSrc(
  src: ImageURISource | undefined,
): src is { uri: string } {
  if (!src || typeof src !== 'object') {
    return false
  } else {
    return 'uri' in src
  }
}
