import React from 'react'
import { Column } from 'native-base'
import { InputFieldFake } from '../input-field-fake'
import { Appearance } from 'react-native'
import {
  dateFromString,
  formatDateForDatabase,
  formatTimeForDatabase,
  formatTimeForDisplay,
} from '../../../../modules/strings/string-helpers'
import { getLocales } from 'react-native-localize'
import { RefCallBack } from 'react-hook-form'
import { selectUser } from '../../../../ducks/user/user'
import { useSelector } from 'react-redux'
import Flatpickr from 'react-flatpickr'
import store from '../../../../ducks/store'
import { useUserDate } from '../../../../hooks/useUserDate'
import { parseISO } from 'date-fns'
import { isSupportedLanguage } from '../../../../modules/language-helpers/language-helpers'

const l10n: any = require('flatpickr/dist/l10n').default

type DatePickerProps = {
  maxDate?: Date
  minDate?: Date
  variant?: 'underlined' | 'unstyled'
  onChangeDate?: (date: string) => void
  date?: string
  displayType?: 'input' | 'select' | 'textInput'
  placeholder?: string
  setError?: any
  setFocus?: any
  fieldName?: string
  isInvalid?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  inputRef?: RefCallBack
  time?: boolean
}

export const DatePicker = React.memo(
  ({
    maxDate,
    minDate,
    variant,
    onChangeDate,
    date,
    displayType = 'input',
    placeholder = '',
    setError,
    setFocus,
    fieldName,
    isInvalid,
    isRequired,
    isDisabled,
    inputRef,
    time = false,
  }: DatePickerProps) => {
    const dateStr = date ? date.substring(0, 10) : undefined
    const timeStrWithDate =
      time && date ? new Date().toISOString().substring(0, 11) + date : ''
    const dateVar = dateFromString(dateStr)
    const user = useSelector(selectUser)

    const userDevicePref = Appearance.getColorScheme() || 'light'
    const val = store.getState().user?.user?.colorMode
    if (!val || val === 'auto') {
      require(`./themes/${userDevicePref}.scss`)
    } else {
      require(`./themes/${val}.scss`)
    }

    // Get current language on device
    const locale = getLocales()[0]
    const deviceLanguage = locale.languageTag || 'en-US'
    const subdomain = window.location.hostname.split('.')[0]
    const fallbackLanguage = isSupportedLanguage(subdomain)
      ? subdomain
      : deviceLanguage

    const userLanguage = user?.languageCode
      ? user?.languageCode + '-' + locale.countryCode
      : fallbackLanguage

    const fp = React.useRef<any>(null)
    const wrapper = React.useRef<any>(null)

    React.useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
          return
        } else {
          if (!fp?.current?.flatpickr) return
          fp.current.flatpickr.open()
        }
      }
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }, [])

    const { dateString } = useUserDate(
      time ? timeStrWithDate : (date as string),
    )

    return (
      <Column ref={wrapper} style={{ width: '100%' }}>
        <InputFieldFake
          inputRef={inputRef}
          mt={0}
          mb={0}
          placeholder={placeholder}
          fieldName={fieldName}
          onPress={() => {}}
          isInvalid={isInvalid}
          type={displayType}
          value={
            time && date
              ? formatTimeForDisplay(parseISO(timeStrWithDate))
              : date
              ? dateString
              : ''
          }
          variant={variant}
          textAlign={'center'}
          setFocus={setFocus}
          isReadOnly={true}
          isDisabled={isDisabled}
        />
        <Flatpickr
          ref={fp}
          id="date"
          disabled={isDisabled}
          onChange={([d]) => {
            if (!d) {
              return
            }
            const dateString =
              time && date ? formatTimeForDatabase(d) : formatDateForDatabase(d)
            onChangeDate && onChangeDate(dateString)
          }}
          value={time ? [date || ''] : [dateFromString(dateStr) || '']}
          onClose={() => {
            if (!dateVar && isRequired) {
              setError(fieldName, { type: 'required' })
            }
          }}
          options={{
            disableMobile: true,
            mode: time ? 'time' : 'single',
            minuteIncrement: 1,
            maxDate: maxDate,
            minDate: minDate,
            locale: l10n[userLanguage.split('-')[0]],
            positionElement: wrapper?.current,
            position: 'auto center',
          }}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          placeholder={placeholder}
        />
      </Column>
    )
  },
)
