import React from 'react'
import {
  createBottomTabNavigator,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs'
import { BottomTabBar } from './BottomTabBar'
import { useTranslation } from 'react-i18next'
import { HeaderTitle } from './shared'
import { MainTabsParamList } from './types'
import MainStack from './MainStack'

const BottomTabs = createBottomTabNavigator<MainTabsParamList>()

export type BottomTabsType = ReturnType<
  typeof createBottomTabNavigator<MainTabsParamList>
>
interface MainTabsProps {
  openDrawer: () => void
  defaultScreenOptions?: BottomTabNavigationOptions
}

export function MainTabs({ openDrawer, defaultScreenOptions }: MainTabsProps) {
  const { t } = useTranslation()
  return (
    <BottomTabs.Navigator
      initialRouteName="MainStack"
      tabBar={(props) => <BottomTabBar {...props} openDrawer={openDrawer} />}
      backBehavior="history"
      screenOptions={({ navigation, route }) => ({
        ...defaultScreenOptions,
        headerTitle: (props) => (
          <HeaderTitle {...props} navigation={navigation} route={route} />
        ),
      })}
    >
      <BottomTabs.Screen
        name="MainStack"
        options={{
          ...defaultScreenOptions,
          headerShown: false,
          headerTitleAlign: 'center',
        }}
      >
        {(props) => (
          <MainStack {...props} defaultScreenOptions={defaultScreenOptions} />
        )}
      </BottomTabs.Screen>
    </BottomTabs.Navigator>
  )
}
