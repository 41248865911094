import React from 'react'
import { Path } from 'react-native-svg'
import { Icon } from '../../components/common/icon/icon'

export function Close({
  color = 'currentColor',
  size = 24,
}: {
  color: string
  size: number
}) {
  return (
    <Icon width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M4 4L20 20" stroke={color} strokeWidth="2" />
      <Path d="M20 4L4 20" stroke={color} strokeWidth="2" />
    </Icon>
  )
}
