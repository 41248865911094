import React from 'react'
import { IAlertProps as NbAlertProps } from 'native-base/lib/typescript/components/composites/Alert'
import { Alert as NbAlert } from 'native-base'

export type IAlertProps = NbAlertProps

// This is a simple wrapper around native-base's View component
export const Alert = ({ children, ...rest }: IAlertProps) => {
  return <NbAlert {...rest}>{children}</NbAlert>
}
