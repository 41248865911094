/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiConfig } from '../models/ApiConfig';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiConfigService {
  /**
   * Retrieves a ApiConfig resource.
   * Retrieves a ApiConfig resource.
   * @param appVersion
   * @returns ApiConfig ApiConfig resource
   * @throws ApiError
   */
  public static apiConfig(
    appVersion?: string,
  ): CancelablePromise<ApiConfig> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/config',
      query: {
        'appVersion': appVersion,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
