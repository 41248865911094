import React from 'react'
import { add } from 'date-fns'
import { uuidv7 } from 'uuidv7'
import { View } from 'react-native'
import { Form } from '../../../components/common/form/form'
import { useDispatch, useSelector } from 'react-redux'
import { newDreamForm } from '../../../forms/dream-form'
import {
  saveDraftDream,
  saveDream,
} from '../../../ducks/dream-tag/thunks/dream-thunks'
import {
  cap,
  formatDateForDatabase,
} from '../../../modules/strings/string-helpers'
import { useTranslation } from 'react-i18next'
import { setDreamFieldsLoading } from '../../../ducks/ui/ui'
import { addFastDream } from '../../../ducks/dream-tag/dream-tag'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import {
  selectCurrentUserProfile,
  selectIsElsewhereTeam,
  selectUserPrefersNoImages,
} from '../../../ducks/user/user'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { newFastDream } from '../../../ducks/dream-tag/modules/dream-helpers'
import { FastDream } from '../../../../../api/_openapi'
import { useAuth } from '../../../contexts/auth-context'
import { EntryType } from '../../../../../api/frontend-types'

export type DreamAddNavigationProp = RootStackNavigationProp<'PrivateDreamAdd'>

export default function DreamAdd({
  navigation,
}: RootStackScreenProps<'PrivateDreamAdd'>) {
  // HOOKS
  const { authData } = useAuth()
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const [dreamType, setDreamType] = React.useState<EntryType>('dream')

  // SELECTORS
  const privateGroupId = useSelector(selectPrivateGroupId)
  const currentUserProfile = useSelector(selectCurrentUserProfile)
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
  const userPrefersNoImages = useSelector(selectUserPrefersNoImages)

  // ON SUBMIT
  const onSubmitForm = (data: any) => {
    if (!privateGroupId) {
      return
    }

    const isDraft = Boolean(data.isDraft)
    // When you send a id, the server will return it as the id of the dream
    const id = uuidv7()
    const baseDream = {
      id,
      title: data.title || '',
      type: dreamType,
      description: data.description || 'Error: No description provided.',
      date: data.date || formatDateForDatabase(new Date()),
      displayDate: data.displayDate,
      userId: authData?.user,
      note: data.note || '',
      isDraft,
      ...data.place,
    } as Partial<FastDream>

    // 1) Dispatch the dream to redux
    const dreamToAddToStore: Partial<FastDream> = {
      ...baseDream,
    }
    dispatch(addFastDream(newFastDream(dreamToAddToStore)))

    // 2) Set image loading (unless the dream is a draft or user doesn't want images)
    if (!isDraft && !userPrefersNoImages) {
      dispatch(
        setDreamFieldsLoading({
          dreamId: id,
          fields: ['imageId'],
          loading: true,
        }),
      )
    }

    // 3) Navigate to the page
    // If it's a draft, navigate to the drafts page
    if (isDraft) {
      navigation.navigate('MainTabs', {
        screen: 'MainStack',
        params: { screen: 'PrivateDraftsFeed' },
      })
    } else {
      if (dreamType === 'dream') {
        navigation.replace('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateDreamView',
            params: {
              id,
              tab: 'dream',
            },
          },
        })
      } else if (dreamType === 'journal-entry') {
        navigation.replace('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateDiaryView',
            params: {
              id,
            },
          },
        })
      }
    }

    // 4) Save the dream
    const dreamToSend = {
      ...baseDream,
    }

    if (isDraft || dreamType === 'journal-entry') {
      dispatch(saveDraftDream(privateGroupId, dreamToSend))
    } else {
      dispatch(saveDream(privateGroupId, id, dreamToSend, subscriptionStatus))
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <Form
        name={'newDreamForm'}
        form={newDreamForm}
        onSubmit={onSubmitForm}
        headerTitle={
          dreamType === 'dream'
            ? t('addDreamPage.header')
            : t('addDreamPage.addDiaryEntry')
        }
        showHeaderOptions={isElsewhereTeam}
        headerOptions={[
          {
            label: cap(t('common.dream_plural', { count: 1 })),
            onPress: () => {
              setDreamType('dream')
            },
            closesMenu: true,
            iconKey: dreamType === 'dream' ? 'check' : undefined,
          },
          {
            label: t('common.diary'),
            onPress: () => {
              setDreamType('journal-entry')
            },
            iconKey: dreamType === 'journal-entry' ? 'check' : undefined,
            closesMenu: true,
          },
          {
            label: t('common.cancel'),
            onPress: () => {},
            closesMenu: true,
          },
        ]}
        prepopulateValues={{
          date: formatDateForDatabase(
            add(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
              ),
              { days: currentUserProfile?.dreamDateOffset || 0 },
            ),
          ),
          title: '',
          description: '',
          note: '',
        }}
        confirmCancelMessage={t('dialog.confirmCancelDream.description')}
        checkOnCancelFields={['description', 'title', 'note']}
        showScanIfFormIsNotValid={true}
      />
    </View>
  )
}
