import React from 'react'
import { useColorModeValue } from 'native-base'
import { SelectWithActionSheet } from '../inputs/select/select-with-action-sheet'
import { CheckboxGroup } from '../inputs/checkbox/field-checkboxes'
import { TextInput } from '../inputs/text-input'
import { TextArea } from '../inputs/input-textarea'
import { InputHeading } from '../inputs/input-heading'
import { DatePicker } from '../inputs/date-picker/date-picker'
import { FormFieldDetails, FormNavigationProp } from './form'
import { useSelector } from '../../../ducks/root-reducer'
import { TagAreaInput } from '../../composite/tags/tag-area-input'
import { InputImage } from '../inputs/input-image'
import { FieldImageUpload } from '../inputs/field-image-upload'
import { Platform } from 'react-native'
import { MultiToggleSwitch } from '../switch/multi-toggle-switch'
import { useTranslation } from 'react-i18next'
import { CountrySelect } from '../inputs/select/select-country'
import { useController, Control } from 'react-hook-form'
import { ColorPicker } from '../../composite/colorpicker/colorpicker'
import { ContentDivider } from '../../layout/content-divider'
import { HeadingSans } from '../copy/heading-sans'
import { CheckboxGroupWithImages } from '../inputs/checkbox/field-checkboxes-images'
import {
  MD_FONTSIZE_PIXELS,
  MD_LINE_HEIGHT,
} from '../../../constants/constants'

import {
  MediaObjectWithDream,
  ImageOnObject,
  LocalTag,
  FormFieldType,
} from '../../../../../api/frontend-types'
import {
  FieldValues,
  UseFormSetError,
  UseFormSetFocus,
  UseFormClearErrors,
} from 'react-hook-form'
import {
  BLACK,
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
  WHITE,
} from '../../../constants/ui-constants'
import { InputToggle } from '../inputs/input-toggle'
import { FieldMultiImagePickerWithCamera } from '../inputs/multi-image-picker-with-camera'
import UserLocation from '../location/user-location'
import { selectIsElsewhereTeam, selectUser } from '../../../ducks/user/user'
import { FastDream, FastTag } from '../../../../../api/_openapi'
import { SwitchWithValue } from '../switch/switch-with-value'
import { Row } from '../row/row'
import { MarkdownText } from '../copy/markdown-text'
import i18n from '../../../i18n/i18nnext'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import { SansText } from '../copy/text-sans'
import { Box } from '../box/box'
import { FormControl } from '../form-control/form-control'
import { CheckboxGroupWithFreeOption } from '../inputs/checkbox/field-checkboxes-with-free-option'

export type FormFieldNavigationProp = FormNavigationProp

const FIELDS_WITH_INLINE_LABELS: FormFieldType[] = ['toggle']
const showLabelInline = (type: FormFieldType) => {
  return FIELDS_WITH_INLINE_LABELS.includes(type)
}

export type CheckboxGroupWithFreeOptionType = {
  freeOption: string
  freeOptionText: string
  values: string[]
}

export type Value =
  | string
  | boolean
  | string[]
  | [boolean, string]
  | LocalTag[]
  | ImageOnObject
  | ImageOnObject[]
  | CheckboxGroupWithFreeOptionType
  | undefined

// Value can be different types
// So we need to use some type guards, I guess
const valueIsString = (value: Value): value is string | undefined => {
  return typeof value === 'string' || value === undefined
}

const valueIsStringArray = (value: Value): value is string[] | undefined => {
  return Array.isArray(value) || value === undefined
}

const valueIsTagArray = (value: Value): value is LocalTag[] | undefined => {
  return Array.isArray(value) || value === undefined
}

const valueIsImage = (value: Value): value is ImageOnObject | undefined => {
  return (
    (typeof value === 'object' &&
      value !== null &&
      (value as ImageOnObject).url !== undefined) ||
    value === undefined ||
    value === null
  )
}

const valueIsImageArray = (value: Value): value is ImageOnObject[] => {
  return Array.isArray(value)
}

const valueIsBoolean = (value: Value): value is boolean | undefined => {
  return typeof value === 'boolean' || value === undefined
}

const valueIsArrayWithBooleanAndValue = (
  value: Value,
): value is [boolean, string] => {
  return (
    (Array.isArray(value) &&
      value.length === 2 &&
      typeof value[0] === 'boolean' &&
      typeof value[1] === 'string') ||
    value === undefined
  )
}

const valueIsCheckboxGroupWithFreeOptionType = (
  value: Value,
): value is CheckboxGroupWithFreeOptionType => {
  return (
    (typeof value === 'object' &&
      Object.keys(value).length === 3 &&
      Object.keys(value).every(
        (i) => ['freeOption', 'freeOptionText', 'values'].indexOf(i) != -1,
      )) ||
    value === undefined
  )
}

export type FormFieldProps = {
  formField: FormFieldDetails
  control: Control
  setFocus?: UseFormSetFocus<FieldValues>
  setError?: UseFormSetError<FieldValues>
  clearErrors?: UseFormClearErrors<FieldValues>
  dream?: FastDream
  defaultValue?: any
  avatarFallbackName?: string
}

export const FormField = ({
  formField,
  control,
  setFocus,
  setError,
  clearErrors,
  dream,
  defaultValue,
  avatarFallbackName,
}: FormFieldProps) => {
  const {
    type,
    label,
    labelSelector,
    placeholder,
    placeholderSelector,
    description,
    options,
    optionsSelector,
    containerProps,
    containerStyleProps,
    displayType,
    datePickerProps,
    imagePickerProps,
    checkboxProps,
    checkboxWithFreeOptionProps,
    loading,
    inputProps,
    searchable,
    name,
    rules,
    hasLowerDivider,
    dir,
    scriptType,
  } = formField

  const {
    field: { ref: inputRef, value, onChange: baseOnChange, onBlur },
    fieldState: { invalid: isInvalid, isTouched, error },
  } = useController({
    name,
    control,
    rules,
  })

  const onChange = (value: any) => {
    if (formField.onChange) {
      formField.onChange(value)
    }
    baseOnChange(value)
  }

  // HOOKS
  const { t } = useTranslation()
  const color = useColorModeValue(BLACK, WHITE)

  // SELECTORS
  // If the options are dynamic
  // we pass a function to get them from the store
  const selectedOpts = optionsSelector ? useSelector(optionsSelector) : []
  const selectedPlaceholder = placeholderSelector
    ? useSelector(placeholderSelector)
    : ''
  const selectedLabel = labelSelector ? useSelector(labelSelector) : ''

  // VARS
  const language = i18n.resolvedLanguage || 'en'
  const fontFamily = getSansFont(language)
  const optionsToUse = options || selectedOpts
  const placeholderKey = selectedPlaceholder || placeholder
  const labelKey = selectedLabel || label
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
  const elsewhereTeamFields = [
    'user-location',
    'user-location-disable-autodetect',
  ]

  const descriptionColorPure = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  // ref containing value to pass to uncontrolled textarea as a default value
  const initialValue = React.useRef<string>(value)

  // update the initialValue if it's changed and the field is clean
  React.useEffect(() => {
    if (!control.getFieldState(name).isDirty) {
      initialValue.current = value
    }
  }, [value])

  if (formField.hidden) {
    return null
  }

  if (elsewhereTeamFields.includes(type) && !isElsewhereTeam) {
    return null
  }

  return (
    <Box
      style={{
        ...containerStyleProps,
      }}
      mt={4}
      mb={4}
      {...containerProps}
    >
      <FormControl isInvalid={isInvalid}>
        {labelKey && !showLabelInline(type) && (
          <HeadingSans mb={2}>{t(labelKey)}</HeadingSans>
        )}
        {description && (
          <MarkdownText
            style={{
              body: {
                fontFamily: fontFamily,
                fontWeight: '400',
                fontSize: MD_FONTSIZE_PIXELS,
                lineHeight: MD_LINE_HEIGHT,
                color: descriptionColorPure,
                marginBottom: 12,
                width: '100%',
              },
              link: {
                marginBottom: Platform.OS === 'android' ? -6.75 : -3.2,
              },
            }}
          >
            {t(description)}
          </MarkdownText>
        )}
        {/* INPUT */}
        {type === 'input' && valueIsString(value) && (
          <TextInput
            inputRef={inputRef}
            value={value}
            isInvalid={isInvalid}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onBlur={onBlur}
            autoCapitalize="none"
            dir={dir}
            scriptType={scriptType}
            autoCorrect={false}
            onChangeText={(value) => onChange(value)}
            {...inputProps}
          />
        )}
        {/* EMAIL */}
        {type === 'email' && valueIsString(value) && (
          <TextInput
            inputRef={inputRef}
            value={value}
            isInvalid={isInvalid}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            autoComplete="email"
            keyboardType="email-address"
            autoCapitalize="none"
            autoCorrect={false}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            {...inputProps}
          />
        )}
        {/* TITLE */}
        {type === 'title' && valueIsString(value) && (
          <InputHeading
            inputRef={inputRef}
            value={value}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onBlur={onBlur}
            onChangeText={onChange}
            autoFocus={formField.autoFocus || false}
            {...inputProps}
          />
        )}
        {/* UNCONTROLLED TEXTAREA */}
        {type === 'textarea-uncontrolled' && valueIsString(value) && (
          <TextArea
            {...inputProps}
            inputRef={inputRef}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onBlur={onBlur}
            onChangeText={onChange}
            initialString={initialValue}
            fieldName={'uncontrolled-' + name}
          />
        )}
        {/* TEXTAREA */}
        {type === 'textarea-controlled' && valueIsString(value) && (
          <TextArea
            {...inputProps}
            inputRef={inputRef}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            fieldName={'controlled-' + name}
          />
        )}
        {isElsewhereTeam && type === 'user-location' && (
          <Row width={'100%'} px={0} alignItems={'center'}>
            <UserLocation
              defaultPlace={defaultValue}
              allowAutoDetect={true}
              onSelected={(value) => onChange(value)}
            />
          </Row>
        )}
        {isElsewhereTeam && type === 'user-location-disable-autodetect' && (
          <Row width={'100%'} px={0} alignItems={'center'}>
            <UserLocation
              defaultPlace={defaultValue}
              allowAutoDetect={false}
              onSelected={(value) => onChange(value)}
            />
          </Row>
        )}
        {/* DATE */}
        {type === 'date' && valueIsString(value) && (
          <DatePicker
            inputRef={inputRef}
            isInvalid={isInvalid}
            isRequired={Boolean(rules?.required)}
            isTouched={isTouched}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            variant="unstyled"
            onChangeDate={(date: string) => {
              clearErrors && clearErrors(name)
              onChange(date)
            }}
            date={
              // Convert string to date on input
              value
            }
            displayType={displayType}
            setError={setError}
            setFocus={setFocus}
            clearErrors={clearErrors}
            fieldName={name}
            {...datePickerProps}
          />
        )}
        {/* SWITCH */}
        {type === 'switch' && valueIsBoolean(value) && (
          <MultiToggleSwitch
            type={'boolean'}
            value={value}
            onToggle={(value: boolean | string) => {
              onChange(value)
            }}
          />
        )}
        {/* SWITCH WITH VALUE */}
        {type === 'switch-with-value' &&
          valueIsArrayWithBooleanAndValue(value) && (
            <SwitchWithValue
              value={value}
              onToggle={(value: [boolean, string]) => {
                onChange(value)
              }}
            />
          )}
        {/* MULTISWITCH */}
        {type === 'multiswitch' && valueIsString(value) && (
          <MultiToggleSwitch
            type={'multi'}
            value={value}
            onToggle={(value: boolean | string) => {
              onChange(value)
            }}
            options={optionsToUse || []}
          />
        )}
        {/* TOGGLE */}
        {type === 'toggle' && valueIsBoolean(value) && (
          <InputToggle
            value={value}
            label={labelKey || ''}
            onValueChange={(value: boolean | undefined) => {
              onChange(value)
            }}
          />
        )}
        {/* CHECKBOXES */}
        {type === 'checkboxes' && valueIsStringArray(value) && (
          <CheckboxGroup
            options={optionsToUse || []}
            onChange={(selectedOptions: string[]) => {
              onChange(selectedOptions)
            }}
            values={value || []}
            defaultValues={defaultValue}
            color={color}
            {...checkboxProps}
          />
        )}
        {type === 'checkboxes-with-free-option' &&
          valueIsCheckboxGroupWithFreeOptionType(value) && (
            <CheckboxGroupWithFreeOption
              options={optionsToUse || []}
              onChange={(selectedOptions: CheckboxGroupWithFreeOptionType) => {
                onChange(selectedOptions)
              }}
              value={
                value ||
                ({
                  freeOption: '',
                  freeOptionText: '',
                  values: [],
                } as CheckboxGroupWithFreeOptionType)
              }
              defaultValue={defaultValue}
              color={color}
              {...checkboxWithFreeOptionProps}
            />
          )}
        {/* CHECKBOXES WITH IMAGES */}
        {type === 'checkboxes-images' && valueIsStringArray(value) && (
          <CheckboxGroupWithImages
            options={optionsToUse || []}
            onChange={(selectedOptions: string[]) => {
              onChange(selectedOptions)
            }}
            defaultValues={defaultValue}
            values={value || []}
            color={color}
            {...checkboxProps}
          />
        )}
        {/* SELECT */}
        {type === 'select' && valueIsString(value) && (
          <SelectWithActionSheet
            options={optionsToUse || []}
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onChange={(option: any) => {
              onChange(option.value)
            }}
            value={value}
            fieldName={name}
            maintainSortOrder={formField.maintainSortOrder || false}
            searchable={Boolean(searchable)}
          />
        )}
        {/* COUNTRY SELECT */}
        {type === 'country-select' && valueIsString(value) && (
          <CountrySelect
            placeholder={(placeholderKey && t(placeholderKey)) || ''}
            onChange={onChange}
            value={value}
          />
        )}
        {/* TAG AREA */}
        {type === 'tagarea' && dream && valueIsTagArray(value) && (
          <TagAreaInput
            tags={value || []}
            onChangeTags={(tags: FastTag[]) => {
              onChange(tags)
            }}
            dream={dream}
            editing={true}
          />
        )}
        {/* COLOR PICKER */}
        {type === 'colorpicker' && dream && valueIsTagArray(value) && (
          <ColorPicker
            tags={value || []}
            onChangeTags={(tags: FastTag[]) => {
              onChange(tags)
            }}
            dream={dream}
            editing={true}
          />
        )}
        {/* IMAGE */}
        {type === 'image' && valueIsImage(value) && (
          <InputImage
            dreamId={dream?.id || ''}
            imageUrl={value?.url}
            loading={loading}
            onChangeImage={(image: MediaObjectWithDream) => {
              onChange(image)
            }}
            control={control}
          />
        )}
        {/* IMAGE PICKER */}
        {type === 'image-picker' && valueIsImage(value) && (
          <FieldImageUpload
            value={value}
            onChange={(image: ImageOnObject) => {
              onChange(image)
            }}
            options={imagePickerProps}
            avatarFallbackName={avatarFallbackName}
          />
        )}
        {/* IMAGE PICKER */}
        {type === 'multi-image-picker-with-camera' &&
          valueIsImageArray(value) && (
            <FieldMultiImagePickerWithCamera
              pickedImages={value}
              onChange={(images: ImageOnObject[]) => {
                onChange(images)
              }}
              options={imagePickerProps}
            />
          )}
        <FormControl.ErrorMessage>
          <SansText fontSize={'sm'}>
            {(error?.message && t(error.message)) || ''}
          </SansText>
        </FormControl.ErrorMessage>
      </FormControl>
      {
        // Divider
        hasLowerDivider && (
          <ContentDivider backgroundColor={'gray.200'} mb={0} mt={6} />
        )
      }
    </Box>
  )
}
