import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { InterpretationBasic } from '../../../../api/frontend-types'

export type InterpretationState = {
  interpretations: {
    [key: string]: InterpretationBasic
  }
}

// This is not for all interpretations, just the ones that are stored in the redux store
const initialState: InterpretationState = {
  interpretations: {},
}

const interpretationSlice = createSlice({
  name: 'interpretations',
  initialState,
  reducers: {
    addInterpretation(state, action: PayloadAction<InterpretationBasic>) {
      state.interpretations[action.payload.id] = action.payload
    },
    removeInterpretation(state, action: PayloadAction<string>) {
      delete state.interpretations[action.payload]
    },
  },
})

export const { addInterpretation, removeInterpretation } =
  interpretationSlice.actions

// SELECTORS
export const selectInterpretationById =
  (id: string) =>
  (state: RootState): InterpretationBasic | undefined => {
    return state.interpretations.interpretations[id]
  }

export default interpretationSlice
