import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../copy/text'

type FormattedTimeAgoProps = {
  timeAgoInSeconds: number // How many seconds ago
}

const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR

// We were using 'react-intl' for this, but updating components twice when
// the language changes is expensive. So we're going to use i18next for this for now
export const FormattedTimeAgo = ({
  timeAgoInSeconds,
}: FormattedTimeAgoProps) => {
  const { t } = useTranslation()

  const timeAgo = Math.abs(timeAgoInSeconds)

  // We use i18n strings like {count, plural, =1{1s ago} other{{count}s ago}}
  // to handle the pluralization of the time units
  // For now, we only do seconds, minutes, hours, and days

  // In the case of 'days ago', we need to take into account the time of day it is now
  // Because if it's 5pm, 1.5 days ago is still 'yesterday'

  let timeAgoString = ''
  if (timeAgo < SECONDS_IN_MINUTE) {
    timeAgoString = t('time.secondsAgo', { count: timeAgo })
  } else if (timeAgo < SECONDS_IN_HOUR) {
    const minutes = Math.floor(timeAgo / SECONDS_IN_MINUTE)
    timeAgoString = t('time.minutesAgo', { count: minutes })
  } else if (timeAgo < SECONDS_IN_DAY) {
    const hours = Math.floor(timeAgo / SECONDS_IN_HOUR)
    timeAgoString = t('time.hoursAgo', { count: hours })
  } else {
    // We need to take into account the time of day
    // Because if it's 5pm, 1.5 days ago is still 'yesterday'
    const timeAgoPlusNow = timeAgo + secondsToPrevMidnight()
    const days = Math.floor(timeAgoPlusNow / SECONDS_IN_DAY)
    timeAgoString = t('time.daysAgo', { count: days })
  }
  return <Text>{timeAgoString}</Text>
}

// Function that returns the seconds between the current time and midnight (in the past)
// e.g. if it's 1am, it will return 3600
export const secondsToPrevMidnight = () => {
  const now = new Date()
  const midnight = new Date(now)
  midnight.setHours(0, 0, 0, 0)
  return Math.abs(Math.ceil((now.getTime() - midnight.getTime()) / 1000))
}
