/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountedFastTag } from '../models/CountedFastTag';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CountedFastTagService {
  /**
   * Retrieves the collection of CountedFastTag resources.
   * Retrieves the collection of CountedFastTag resources.
   * @param id CountedFastTag identifier
   * @param type
   * @param numTagsPerType
   * @param startDate
   * @param endDate
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns CountedFastTag CountedFastTag collection
   * @throws ApiError
   */
  public static countedTypeTags(
    id: string,
    type?: string,
    numTagsPerType?: number,
    startDate?: string,
    endDate?: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<CountedFastTag>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/groups/{id}/counted-type-tags',
      path: {
        'id': id,
      },
      query: {
        'type': type,
        'numTagsPerType': numTagsPerType,
        'startDate': startDate,
        'endDate': endDate,
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
}
