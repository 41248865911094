import React from 'react'
import { InterfaceIconProps as NbIconProps } from 'native-base/lib/typescript/components/primitives/Icon/types'
import { Icon as NIcon } from 'native-base'

export type IconProps = NbIconProps

// This is a simple wrapper around native-base's View component
export const Icon = ({ children, ...rest }: IconProps) => {
  return <NIcon {...rest}>{children}</NIcon>
}
