import { useWindowDimensions, Dimensions } from 'react-native'
import { useEffect, useState } from 'react'
import {
  LINE_WIDTH,
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import { FRAME_PADDING_PX, MAX_IMG_W } from '../../constants/ui-constants'

// ContentWidth is the full width of the content area
// i.e. screen width on mobile size, WEB_MAX_WIDTH otherwise
// paddedContentWidth is the width of the content area minus padding
// i.e. contentWidth - PADDING_HORIZONTAL_PIXELS * 2 on mobile size,
// just contentWidth on desktop size
type WidthInfo = {
  contentWidth: number
  paddedContentWidth: number
  imageWidth: number
  paddedImageWidth: number
}

// Get the width of the content area (middle of the screen)
// If screenWidth > WEB_MAX_WIDTH, the content area will be WEB_MAX_WIDTH
// If screenWidth <= WEB_MAX_WIDTH, the content area will be the screen width minus PADDING_HORIZONTAL_PIXELS * 2
export default function useContentWidth(webMaxWidth?: number): WidthInfo {
  const initialWindowWidth = Dimensions.get('window').width
  const defaultWidthInfo = widthInfoFromWindowWidth(initialWindowWidth)

  const { width: windowWidth } = useWindowDimensions()

  // The full width of the content area
  // i.e. screen width on mobile size, WEB_MAX_WIDTH otherwise
  const [widthInfo, setWidthInfo] = useState<WidthInfo>(defaultWidthInfo)

  useEffect(() => {
    const widthInfo = widthInfoFromWindowWidth(windowWidth, webMaxWidth)
    setWidthInfo(widthInfo)
  }, [windowWidth])

  return widthInfo
}

function widthInfoFromWindowWidth(
  windowWidth: number,
  webMaxWidth?: number,
): WidthInfo {
  // Minimum space on both sides of the image
  const minHorizontalFrameWidth = FRAME_PADDING_PX * 4 + LINE_WIDTH * 2
  const totalFramePadding = FRAME_PADDING_PX * 2 + LINE_WIDTH * 2

  const webMaxW = webMaxWidth || WEB_MAX_WIDTH

  // 'Web' size
  if (windowWidth > webMaxW) {
    return {
      contentWidth: webMaxW,
      paddedContentWidth: webMaxW,
      imageWidth: MAX_IMG_W,
      paddedImageWidth: MAX_IMG_W + totalFramePadding,
    }
  } else {
    // 'Mobile' size
    const imageWidth = windowWidth - minHorizontalFrameWidth
    return {
      contentWidth: windowWidth,
      paddedContentWidth: windowWidth - PADDING_HORIZONTAL_PIXELS * 2,
      imageWidth: imageWidth,
      paddedImageWidth: imageWidth + totalFramePadding,
    }
  }
}
