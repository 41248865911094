/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DreamImage_MediaObject_read } from '../models/DreamImage_MediaObject_read';
import type { DreamImage_read } from '../models/DreamImage_read';
import type { DreamImage_write } from '../models/DreamImage_write';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DreamImageService {
  /**
   * Retrieves the collection of DreamImage resources.
   * Retrieves the collection of DreamImage resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns DreamImage_read DreamImage collection
   * @throws ApiError
   */
  public static apiDreamImagesGetCollection(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<DreamImage_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dream_images',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a DreamImage resource.
   * Creates a DreamImage resource.
   * @param requestBody The new DreamImage resource
   * @returns DreamImage_MediaObject_read DreamImage resource created
   * @throws ApiError
   */
  public static dreamImage(
    requestBody: DreamImage_write,
  ): CancelablePromise<DreamImage_MediaObject_read> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/power-di/dream-image',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
