import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Quote } from '../../layout/quote'
import { NUM_QUOTES } from '../../../constants/ui-constants'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Box } from '../../common/box/box'

type LoadingQuoteProps = InterfaceBoxProps & {}

export const RandomDreamQuote = ({ ...props }: LoadingQuoteProps) => {
  const { t } = useTranslation()

  // Quote i18n keys are in this form:
  // quotes.quote3 / quotes.quote3.author etc
  // Let's mod the minute of the hour to get a random quote
  const { quoteKey, authorKey, sourceKey } = useMemo(() => {
    const quoteNumber = (new Date().getMinutes() % NUM_QUOTES) + 1
    const quoteKey = `quotes.quote${quoteNumber}`
    const authorKey = `quotes.quote${quoteNumber}.author`
    const sourceKey = `quotes.quote${quoteNumber}.source`
    return { quoteKey, authorKey, sourceKey }
  }, [])

  const quote = t(quoteKey)
  const author = t(authorKey)
  const source = t(sourceKey)
  const hasSource = !source.includes('quotes.') && !(source === '')
  const sourceString = hasSource ? source : undefined

  return (
    <Box maxW={300} {...props}>
      <Quote quote={quote} author={author} source={sourceString} />
    </Box>
  )
}
