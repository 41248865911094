import React from 'react'
import { GridDisplay, GridItem } from '../../components/layout/grid-display'
import { InterpretationStyleKey } from '../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { Img } from '../../components/common/image/image'
import { interpreters, SM_THUMB_RES } from '../../constants/ui-constants'
import { SansText } from '../../components/common/copy/text-sans'
import { Row } from '../../components/common/row/row'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { BodyText } from '../../components/common/copy/text-body'
import i18n from '../../i18n/i18nnext'
import { useMediaQuery } from 'native-base'
import useContentWidth from '../../hooks/useContentWidth'
import { HOMEPAGE_MAX_CONTENT_WIDTH } from '../../constants/constants'
import { Column } from '../../components/common/column/column'
import { Link } from '../../components/common/link/link'

const interpStyles: InterpretationStyleKey[] = [
  'standard',
  'jungian',
  'freudian',
]

export const InterpLandingLinks = () => {
  // I18N
  const { t } = useTranslation()
  const lang = i18n.resolvedLanguage || 'en'
  const [isSmallScreen] = useMediaQuery({
    maxWidth: HOMEPAGE_MAX_CONTENT_WIDTH + 32,
  })

  const { contentWidth } = useContentWidth(HOMEPAGE_MAX_CONTENT_WIDTH)

  // There must be a better way to do this
  const columnGap = 20
  const paddingHorizontal = 16
  const wideScreenColWidth = contentWidth * (1 / 2) - columnGap
  const smallScreenColWidth = contentWidth - paddingHorizontal * 2
  const columnWidth = isSmallScreen ? smallScreenColWidth : wideScreenColWidth

  const interpBoxes: GridItem[] = interpStyles.map((interpStyle) => {
    const img =
      interpreters[interpStyle].cutoutImg || interpreters[interpStyle].img
    return {
      id: interpStyle,
      item: (
        <Link
          href={`https://interpret.elsewhere.to/${lang}/styles/${interpStyle}/`}
        >
          <Column
            key={interpStyle}
            justifyContent={'center'}
            alignItems={'center'}
            pb={3}
            width={'100%'}
          >
            <Column>
              <Img
                source={img}
                alt={t(interpreters[interpStyle].i18nKey)}
                width={SM_THUMB_RES}
                height={SM_THUMB_RES}
              />
            </Column>
            <Column>
              <SansText style={{ textAlign: 'center' }}>
                {t(interpreters[interpStyle].i18nKey)}
              </SansText>
            </Column>
          </Column>
        </Link>
      ),
    }
  })

  return (
    <Row
      alignItems={'center'}
      flexDirection={isSmallScreen ? 'column' : 'row'}
      width={contentWidth}
      justifyContent={'center'}
      style={{
        gap: columnGap,
      }}
      marginTop={10}
      marginBottom={10}
    >
      <Column width={columnWidth} paddingLeft={2} paddingRight={2}>
        <HeadingSmall mt={0}>{t('common.aiDreamInterpretation')}</HeadingSmall>
        <BodyText>{t('homePage.introInterpretations')}</BodyText>
      </Column>
      <Column
        width={columnWidth}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <GridDisplay items={interpBoxes} width={columnWidth} />
      </Column>
    </Row>
  )
}
