import React from 'react'
import i18n from 'i18next'
import { useColorModeValue } from 'native-base'
import { BLACK, DARK_MID_GRAY, WHITE } from '../../../constants/ui-constants'
import { Pressable } from 'react-native'
import { LINE_WIDTH } from '../../../constants/constants'
import { SansText } from '../copy/text-sans'
import { useTranslation } from 'react-i18next'
import { cap } from '../../../modules/strings/string-helpers'
import { Row } from '../row/row'
import { Box } from '../box/box'

type InputToggleProps = {
  label: string
  value: boolean | undefined
  onValueChange: (value: boolean | undefined) => void
  disabled?: boolean
}

export const InputToggle = ({
  label,
  value,
  onValueChange,
  disabled,
}: InputToggleProps) => {
  // HOOKS
  const { t } = useTranslation()
  const toggleBgColorActive = useColorModeValue(BLACK, WHITE)
  const toggleBgColorInactive = useColorModeValue(DARK_MID_GRAY, DARK_MID_GRAY)
  const toggleColor = useColorModeValue(WHITE, BLACK)

  // VARS
  const toggleBgColor = value ? toggleBgColorActive : toggleBgColorInactive
  const isRtl = i18n.dir() === 'rtl'
  const paddingLeftLtr = value ? 20 : 2
  const paddingRightLtr = value ? 2 : 20
  const paddingLeftRtl = value ? 2 : 20
  const paddingRightRtl = value ? 20 : 2
  const paddingLeft = isRtl ? paddingLeftRtl : paddingLeftLtr
  const paddingRight = isRtl ? paddingRightRtl : paddingRightLtr

  return (
    <Row alignItems="center">
      <Pressable
        onPress={() => onValueChange(!value)}
        disabled={disabled}
        role="button"
      >
        <Row
          style={{
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            borderWidth: LINE_WIDTH,
            borderColor: toggleColor,
            borderRadius: 20,
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: toggleBgColor,
          }}
        >
          <Box
            style={{
              height: 16,
              width: 16,
              backgroundColor: toggleColor,
              borderRadius: 20,
            }}
          />
        </Row>
      </Pressable>
      <SansText paddingX={2} color={toggleBgColor}>
        {cap(t(label))}
      </SansText>
    </Row>
  )
}
