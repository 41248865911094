import { DateRangeKey } from '../../../api/frontend-types'

// Last 2 weeks / Last 3 months / Last year
// give some symmetry and simplicity to the UI
// Since they are 14 days, 12 weeks, and 12 months
export const DATE_RANGES_TO_SHOW: DateRangeKey[] = [
  'LAST_TWO_WEEKS',
  'LAST_THREE_MONTHS',
  'LAST_YEAR',
  'ALL_TIME',
]

export const X_TICKS_PER_RANGE: { [key in DateRangeKey]: number } = {
  LAST_TWO_WEEKS: 14,
  LAST_THREE_MONTHS: 12,
  LAST_YEAR: 13,
  ALL_TIME: 12,
  CUSTOM: 12,
}
