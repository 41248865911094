import React, { useEffect } from 'react'
import { useColorModeValue } from 'native-base'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { SansText } from '../common/copy/text-sans'
import { SMALL_FONT_SIZE } from '../../constants/constants'
import { Size, avatarAndIconButtonSizes } from '../../core/theme'
import { RandomDreamQuote } from '../composite/random-quote/random-quote'
import { Box } from '../common/box/box'

// Approximately based on https://www.codedaily.io/tutorials/Creating-Animated-Rings-with-React-Native-Reanimated

type LoadingCircleProps = {
  delay: number
  width: number
  borderWidth: number
  color?: string
}

const LoadingCircle = ({
  delay,
  width,
  borderWidth,
  color,
}: LoadingCircleProps) => {
  const ring = useSharedValue(0)

  const ringStyle = useAnimatedStyle(() => {
    return {
      opacity: 1 - ring.value,
    }
  })

  useEffect(() => {
    ring.value = withDelay(
      delay,
      withRepeat(
        withTiming(0.8, {
          duration: 900,
        }),
        -0.8,
        false,
      ),
    )
  }, [])

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          width: width,
          height: width,
          borderRadius: width / 2,
          borderColor: color,
          borderWidth: borderWidth,
        },
        ringStyle,
      ]}
    />
  )
}

type LoadingProps = {
  size?: Size
  width?: number
  color?: string
  loadingLabel?: string
  hasQuote?: boolean
  fontSize?: number
  mt?: number
  mb?: number
}

// Three rings, each with a delay
export const Loading = ({
  size = 'sm',
  width,
  color,
  loadingLabel,
  fontSize = SMALL_FONT_SIZE,
  hasQuote,
  mt = 0,
  mb = 0,
}: LoadingProps) => {
  const highlight = useColorModeValue(BLACK, WHITE)
  if (!color) {
    color = highlight
  }

  // Get pixel dimensions from size
  const { width: widthProp, height: heightProp } =
    avatarAndIconButtonSizes[size]
  const pixelWidth = width || widthProp * 4
  const pixelHeight = width || heightProp * 4

  // Divide width by 11 to get the borderWidth of each ring
  const borderWidth = Math.floor(pixelWidth / 11)
  const innerCircleWidth = borderWidth * 3
  const middleCircleWidth = borderWidth * 7
  const outerCircleWidth = borderWidth * 11

  // Height of the loading component is the width + the height of the label
  // (if there is one)
  let loaderHeight = pixelWidth + (loadingLabel ? fontSize * 2 : 0)
  if (pixelHeight) {
    loaderHeight = pixelHeight
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      mt={mt}
      mb={mb}
    >
      <Box
        style={{
          height: loaderHeight,
        }}
      >
        <Box
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: pixelWidth,
            height: pixelWidth,
          }}
        >
          <LoadingCircle
            delay={0}
            width={innerCircleWidth}
            borderWidth={borderWidth}
            color={color}
          />
          <LoadingCircle
            delay={300}
            width={middleCircleWidth}
            borderWidth={borderWidth}
            color={color}
          />
          <LoadingCircle
            delay={600}
            width={outerCircleWidth}
            borderWidth={borderWidth}
            color={color}
          />
        </Box>
      </Box>
      {loadingLabel && (
        <SansText
          style={{
            color: color,
            fontSize: fontSize,
            textTransform: 'uppercase',
          }}
        >
          {loadingLabel}
        </SansText>
      )}
      {
        // If there is a quote, display
        hasQuote && <RandomDreamQuote mt={4} />
      }
    </Box>
  )
}
