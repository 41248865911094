/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JoinGroup } from '../models/JoinGroup';
import type { JoinGroup_FastMembership } from '../models/JoinGroup_FastMembership';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JoinGroupService {
  /**
   * Creates a JoinGroup resource.
   * Creates a JoinGroup resource.
   * @param requestBody The new JoinGroup resource
   * @returns JoinGroup_FastMembership JoinGroup resource created
   * @throws ApiError
   */
  public static groupJoin(
    requestBody: JoinGroup,
  ): CancelablePromise<JoinGroup_FastMembership> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/groups/join',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
