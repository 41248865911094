import React from 'react'
import { NativeBaseProvider } from './libs/native-base'
import { SafeAreaProvider } from './libs/safe-area-view'
import { Compose } from './components/common/Compose'
import { Provider as ReduxProvider } from 'react-redux'
import store, { persistor } from './ducks/store'
import { PersistGate } from 'redux-persist/integration/react'
import { AuthProvider } from './contexts/auth-context'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { ReduxNetworkProvider } from './libs/redux-network-provider'
import { NetworkProvider } from './libs/react-native-offline'
import { DrawerProvider } from './contexts/drawer-context'

export interface AppProvidersProps {
  children: React.ReactNode
}

export default function AppProviders(props: AppProvidersProps) {
  return (
    <Compose
      components={[
        //@ts-ignore
        (child) => <NetworkProvider children={child} />,
        (child) => (
          <GestureHandlerRootView style={{ flex: 1 }} children={child} />
        ),
        (child) => <ReduxProvider children={child} store={store} />,
        (child) => <NativeBaseProvider children={child} />,
        (child) => <ReduxNetworkProvider children={child} />,
        (child) => (
          <PersistGate children={child} loading={null} persistor={persistor} />
        ),
        (child) => <SafeAreaProvider children={child} />,
        (child) => <AuthProvider children={child} />,
        (child) => <DrawerProvider children={child} />,
      ]}
    >
      <>{props.children}</>
    </Compose>
  )
}
