/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserTheme } from '../models/UserTheme';
import type { UserTheme_read_date_trait_read } from '../models/UserTheme_read_date_trait_read';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserThemeService {
  /**
   * Retrieves the collection of UserTheme resources.
   * Retrieves the collection of UserTheme resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns UserTheme_read_date_trait_read UserTheme collection
   * @throws ApiError
   */
  public static getUserThemes(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<UserTheme_read_date_trait_read>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user-themes',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a UserTheme resource.
   * Creates a UserTheme resource.
   * @param requestBody The new UserTheme resource
   * @returns UserTheme_read_date_trait_read UserTheme resource created
   * @throws ApiError
   */
  public static createUserTheme(
    requestBody: Partial<UserTheme>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<UserTheme_read_date_trait_read> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user-themes',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
