import { ImageSourcePropType, ImageURISource } from 'react-native'
import { elsewhereToast } from '../../components/common/toast/toast'
import { LAST_IMAGE_WARNING } from '../../constants/constants'
import { IMG_RES } from '../../constants/ui-constants'

export function updateCloudinaryUrlForDisplay(
  url: string | null | undefined,
  res: number = IMG_RES,
): string {
  // If no URL, return empty string
  if (!url) {
    return ''
  }
  // If image is already scaled, don't scale it again
  else if (url.includes(`upload/w_`)) {
    return url.replace('.png', '.jpg')
  }
  // Else, scale the image
  else {
    return url
      .replace('/upload/', `/upload/w_${res},h_${res},c_scale/`)
      .replace('.png', '.jpg')
  }
}

// Remove specifications for height, width, and scaling from a Cloudinary URL
export function unscaleCloudinaryResolution(url: string) {
  return url.replace(/\/w_\d+,h_\d+,c_scale\//, '/').replace('.jpg', '.png')
}

export function scaleCloudinaryImageWidth(url: string, width: number) {
  return url.replace('/upload/', `/upload/w_${width},c_scale/`)
}

// Check if the source is an ImageURISource
// (i.e. in the form { uri: 'https://...' })
export const sourceIsUriSource = (
  value: ImageSourcePropType | undefined,
): value is ImageURISource => {
  return (
    typeof value === 'object' &&
    value.constructor.name !== 'Array' &&
    (value as ImageURISource).uri !== undefined
  )
}

export const showImageToast = ({
  imagesRemaining,
  creditsRemaining,
}: {
  imagesRemaining?: number | null
  creditsRemaining?: number | null
}) => {
  const remaining = imagesRemaining || creditsRemaining
  if (remaining && [LAST_IMAGE_WARNING].includes(remaining - 1)) {
    setTimeout(() => {
      elsewhereToast.showToast({
        title: 'Image generated',
        description:
          'Image generated, ' +
          (remaining - 1) +
          ` ${imagesRemaining ? 'images' : 'credits'} remaining.`,
        status: 'warning',
        duration: 6000,
      })
    }, 1000)
  }
}
