import React from 'react'
import { Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { BodyText } from './text-body'
import { InterfaceTextProps } from 'native-base/lib/typescript/components/primitives/Text/types'
import { PARAGRAPH_MARGIN } from '../../../constants/ui-constants'
import { getMarkdownMarginBottomOffset } from '../../../modules/ui-helpers/ui-helpers'
import Autolink from 'react-native-autolink'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'

export type BodyTextTranslatedProps = InterfaceTextProps & {
  color: string
  linkProps?: any
  i18nKey: string
  values?: any // What gets interpolated into the string
}

export const BodyTextTranslated = ({
  linkProps,
  i18nKey,
  values,
  color,
  ...rest
}: BodyTextTranslatedProps) => {
  const { t } = useTranslation()
  const text = t(i18nKey)

  // If the whole text is wrapped in markdown,
  // We keep the paragraph margins. But if it's not,
  // We remove the margins
  const wrapped = text.startsWith('<markdown>') && text.endsWith('</markdown>')
  const paragraphPadding = {
    marginBottom: PARAGRAPH_MARGIN,
    marginTop: PARAGRAPH_MARGIN,
  }
  const noPadding = {
    marginBottom: getMarkdownMarginBottomOffset(Platform.OS),
    marginTop: 0,
  }
  const pReset = wrapped ? paragraphPadding : noPadding

  // Need a default link
  const to = linkProps || {
    screen: 'MainTabs',
    params: {
      screen: 'MainStack',
      params: { screen: 'PrivateFeed' },
    },
  }

  const { onPress, ...restLink } = useLinkProps({ to })

  return (
    <BodyText {...rest}>
      <Autolink
        text={t(i18nKey, values) as string}
        matchers={[
          {
            pattern: /<linkTag>(.*)<\/linkTag>/g,
            getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
            onPress: () => onPress(),
          },
        ]}
        linkProps={{
          style: {
            textDecorationLine: 'underline',
          },
          onPress,
          ...restLink,
        }}
      />
    </BodyText>
  )
}
