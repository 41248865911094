/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastTagDescription } from '../models/FastTagDescription';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastTagDescriptionService {
  /**
   * Retrieves a FastTagDescription resource.
   * Retrieves a FastTagDescription resource.
   * @param id FastTagDescription identifier
   * @returns FastTagDescription FastTagDescription resource
   * @throws ApiError
   */
  public static getTagDescription(
    id: string,
  ): CancelablePromise<FastTagDescription> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tag-descriptions/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastTagDescription resources.
   * Retrieves the collection of FastTagDescription resources.
   * @param id FastTagDescription identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastTagDescription FastTagDescription collection
   * @throws ApiError
   */
  public static getTagDescriptionsByTag(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastTagDescription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tags/{id}/tag-descriptions',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a FastTagDescription resource.
   * Creates a FastTagDescription resource.
   * @param id FastTagDescription identifier
   * @param requestBody The new FastTagDescription resource
   * @returns FastTagDescription FastTagDescription resource created
   * @throws ApiError
   */
  public static createUserTagAssociation(
    id: string,
    requestBody: Partial<FastTagDescription>,
  ): CancelablePromise<FastTagDescription> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/users/{id}/tag-descriptions',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a FastTagDescription resource.
   * Retrieves a FastTagDescription resource.
   * @param id FastTagDescription identifier
   * @param tagId FastTagDescription identifier
   * @returns FastTagDescription FastTagDescription resource
   * @throws ApiError
   */
  public static getUserTagAssociation(
    id: string,
    tagId: string,
  ): CancelablePromise<FastTagDescription> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/users/{id}/tag-descriptions/tag/{tagId}',
      path: {
        'id': id,
        'tagId': tagId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the FastTagDescription resource.
   * Replaces the FastTagDescription resource.
   * @param userId FastTagDescription identifier
   * @param id FastTagDescription identifier
   * @param requestBody The updated FastTagDescription resource
   * @returns FastTagDescription FastTagDescription resource updated
   * @throws ApiError
   */
  public static updateUserTagAssociation(
    userId: string,
    id: string,
    requestBody: Partial<FastTagDescription>,
  ): CancelablePromise<FastTagDescription> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/users/{userId}/tag-descriptions/{id}',
      path: {
        'userId': userId,
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
}
