import React, { useEffect, useState } from 'react'
import { useDeviceLocation } from '../../../hooks/useDeviceLocation'
import { processPlace } from '../../../hooks/useDeviceLocation/location'
import { fromLatLng } from 'react-geocode'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../copy/text-sans'
import { useColorModeValue } from 'native-base'
import LocationInput from './location-input'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { ActionSheetMenu } from '../action-sheet/action-sheet-menu'
import { useTranslation } from 'react-i18next'
import {
  selectAlwaysDetectDreamLocation,
  setAlwaysDetectDreamLocation,
} from '../../../ducks/ui/ui'
import { useDispatch } from 'react-redux'
import { ButtonIcon } from '../buttons/button-icon'
import { HeadingSansWithOptionalButtonRow } from '../../layout/heading-sans-button-row'
import { LINE_WIDTH } from '../../../constants/constants'
import { Row } from '../row/row'
import { Column } from '../column/column'

type UserLocationProps = {
  defaultPlace: string
  onSelected: (place: any) => void
  allowAutoDetect?: boolean
}

export default function UserLocation({
  defaultPlace,
  onSelected,
  allowAutoDetect = true,
}: UserLocationProps) {
  const user = useSelector(selectUser)
  const [place, setPlace] = useState(defaultPlace)
  const [actionsMenuSheetOpen, setActionsMenuSheetOpen] = useState(false)
  const { getLocation } = useDeviceLocation()
  const color = useColorModeValue(BLACK, WHITE)
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const { t } = useTranslation()
  const alwaysDetectDreamLocation = useSelector(selectAlwaysDetectDreamLocation)
  const dispatch = useDispatch()

  const handlePlaceSelect = (data: any) => {
    const place = processPlace(data)
    setPlace(place.place)
    onSelected(place)
  }

  const detectDreamLocation = async () => {
    const location = await getLocation()
    if (location) {
      const placeResult = await fromLatLng(
        location.latitude,
        location.longitude,
        process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
      )

      if (placeResult) {
        const bestPlace = placeResult.results[0]
        if (bestPlace) {
          const place = processPlace(bestPlace)
          setPlace(place.place)
          console.log('reverse geocoded place', place)
          onSelected(place)
        }
      }
    }
  }

  useEffect(() => {
    if (allowAutoDetect && alwaysDetectDreamLocation) {
      detectDreamLocation()
    }
  }, [alwaysDetectDreamLocation, allowAutoDetect])

  return (
    <>
      <Column
        width={'100%'}
        borderTopWidth={LINE_WIDTH}
        borderColor={color}
        marginBottom={-2}
      >
        <HeadingSansWithOptionalButtonRow
          heading={t('addDreamPage.location')}
          hasButton={false}
          marginTop={4}
          marginBottom={4}
        />
        <Row alignItems={'center'} justifyContent="flex-start" w="100%">
          <Column width="90%">
            {!place && (
              <LocationInput
                color={color}
                backgroundColor={backgroundColor}
                onSelected={handlePlaceSelect}
                language={user?.languageCode || 'en'}
              />
            )}
            {place && <SansText>{place}</SansText>}
          </Column>
          <Column width="10%">
            {!place && (
              <ButtonIcon
                size={'sm'}
                onPress={() => {
                  if (!allowAutoDetect) {
                    detectDreamLocation()
                  } else {
                    setActionsMenuSheetOpen(true)
                  }
                }}
                variant={'ghost'}
                iconKey={'location'}
                m={0}
                p={0}
              />
            )}
            {place && (
              <ButtonIcon
                variant={'ghost'}
                size={'sm'}
                onPress={() => {
                  setPlace('')
                  onSelected(processPlace(null))
                }}
                iconKey={'close'}
                m={0}
                p={0}
              />
            )}
          </Column>
        </Row>
      </Column>
      {actionsMenuSheetOpen && (
        <ActionSheetMenu
          menuItems={[
            {
              label: t('Just this time'),
              closesMenu: true,
              onPress: () => {
                dispatch(setAlwaysDetectDreamLocation(false))
                detectDreamLocation()
              },
            },
            {
              label: t('Detect every time'),
              closesMenu: true,
              onPress: () => {
                dispatch(setAlwaysDetectDreamLocation(true))
              },
            },
          ]}
          isOpen={actionsMenuSheetOpen}
          onClose={() => {
            setActionsMenuSheetOpen(false)
          }}
        />
      )}
    </>
  )
}
