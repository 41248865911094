import React from 'react'
import { View, Text } from 'react-native'
import { FastDream, FastTag } from '../../../../../api/_openapi'

type ColorPickerProps = {
  tags: FastTag[]
  onChangeTags: (tags: FastTag[]) => void
  editing?: boolean
  dream: FastDream
}

export const ColorPicker = ({
  tags,
  onChangeTags,
  editing = true,
  dream,
}: ColorPickerProps) => {
  return (
    <View>
      <Text>I am a colorpicker</Text>
    </View>
  )
}
