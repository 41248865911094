import React from 'react'
import { Header } from '@react-navigation/elements'
import { HeaderLeft } from './form-header-left'
import { HeaderRight } from './form-header-right'
import { LINE_WIDTH, WEB_MAX_WIDTH } from '../../../constants/constants'
import { UseFormGetValues } from 'react-hook-form'
import { SansText } from '../copy/text-sans'
import { FormActionSheet } from '../action-sheet/form-action-sheet'
import { useSafeArea } from '../../../libs/safe-area-view'
import { Scan } from '../../../assets/react-native-svg/Scan'
import { OfflineBar } from '../../composite/offline-bar/offline-bar'
import { IconCaretDown } from '../../../assets/react-native-svg/icons/icons'
import { Row } from '../row/row'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n/i18nnext'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import { Box } from '../box/box'
import { IconButton } from '../icon-button/icon-button'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../action-sheet/action-sheet-menu'

type FormHeaderProps = {
  headerTitle: string
  statusBarColor: string
  isFirstPage: boolean
  isLastPage: boolean
  nextButtonLabel: string
  navigation: any
  formIsValid: boolean
  color: string
  onPreviousPage: () => void
  onNextPage: () => void
  reset: () => void
  handleSubmit: (values: any) => void
  onSubmit: (values: any) => void
  name?: string
  isDisabled?: boolean
  confirmCancelMessage?: string
  setCancelMsgDialogOpen?: (cancelMsgDialogOpen: boolean) => void
  onCancelAndExitForm?: () => void
  getValues?: UseFormGetValues<any>
  checkOnCancelFields?: string[]
  formActionSheet?: FormActionSheet
  showScanIfFormIsNotValid?: boolean
  navigateToDreamScanFromDreamAdd?: () => void
  showHeaderOptions?: boolean
  headerOptions?: ActionSheetMenuItem[]
  defaultValues?: any
}

export function FormHeader({
  headerTitle,
  showHeaderOptions = false,
  headerOptions = [],
  statusBarColor,
  isFirstPage,
  isLastPage,
  nextButtonLabel,
  navigation,
  formIsValid,
  color,
  onPreviousPage,
  onNextPage,
  reset,
  handleSubmit,
  onSubmit,
  name,
  isDisabled,
  setCancelMsgDialogOpen,
  onCancelAndExitForm,
  getValues,
  confirmCancelMessage = '',
  checkOnCancelFields = [],
  formActionSheet = undefined,
  showScanIfFormIsNotValid,
  navigateToDreamScanFromDreamAdd,
  defaultValues,
}: FormHeaderProps) {
  const { top } = useSafeArea()
  const { t } = useTranslation()
  const [actionsMenuOpen, setActionsMenuOpen] = React.useState(false)

  // VARS
  const language: string = i18n.resolvedLanguage || 'en'
  const fontFamily = getSansFont(language)
  const hasHeaderOptions = showHeaderOptions && headerOptions.length > 0

  const HeaderOptionsActionSheet = () => {
    if (!hasHeaderOptions || !actionsMenuOpen) return null

    return (
      <>
        <ActionSheetMenu
          menuItems={headerOptions}
          isOpen={actionsMenuOpen}
          onClose={() => setActionsMenuOpen(false)}
          heading={t('addDreamPage.typeOfEntry')}
        />
      </>
    )
  }

  const HeaderTitleComponent = () => {
    return hasHeaderOptions ? (
      <>
        <Row justifyContent={'center'} alignItems={'center'}>
          <SansText fontSize={'lg'}>{headerTitle}</SansText>
          <IconButton
            ml={-2}
            variant="unstyled"
            _pressed={{
              opacity: 0.5,
            }}
            onPress={() => setActionsMenuOpen(true)}
            icon={<IconCaretDown color={color} size={'24px'} />}
          />
        </Row>
        <HeaderOptionsActionSheet />
      </>
    ) : (
      <SansText fontSize={'lg'}>{headerTitle}</SansText>
    )
  }

  return (
    <Box maxWidth={WEB_MAX_WIDTH} mx="auto" width={'100%'}>
      <Box px={3} style={{ paddingTop: top }}>
        <Header
          title={headerTitle || ''}
          headerTitleStyle={{
            fontFamily: fontFamily,
            fontWeight: '400',
          }}
          headerStyle={{
            backgroundColor: statusBarColor,
            borderBottomColor: color,
            borderBottomWidth: LINE_WIDTH,
          }}
          headerTitleAlign="center"
          headerLeft={() => (
            <HeaderLeft
              isDisabled={isDisabled}
              isFirstPage={isFirstPage}
              onPreviousPage={onPreviousPage}
              navigation={navigation}
              color={color}
              name={name}
              reset={reset}
              setCancelMsgDialogOpen={setCancelMsgDialogOpen}
              onCancelAndExitForm={onCancelAndExitForm}
              confirmCancelMessage={confirmCancelMessage}
              getValues={getValues}
              checkOnCancelFields={checkOnCancelFields}
              defaultValues={defaultValues}
            />
          )}
          headerTitle={HeaderTitleComponent}
          headerRight={
            showScanIfFormIsNotValid &&
            !formIsValid &&
            navigateToDreamScanFromDreamAdd
              ? () => (
                  <IconButton
                    mr={-3}
                    variant="unstyled"
                    _pressed={{
                      opacity: 0.5,
                    }}
                    onPress={() => navigateToDreamScanFromDreamAdd()}
                    icon={<Scan color={color} size={24} />}
                  />
                )
              : () => (
                  <HeaderRight
                    isDisabled={isDisabled}
                    formIsValid={formIsValid}
                    isLastPage={isLastPage}
                    nextButtonLabel={nextButtonLabel}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    onNextPage={onNextPage}
                    color={color}
                    formActionSheet={formActionSheet}
                  />
                )
          }
        />
      </Box>
      <OfflineBar hasBorderTop={false} />
    </Box>
  )
}
