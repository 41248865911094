import { ImageSourcePropType } from 'react-native'
import {
  sourceIsUriSource,
  unscaleCloudinaryResolution,
} from '../../../modules/image-helpers/image-helpers'
import { snakeCase } from '../../../modules/strings/string-helpers'
import { elsewhereToast } from '../toast/toast'

export const saveImageToCameraRoll = async (
  source: ImageSourcePropType | undefined,
  alt: string,
  savedDescription: string,
  errorTitle: string,
  errorDescription: string,
) => {
  const imageTitle = Boolean(alt) ? snakeCase(alt) : 'image'
  const imageExtension = 'png'
  const imageFileName = `${imageTitle}.${imageExtension}`

  if (sourceIsUriSource(source)) {
    const sourceUri = source.uri || ''
    const unscaledUri = unscaleCloudinaryResolution(sourceUri)

    fetch(unscaledUri)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = imageFileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .then(() => {
        elsewhereToast.showToast({
          description: savedDescription,
          status: 'success',
        })
      })
      .catch((err) => {
        elsewhereToast.showToast({
          description: `${errorTitle} ${err.message}`,
          status: 'error',
        })
      })
  }
}
