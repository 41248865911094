import { AnalysisResultService } from '../../../../../api/_openapi'

// Delete an insight (AnalysisResult)
// (Update and mark as deleted)
export const markInsightAsDeleted = async (analysisResultId: string) => {
  return AnalysisResultService.apiAnalysisResultsIdPut(
    analysisResultId,
    // @ts-ignore
    { deleted: true },
  )
    .then((res) => {
      console.log('markInsightAsDeleted res', res)
      return true
    })
    .catch((err) => {
      console.log('markInsightAsDeleted err', err)
    })
}
