import React from 'react'
import {
  FeatureScreen,
  ScreenshotInPhone,
} from '../screenshot-in-phone/screenshot-in-phone'
import { useColorMode, useMediaQuery } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BulletList } from '../../common/copy/bullet-list'
import { BodyText } from '../../common/copy/text-body'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'

type FeatureRow = {
  featureScreenKey: FeatureScreen
  text: JSX.Element
}

type FeatureListProps = {}

export const FeatureList = ({}: FeatureListProps) => {
  // HOOKS
  const { colorMode } = useColorMode()
  const { t } = useTranslation()
  const [isSmallScreen] = useMediaQuery({
    maxWidth: 750,
  })

  const mode = colorMode || 'light'

  const featureRows: FeatureRow[] = [
    {
      featureScreenKey: 'feed',
      text: (
        <BulletList
          mt={0}
          items={[
            t(`aboutElsewherePage.features5`),
            t(`aboutElsewherePage.features6`),
            t(`aboutElsewherePage.features7`),
            t(`aboutElsewherePage.features8`),
          ]}
        />
      ),
    },
    {
      featureScreenKey: 'symbols',
      text: (
        <BulletList
          items={[
            t(`aboutElsewherePage.features1`),
            t(`aboutElsewherePage.features2`),
            t(`aboutElsewherePage.features3`),
          ]}
        />
      ),
    },
    {
      featureScreenKey: 'charts',
      text: <BodyText>{t('aboutElsewhere.whyElsewhereNow')}</BodyText>,
    },
  ]

  return (
    <Column mb={16}>
      {featureRows.map((featureRow, index) => {
        const img = (
          <ScreenshotInPhone
            imageKey={featureRow.featureScreenKey}
            colorMode={mode}
          />
        )
        const evenRow = index % 2 === 1 || isSmallScreen
        const textMarginLeft =
          evenRow && !isSmallScreen ? 16 : isSmallScreen ? 8 : 0
        const textMarginRight = !evenRow && !isSmallScreen ? 16 : 0
        const textMarginTop = isSmallScreen ? 8 : 0

        return (
          <Box
            key={index}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            mt={index === 0 ? 0 : 8}
          >
            {evenRow ? (
              <>
                {img}
                <Column
                  space={4}
                  alignItems="center"
                  ml={textMarginLeft}
                  mr={textMarginRight}
                  mt={textMarginTop}
                  style={{
                    width: 400,
                    maxWidth: '100%',
                  }}
                >
                  {featureRow.text}
                </Column>
              </>
            ) : (
              <>
                <Column
                  space={4}
                  alignItems="center"
                  ml={textMarginLeft}
                  mr={textMarginRight}
                  mt={textMarginTop}
                  style={{
                    width: 400,
                    maxWidth: '100%',
                  }}
                >
                  {featureRow.text}
                </Column>
                {img}
              </>
            )}
          </Box>
        )
      })}
    </Column>
  )
}
