import React from 'react'
import { FastTag } from '../../../../api/_openapi'
import { SymbolComponent, SymbolComponentMap } from '../../assets/symbols'
import { snakeCase } from '../../modules/strings/string-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { BLACK, CREAM, LIGHT_BLACK, WHITE } from '../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { Platform } from 'react-native'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { Center } from '../../components/common/center/center'

type TagViewImageProps = {
  tag: FastTag | null
  pageWidth: number
}

export default function TagViewImage({ tag, pageWidth }: TagViewImageProps) {
  const color = useColorModeValue(BLACK, WHITE)
  const imageBgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const isWeb = Platform.OS === 'web'
  const isSymbolType = tag?.type === 'SYMBOL'

  if (!tag) {
    return null
  }

  if (isSymbolType && SymbolComponentMap[snakeCase(tag?.name)]) {
    const SymbolComponent: SymbolComponent =
      SymbolComponentMap[snakeCase(tag?.name)]
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <SymbolComponent
          color={color}
          size={Math.min(pageWidth, WEB_MAX_WIDTH / 2) * (3 / 4)}
        />
      </Center>
    )
  } else if (tag.imageUrl) {
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <AvatarCircle
          source={{ uri: tag.imageUrl }}
          name={'dunno'}
          loading={false}
          disablePress={true}
          size={'2xl'}
        />
      </Center>
    )
  } else {
    return null
  }
}
