import React, { useState } from 'react'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { ButtonPill } from '../../../components/common/buttons/button-pill'
import { useDispatch } from 'react-redux'
import { selectUser } from '../../../ducks/user/user'
import { useAuth } from '../../../contexts/auth-context'
import { deleteUser } from '../../../ducks/user/thunks/user-thunks'
import { useSelector } from '../../../ducks/root-reducer'
import { AlertPopup } from '../../../components/common/dialog/alert-dialog'
import { useTranslation } from 'react-i18next'
import { BodyText } from '../../../components/common/copy/text-body'
import { useDrawer } from '../../../contexts/drawer-context'
import { RootStackNavigationProp } from '../../../navigation/types'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type DataDeleteNavigationProp =
  RootStackNavigationProp<'PrivateDataDelete'>

export default function DataDelete() {
  const [loading, setLoading] = useState(false)
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const dispatch = useDispatch<any>()
  const user = useSelector(selectUser)
  const { signOut } = useAuth()
  const { setDrawerVisible } = useDrawer()
  const { t } = useTranslation()

  const handlePress = async () => {
    await dispatch(deleteUser(user?.id))
    setLoading(false)
    await signOut()
    setDrawerVisible(false)
  }

  return (
    <ScrollView width="100%" height="100%">
      <PaddedContentArea>
        {[
          'dataDeletionPage.description.paragraph1',
          'dataDeletionPage.description.paragraph2',
          'dataDeletionPage.description.paragraph3',
        ].map((paragraph, index) => {
          return (
            <BodyText mt={4} key={index}>
              {t(paragraph)}
            </BodyText>
          )
        })}
        <ButtonPill
          isLoading={loading}
          mt={10}
          onPress={() => {
            setAlertDialogOpen(true)
          }}
        >
          {t('dataDeletionPage.buttons.deleteData')}
        </ButtonPill>
      </PaddedContentArea>
      {alertDialogOpen && (
        <AlertPopup
          isOpen={alertDialogOpen}
          header={t('dataDeletionPage.warningDialog.title')}
          description={t('dataDeletionPage.warningDialog.description')}
          confirmLabel={t('common.delete')}
          onConfirm={() => {
            setLoading(true)
            setAlertDialogOpen(false)
            handlePress()
          }}
          onClose={() => {
            setAlertDialogOpen(false)
          }}
        />
      )}
    </ScrollView>
  )
}
