import { addFastDream } from './dream-tag/dream-tag'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { fetchFastDreamById } from './dream-tag/functions/dream-functions'

export type QueueDispatchActionKey = 'addFastDream'

export const queueDispatchActions: Record<
  QueueDispatchActionKey,
  ActionCreatorWithPayload<any, any>
> = {
  addFastDream: addFastDream,
}

export type RefetchFunctionKey = 'fetchFastDreamById'
export type RefetchFunction = (arg1: string, arg2: string) => Promise<any>

export const refetchFunctions: Record<RefetchFunctionKey, RefetchFunction> = {
  fetchFastDreamById: (groupId: string, dreamId: string) =>
    fetchFastDreamById(groupId, dreamId, false),
}
