import WebMercatorViewport from '@math.gl/web-mercator'
import { DreamPoint } from '../../../../../api/frontend-types'
import { ViewState } from 'react-map-gl'
import { defaultViewport } from './map-config'

function toRadians(degrees: number) {
  return degrees * (Math.PI / 180)
}

export function haversineDistance(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
) {
  const R = 6371e3 // Earth radius in meters
  const φ1 = toRadians(lat1)
  const φ2 = toRadians(lat2)
  const Δφ = toRadians(lat2 - lat1)
  const Δλ = toRadians(lng2 - lng1)

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distanceInMeters = R * c
  const distanceInFeet = distanceInMeters * 3.28084 // Convert meters to feet

  return distanceInFeet
}

// Take an array of [long, lat] and return a bounding box
type BoundingBox = [
  [number, number], // [minLng, minLat]
  [number, number], // [maxLng, maxLat
]
export function getBoundingBox(points: number[][]): BoundingBox {
  const lats = points.map((point) => point[1])
  const lngs = points.map((point) => point[0])
  const latsToCompute = lats.length ? lats : [40]
  const lngsToCompute = lngs.length ? lngs : [-100]

  const minLat = Math.min(...latsToCompute)
  const maxLat = Math.max(...latsToCompute)
  const minLng = Math.min(...lngsToCompute)
  const maxLng = Math.max(...lngsToCompute)

  const bb = [
    [minLng, minLat],
    [maxLng, maxLat],
  ] as BoundingBox

  return bb
}

// For initial map view, get the lat, long, and zoom level
export function getInitialViewport(
  dreamPoints: DreamPoint[],
  width: number,
  height: number,
): ViewState {
  const minZoom = 0
  const maxZoom = 16

  const coordsArray = dreamPoints
    .map((point) => [point.longitude, point.latitude])
    .filter((x) => Boolean(x)) as [number, number][]

  const bb = getBoundingBox(coordsArray)

  let newViewport = defaultViewport
  try {
    const fitBoundsOptions = {
      padding: 20, // Don't put points right on map edge
    }
    newViewport = new WebMercatorViewport({
      width,
      height,
    }).fitBounds(bb, fitBoundsOptions)
  } catch (e) {
    console.error('Error fitting bounds', e)
  }

  let zoom
  if (newViewport.zoom < minZoom) {
    zoom = minZoom
  } else if (newViewport.zoom > maxZoom) {
    zoom = maxZoom
  } else {
    zoom = newViewport.zoom
  }

  const vp = {
    latitude: newViewport.latitude,
    longitude: newViewport.longitude,
    zoom: zoom,
    bearing: 0,
    pitch: 0,
    padding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  }

  return vp
}
