import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap, capitalize } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateInsights"
        component={Screens.InsightsMain}
        options={{
          title: capitalize(t('common.insight_plural', { count: FEW })),
        }}
      />
      <MainStack.Screen
        name="PrivateInsightList"
        component={Screens.InsightList}
        options={{
          title: capitalize(t('common.insight_plural', { count: FEW })),
          headerLeft: HeaderLeft,
        }}
      />
      <MainStack.Screen
        name="PrivateInsightView"
        component={Screens.InsightView}
        options={{
          title: cap(t('common.insight_plural', { count: 1 })),
          headerLeft: HeaderLeft,
        }}
      />
    </>
  )
}
