import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { PURGE } from 'redux-persist'
import {
  SubscriptionStatus,
  SubscriptionTierKey,
} from '../../../../api/frontend-types'

export type SubscriptionState = {
  subscriptionStatus: SubscriptionStatus
}

const initialState: SubscriptionState = {
  subscriptionStatus: {
    tier: 'free',
    status: 'active',
    creditsRemaining: 0,
    imagesRemaining: 0,
    insightsRemaining: 0,
    loading: true,
  },
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState
    })
  },
  reducers: {
    setSubscriptionStatus(state, action: PayloadAction<SubscriptionStatus>) {
      state.subscriptionStatus = action.payload
    },
    setSubscriptionStatusLoading(state, action: PayloadAction<boolean>) {
      state.subscriptionStatus.loading = action.payload
    },
  },
})

export const { setSubscriptionStatus, setSubscriptionStatusLoading } =
  subscriptionSlice.actions

// Selectors
export const selectSubscriptionStatus = (
  state: RootState,
): SubscriptionStatus => {
  return {
    ...state.subscription.subscriptionStatus,
    tier: (state.user.user?.subscriptionTier || 'free') as SubscriptionTierKey,
    creditsRemaining: state.user.user?.creditsAvailable || 0,
  }
}

export const selectSubscriptionStatusLoading = (state: RootState): boolean => {
  return state.subscription.subscriptionStatus.loading
}

export default subscriptionSlice
