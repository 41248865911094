import React from 'react'
import i18n from 'i18next'
import { useColorModeValue } from 'native-base'
import { InterfaceButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types'
import { InterfaceIconProps } from 'native-base/lib/typescript/components/primitives/Icon/types'
import {
  IconCaretLeft,
  IconCaretRight,
} from '../../../assets/react-native-svg/icons/icons-ui'
import {
  BLACK,
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
  WHITE,
} from '../../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { SansText } from '../../common/copy/text-sans'
import { SettingsLinks } from '../../../navigation/types'
import { PressableFixed } from '../../../utilities/PressableFixed'
import { Row } from '../../common/row/row'
import { Box } from '../../common/box/box'

type MenuLinkProps = InterfaceButtonProps & {
  label: string
  subLabel?: string
  icon?: React.FC<InterfaceIconProps>
  internalLink?: SettingsLinks
}

export const SettingsMenuItem = React.memo(
  ({ label, subLabel, internalLink, icon, ...rest }: MenuLinkProps) => {
    // HOOKS
    const color = useColorModeValue(BLACK, WHITE)
    const backgroundColor = useColorModeValue(WHITE, BLACK)
    const mutedColor = useColorModeValue(
      MUTED_TEXT_COLOR_LIGHT,
      MUTED_TEXT_COLOR_DARK,
    )

    // VARS
    const CaretIcon = i18n.dir() === 'rtl' ? IconCaretLeft : IconCaretRight

    return (
      <PressableFixed
        backgroundColor={backgroundColor}
        display={'flex'}
        pt={3}
        pb={3}
        pl={0}
        pr={0}
        borderRadius={0}
        borderWidth={0}
        flexDirection={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        maxWidth={WEB_MAX_WIDTH}
        {...rest}
      >
        <Row
          // This shouldn't be needed
          // 100% width is not working
          width={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Row width="90%" alignItems={'center'}>
            {icon && icon({ color: color || 'currentColor', width: '10%' })}
            <Box width="90%" flexDirection={'column'} ml={4}>
              <SansText width={'90%'}>{label}</SansText>
              {subLabel && (
                <SansText width={'90%'} fontSize={'sm'} color={mutedColor}>
                  {subLabel}
                </SansText>
              )}
            </Box>
          </Row>
          <Box>{internalLink && <CaretIcon color={color} />}</Box>
        </Row>
      </PressableFixed>
    )
  },
)
