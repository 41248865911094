import React, { useEffect } from 'react'
import { Form } from '../../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { editDreamForm } from '../../../forms/dream-form'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import { useSelector } from '../../../ducks/root-reducer'
import { updateDream } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { undecorateId } from '../../../../../api/decorate-ids'
import { formatDateTimeForDatabase } from '../../../modules/strings/string-helpers'
import { useTranslation } from 'react-i18next'
import { RootStackScreenProps } from '../../../navigation/types'
import { selectUser } from '../../../ducks/user/user'
import { FastDream, FastTag, FastTagService } from '../../../../../api/_openapi'
import { LoadingPage } from '../../../components/layout/loading-page'

export default function DreamEdit({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDreamEdit'>) {
  const { t } = useTranslation()
  const { id } = route.params
  const dream = useSelector(selectFastDreamById(id))
  const user = useSelector(selectUser)
  const dispatch = useDispatch<any>()
  const [tags, setTags] = React.useState<FastTag[] | null>(null)

  useEffect(() => {
    if (tags === null) {
      FastTagService.dreamTags(id).then((tags) => {
        setTags(tags)
      })
    }
  }, [tags])

  //   // Todo: Add validation then remove "||" checks
  const onSubmitForm = async (data: any) => {
    if (!user?.userGroupId) return

    //todo: fast - there may be an unsaved tag change?

    // TODO?
    // I think sometimes this is .id, and sometimes .image?
    let imageId = null
    const imgId = data.image?.id
    if (imgId) {
      imageId = undecorateId(imgId)
    }

    const isDraft = Boolean(data.isDraft)

    const updatedDream: FastDream = {
      ...dream,
      title: data.title || '',
      description: data.description || 'Error: No description found',
      date: data.date,
      displayDate: data.displayDate || '',
      note: data.note || '',
      updatedAt: formatDateTimeForDatabase(new Date()),
      imageId,
      isDraft,
      ...data.place,
      tags: data.tags || [],
    }

    dispatch(updateDream(user.userGroupId, id, updatedDream))
    if (!navigation?.canGoBack()) {
      if (isDraft) {
        navigation.navigate('MainTabs', {
          screen: 'MainStack',
          params: { screen: 'PrivateDraftsFeed' },
        })
      } else {
        navigation.push('MainTabs', {
          screen: 'MainStack',
          params: { screen: 'PrivateFeed' },
        })
      }
    } else {
      navigation.goBack()
    }
  }

  if (!dream || tags === null) {
    return <LoadingPage />
  }

  return (
    <Form
      name={'editDreamForm'}
      form={editDreamForm}
      onSubmit={onSubmitForm}
      prepopulateValues={{
        ...dream,
        image: {
          id: dream.imageId,
          url: dream.imageUrl,
          alt: dream.imageAlt,
        },
        tags,
      }}
      dream={dream}
      headerTitle={t('editDreamPage.header')}
      pt={0}
    />
  )
}
