import { useSelector } from '../../ducks/root-reducer'
//import { selectFriendByUsername } from '../../ducks/friends/friends'
import { useNavigationState, findFocusedRoute } from '@react-navigation/core'
import { selectPrivateGroupId } from '../../ducks/groups/groups'

const findDeepestParams = (params: any): any => {
  if (params?.params) {
    return findDeepestParams(params.params)
  }
  return params
}

// export function useActiveGroupId(): string | null {
//   const user = useSelector(selectUser)
//   const groupId = useSelector(selectGroupIdFromRoute)
//   const friendUsername = useSelector(selectFriendUsernameFromRoute)
//   const friend = useSelector(selectFriendByUsername(friendUsername))
//   return groupId || friend?.groupId || user?.userGroupId || null
// }

export function useActiveGroupId(): string | null {
  const navState = useNavigationState((state) => state)
  const route: any = findFocusedRoute(navState)
  const params = findDeepestParams(route?.params)
  const groupIdFromRoute = params?.groupId
  const privateGroupId = useSelector(selectPrivateGroupId)
  const groupId = groupIdFromRoute || privateGroupId
  //const friendUsername = route?.params?.username
  //const friend = useSelector(selectFriendByUsername(friendUsername))
  return groupId || null
}
