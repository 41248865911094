import React from 'react'
import { Image } from 'native-base'
import { IImageProps } from 'native-base/lib/typescript/components/primitives/Image/types'

export type ImageProps = IImageProps

// This is a simple wrapper around native-base's Image component
export const NbImage = ({ ...rest }: ImageProps) => {
  return <Image {...rest} />
}
