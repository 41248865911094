/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StreakFreeze } from '../models/StreakFreeze';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StreakFreezeService {
  /**
   * Retrieves the collection of StreakFreeze resources.
   * Retrieves the collection of StreakFreeze resources.
   * @param id StreakFreeze identifier
   * @returns StreakFreeze StreakFreeze collection
   * @throws ApiError
   */
  public static streakFreezes(
    id: string,
  ): CancelablePromise<Array<StreakFreeze>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/users/{id}/streak-freezes',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Creates a StreakFreeze resource.
   * Creates a StreakFreeze resource.
   * @param id StreakFreeze identifier
   * @param requestBody The new StreakFreeze resource
   * @returns StreakFreeze StreakFreeze resource created
   * @throws ApiError
   */
  public static createStreakFreeze(
    id: string,
    requestBody: Partial<StreakFreeze>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<StreakFreeze> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/users/{id}/streak-freezes',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
