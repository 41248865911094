import React from 'react'
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'
import { Column as NbColumn } from 'native-base'

export type ColumnProps = InterfaceHStackProps

// This is a simple wrapper around native-base's Column component
export const Column = ({ children, ...rest }: ColumnProps) => {
  return <NbColumn {...rest}>{children}</NbColumn>
}
