import { FastTag } from '../../../../api/_openapi'

export type TagDisplayBuckets = {
  regularTags: (FastTag & { index?: number })[]
  colorTags: FastTag[]
  symbolTags: FastTag[]
  dreamTypeTags: FastTag[]
  themeTags: FastTag[]
}

// Split an array of tags into regular tags, color tags and symbol tags
export function separateTagsForDisplay(
  tags: FastTag[] | undefined,
): TagDisplayBuckets {
  const regularTags: (FastTag & { index?: number })[] = []
  const colorTags: FastTag[] = []
  const symbolTags: FastTag[] = []
  const dreamTypeTags: FastTag[] = []
  const themeTags: FastTag[] = []

  if (!tags) {
    return {
      regularTags,
      colorTags,
      symbolTags,
      dreamTypeTags,
      themeTags,
    }
  }

  tags.forEach((tag, index) => {
    if (tag.type === 'COLOR') {
      if (colorTags.find((ct) => ct.id === tag.id)) {
        return
      } else {
        colorTags.push(tag)
      }
    } else if (tag.type === 'SYMBOL') {
      if (symbolTags.find((st) => st.id === tag.id)) {
        return
      } else {
        symbolTags.push(tag)
      }
    } else if (tag.type === 'DREAM_TYPE') {
      if (symbolTags.find((st) => st.id === tag.id)) {
        return
      } else {
        dreamTypeTags.push(tag)
      }
    } else if (tag.type === 'THEME') {
      if (themeTags.find((st) => st.id === tag.id)) {
        return
      } else {
        themeTags.push(tag)
      }
    } else {
      if (regularTags.find((rt) => rt.id === tag.id)) {
        return
      } else {
        regularTags.push(tag)
      }
    }
  })

  return {
    regularTags,
    colorTags,
    symbolTags,
    dreamTypeTags,
    themeTags,
  }
}

// Turn the name of a tag into an i18n key
export function getTagI18nKey(tag: FastTag): string {
  const { type, name } = tag
  switch (type) {
    case 'DREAM_TYPE':
      return `dreamType.${name.toLowerCase().replace(' ', '_')}`
    case 'THEME':
      return name
    default:
      return name
  }
}
