import React from 'react'
import AvatarBauhaus from './avatar-bauhaus'
import type { BoringAvatarProps } from '../types'

const variants = ['bauhaus']

function getAvatar(avatarType: string, avatarProps: BoringAvatarProps): any {
  const avatars: any = {
    bauhaus: () => <AvatarBauhaus {...avatarProps} />,
  }
  return avatars[avatarType]()
}

const Avatar = ({
  variant = 'beam',
  colors = ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'],
  name = 'Sacagawea',
  square = false,
  size = 40,
  ...props
}) => {
  const avatarProps = { colors, name, size, square, ...props }
  const checkedVariant = () => {
    if (variants.includes(variant)) {
      return variant
    }
    return 'sunset'
  }

  const avatarComponent = getAvatar(checkedVariant(), avatarProps)

  return avatarComponent
}

export default Avatar
