import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useColorModeValue } from 'native-base'
import { SansText } from '../../common/copy/text-sans'
import { LINE_WIDTH } from '../../../constants/constants'
import { useDispatch } from 'react-redux'
import { setIsOffline } from '../../../ducks/ui/ui'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { useIsConnected } from '../../../hooks/useIsConnected'
import { Platform } from 'react-native'
import { Box } from '../../common/box/box'
import { Row } from '../../common/row/row'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'

type OfflineBarProps = {
  width?: number
  hasBorderTop?: boolean
}

export const OfflineBar = ({ width, hasBorderTop = true }: OfflineBarProps) => {
  // HOOKS
  const { t } = useTranslation()
  const bg = useColorModeValue(CREAM, LIGHT_BLACK)
  const textColor = useColorModeValue(BLACK, WHITE)
  const dispatch = useDispatch<any>()
  const isConnected = useIsConnected()

  // VARS
  const offlineLabel = t('common.offline')
  const isWeb = Platform.OS === 'web'

  // EFFECTS
  // Update the store with the offline status
  useEffect(() => {
    if (isConnected !== null) {
      dispatch(setIsOffline(!isConnected))
    }
  }, [isConnected])

  if (isConnected !== null && !isConnected) {
    return (
      <Box
        bg={bg}
        w={width || '100%'}
        p={isWeb ? 3 : 2}
        alignItems="center"
        borderColor={textColor}
        borderBottomWidth={LINE_WIDTH}
        borderTopWidth={hasBorderTop ? LINE_WIDTH : 0}
      >
        <Row justifyContent={'center'} alignItems={'center'}>
          <SansText mr={2}>{offlineLabel}</SansText>
          <ElsewhereIcon iconKey="offline" size="sm" />
        </Row>
      </Box>
    )
  } else {
    return <></>
  }
}
