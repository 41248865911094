import React from 'react'
import { ColorTag } from './tag-color'
import { FastTag } from '../../../../../api/_openapi'
import { Row } from '../../common/row/row'

type ColorTagAreaProps = {
  tags: FastTag[]
  editable: boolean
  onDeleteTag?: (tag: FastTag) => void
}

export const ColorTagArea = React.memo(
  ({ tags, editable, onDeleteTag }: ColorTagAreaProps) => {
    return (
      <Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
        {tags.map((tag) => {
          return (
            <ColorTag
              tag={tag}
              key={`color-tag-${tag.id}`}
              onDeleteTag={() => {
                onDeleteTag && onDeleteTag(tag)
              }}
              tagState={editable ? 'editable' : 'passive'}
            />
          )
        })}
      </Row>
    )
  },
)
