import React from 'react'
import { useMediaQuery, useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { BLACK, WHITE, moonTransparentImg } from '../../constants/ui-constants'
import {
  HOMEPAGE_MAX_CONTENT_WIDTH,
  LINE_WIDTH,
} from '../../constants/constants'
import { SansText } from '../../components/common/copy/text-sans'
import Autolink from 'react-native-autolink'
import { RootStackScreenProps } from '../../navigation/types'
import { useLinkProps } from '../../utilities/useLinkPropsFixed'
import { FeatureList } from '../../components/composite/feature-list/feature-list'
import { ElsewhereIcon } from '../../components/common/elsewhere-icon/elsewhere-icon'
import { HomepageFooter } from './homepage-footer'
import { Img } from '../../components/common/image/image'
import { BodyText } from '../../components/common/copy/text-body'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { AppLinks } from './app-links'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Row } from '../../components/common/row/row'
// @ts-ignore
import { Helmet } from 'react-helmet'
import { InterpLandingLinks } from './interp-landing-links'
import i18n from '../../i18n/i18nnext'
import { Column } from '../../components/common/column/column'
import { Center } from '../../components/common/center/center'
import { ScrollView } from '../../components/common/scrollview/scrollview'

export default function Home({
  navigation,
  route,
}: RootStackScreenProps<'Home'>) {
  const [isSmallScreen] = useMediaQuery({
    maxWidth: 750,
  })

  // HOOKS
  const { t } = useTranslation()
  const color = useColorModeValue(BLACK, WHITE)
  const invertedColor = useColorModeValue(WHITE, BLACK)

  const { onPress, ...rest } = useLinkProps({
    to: { screen: 'Login' },
  })

  const lang = i18n.resolvedLanguage || 'en'

  const animatedYOffset = useSharedValue<number>(10)
  const animatedXOffset = useSharedValue<number>(-10)
  const animatedScale = useSharedValue<number>(0.9)
  const animatedOpacity = useSharedValue<number>(0.5)

  const moonOffsetY = 0
  const moonOffsetX = 0
  const duration = 1500

  React.useEffect(() => {
    animatedYOffset.value = withTiming(moonOffsetY, {
      duration: duration,
    })
    animatedXOffset.value = withTiming(moonOffsetX, {
      duration: duration,
    })
    animatedScale.value = withTiming(1, {
      duration: duration,
    })
    animatedOpacity.value = withTiming(1, {
      duration: duration,
    })
  }, [])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: animatedYOffset.value,
        },
        {
          translateX: animatedXOffset.value,
        },
        {
          scale: animatedScale.value,
        },
      ],
      opacity: animatedOpacity.value,
    }
  })

  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }}>
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type" : "WebSite",
              "name": "${t('common.siteName')}",
              "description": "${t('common.aPlaceForYourDreams')}",
              "url": "${window.location.origin}"
          }`}
        </script>
        <title>{`${t('common.siteName')}`}</title>
        <meta name="description" content={t('common.aPlaceForYourDreams')} />
        <meta property="og:title" content={`${t('common.siteName')}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={`${t('common.aPlaceForYourDreams')}`}
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/images/elsewhere-og-image.jpg`}
        />
        <meta property="og:url" content={`${window.location.origin}`} />
      </Helmet>
      {/* HEADER */}
      <Row
        pt={4}
        pb={4}
        pl={5}
        pr={5}
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottomColor={color}
        borderBottomWidth={LINE_WIDTH}
      >
        <Row alignItems={'center'}>
          <ElsewhereIcon iconKey="elsewhere" size={'md'} />
          <SansText
            paddingLeft={4}
            paddingRight={4}
            fontSize={20}
            paddingTop={1}
            textTransform={'uppercase'}
            language="en"
          >
            Elsewhere
          </SansText>
        </Row>
        <Row alignItems={'center'}>
          <SansText mr={4} ml={4}>
            <Autolink
              text={t('common.login')}
              linkStyle={{ textDecorationLine: 'underline' }}
              matchers={[
                {
                  pattern: RegExp(t('common.login'), 'g'),
                  onPress: () => onPress(),
                },
              ]}
              linkProps={{
                style: {
                  textDecorationLine: 'underline',
                  color,
                },
                onPress,
                ...rest,
              }}
            />
          </SansText>
          <ButtonPill
            type={'primary'}
            onPress={() => {
              navigation.navigate('Signup')
            }}
            buttonSize={'sm'}
            width={'fit-content'}
          >
            {t('common.signUp')}
          </ButtonPill>
        </Row>
      </Row>
      {/* BODY */}
      <ScrollView>
        <Row justifyContent={'center'} p={4} backgroundColor={invertedColor}>
          <Column
            w={HOMEPAGE_MAX_CONTENT_WIDTH}
            maxW={'100%'}
            alignItems={'center'}
          >
            {/* HEADER SECTION */}
            <Row
              w={'100%'}
              flexDirection={isSmallScreen ? 'column' : 'row'}
              mt={10}
              mb={10}
              minHeight={300}
            >
              <Row
                w={isSmallScreen ? '100%' : 250}
                minHeight={300}
                maxW={'100%'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Animated.View style={[animatedStyle]}>
                  <Img
                    width={150}
                    height={150}
                    source={moonTransparentImg}
                    alt="rising moon"
                  />
                </Animated.View>
              </Row>

              <Column
                w={isSmallScreen ? '100%' : 500}
                minHeight={300}
                alignItems={'center'}
                justifyContent={'center'}
                paddingLeft={5}
                paddingRight={5}
                paddingTop={5}
                paddingBottom={5}
              >
                <Row h={10} alignItems={'center'}>
                  <HeadingMain>{t('common.aPlaceForYourDreams')}</HeadingMain>
                </Row>
                <BodyText mt={5} mb={10} textAlign={'center'}>
                  {t('homePage.intro')}
                </BodyText>
                <Row h={10} alignItems={'center'}>
                  <AppLinks />
                </Row>
              </Column>
            </Row>
            <InterpLandingLinks />
            <PaddedContentArea mt={20}>
              <FeatureList />
            </PaddedContentArea>
            <Center mt={5} mb={20}>
              <AppLinks />
            </Center>
          </Column>
        </Row>
        <HomepageFooter isSmallScreen={isSmallScreen} color={color} />
      </ScrollView>
    </>
  )
}

export { Home }
