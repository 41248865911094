import React, { useEffect, useState } from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { View } from 'react-native'
import { FastTag, FastTagLocationService } from '../../../../api/_openapi'
import { BodyText } from '../../components/common/copy/text-body'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { UserTagDescription } from './user-tag-description'
import { SymbolComponent } from '../../assets/symbols'
import { TagViewNavigationProp } from '.'
import DreamMap from '../Dreams/DreamMap/DreamMap'
import { DreamPoint } from '../../../../api/frontend-types'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import { useTranslation } from 'react-i18next'
import TagViewImage from './tag-view-image'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Text } from '../../components/common/copy/text'

export type TagPageHeaderNavigationProp = TagViewNavigationProp

type TagPageHeaderProps = {
  title: string
  fullTag: FastTag | null
  tagSummary: string
  pageWidth: number
  SymbolComponent?: SymbolComponent
  userCanSeeMap?: boolean
}

export const TagPageHeader = ({
  title,
  fullTag,
  tagSummary,
  pageWidth,
  userCanSeeMap,
}: TagPageHeaderProps) => {
  const backgroundColor = useColorModeValue(WHITE, BLACK)

  const user = useSelector(selectUser)
  const { t } = useTranslation()

  const [dreamPoints, setDreamPoints] = useState<DreamPoint[] | null>(null)
  useEffect(() => {
    if (fullTag?.type === 'SETTING' && dreamPoints === null && user) {
      FastTagLocationService.tagLocations(fullTag.id)
        .then((results) => {
          setDreamPoints(results)
        })
        .catch((error) => {
          console.error('Error with apiTagLocationsGetCollection', error)
        })
    }
  }, [fullTag, user])

  // Find color tags in text and replace them
  // Bit of a hack, but it works
  // We're looking for strings that look like this:
  // specificcolor (generalcolor) #hexcode
  // e.g. red (red) #ff0000
  // We want to replace them with a colored box
  // We're using a regex to find them
  // The regex is:
  // \w+ \(\w+\) #[0-9a-fA-F]{6}

  // First, split on the regex
  const splitTagSummary = tagSummary.split(/(\w+ \(\w+\) #[0-9a-fA-F]{6})/)

  // Then map over the array
  // If the item matches the regex, replace it with a colored box
  // Otherwise, return the item
  const coloredTagSummary = splitTagSummary.map((item, index) => {
    const regex = /\w+ \(\w+\) #[0-9a-fA-F]{6}/
    const match = item.match(regex)
    if (match) {
      const [colorTag] = match
      const [_name, _type, colorHex] = colorTag.split(' ')
      return (
        <Text key={index}>
          {/* Blank space */}
          <Text> </Text>
          <Text
            style={{
              backgroundColor: '#eee',
              borderRadius: 4,
              alignSelf: 'flex-start',
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'black',
              height: 16,
              fontSize: 12,
            }}
            color={colorHex}
            borderRadius={'full'}
          >
            &nbsp;▇&nbsp;
          </Text>
          {/* Blank space */}
          <Text> </Text>
        </Text>
      )
    } else {
      return <Text key={index}>{item}</Text>
    }
  })

  return (
    <View
      pointerEvents="box-none"
      style={{
        backgroundColor,
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        alignItems: 'center',
      }}
    >
      <TagViewImage tag={fullTag} pageWidth={pageWidth} />
      <PaddedContentArea mb={4} pointerEvents="none">
        <HeadingMain mt={4} mb={4}>
          {title}
        </HeadingMain>
        <BodyText textAlign={tagSummary.length < 50 ? 'center' : 'left'}>
          {coloredTagSummary}
        </BodyText>
      </PaddedContentArea>
      <UserTagDescription tag={fullTag} tagName={title} />
      {userCanSeeMap && fullTag?.type === 'SETTING' && (
        <PaddedContentAreaConditional mb={4} webPadding={0}>
          <HeadingMain mt={8} mb={4}>
            {t('common.map')}
          </HeadingMain>
          <DreamMap
            dream={null}
            mode={'tag'}
            tag={fullTag}
            dreamPoints={dreamPoints || []}
            allowAdding={true}
            setDreamPoints={setDreamPoints}
            useCurrentLocation={true}
          />
        </PaddedContentAreaConditional>
      )}
    </View>
  )
}
