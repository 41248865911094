import { FastDream } from '../../../../api/_openapi'
import { useSelector } from '../../ducks/root-reducer'
import { useActiveGroupId } from '../useActiveGroupId'
import { selectUser } from '../../ducks/user/user'
import {
  selectDreamsAreLoading,
  selectFastDreamById,
  selectGroupDreamById,
  selectGroupDreamsLoading,
} from '../../ducks/dream-tag/dream-tag'

export type UseDream = {
  loading: boolean
  dream: FastDream | null
  activeGroupId: string | null
}

export function useDream(dreamId: string): UseDream {
  const user = useSelector(selectUser)
  const activeGroupId = useActiveGroupId()

  const userDream = useSelector(selectFastDreamById(dreamId))
  const userDreamsLoading = useSelector(selectDreamsAreLoading)

  const groupDream = useSelector(selectGroupDreamById(dreamId))
  const groupDreamsLoading = useSelector(selectGroupDreamsLoading)

  const returnGroupDream = activeGroupId && activeGroupId !== user?.userGroupId
  return returnGroupDream
    ? {
        loading: groupDreamsLoading,
        dream: groupDream || null,
        activeGroupId,
      }
    : {
        loading: userDreamsLoading,
        dream: userDream || null,
        activeGroupId,
      }
}
